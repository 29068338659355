.ooah-pick {
    overscroll-behavior-y: contain;
    background-color: $color-gray-01;
    section {
        background-color: $color-gray-14;
    }
    .l-grid {
        margin-top: 0;
    }
    .c-starrate + .c-card-item__benefit {
        margin-top: 10px;
    }
}
