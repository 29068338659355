[data-type="GridScroll_ImgText_CircleBox_Large"] {
    .c-listimg {
        display: flex;
        flex-wrap: wrap;
        width: (76 * 10) + 36px; // 아이템 1개 width 76 + 양쪽 여백 18
        margin: 0 auto;
        padding: 16px 18px 0;
        box-sizing: border-box;
        &__item {
            display: table-cell;
            vertical-align: top;
            width: auto;
            padding: 0 6px;
        }
        &__link {
            display: block;
            width: 64px;
            margin: 0 auto;
            border: 0;
            text-align: center;
        }
        &__name {
            overflow: hidden;
            height: 16px;
            margin-top: 6px;
        }
        &__icon-wrap {
            overflow: hidden;
            border-radius: 50%;
        }
    }

    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
    //skpui css 초기화
    .c-listimg__item {
        .c-listimg__link {
            border: 0;
            .c-listimg__icon-wrap {
                @include size(64px);
                .c-listimg__icon {
                    @include size(64px);
                }
            }
            .c-listimg__name {
                white-space: normal;
            }
        }
    }
}
