[data-type="GridList_Narrow"] {
    .c-lazyload {
        width: 100%;
    }
    .c-category {
        position: relative;
        width: 70px;
        margin: 0 auto;

        &__badge {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;

            img {
                display: block;
                height: 16px;
            }
        }
        &__image {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            margin: 0 auto 6px;
            background-color: $color-gray-12;
            border-radius: 50%;

            &--small .c-lazyload {
                width: 36px;
                height: 36px;
                padding-bottom: 0;
            }
        }
        &__text {
            overflow: hidden;
            height: 26px;
            display: block;
            color: $color-gray-02;
            font-size: 12px;
            line-height: 13px;
            text-align: center;
            word-break: keep-all;
        }
    }
    .c-gridlist {
        overflow: hidden;
        container-type: inline-size;
        container-name: gridListNarrow;

        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;
        }
        &__item {
            width: 33.33%;
            padding-bottom: 12px;

            @container gridListNarrow (min-width: 320px) and (max-width: 449px) {
                width: 25%;
            }
            @container gridListNarrow (min-width: 450px) and (max-width: 767px) {
                width: 20%;
            }
            @container gridListNarrow (min-width: 768px) {
                width: 12.5%;
            }
        }
        &__link {
            display: block;
        }

        @supports (display: grid) {
            &__list {
                display: grid;
                grid-template-columns: auto auto auto;
                justify-content: space-between;

                @container gridListNarrow (min-width: 320px) and (max-width: 449px) {
                    grid-template-columns: repeat(4, auto);
                }
                @container gridListNarrow (min-width: 450px) and (max-width: 767px) {
                    grid-template-columns: repeat(5, auto);
                }
                @container gridListNarrow (min-width: 768px) {
                    grid-template-columns: repeat(8, auto);
                }
            }
            &__item {
                width: auto;
            }
        }
    }

    .c-skeleton {
        &__box,
        &__bar {
            background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
            animation: none;
        }
        &__box {
            width: 50px;
            height: 50px;
            margin: 0 auto 6px;
            padding: 0;
            border-radius: 50%;
        }
        &__bar {
            width: 42px;
            height: 14px;
            margin: 0 auto;
            border-radius: 4px;
        }
    }
}
