[data-type="Contents_Mart_Column"] {
    .c-time-panel {
        padding: 16px;

        &__head {
            margin-bottom: 10px;
            color: $color-gray-02;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
        }
        &__list {
            margin: 0 -4px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        &__item {
            float: left;
            width: 33.33%;
            padding: 4px;
        }
        &__box {
            overflow: hidden;
            // display: block;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 84px;
            padding-top: 6px;
            border: 0;
            background-color: $color-gray-14;
            border-radius: 4px;
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.28), 0 2px 6px rgba(0, 0, 0, 0.06);
        }
        &__info {
            display: block;
            width: 100%;
            padding: 0 10px;
        }
        &__logo {
            display: block;
            width: auto;
            height: 22px;
        }
        &__name {
            overflow: hidden;
            display: block;
            width: 100%;
            height: 17px;
            color: $color-gray-02;
            font-size: 13px;
            line-height: 17px;
        }
        &__inner {
            position: relative;
            width: 100%;
            height: 31px;
            margin-top: 8px;
            padding: 8px 10px 0;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                width: 100%;
                height: 1px;
                background-color: var(--martPointColor);
                opacity: 0.06;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                background-color: var(--martPointColor);
                opacity: 0.06;
            }
        }
        &__time {
            overflow: hidden;
            position: relative;
            z-index: 20;
            display: block;
            height: 17px;
            color: var(--martPointColor);
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
        }
    }
}
