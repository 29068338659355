[data-type="ProductScroll_Roadshop_ShopName"] {
    @import "../../roadshop/block/b-card--roadshop";

    padding-bottom: 10px;
    .b-card--roadshop {
        @include b-card--roadshop;
        padding: 0 4px;
        width: 144px;
        .c-like-button--product {
            right: 4px;
            top: -55px;
        }
        .c-relates {
            .c-relates__button-link {
                &:after {
                    display: inline-block;
                    position: relative;
                    right: 0;
                }
                .c-relates__emphasis {
                    padding-right: 8px;
                }
            }
        }
    }
    .s-list {
        li {
            position: relative;
            padding: 0;
        }
    }
    .c-like-button--product {
        @include c-like-button--44;
        position: absolute;
        right: 0;
        bottom: 90px;
    }
}
