[data-type="Cap_Title_Reco"] {
    * {
        font-family: $font-family3;
    }

    .c-headline {
        &--right .c-headline__title {
            text-align: right;
        }

        &__text {
            & + .c-headline__info {
                margin-top: 3px;
            }
        }

        &__title {
            height: auto;
            padding: 0 16px;
            line-height: 22px;
        }

        &__info {
            display: flex;
            align-items: center;
            position: static;
            padding: 0 16px;
            transform: initial;
        }

        .c-button-info {
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            padding: 3px 0;
            background: initial;

            &:after {
                display: inline-block;
                flex: 0 0 auto;
                width: 12px;
                height: 12px;
                margin-left: 4px;
                background: url("/MW/img/svg/ic-info.svg") no-repeat;
                vertical-align: top;
                content: "";
            }

            &--hidden {
                &:after {
                    content: none;
                }
            }
        }

        &__logo {
            flex: 0 0 auto;
            height: 14px;
        }

        &__subtext {
            flex: 0 0 auto;
            margin-top: 0;
            padding: 0;
            font-size: 12px;
            font-weight: normal;
            color: $color-gray-04;
            line-height: 14px;
            white-space: initial;
        }

        &__subinfo {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            margin-left: auto;
        }

        &__subinfo-text {
            flex: 0 0 auto;
            font-size: 12px;
            color: $color-gray-06;
            line-height: 14px;

            & + .c-headline__reload {
                margin-left: 6px;
            }
        }

        &__reload {
            border: none;
            background-color: transparent;
            color: transparent;
            @include sprite-retina($sp_icons_reloading_point_small);
        }
    }
}
