[data-type="PageTitle_Close"] {
    z-index: 10;
    position: fixed;
    width: 100%;

    .c-headline {
        border-bottom: 1px solid $color-gray-10;

        &__title {
            height: 56px;
            font-size: 19px;
            color: $color-gray-01;
            letter-spacing: 0;
        }
    }

    & + .l-grid__row {
        margin-top: 57px;
    }
}
