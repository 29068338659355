[data-type="Tabs_NewRoundBtn_Fill_Sticky"] {
    .l-grid__col--12 {
        position: relative;
    }

    .c-magnet--fixed & {
        width: 100%;
        z-index: 1;
    }

    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 100;
        }
        &--absolute {
            position: absolute;
        }
    }

    // skpui.magnet 기능 사용시
    .c-magnet--fixed &.is--active .w-magnet {
        width: 100%;
    }

    .c-nav {
        border-bottom: 0;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $color-gray-12;
        }

        &__wrap {
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            margin-right: 0;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__list {
            width: auto;
            padding: 13px;
            padding-top: 12px;
        }
        &__line {
            display: block;
            width: 100%;
            height: 45px;
            padding: 0 8px;
            border: 0;
            border-bottom: 2px solid transparent;
            background-color: transparent;
            color: $color-gray-02;
            font-size: 15px;
            line-height: 1;
            box-sizing: border-box;

            &[aria-selected="true"] {
                border-color: $color-11st-red;
                color: $color-11st-red;
            }
        }
        &__chip {
            height: 32px;
            margin: 0 3px;
            padding: 0 12px;
            border: 1px solid $color-gray-10;
            color: $color-gray-03;
            font-size: 14px;
            border-radius: 32px;
            background-color: transparent;
            white-space: nowrap;

            &[aria-selected="true"] {
                border-color: transparent;
                color: $color-gray-14;
                font-weight: 700;
                background-color: $color-11st-red;
            }
        }

        &--depth .c-nav__list {
            padding: 0 8px;
        }
    }
}
