@import "../../svg/sp_live11";

[data-type="Contents_Profile"] {
    padding: 18px 16px 8px;

    .c-chn-profile {
        &__box {
            display: flex;
            align-items: center;
            margin-top: -2px;
        }
        &__thumb {
            flex-shrink: 0;
            position: relative;
            border-radius: 50%;
            width: 72px;
            height: 72px;
            vertical-align: top;
            .img {
                width: 100%;
                height: 100%;
                border: 1px solid rgba($color-gray-01, 0.04);
                border-radius: 50%;
                object-fit: cover;
                vertical-align: top;
            }
        }
        &__link {
            display: block;
            height: 100%;
        }
        &__info {
            flex: 1;
            overflow: hidden;
            padding-left: 12px;
        }
        &__link-text {
            display: inline-flex;
            align-items: center;
            max-width: 100%;
            color: $color-gray-03;
            vertical-align: top;
            &:after {
                @include ico-arrow-right(7.8px, currentColor);
                display: block;
                flex-shrink: 0;
                margin: -2px 1px 0 5px;
            }
        }
        &__name {
            @include text-ellipsis;
            display: block;
            font-weight: 700;
            font-size: 19px;
            color: $color-gray-03;
            line-height: 24px;
        }
        &__meta {
            display: flex;
            margin-top: 7px;
            font-size: 13px;
            color: $color-gray-04;
            line-height: 16px;
            dt,
            dd {
                display: block;
                flex-shrink: 0;
            }
            dt {
                &:not(:first-of-type) {
                    position: relative;
                    margin: 0 2px 0 10px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -6px;
                        margin-top: -2px;
                        width: 2px;
                        height: 2px;
                        border-radius: 50%;
                        background-color: $color-gray-10;
                    }
                }
            }
            dd {
                font-weight: 700;
            }
        }
        &__desc {
            @include text-ellipsis-multiple(2);
            margin: 12px 0 -4px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 20px;
            text-align: left;
        }

        &--live {
            .c-chn-profile__thumb {
                padding: 2px;
                border: 2px solid $color-11st-red;
            }
            .live {
                position: absolute;
                left: 50%;
                bottom: -6px;
                z-index: 1;
                transform: translateX(-50%);
                width: 35px;
                height: 18px;
                i {
                    display: block; // 로띠 영역 대응
                }
            }
            .live-img {
                position: absolute;
                left: 50%;
                bottom: -6px;
                z-index: 1;
                transform: translateX(-50%);
                width: 35px;
                height: 18px;
                background: url(/MW/img/pui/ico_live_round.png) no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .c-chn-action {
        display: flex;
        margin: 16px 0 8px;

        &__btn-share,
        &__btn-chat {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border: 1px solid $color-gray-10;
            border-radius: 20px;
            background: transparent;
            &:before {
                content: "";
                @include get-sprite-live11;
            }

            span {
                @include sr-only;
            }

            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        &__btn-alarm {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 auto;
            height: 40px;
            border: 1px solid $color-11st-red;
            border-radius: 20px;
            background: transparent;
            font-size: 14px;
            color: $color-11st-red;
            &:before {
                content: "";
                @include get-sprite-live11("ic-alarm", 24);
                margin: 0 4px 0 -2px;
            }
            > span {
                margin-top: 1px;
            }
            &--active {
                border-color: $color-gray-10;
                color: $color-gray-04;
                &:before {
                    @include get-sprite-position-live11("ic-alarm-off", 24);
                }
            }
        }

        &__btn-chat {
            &:before {
                @include get-sprite-position-live11("ic-chat", 24);
            }
        }
        &__btn-share {
            &:before {
                @include get-sprite-position-live11("ic-share", 24);
            }
        }
    }
}

// 채널 프로필 내 예고 없는 경우 여백 조절
#carrier_8270 {
    & + #carrier_8273 {
        .l-grid__gap {
            padding-top: 8px;
        }
    }
}
