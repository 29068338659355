@import "../../roadshop/sprites/sp_icon";
[data-type="CaptionCarrier2"] {
    .c-headline {
        padding: 26px 0 14px;
        .c-headline__link {
            text-align: center;
            .c-headline__title {
                display: inline-block;
                position: relative;
                width: auto;
                height: initial;
                padding: 0 30px 0 0;
                line-height: 24px;
                font-size: 19px;
                font-weight: 600;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    content: '';
                    @include sprite-retina($sp_icon_ic_arrow_bl);
                }
            }
            .c-headline__subtext {
                margin-top: 5px;
                padding: 0;
            }
            .c-headline__txtlink {
                display: none;
            }
            &:after {
                display: none;
            }
        }
        .c-headline__text {
            text-align: center;
            .c-headline__title {
                display: inline-block;
                position: relative;
                width: auto;
                height: initial;
                padding: 0;
                line-height: 24px;
                font-size: 19px;
                font-weight: 600;
            }
            .c-headline__subtext {
                margin-top: 5px;
                padding: 0;
            }
            &:after {
                display: none;
            }
        }
    }
}
