@import "../../common/partial/icon";

[data-type="ContentsRowList_StoreRanking"] {
    padding: 0 28px 16px 16px;

    .l-grid__col {
        width: 100%;

        &.l-grid__col--12 {
            padding-right: 0 !important;
        }
    }

    .c-rank-item {
        display: block;
        position: relative;
        height: 60px;
        padding-left: 40px;

        &__no {
            @include size(34px 30px);
            display: block;
            position: absolute;
            left: 0;
            top: 14px;
            font-size: 26px;
            text-align: center;
            color: #000;
        }

        &__link {
            display: block;
            height: 59px;
            border-bottom: 1px solid #e4e4e4;
            padding-right: 30px;
            .c-rank-item__thumb {
                @include size(44px);
                display: block;
                float: left;
                margin-top: 7px;
                margin-right: 10px;
                img {
                    @include size(44px);
                    display: block;
                }
            }
            .c-rank-item__shopinfo {
                display: table-cell;
                height: 44px;
                padding-top: 7px;
                width: auto;
                .c-rank-item__shopname {
                    display: block;
                    padding-top: 3px;
                    font-size: 15px;
                    color: #19b275;
                    overflow: hidden;
                    height: 22px;
                }
                .c-rank-item__area,
                .c-rank-item__tag {
                    font-size: 13px;
                    color: #878787;
                    @include mq-320 {
                        font-size: 11px;
                    }
                    @include mq-360 {
                        font-size: 13px;
                    }
                }
                .c-rank-item__area {
                    position: relative;
                    display: inline-block;
                    padding-right: 12px;
                    &:after {
                        @include size(1px 11px);
                        content: "";
                        position: absolute;
                        top: 2px;
                        right: 5px;
                        background: #ececec;
                    }
                }
            }
        }
    }
    & li:last-child {
        .c-rank-item__link {
            border-bottom: 0;
        }
    }
    .c-like-button--store {
        @include c-store-like-button;
        position: absolute;
        z-index: 30;
        top: 50%;
        right: -6px;
        margin-top: -18px;
        border: 6px solid #fff;
        box-sizing: content-box;
    }
}
