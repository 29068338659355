[data-type="ProductGrid_RoadshopImg_Col3_Tag"] {
    .w-swiper {
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 12px;
        .c-nav {
            margin-left: 0;
            border-bottom: 0;
        }
        .c-nav__wrap {
            display: flex;
            margin-right: 0;
            .c-nav__item {
                a {
                    padding: 0 8px;
                    font-weight: normal;
                }
                &.on a {
                    padding: 0;
                    margin: 0 8px;
                    color: #38d3b0;
                    &:after {
                        background-color: #38d3b0;
                        bottom: 4px;
                    }
                }
            }
        }
    }
    .c-listimg {
        padding: 0 12px 12px 16px;
        display: table;
        &__item {
            width: 33.33333%;
            display: table-cell;
            padding: 0 4px 4px 0;
            .c-listimg__link {
                height: 100%;
                &:after {
                    display: none;
                }
                img {
                    width: auto;
                    height: 100%;
                    max-width: initial;
                    max-height: initial;
                    left: -100%;
                    right: -100%;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                .c-listimg__more {
                    z-index: 1;
                    &-text {
                        font-size: 12px;
                        .c-listimg__qty {
                            position: relative;
                            width: auto;
                            margin: 0 auto;
                            font-size: 25px;
                            line-height: 40px;
                            text-align: center;
                            @include mq-320 {
                                font-size: 22px;
                            }
                            @include mq-360 {
                                font-size: 25px;
                            }
                            &:before {
                                display: inline-block;
                                position: relative;
                                top: 40%;
                                left: -3px;
                                margin-top: -6px;
                                font-weight: 100;
                                content: '+';
                            }
                        }
                    }
                }
            }
            &:nth-child(3n+1) {
                clear: both;
            }
            &:nth-child(4n+1) {
                clear: none;
            }
            &:nth-child(4n) {
                .c-listimg__link:after {
                    border-right-width: 4px;
                }
            }
        }
    }
}
