[data-type="Cap_Luxury_BrandTitle"] {
    .c-n-headline {
        padding: 24px 72px 24px 16px;

        &__title {
            font-size: 22px;
            font-family: $font-outfit;
            font-weight: 500;
            line-height: 28px;
        }
        &__desc {
            margin-top: 4px;
            font-size: 13px;
            line-height: 16px;
        }
    }
    .c-n-like {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
    }
}
