[data-type="Tabs_Luxury_RoundBtn_Scroll"] {
    .w-scroll-css {
        background: $color-gray-14;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .w-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .s-list {
        padding-top: 0;
        padding-bottom: 0;

        li {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
