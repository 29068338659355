@import "../../svg/sp_live11";

[data-type="RoundButton_Center_Live11"] {
    background: transparent;

    .c-button-live {
        display: inline-flex;
        align-items: center;
        position: fixed;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        height: 38px;
        padding: 0 12px;
        border-radius: 19px;
        background-image: linear-gradient(to right, rgba(#f00, 0.8), rgba(#ff10c4, 0.8));
        font-size: 14px;
        color: $color-gray-14;

        .live {
            display: block;
            margin: 0 3px 2px 0;
            width: 30px;
            height: 18px;
        }

        .live-img {
            display: block;
            width: 30px;
            height: 18px;
            margin: 0 3px 2px 0;
            background: url(/MW/img/pui/ico_live_text.png) no-repeat;
            background-size: 100% 100%;
        }

        &--right {
            &:after {
                content: "";
                @include get-sprite-live11("ic-chevron-right", 8);
                margin: 0 0 1px 2px;
            }
        }
    }
}
