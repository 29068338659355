.store_coupon {
    background: $color-gray-11;
    #cts,
    .main {
        position: relative;
        margin: 0 0 10px;
    }
    [data-type="Contents_Tag_Filter"] {
        border-top: 0;
        .c-filter-normal {
            padding: 0 16px 8px;
        }
        .c-filter--fixed {
            position: fixed;
            top: 57px;
            left: 0;
            width: 100%;
            z-index: 40;
            padding: 16px 0 8px;
            border-bottom: 1px solid $color-gray-11;
            .w-scroll--css {
                .s-list {
                    display: flex;
                    overflow-x: scroll;
                    padding: 0 0 0 16px;
                    font-size: 0;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    scroll-behavior: smooth; // 부드러운 스크롤
                    text-align: start;
                }
            }
        }
        // snap scroll
        .w-scroll--css {
            .s-list {
                display: block;
                padding: 0;
                overflow-x: auto;
                white-space: normal;
                text-align: center;
            }
        }
        .c-chip {
            span {
                margin-left: 3px;
                color: $color-11st-red;
            }
            &--selected {
                background: $color-11st-red;
                border-color: $color-11st-red;
                color: $color-gray-14;
                span {
                    color: $color-gray-14;
                }
            }
        }
    }
    [data-type="Cap_List_Sorting"] {
        height: 52px;
        .l-grid__col {
            &:after {
                display: none;
            }
        }
        .c-list__sorting {
            button {
                padding: 0 36px 0 10px;
                font-size: 14px;
                color: $color-gray-03;
                &:after {
                    @include sprite-retina($sp_sorting_arrow);
                    margin: -12px 0 0;
                    border: 0;
                    transform: rotate(0);
                }
            }
        }
    }
    [data-type="ProductGrid_Standard"] {
        margin: -6px;
        padding: 0 16px 24px;
        .l-grid__col {
            margin: 0;
            border: 0;
        }
        .c-flex {
            &__item {
                margin: 6px 0;
                padding: 0;
                &:before,
                &:after {
                    display: none;
                }
            }
        }
        .c-card-item {
            @include rounding-box;
            height: 100%;
            margin: 0 6px;
            &__info {
                padding: 12px;
            }
            &__price {
                &-info {
                    margin: 10px 0 0;
                }
                &-del {
                    color: $color-gray-06;
                }
            }
        }
    }
    [data-type="FlatButton_Arrow"] {
        .c-relates {
            background: $color-gray-13;
            border-top: 0;
            &__link {
                background: none;
            }
        }
    }
    [data-type="Contents_SubTitle_Notice"] {
        .c-headline__title {
            color: $color-gray-03;
        }
    }
    .b-layer__modal {
        &.view-coupon {
            box-shadow: 0 -1px 2px 1px rgba(0, 0, 0, 0.15);
            * {
                box-sizing: border-box;
            }
            .b-layer__modal-content {
                padding: 0;
                max-height: 100vh;
                background: $color-gray-11;
                .l-grid {
                    &:first-of-type {
                        margin-top: 0;
                    }
                }
            }
            .b-layer__modal-scroll {
                overflow-y: auto;
                position: relative;
                height: calc(100vh - 200px);
                -webkit-overflow-scrolling: touch;
                overscroll-behavior: contain;
            }
            .b-layer__modal-title {
                border-bottom: 1px solid $color-gray-11;
            }
            .c-store {
                padding: 19px 70px 19px 14px;
                &__title {
                    font-size: 20px;
                    color: $color-gray-01;
                    &:before {
                        @include sprite-retina($sp_store_coupon_icon_store_black2);
                        display: inline-block;
                        margin: -1px 3px 1px 0;
                        vertical-align: middle;
                        content: "";
                    }
                    &:after {
                        @include ico-arrow-right(8px, #999, 1px);
                        @include rotate(45deg);
                        display: inline-block;
                        margin: -1px 0 1px 3px;
                        vertical-align: middle;
                        content: "";
                    }
                    a {
                        display: inline-block;
                        overflow: hidden;
                        max-width: 192px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                    }
                }
                &__description {
                    margin-top: 5px;
                    font-size: 15px;
                    color: $color-gray-04;
                }
                .c-like {
                    @include size(44px);
                    position: absolute;
                    top: 20px;
                    right: 16px;
                    border: 1px solid $color-gray-10;
                    border-radius: 50%;
                    background-color: $color-gray-14;
                    &:before {
                        @include sprite-retina($sp_btn_store_zim_off);
                        display: block;
                        margin: 0 auto;
                        content: "";
                    }
                    &[aria-pressed="true"] {
                        &:before {
                            @include sprite-retina($sp_btn_store_zim_on);
                            content: "";
                        }
                    }
                }
            }
            [data-type="Contents_Tag_Filter"] {
                .c-filter-normal {
                    width: 100%;
                    height: 58px;
                    padding: 0;
                }
                .c-filter--fixed {
                    position: fixed;
                    top: 55px;
                    left: 0;
                    width: 100%;
                    z-index: 40;
                }
                .w-scroll--css {
                    overflow-y: hidden;
                    height: 58px;
                    background: $color-gray-13;
                    border-top: 1px solid $color-gray-11;
                    border-bottom: 1px solid $color-gray-11;
                }
                .s-list {
                    display: flex;
                    width: 100%;
                    height: 58px;
                    padding: 12px;
                    overflow-x: scroll;
                    -webkit-overflow-scrolling: touch;
                    scroll-behavior: smooth; // 부드러운 스크롤
                    text-align: start;
                    white-space: nowrap;
                }
                .c-chip {
                    margin: 0 4px 0 0;
                }
            }
            [data-type="ProductGrid_Standard"] {
                margin: 0;
                padding: 0;
                li {
                    .c-flex__item {
                        &:first-child {
                            border-right: 1px solid $color-gray-12;
                        }
                        margin: 0;
                        padding: 16px;
                        border-bottom: 1px solid $color-gray-12;
                        border-right: 1px solid $color-gray-14;
                    }
                    .c-card-item {
                        height: 100%;
                        border: 0;
                        box-shadow: none;
                        border-radius: 0;
                        &__info {
                            padding: 12px 0 0;
                        }
                        &__price {
                            &-info {
                                margin: 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
