[data-type="ContentsScroll_LiveNotiBox"] {
    .w-swiper {
        padding: 0 10px;
    }
    .swiper-wrapper {
        padding-bottom: 24px;
    }
    .swiper-slide {
        &:not(:only-child) {
            width: 87.324%;
            padding: 0 6px;
        }
    }
    .c-pagination {
        margin-top: -24px;
    }
    .c-pagination__indicator {
        line-height: 40px;
        .c-pagination__bullet {
            &:only-child {
                display: none;
            }
        }
    }

    .c-card-item {
        @include rounding-box;
        border-radius: 4px;
        z-index: 1;
        padding: 0;
        &__thumb {
            width: 96px;
            a {
                display: block;
                position: relative;
                line-height: 0;

                img {
                    object-fit: cover;
                }
            }
            &-cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 10px 0 0 20px;
                line-height: 1.2;
            }
            &-ready {
                color: $color-gray-14;
                font-size: 13px;
                .live_time {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 100%;
                    color: $color-gray-14;
                    font-size: 13px;
                    text-align: center;
                    font-weight: bold;
                    .time {
                        font-size: 20px;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    @include dimned-opacity(0.4);
                }
            }
        }

        &__info {
            padding: 14px 12px 9px 14px;
            .c-profile {
                padding-right: 0;
                &__link {
                    background-color: transparent;
                    border: 0;
                    font-size: 0;
                }
                &__pic {
                    display: inline-block;
                    float: none;
                    margin-right: 4px;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 11;
                        border: 1px solid rgba(0, 0, 0, 0.04);
                        border-radius: 50%;
                        content: "";
                    }
                }
                &__pic,
                &__pic span {
                    @include size(20px);
                }

                &__pic img {
                    @include size(20px);
                    border-radius: 50%;
                }

                &__name {
                    display: inline-block;
                    height: 18px;
                    line-height: 20px;
                    font-size: 14px;
                    color: $color-gray-04;
                }
                .c-lazyload {
                    &::before {
                        background: none;
                    }
                }
            }
        }

        &__status {
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            line-height: 16px;
            strong {
                @include text-ellipsis;
                display: block;
                margin-right: 6px;
                color: $color-11st-red;
            }
        }

        &__name {
            display: block;
            height: 38px;
            margin: 8px 0 12px;
            color: $color-gray-02;
            font-weight: bold;
            dd {
                line-height: 20px;
            }
        }

        &__mini-card {
            display: flex;
            overflow: hidden;
            padding-top: 10px;
            border-top: 1px solid $color-gray-12;
            font-size: 14px;

            &-thumb {
                @include size(36px);
                display: block;
                position: relative;
                flex-shrink: 0;
                margin-right: 8px;
                .c-lazyload {
                    &:before {
                        background-size: 36px auto;
                    }
                }
                img {
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 4px;
                }
            }

            dl {
                display: flex;
                flex-direction: column;
                height: 36px;
                justify-content: center;
                dd {
                    overflow: hidden;
                    height: 15px;
                    line-height: 15px;
                    font-size: 13px;
                    color: $color-gray-02;
                    span {
                        font-size: 13px;
                        font-weight: bold;
                    }
                    &:nth-child(4) {
                        position: relative;
                        top: 1px;
                        font-size: 11px;
                        span {
                            float: left;
                        }
                    }
                }
            }
        }
    }
}
