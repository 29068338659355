// 편성표 페이지 진입 시 스와이프 액션 안내
[data-ui-type="Guide_SwipeImage"] {
    .c-guide-image {
        @include size(110px);
        position: fixed;
        top: calc(50% + 139px); // 50% + (헤더 + 검색창 + 편성표)/2
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        background: url("/MW/img/live11/img-guide.png") no-repeat 0 0;
        background-size: 100% auto;
        font-size: 0;
        animation: fadeInOutSwipeGuide 3s forwards;
    }
    @keyframes fadeInOutSwipeGuide {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        66% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
