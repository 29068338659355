[data-ui-type="Ooah_Pick_Billboard"] {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: var(--OoahPickVh);
    @at-root .ooah-pick & {
        background-color: $color-gray-01;
    }
    .c-billboard {
        &__wrapper {
            width: 100%;
            height: 100%;
            @at-root .aos & {
                transform: translateY(1px);
            }
        }
        &__card {
            position: absolute;
            bottom: -90px;
            width: 100%;
            height: 90px;
            transition: all 0.3s ease-in;
            will-change: height, bottom;
            background-color: $color-gray-01;
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                &::-webkit-media-controls {
                    display: none;
                }
            }

            // li 비디오 위치 관련
            &--prev,
            &--show {
                bottom: 180px;
                height: calc(var(--OoahPickVh) - 180px);
                @at-root .aos & {
                    height: calc(100% - 179px);
                }
            }
            &--show {
                backface-visibility: hidden;
            }
            &--wait1,
            &--wait2 {
                height: 90px;
            }
            &--wait1 {
                bottom: 90px;
            }
            &--wait2 {
                bottom: 0;
            }
        }
    }
    .c-video {
        &--box {
            position: relative;
            width: 100%;
            height: var(--OoahPickVh);

            video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                &::-webkit-media-controls {
                    display: none;
                }
            }
        }
        &__thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__volume {
            $volume-size: 36px;
            position: absolute;
            top: 65px;
            right: 8px;
            z-index: 20;
            width: $volume-size;
            height: $volume-size;
            border: 0;
            border-radius: $volume-size;
            transition: opacity 0.3s ease-out;

            &[aria-checked="false"] {
                background: rgba($color: $color-gray-01, $alpha: 0.2) url("/MW/img/svg/ooah-pick/ic-speaker-mute.svg") no-repeat center;
                background-size: 24px auto;
            }
            &[aria-checked="true"] {
                background: rgba($color: $color-gray-01, $alpha: 0.2) url("/MW/img/svg/ooah-pick/ic-speaker.svg") no-repeat center;
                background-size: 24px auto;
            }

            // 스크롤 시 숨김 처리
            &.is-hidden {
                opacity: 0;
            }
        } //c-video__volume
    }
}

.ooah-pick-home {
    .c-billboard__card video {
        opacity: 0;
        transition: all 0.2s ease-out;
        object-fit: cover;
        &.c-pick-video--show {
            opacity: 1;
        }
        &.c-pick-video--ios17 {
            transition-delay: 0.5s;
        }
    }
    // z-index 모음 - 카드별
    .c-billboard__card--show {
        z-index: 10;
    }
    .c-billboard__card--wait1 {
        z-index: 11;
    }
    .c-billboard__card--wait2 {
        z-index: 10;
        + .c-billboard__card {
            z-index: 9;
        }
    }
    // z-index 모음 - 카드 - 구성
    .video {
        z-index: 1;
    }
    .c-video__filter {
        z-index: 2;
    }
    .c-video__info {
        z-index: 3;
    }
}
.ooah-pick-brand {
    // 상세 video 다음에 썸네일 사용
    .c-video--box video {
        min-width: 100%;
        min-height: 100%;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.35s ease-out;
        &.c-pick-video--show {
            opacity: 1;
        }
    }
    .c-video__filter {
        z-index: 3;
    }
    .c-video__info {
        z-index: 4;
    }
}
