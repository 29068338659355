.ooah-pick .b-fixed {
    top: -1px;
    background-color: transparent;
}
// TODO: MPSR-149683 배포 후 common/gnb로 통합
.c-gnb--ooah-pick {
    $logo_height: 36px;
    background-color: transparent;
    border-bottom: none;
    color: $color-gray-14;
    transition: background-color 0.3s ease-in;

    .c-gnb {
        padding-top: 1px;
        &__menu {
            display: flex;
            align-items: center;
        } //c-gnb__menu
        &__logo {
            position: relative;
            top: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 56px;
            margin-left: 16px;
        } //c-gnb__logo
        &__back {
            display: inline-block;
            @include size(36px);
            background: url("/MW/img/svg/ooah-pick/ic-arrow.svg") no-repeat center;
            background-size: 100% auto;
            margin-left: 8px;
            border: 0;
            vertical-align: top;
            @at-root .ooah-pick-brand & {
                display: block;
            }
            & + .c-gnb__logo {
                margin-left: 8px;
            }
        } //c-gnb__back
        &__link {
            position: relative;
            & + .c-gnb__link::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 28px;
                top: 50%;
                left: 11px;
                background-color: rgba($color: $color-gray-14, $alpha: 0.5);
                transform: translateY(-50%);
            }
        }
        &__share {
            display: none;
            overflow: hidden;
            position: absolute;
            top: 10px;
            right: 8px;
            @include size(36px);
            background: url("/MW/img/svg/ooah-pick/ic-share.svg") no-repeat center;
            background-size: 100% auto;
            color: transparent;
            font-size: 10px;
        }
        &__cart {
            right: 8px;
            background: url("/MW/img/svg/ooah-pick/ic-cart.svg") no-repeat center;
            background-size: 100% auto;

            .c-badge {
                filter: none;
                padding: 0 4px;
                background-color: $color-luxury;
                font-weight: bold;

                @at-root .brand-pick & {
                    background-color: $color-11st-red;
                }
            }
        }
        &__brand {
            position: absolute;
            top: 50%;
            left: 50%;
            display: none;
            align-items: center;
            transform: translate(-50%, -50%);
            max-width: calc(100% - 96px);

            &-logo {
                $size: 28px;
                overflow: hidden;
                position: relative;
                flex: 0 0 auto;
                width: $size;
                height: $size;
                border-radius: 100%;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                }
                + .c-gnb__brand-name {
                    margin-left: 8px;
                }
            } //c-gnb__brand-logo
            &-name {
                font-family: $font-outfit;
                font-size: 16px;
                font-weight: 500;
                color: $color-gray-14;
                @include text-ellipsis;
            } //c-gnb__brand-name
            &-img {
                height: 28px;
                img {
                    width: auto;
                    height: 100%;
                }
            }
        } //c-gnb__brand
    }
    .logo-11st {
        overflow: hidden;
        display: block;
        width: 37px;
        height: 16px;
        color: transparent;
        font-size: 10px;
        background: url("/MW/img/svg/logo/logo-11st.svg") no-repeat 0 0;
        background-size: 100% auto;
        filter: invert(100%);
    }
    .logo-ooah-pick {
        display: flex;
        height: 58px;
        width: auto;
        align-items: center;
        margin-left: 21px;
        color: transparent;
        font-size: 10px;
        img {
            width: auto;
            height: 28px;
            vertical-align: top;
        }
        canvas {
            width: 121px !important;
            height: 58px !important;
        }
    }
    //브랜드 상세 스크롤시 gnb 변화
    @at-root &.is-fixed {
        background-color: rgba($color: $color-gray-01, $alpha: 0.6);
        backdrop-filter: blur(24px);

        @at-root .webview-aos & {
            // 안드로이드 - 웹뷰 분기 MPQA-68004
            backdrop-filter: none;
            background-color: rgba($color: $color-gray-01, $alpha: 0.8);
        }

        .c-gnb__logo,
        .c-gnb__cart {
            display: none;
        }
        .c-gnb__share {
            display: block;
        }
        .c-gnb__brand {
            display: flex;
        }
    }
}
