.c-n-radio {
    &__label {
        position: relative;
        display: block;
        padding-left: 30px;
        font-family: $font-family3;
        line-height: 22px;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 1px;
            width: 20px;
            height: 20px;
            border: 5px solid #fff;
            background-color: #ddd;
            border-radius: 50%;
            box-shadow: 0 0 0 1px #bbb;
            box-sizing: border-box;
            transform: translateY(-50%);
        }
    }
    &__input {
        position: absolute;
        width: 22px;
        height: 22px;
        opacity: 0;
    }
    &__input:checked + &__label:before {
        border-color: #111;
        background-color: #fff;
        box-shadow: 0 0 0 1px #111;
    }
    &__input:disabled + &__label {
        opacity: 0.5;
    }
}

// c-chip
.c-n-radio {
    &__input:checked + .c-n-chip {
        @extend .c-n-chip--selected;
    }
    &__input:checked + .c-n-chip-dark {
        @extend .c-n-chip-dark--selected;
    }
}
