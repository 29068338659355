.c-bottom-drawer {
    display: none;
    overflow: hidden;
    position: fixed;
    width: 100%;
    max-width: 1280px;
    background-color: $color-gray-14;
    z-index: 102;
    box-sizing: border-box;
    border-radius: 12px 12px 0 0;
    outline: 0;

    &__image {
        display: block;
        width: 100%;
    }
    &__tools {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        min-height: 56px;
        padding: 10px 16px;
        box-sizing: border-box;
    }
    &__close {
        padding: 2px 4px 0;
        border: 0;
        color: $color-gray-05;
        font-size: 15px;
        line-height: 18px;
        background-color: transparent;
    }
    &__button {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 2px 16px 0;
        border: 1px solid $color-gray-10;
        color: $color-gray-02;
        font-size: 15px;
        line-height: 1em;
        background-color: transparent;
        border-radius: 36px;
        box-sizing: border-box;

        &--arrow:after {
            content: "";
            display: inline-block;
            width: 7px;
            height: 7px;
            margin: -1px 0 1px 4px;
            border-style: solid;
            border-color: $color-gray-04;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
        }
    }
    &__indicator {
        display: block;
        width: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
        height: 16px;
        border: 0;
        background-color: transparent;
        box-sizing: border-box;

        &:before {
            content: "";
            display: block;
            width: 48px;
            height: 4px;
            margin: 0 auto;
            border-radius: 2px;
            background-color: $color-gray-10;
            box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05) inset;
        }
    }

    .c-lazyload {
        padding-top: 81.94%;
    }

    .c-pagination {
        position: absolute;
        top: 24px;
        right: 20px;
        z-index: 20;

        &__indicator {
            line-height: initial;

            span {
                width: 6px;
                height: 6px;
                margin-left: 4px;
                background-color: $color-gray-14;
            }
        }
    }
}
