[data-type="Cap_Box_Lottie"] {
    .c-headline {
        overflow: hidden;

        &__title {
            display: flex;
            align-items: center;

            strong + span {
                @include text-ellipsis;

                width: 100%;
                margin-left: 14px;
                padding-right: 0;
            }
        }

        // TODO: MPSR-131562 배포 후 제거 Start
        &__lottie {
            display: inline-block;
            line-height: 1;
            width: 67px;
            height: 16px;
            vertical-align: middle;
            & > span {
                display: inline-block;
                width: 67px;
                height: 16px;
            }
        }
        &__lottie-11live {
            width: 67px;
            height: 16px;

            .canvas-container {
                display: inline-block;
                width: 67px;
                height: 16px;
            }
        }
        // MPSR-131562 배포 후 제거 End
    }

    .c-lottie {
        display: flex;
        line-height: normal; // .c-headline__title reset
        &__container {
            display: block;
            width: var(--lottieWidth, 67px);
            height: var(--lottieHeight, 16px);
        }
    }

    .c-cap-sorting {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;

        &__item {
            display: flex;
            align-items: center;
        }
        &__item + .c-cap-sorting__item:before {
            content: "";
            display: block;
            width: 1px;
            height: 10px;
            margin-right: 12px;
            margin-left: 12px;
            background-color: $color-gray-11;
        }
        &__label {
            display: flex;
            align-items: center;
            color: $color-gray-03;
            font-size: 14px;
            font-family: $font-11st;
            font-weight: 500;

            &:before {
                content: "";
                display: none;
                width: 14px;
                height: 14px;
                margin-right: 6px;
                background: url("/MW/img/svg/ic-check-red.svg") no-repeat 0 0;
                background-size: 100% auto;
            }
        }
        &__input {
            position: absolute;
            width: 22px;
            height: 22px;
            border: 0;
            opacity: 0;

            &:checked + label {
                color: $color-11st-red;

                &:before {
                    display: block;
                }
            }
        }
    }

    .c-cap-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
