[data-type="ExhibitionBannerB"] {
    .c-headline--center {
        .c-lazyload--ratio_300x80 {
            @include size(300px 80px);
            display: block;
            margin: auto;
            padding-bottom: 0;
        }
    }
    .w-swiper {
        padding-bottom: 16px;
    }
}