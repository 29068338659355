.c-accordion {
    position: relative;
    font-family: $font-family3;

    // 구버전 PUI CSS 겹치는 문제
    min-width: 100%;
    margin-bottom: 0;
    background-color: transparent;

    &:before {
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        height: 1px;
        background: $color-gray-12;
        content: "";
    }
    &__type {
        display: block;
        position: relative;
        cursor: pointer;
        outline: none;

        &::-webkit-details-marker {
            display: none;
        }
        &:after {
            @include ico-arrow-down(8px, $color-gray-03, 1px);
            position: absolute;
            top: 50%;
            right: 18px;
            margin-top: -6px;
            content: "";
        }
    }
    &__detail {
        position: relative;
        z-index: 1;
        margin-bottom: -1px;
        background: $color-gray-14;

        // 구버전 PUI CSS 겹치는 문제
        padding: 0;
        border-bottom: 0;
    }
    &[open] {
        .c-accordion__type {
            &:after {
                margin-top: -2px;
                transform: rotate(315deg);
            }
        }
    }
}
