@mixin ootdLikeButton {
    .c-n-like {
        position: absolute;
        bottom: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
        padding: 4px;
        background: rgba($color-gray-01, 0.2);
        border-radius: 50%;

        &:before {
            @include get-sprite-ootd("ic_heart", 24);

            content: "";
            display: block;
            border-radius: 50%;
        }

        &__icon {
            overflow: hidden;
            display: block;
            padding: 6px;
            background-color: rgba($color-gray-01, 0.2);
        }
        &__lottie {
            overflow: hidden;
            display: block;
            width: 24px;
            height: 24px;
        }
        &__svg {
            overflow: hidden;
            display: block;
            width: 20px;
            height: 20px;
        }

        &[aria-pressed="true"]:before {
            @include get-sprite-ootd("ic_heart_on", 24);
        }

        &--loaded,
        &--loaded[aria-pressed="true"] {
            &:before {
                background-image: none;
            }
        }
    }
}
