[data-type="Tabs_Luxury_RoundBtn_Scroll_Sticky"] {
    .s-list {
        padding: 12px 12px 20px;
    }
    .w-scroll-css {
        background: $color-gray-14;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .w-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 10;
            border-bottom: 1px solid $color-gray-12;
        }
        &--absolute {
            position: absolute;
        }
    }
}
