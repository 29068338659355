@import "../../roadshop/block/b-card--roadshop";

[data-type="ProductGrid_Outlet"] {
    @include grid(2, 2, false);
    background-color: #fff !important;
    &.l-grid__row {
        padding-top: 4px;
        padding-bottom: 8px;
    }
    .b-card--outlet {
        @include b-card--roadshop;

        .c-card__gallery {
            border: 1px solid #f2f2f2;
            .c-card__badge-text {
                display: block;
                height: 18px;
                padding: 0 6px;
                box-sizing: border-box;
                line-height: 18px;
                background: #c29254;
                color: #fff;
                font-size: 11px;
                text-align: center;
                letter-spacing: 0;
            }
            .c-card__info {
                padding-left: 8px;
                padding-bottom: 6px;
                padding-right: 8px;
                .c-card__name {
                    -webkit-line-clamp: 2;
                    height: 33px;
                    margin-bottom: 3px;
                }
            }
        }

        .c-like-button--product {
            top: -56px;
        }

        .c-relates {
            background-color: #fbfbfb;
            border: 1px solid #f2f2f2;
            border-top: 0;
            .c-relates__function {
                .c-relates__button-link {
                    display: block;
                    padding: 8px;
                    height: 48px;
                    font-size: 12px;
                    &:after {
                        display: none;
                    }
                    .c-relates__emphasis {
                        display: block;
                        color: #c29254;
                    }
                    .c-relates__item {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .c-noinfo {
        padding: 50px 0;
        strong {
            color: #888;
            font-size: 14px;
        }
    }
}
