[data-type="Tabs_Luxury_BoxBtn"] {
    .c-n-tab {
        padding: 0 10px;

        &__item {
            padding: 0 6px;
        }
    }
    .c-n-chip-dark {
        @include size(100% 42px);
        border-radius: 4px;
        padding: 0 8px;
        font-weight: normal;
        line-height: 42px;

        &[aria-selected="true"] {
            font-weight: 600;
        }
    }
}
