[data-type="AiFeed_TextImg"] {
    $paddingLeft: 48px;
    $iconTalk: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7243 4C9.5399 4 7.62367 4.87698 6.24723 6.29971C4.84209 7.75212 4 9.7705 4 12C4 14.2295 4.84209 16.2479 6.24723 17.7003C7.62367 19.123 9.5399 20 11.7243 20H19.4319L17.5896 17.5885C19.064 16.1479 20 14.1763 20 12C20 9.8105 19.0527 7.82837 17.5627 6.38537C16.0428 4.91335 13.9572 4 11.7243 4Z' stroke='white' stroke-linejoin='round'/%3E%3Cpath d='M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12Z' fill='white'/%3E%3Cpath d='M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z' fill='white'/%3E%3Cpath d='M14 12C14 12.5523 14.4477 13 15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12Z' fill='white'/%3E%3C/svg%3E");
    $iconPeople: url("/MW/img/vertical/aifeed/aifeed_people.png");
    $iconLikeHeart: url("/MW/img/vertical/aifeed/aifeed_heart.png");
    $iconLikeBurst: url("/MW/img/vertical/aifeed/aifeed_burst.png");
    $lineHeight20: 20px;
    $feedPolygonHight: 84%;
    $feedPolygon: polygon(100% 100%, 0% 100%, 0% $feedPolygonHight, 100% $feedPolygonHight, 100% 0%);
    $feedHeight_6: 116px; // 기본 피드 말줄임 높이
    $feedHeight_2: 40px; // 내활동 피드 말줄임 높이

    @mixin text-styles($color, $font-size, $font-weight, $line-height: normal) {
        font-size: $font-size;
        font-weight: $font-weight;
        color: $color;
        line-height: $line-height;
    }
    // 피드
    .ai-feed {
        &-section {
            padding: 32px 20px;
            background-color: $color-gray-14;

            & + .c-headline {
                border-top: 10px solid $color-gray-11;
            }

            & + .ai-feed-section {
                border-top: 1px solid $color-gray-11;
            }
        }
        &-item {
            //피드 상단 정보
            position: relative;
            overflow: visible;
            background-color: $color-gray-14;
            &__info {
                position: relative;
                padding-left: $paddingLeft;
                background-color: $color-gray-14;
            }
            &__data {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            &__thumbnail {
                $size: 40px;
                position: absolute;
                overflow: hidden;
                top: -3px;
                left: 0;
                width: $size;
                height: $size;
                border-radius: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                &::after {
                    position: absolute;
                    border: 1px solid rgba($color: $color-gray-01, $alpha: 0.05);
                    border-radius: 100%;
                    content: "";
                    inset: 0;
                }
            }
            &__name {
                @include text-styles($color-gray-02, 16px, 700, normal);
                @include text-ellipsis;
                max-width: 130px;
                word-break: keep-all;
            }
            &__category {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 4px;
                @include text-styles($color-gray-02, 13px, 400, normal);
                &::before {
                    $size: 3px;
                    display: inline-block;
                    width: $size;
                    height: $size;
                    margin-right: 4px;
                    border-radius: 100%;
                    background-color: #d9d9d9;
                    content: "";
                }
            }
            &__option {
                position: absolute;
                top: -12px;
                right: -10px;
                width: 33px;
                height: 27px;
                border: 0;
                background: url("data:image/svg+xml,%3Csvg width='13' height='3' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1.5' cy='1.5' r='1.5' fill='%23949494' /%3E%3Ccircle cx='5.5' cy='1.5' r='1.5' fill='%23949494' /%3E%3Ccircle cx='9.5' cy='1.5' r='1.5' fill='%23949494' /%3E%3C/svg%3E") center no-repeat;
            }
            //피드 광고 및 내용
            &__article {
                margin-top: 8px;
                padding-left: $paddingLeft;
                white-space: pre-line;
                &-box {
                    // 피드 본문
                    position: relative;
                    overflow: hidden;
                    margin-top: 12px;
                }
            }
            //피드 상단 광고
            &__ad {
                display: block;
                margin-bottom: 16px;
                @include text-styles($color-gray-03, 14px, 700, $lineHeight20);
            }
            &__body-text {
                @include text-styles($color-gray-03, 14px, 400, $lineHeight20);
            }
            &__show-button {
                // 더보기 버튼
                display: none;
                position: absolute;
                opacity: 0;
                inset: 0;
            }
            &__more {
                // 더보기 텍스트
                display: none;
                align-items: flex-end;
                justify-content: right;
                float: right;
                height: $feedHeight_6;
                margin: 0;
                padding: 0;
                border: 0;
                background: transparent;
                font-size: 14px;
                font-weight: 400;
                color: $color-gray-06;
                line-height: 20px;
                shape-outside: $feedPolygon;
                &::before {
                    font-size: 14px;
                    font-weight: 400;
                    color: $color-gray-03;
                    line-height: 20px;
                    content: "...";
                }
            }
            &__hashtag {
                display: -webkit-box;
                overflow: hidden;
                margin-top: 8px;
                padding-left: $paddingLeft;
                word-break: break-all;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                @include text-styles(#8155ea, 14px, 400, $lineHeight20);
            }
            &__product-img {
                $size: 48px;
                position: absolute;
                overflow: hidden;
                top: 30px;
                right: 0;
                width: $size;
                height: $size;
                border-radius: 4px;

                &:after {
                    z-index: 10;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color: $color-gray-01, $alpha: 0.04);
                    content: "";
                }

                img {
                    $size: 100%;
                    width: $size;
                    height: $size;
                    object-fit: contain;
                }
            }

            // 말줄임 상태
            &--ellipsis {
                .ai-feed-item__article-box {
                    // 피드 전체 내용
                    height: $feedHeight_6;
                }
                .ai-feed-item__show-button {
                    //전체 보기 버튼
                    display: block;
                }
                .ai-feed-item__more {
                    // 더보기 텍스트
                    display: flex;
                }
            } //ai-feed-item--ellipsis
            // 내활동 상태
            &--product {
                // 내 활동 - width
                .ai-feed-item__article,
                .ai-feed-item__hashtag {
                    width: calc(100% - 60px);
                }
            } //ai-feed-item--product

            //내활동 - 말줄임 - 2줄
            &--ellipsis.ai-feed-item--product {
                .ai-feed-item__ad,
                .ai-feed-item__hashtag {
                    display: none;
                }
                .ai-feed-item__product-img {
                    top: 34px;
                }
                .ai-feed-item__more {
                    height: $feedHeight_2;
                }
                .ai-feed-item__article-box {
                    // 피드 전체 내용
                    height: $feedHeight_2;
                }
            }
        } //ai-feed-item

        &-product {
            margin-top: 20px;
            padding-left: 48px;
            background-color: $color-gray-14;
        }

        &-product-recommend {
            margin-top: 8px;

            &__text {
                margin-top: 2px;
            }

            &__emphasis {
                margin-left: 4px;
                font-weight: 700;
                color: #8155ea;
            }
        }

        &-product-big {
            &__detail {
                margin-top: 4px;
            }

            & + .ai-feed-product-recommend {
                .ai-feed-product-recommend__text {
                    margin-top: 0;
                }
            }
        }
    }

    .ai-feed-time {
        margin-left: 6px;
    }

    // 참여 유도 문구 --------------------------------
    // [TODO][김영득] ai-feed-cta 페이드 아웃 trigger 로 교체, 이후 삭제
    .ai-feed-cta {
        display: inline-block;
        padding: 6px 8px 5px;
        border-radius: 8px;
        background: #f1ecff;
        @include text-styles($color-gray-03, 13px, 400, normal);

        &__button {
            width: 100%;
            margin: 20px 0 0 $paddingLeft;
            border: 0;
            background-color: $color-gray-14;
            text-align: left;
        }
    }
    // end 이후 삭제
    // 액션 버튼
    .ai-feed-action {
        $button-height: 36px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
        margin-left: $paddingLeft;

        button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: $button-height;
            margin: 0 4px 6px 0;
            padding: 0 10px 0 8px;
            border-radius: 360px;
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;

            [class^="ai-feed-icon"] {
                $size: 24px;
                width: $size;
                height: $size;
            }
        }
        .ai-feed-action__button-gradient {
            align-items: center;
            justify-content: center;
            z-index: 1;
            position: relative;
            overflow: hidden;
            padding: 2px;
            border: 0;
            font-weight: bold;
            color: $color-gray-14;
            letter-spacing: -0.5px;
            animation: aiFeedActionButtonTalkBg 2s infinite alternate;
            &::before {
                $size: 150px;
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                width: $size;
                height: $size;
                background: radial-gradient(circle, rgba(223, 80, 247, 1) 0%, rgba(223, 80, 247, 0) 70%);
                transform: translate(-50%, -50%);
                animation: aiFeedActionButtonTalkTop 2s infinite alternate;
                content: "";
                mix-blend-mode: color; // 텍스트와 배경 간 합성 분리
            }
            &-inner {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                height: 100%;
                padding: 4px 10px 4px 8px;
                border-radius: 360px;
                background-color: transparent;
            }
            //대화중
            &--ing {
                .ai-feed-action__button-gradient-inner {
                    background: $color-gray-14;
                    color: #8155ea;
                }
                .ai-feed-icon-talk {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7243 4C9.5399 4 7.62367 4.87698 6.24723 6.29971C4.84209 7.75212 4 9.7705 4 12C4 14.2295 4.84209 16.2479 6.24723 17.7003C7.62367 19.123 9.5399 20 11.7243 20H19.4319L17.5896 17.5885C19.064 16.1479 20 14.1763 20 12C20 9.8105 19.0527 7.82837 17.5627 6.38537C16.0428 4.91335 13.9572 4 11.7243 4Z' stroke='url(%23paint0_linear_2195_3841)' stroke-linejoin='round'/%3E%3Cpath d='M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12Z' fill='%238155EA'/%3E%3Cpath d='M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z' fill='%238155EA'/%3E%3Cpath d='M14 12C14 12.5523 14.4477 13 15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12Z' fill='%238155EA'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2195_3841' x1='20' y1='12' x2='2.75486' y2='12' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2347B8E7'/%3E%3Cstop offset='1' stop-color='%23DF50F7'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
                }
            }
        }
        .ai-feed-action__button,
        .ai-feed-action__button-like {
            border: 1px solid $color-gray-11;
            background: $color-gray-14;
            color: $color-gray-02;
        }

        .ai-feed-action__button-like {
            color: $color-gray-02;
            transition: color 0.2s ease-in-out;

            &--action {
                color: #8155ea;
            }
        }
        &__counting {
            $numberSize: 11px;
            $countingMotion: all 0.6s ease-in-out;
            width: 7px;
            height: $numberSize;
            font-size: 12px;
            font-weight: 400;
            [class*="ai-feed-action__counting-"] {
                display: block;
                height: $numberSize;
                line-height: 12px;
            }
            &-first {
                opacity: 1;
                transition: $countingMotion;
                transform: perspective(200px) rotateX(0deg);
                transform-origin: center center;
            }
            &-second {
                opacity: 0;
                transition: $countingMotion;
                transform: perspective(200px) rotateX(45deg);
                transform-origin: top center;
            }
            &--up {
                .ai-feed-action__counting-first {
                    opacity: 0;
                    transform: perspective(200px) translateY(-$numberSize) rotateX(25deg); // 순서 중요
                    transform-origin: bottom center;
                }
                .ai-feed-action__counting-second {
                    opacity: 1;
                    transform: perspective(200px) rotateX(0deg) translateY(-$numberSize); // 순서 중요
                    transform-origin: center center;
                }
            }
        }
    }
    // 댓글 영역
    .ai-feed-comment {
        margin-top: 20px;
        padding-left: 48px;
        background-color: $color-gray-14;

        &__info {
            display: flex;
            align-items: center;
        }

        &__body-text {
            margin-top: 6px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 20px;
            @include text-ellipsis-multiple(2);
        }

        &__indent {
            position: relative;
            margin-top: 12px;
            padding-left: 18px;

            &:before {
                position: absolute;
                top: 0;
                bottom: 6px;
                left: 0;
                width: 2px;
                background-color: $color-gray-11;
                content: "";
            }
        }

        &__show-button {
            width: 100%;
            margin: 2px 0 0 -18px;
            padding: 6px 0 6px 18px;
            border: none;
            background-color: transparent;
            font-size: 14px;
            color: $color-gray-06;
            line-height: 16px;
            text-align: left;
        }
    }
    .ai-feed-chat-profile-wrap {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    .ai-feed-icon {
        &-heart,
        &-people {
            &:before,
            &:after {
                width: 24px;
                height: 24px;
                background-position: 0 0;
                background-size: cover !important;
                transition: none;
                transform-origin: bottom center;
                content: "";
            }
        }

        &-heart {
            position: relative;
            margin-right: 2px;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                left: 0;
            }

            // 하트
            &:before {
                background: $iconLikeHeart no-repeat;
                background-position: 0 0; // 기본 위치 설정
            }

            // 폭죽 터지는 효과
            &:after {
                visibility: hidden;
                background: $iconLikeBurst no-repeat;
            }

            &--animate {
                &:before {
                    background-position: -792px 0; // 기본 위치 설정
                    animation: aiFeedLikeBackground 1.2s steps(34), aiFeedLikePosition 1.2s ease-in-out;

                    /* 갤럭시, 안드로이드 FHD+ 해상도만 적용 */
                    @media screen and (min-width: 1560px) and (max-width: 3120px) and (-webkit-min-device-pixel-ratio: 2) {
                        background: url("/MW/img/svg/ic-heart-purple.svg") no-repeat;
                        animation: none;
                    }
                }

                &:after {
                    animation: aiFeedHeartBurst 1.2s steps(21);

                    /* 갤럭시, 안드로이드 FHD+ 해상도만 적용 */
                    @media screen and (min-width: 1560px) and (max-width: 3120px) and (-webkit-min-device-pixel-ratio: 2) {
                        animation: none;
                    }
                }
            }
        }
        &-people {
            &:before {
                display: inline-block;
                background: $iconPeople no-repeat;
                vertical-align: top;
                animation: aifeedPeopleMotion 5s steps(75) infinite;

                /* 갤럭시, 안드로이드 FHD+ 해상도만 적용 */
                @media screen and (min-width: 1560px) and (max-width: 3120px) and (-webkit-min-device-pixel-ratio: 2) {
                    background: url("/MW/img/svg/ic-people.svg") no-repeat;
                    animation: none;
                }
            }

            &:after {
                content: none;
            }
        }
        &-talk {
            background: $iconTalk center no-repeat;
        }
    }
}

.ai-feed-chat-text {
    margin-top: -1px;
    background: linear-gradient(90deg, $color-gray-02 0%, $color-gray-02 50%, #8155ea 50%, #8155ea 100%);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% 100%;
    font-size: 12px;
    color: transparent;
    letter-spacing: -0.5px;
    animation: textWavePeople 5s infinite;
    -webkit-text-fill-color: transparent;
}

// 피드 scroll sna, QA 중 이슈 발생할 경우 아래 코드만 삭제.
.wrap-ai-feed {
    overflow-x: hidden;
    overflow-y: hidden;
    .main {
        // [M] QA - And, iOS 하위 버전 static 미지원 기기 나올 경우 사용
        // position: absolute;
        // inset: 0 0 0 0;
        // height: calc(100% - 57px);
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
    }
}

//피드 스크롤 후 모션
.ai-feed-section {
    $feedTransition: transform 0.3s ease-in-out;
    .ai-feed-product {
        transition: $feedTransition;
    }
    .ai-feed-cta__button,
    .ai-feed-trigger,
    .ai-feed-action {
        transition: $feedTransition 0.2s;
    }
    .ai-feed-comment {
        transition: $feedTransition 0.4s;
    }
}
.ai-feed-section.ai-feed-section--motion {
    .ai-feed-product,
    .ai-feed-cta__button,
    .ai-feed-action,
    .ai-feed-trigger,
    .ai-feed-comment {
        transform: translateY(20px);
    }
}
@keyframes aiFeedActionButtonTalkBg {
    // 대화하기 - 배경색
    0% {
        background: #38a0ff;
    }
    50% {
        background: #1dbdff;
    }
    100% {
        background: #38a0ff;
    }
}
@keyframes aiFeedActionButtonTalkTop {
    // 대화하기 - aiFeedActionButtonTalkBg 위에 포인트 컬러 이동
    0% {
        top: -30%;
        left: -10%;
    }
    50% {
        top: -30%;
    }
    100% {
        top: 50%;
        left: 120%;
    }
}

@keyframes aiFeedLikeBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -816px 0;
    }
}

@keyframes aiFeedLikePosition {
    0% {
        transform: translateY(0);
    }
    29.17% {
        /* 0.35초 */
        transform: translateY(-50px);
    }
    50% {
        /* 0.6초 */
        margin-top: 3px;
        transform: translateY(0);
    }
    100% {
        margin-top: 0;
        transform: translateY(0);
    }
}

@keyframes aiFeedHeartBurst {
    0% {
        visibility: hidden; // 초기 상태에서 보이지 않게 설정
    }
    49.99% {
        visibility: hidden; // 50%까지 보이지 않게 설정
    }
    50% {
        visibility: visible; // 50%에서 보이게 설정
        background-position: 0 0;
    }
    100% {
        background-position: -504px 0; /* 24px * 21 frames = 504px */
    }
}

@keyframes aifeedPeopleMotion {
    0% {
        background-position: 0 0;
    }
    40% {
        background-position: 0 0;
    }
    100% {
        background-position: -1800px 0;
    }
}

@keyframes textWavePeople {
    0% {
        background-position: 200% 0;
    }
    40% {
        background-position: 200% 0;
    }
    46% {
        background-position: 100% 0;
    }
    92% {
        background-position: 100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
