[data-type="Tabs_TextBar"] {
    @include grid(0, 0, false);
    .c-tab__list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        border-bottom: 1px solid $color-gray-11;
        .c-tab__item {
            .c-tab__link {
                background-color: $color-gray-14;
                border: 0;
                height: 51px;
                font-size: 16px;
                font-weight: 700;
                color: $color-gray-02;
                line-height: 51px;
            }
            &.on {
                position: relative;
                &:after {
                    @include size(100% 2px);
                    position: absolute;
                    content: "";
                    display: block;
                    background-color: var(--blockPointColor, $color-11st-red);
                    bottom: -1px;
                    z-index: 10;
                }
                .c-tab__link {
                    color: var(--blockPointColor, $color-11st-red);
                }
            }
        }
    }
}
