[data-type="LineBanner_Basic"] {
    .c-banner {
        padding: 16px;

        &__link {
            display: block;
        }
        &__highlight {
            overflow: hidden;
            display: block;
            height: 18px;
            margin-bottom: 4px;
            color: $color-gray-02;
            font-size: 15px;
            line-height: 18px;
        }
        &__desc {
            overflow: hidden;
            display: block;
            width: 100%;
            height: 16px;
            color: $color-gray-02;
            font-size: 13px;
            line-height: 16px;
        }
        &__wrapper {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 70px;
            border-radius: 4px;
        }
    }

    .c-banner__wrapper--type-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 112px;
        padding-left: 24px;

        .c-banner__image {
            position: absolute;
            bottom: 0;
            right: 12px;
            z-index: 10;
            width: 100px;
            height: 86px;
        }
    }
    .c-banner__wrapper--type-image {
        overflow: hidden;

        .c-banner__image {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            transform: translateX(-50%);
        }
    }
}
