.c-radio,
.c-checkbox {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    opacity: 0;

    & + label {
        display: block;
        position: relative;
        height: 22px;
        padding-left: 30px;
        font-size: 15px;
        line-height: 23px;
        cursor: pointer;

        &:before {
            @include size(22px);

            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid $color-gray-08;
            background-color: $color-gray-14;
            box-sizing: border-box;
            border-radius: 4px;
            content: "";
        }

        &:after {
            position: absolute;
            content: "";
        }
    }

    &[disabled] {
        cursor: not-allowed;

        & + label {
            cursor: not-allowed;
            color: $color-gray-09;

            &:before {
                border-color: $color-gray-11;
                background: $color-gray-11;
            }

            &:hover,
            &:before,
            &:after {
                cursor: not-allowed;
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                border-color: $color-11st-red;
                background: $color-11st-red;
                animation-name: none;
            }

            &:after {
                display: block;
            }
        }

        &[disabled] {
            & + label {
                &:before {
                    border-color: $color-gray-11;
                    background: $color-gray-11;
                }
            }
        }
    }
}

.c-checkbox {
    & + label {
        color: $color-gray-03;

        &:after {
            @include size(6px 10px);
            top: 4px;
            left: 8px;
            border: 1px solid $color-gray-10;
            border-width: 0 1px 1px 0;
            box-sizing: border-box;
            transform: rotate(45deg);
        }
    }

    &:checked {
        & + label {
            &:after {
                border-color: $color-gray-14;
            }
        }
    }

    &[disabled] {
        & + label {
            &:after {
                border-color: $color-gray-14;
            }
        }
    }

    &--round {
        + label {
            &:before {
                border-radius: 50%;
            }
        }
    }

    &--arrow {
        & + label {
            font-size: 12px;

            &:before {
                border: 0;
            }

            &:after {
                top: 0;
                left: 5px;
                width: 12px;
                height: 20px;
                border-width: 0 3px 3px 0;
                border-color: $color-gray-09;
                transform: rotate(45deg) scale(0.5);
            }
        }

        &[disabled] + label {
            &:before {
                background: none;
            }

            &:after {
                border-color: $color-gray-10;
                background: none;
            }
        }

        &:checked {
            & + label {
                &:before {
                    background: none;
                }
                &:after {
                    border-color: $color-11st-red;
                }
            }
            &[disabled] {
                & + label {
                    &:after {
                        border-color: $color-gray-10;
                    }
                }
            }
        }
    }
}

.c-radio {
    & + label {
        &:before {
            border-radius: 50%;
        }

        &:after {
            @include size(10px);

            background: $color-gray-10;
            top: 6px;
            left: 6px;
            border-radius: 50%;
        }
    }

    &[disabled] {
        & + label {
            &:after {
                background: $color-gray-14;
            }
        }
    }

    &:checked + label {
        &:after {
            background-color: $color-gray-14;
        }
    }
}

.c-checkbox-toggle {
    display: inline-block;
    margin: 0;

    > em {
        @include size(40px 24px);
        display: block;
        float: left;
        position: relative;
        cursor: pointer;
        transition: background-position 0.2s ease-in;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 34px;
            height: 14px;
            border-radius: 34px;
            background: $color-gray-09;
            opacity: 0.5;
            transform: translate(-50%, -50%);
            content: "";
        }

        &:after {
            @include size(20px 20px);
            @include translateY(-50%);
            @include border-radius(100%);
            position: absolute;
            top: 50%;
            left: 1px;
            border: 1px solid $color-gray-09;
            background: $color-gray-14;
            transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease;
            box-sizing: border-box;
            content: "";
        }

        &:active {
            &:after {
                transform: translateY(-50%) scale3d(1.15, 0.85, 1);
            }
        }
    }

    span {
        color: $color-gray-03;
        font-size: 15px;
        display: block;
        float: left;
        padding-left: 8px;
        height: 24px;
        line-height: 25px;

        &:first-child {
            padding: 0 8px 0 0;
        }
    }

    > input {
        @include size(0 0);
        display: block;
        position: absolute;
        opacity: 0;

        &:checked + em {
            &:before {
                background: $color-11st-red;
            }

            &:after {
                left: calc(100% - 23px);
                border-color: rgba(0, 0, 0, 0.1);
                background-color: $color-11st-red;
            }
        }

        &:disabled + em {
            cursor: default;

            &:before,
            &:after {
                background: $color-gray-11;
            }

            &:after {
                border: 0;
                box-shadow: none;
            }

            &:active {
                &:after {
                    @include translateY(-50%);
                }
            }
        }
    }

    &--disabled {
        span {
            color: $color-gray-09;
        }
    }
}
