[data-ui-type="Contents_11live_review"] {
    @include grid(0, 2, false);

    .l-grid__col {
        z-index: 0;
    }

    .c-input-expect {
        padding: 0 0 20px;
        &__comment__box {
            border: 1px solid $color-gray-10;
            display: flex;
        }
        textarea {
            height: 40px;
            padding: 12px 10px 10px 10px;
            border: 0;
            resize: none;
            flex: 1;
            color: #333;
            font-size: 15px;
            font-weight: normal;
            line-height: 1.2;

            &::placeholder {
                color: #919191;
                font-size: 15px;
                font-weight: normal;
                line-height: 1.2;
            }

            &.expand {
                animation-duration: 1s;
                animation-name: expand;
                animation-iteration-count: 1;
            }

            &.reduction {
                animation-duration: 1s;
                animation-name: reduction;
                animation-iteration-count: 1;
            }

            @keyframes expand {
                from {
                    height: 40px;
                }

                to {
                    height: 124px;
                }
            }

            @keyframes reduction {
                from {
                    height: 124px;
                }

                to {
                    height: 40px;
                }
            }
        }
        button {
            width: 58px;
            flex: none;
            margin-left: auto;
            background-color: transparent;
            border: 0;
            border-left: 1px solid $color-gray-10;
            color: $color-11st-red;
            font-size: 15px;
        }
    }
    .c-profile {
        &__name {
            font-weight: bold;
        }
    }
    .c-expect-view {
        li {
            padding: 20px 0;
            border-bottom: 1px solid $color-gray-12;

            &:first-child {
                padding: 0 0 20px 0;
            }

            .c-expect-view-com {
                padding-top: 12px;
                font-size: 16px;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                color: $color-gray-03;
            }
        }
    }
    & + [data-type="FlatButton_More"] {
        button.c-relates__link {
            background: $color-gray-13;
        }
    }
}
