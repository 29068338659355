.c-ooah-pick__banner {
    position: relative;
    display: block;
    width: 100%;
    height: 300px;
    &__inner {
        $position: 72px;
        position: absolute;
        left: $position;
        right: $position;
        top: 50%;
        z-index: 3;
        transform: translateY(-50%);
        text-align: center;
    } //c-ooah-pick__banner__inner
    &__text {
        @include text-ellipsis-multiple(2);
        color: $color-gray-14;
        &:first-child {
            font-family: $font-outfit;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
        }
    }
    &__ci {
        width: auto;
        height: 24px;
        vertical-align: top;
    } //c-ooah-pick__banner__ci

    &__text + &__text,
    &__ci + &__text {
        // 두번째 나오는 텍스트
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }

    &__image {
        //배경 이미지
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    } //c-ooah-pick__banner__image
    &::after,
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    &::after {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }
    &::before {
        background-color: rgba(0, 0, 0, 0.3);
    }
} // c-ooah-pick__banner
