[data-type="Contents_Luxury_BrandList"] {
    .c-item {
        display: flex;
        position: relative;
        padding: 15px 56px 15px 16px;
        align-items: center;

        &:after {
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 0;
            height: 1px;
            background: $color-gray-12;
            content: "";
        }
        &__link {
            flex: 1;
            display: flex;
            align-items: center;
            width: 100%;
        }
        &__title {
            flex-shrink: 0;
            overflow: hidden;
            display: block;
            max-width: 76.9%;
            font-size: 16px;
            color: $color-gray-02;
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;

            &-sub {
                overflow: hidden;
                display: block;
                margin-left: 3px;
                font-size: 14px;
                font-weight: normal;
                line-height: 30px;
                color: $color-gray-06;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        &__side {
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translateY(-50%);
        }
    }
}
