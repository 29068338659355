[data-type="GridList_Luxury_ImgText_Col4"] {
    .c-imgtext {
        padding: 24px;
        padding-bottom: 0;

        &__list {
            display: grid;
            grid-template-columns: auto auto auto auto;
            justify-content: space-between;
            margin-top: -16px;
        }
        &__item {
            // flex: 1 1 25%;
            display: block;
            margin-top: 16px;
        }
        &__link {
            display: block;
            width: 64px;
            margin: 0 auto;
        }
        &__image {
            overflow: hidden;
            display: block;
            width: 100%;
            border-radius: 50%;
        }
        &__text {
            overflow: hidden;
            display: block;
            height: 15px;
            margin-top: 6px;
            color: #222;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
        }
    }
}
