[data-type="Cap_Title_Narrow"] {
    .c-headline {
        display: flex;
        align-items: center;
        padding: 20px 20px 18px 20px;

        &__title {
            height: auto;
            padding: 0;
            color: $color-gray-01;
            font-size: 15px;
            line-height: 1;
        }
        &__link {
            padding-right: 15px;

            &:after {
                @include get-sprite-common("ic_chevron", 8);

                right: 0;
                margin: 0;
                border: 0;
                transform: rotate(0) translateY(-50%);
            }
        }
        &__tooltip {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            margin-left: 4px;
            border: 0;
            background-color: transparent;
        }
        &__tooltip-icon {
            @include get-sprite-common("ic_info", 14);

            overflow: hidden;
            color: transparent;
        }
    }
    .c-badge-beta {
        @include get-sprite-common("beta");

        overflow: hidden;
        font-size: 1px;
        color: transparent;
        vertical-align: 2px;
        margin-left: 2px;
    }

    .c-skeleton__bar {
        display: block;
        width: 65px;
        height: 18px;
        margin: 0;
        padding: 0;
        background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
        animation: none;
    }
}
