[data-type="Contents_Luxury_SearchKeyword"] {
    .c-list {
        padding: 0 16px;

        &__item {
            position: relative;
            padding-right: 40px;
            border-bottom: 1px solid $color-gray-12;
        }
        &__link {
            display: block;
            overflow: hidden;
            height: 51px;
            font-size: 14px;
            color: $color-gray-02;
            line-height: 52px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .c-n-delete {
        @include btn-close-x(14px, 1px, #111);

        position: absolute;
        top: 0;
        right: -16px;
        width: 56px;
        height: 52px;
        border: 0;
        background-color: transparent;
    }
}
