[data-type="ContentsGrid_Col3"] {
    @include grid(1, 1, false);
    letter-spacing: $body-spacing;
    &.l-grid__row {
        padding: 0 12px 12px;
    }
    .c-card {
        &--brand {
            .c-card__brand-info {
                position: relative;
                background-color: #fafafa;
                border: 1px solid #f4f4f4;
                .c-card__brand-name {
                    padding: 4px;
                    height: 64px;
                    color: #666;
                    font-size: 13px;
                    span {
                        margin-top: 34px;
                        display: block;
                        white-space: nowrap;
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .c-card__brand-logo {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    margin: -25px 0 0 -25px;
                    @include size(50px);
                    @include mq-320 {
                        & {
                            @include size(50px);
                            margin: -25px 0 0 -25px;
                        }
                    }
                    @include mq-360 {
                        & {
                            @include size(64px);
                            margin: -32px 0 0 -32px;
                        }
                    }
                    @include mq-640 {
                        & {
                            @include size(64px);
                            margin: -32px 0 0 -25px;
                        }
                    }
                }
                img {
                    border-radius: 50%;
                    border: 1px solid #f4f4f4;
                }
            }
            &:before {
                display: none;
            }
            .c-lazyload {
                img {
                    width: 100%;
                }
            }
        }
    }
}
