// ootd 뷰타입 옵션
.c-column-option {
    overflow: hidden;
    width: 40px;
    height: 40px;
    background-color: $color-gray-14;
    border-radius: 40px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(16, 0, 0, 0.14);
    box-sizing: border-box;
    transition: width 0.15s cubic-bezier(0.42, 0, 1, 1);

    &:only-child {
        margin-top: 0;
    }

    &__wrap {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        transition: all 0.15s cubic-bezier(0.42, 0, 1, 1);
    }
    &__button {
        flex-shrink: 0;
        overflow: hidden;
        display: block;
        width: 40px;
        height: 40px;
        border: 0;
        color: transparent;
        background-color: $color-gray-14;

        &:before {
            @include get-sprite-ootd("ic_view_col1", 24);

            content: "";
            display: block;
            margin: 8px;
        }

        &--col1 {
            &[aria-pressed="true"]:before {
                @include get-sprite-position-ootd("ic_view_col1_on");
            }
        }
        &--col2 {
            &:before {
                @include get-sprite-position-ootd("ic_view_col2");
            }

            &[aria-pressed="true"] {
                margin-left: -40px;

                &:before {
                    @include get-sprite-position-ootd("ic_view_col2_on");
                }
            }
        }
        &--col3 {
            &:before {
                @include get-sprite-position-ootd("ic_view_col3");
            }

            &[aria-pressed="true"] {
                margin-left: -80px;

                &:before {
                    @include get-sprite-position-ootd("ic_view_col3_on");
                }
            }
        }
    }

    &--expand {
        width: 104px;

        .c-column-option {
            &__wrap {
                width: 84px;
                margin: 0 10px;
            }
            &__button {
                width: 24px;
                height: 24px;
                margin: 0 2px;

                &:before {
                    margin: 0;
                }
            }
        }
    }
}
