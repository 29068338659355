[data-type="Cap_HasSubtitle"] {
    .c-headline {
        padding: 23px 16px 8px;
        text-align: center;

        &__subtext {
            overflow: hidden;
            position: relative;
            display: inline-block;
            height: 16px;
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: 17px;
            white-space: normal;
            vertical-align: top;

            &[href] {
                padding-right: 12px;

                &:after {
                    content: "";
                    position: absolute;
                    top: 1px;
                    right: 0;
                    width: 8px;
                    height: 14px;
                    background: url("/MW/img/pui/ic_chevron_right.png") no-repeat 0 0;
                    background-size: 100% auto;
                }
            }

            & + .c-headline__title {
                margin-top: -1px;
            }
        }
        &__title {
            height: 27px;
            padding: 0;
            font-size: 19px;
            line-height: 27px;
            white-space: normal;
        }
    }
}
