@import "../../svg/sp_common";

[data-type="ProductScroll_Box"] {
    .s-list {
        height: 100%;
        padding: 0 10px 20px;

        li {
            padding: 0 6px 4px;
        }
    }
    .c-card-item {
        &--box {
            @include size(152px 100%);

            .c-ad__btn {
                top: -28px;
                margin: 0 12px;
            }
        }
        &__link {
            display: block;
            height: 100%;

            &:nth-last-child(3) {
                padding-bottom: 40px;
            }
        }
        &__thumb {
            padding: 12px 12px 0;
        }
        &__info {
            padding-bottom: 11px;
        }
        &__rank {
            top: 12px;
            left: 12px;
            border-radius: 0;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 38px;
            margin-bottom: 4px;
            line-height: 19px;
        }
        &__rate {
            strong {
                font-weight: normal;
            }
        }
        &__lowest {
            display: flex;
        }
        &__lowest-info {
            flex-shrink: 0;
            margin-top: 1px;
        }
        &__benefit {
            margin-top: 6px;
            padding-top: 0;
            font-size: 14px;

            .sktpass {
                overflow: hidden;
                height: 17px;
                padding-top: 1px;
                font-size: 12px;

                &:before {
                    @include get-sprite-position-common("logo_sktpass", 49);
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }

            .shooting-delivery,
            .shooting-install {
                display: flex;
                align-items: center;
                height: 17px;
                strong {
                    flex-shrink: 0;
                    margin: 0;
                }
            }
        }
        &__addition {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
        &__more {
            width: 130px;
            height: 100%;
        }
        &__cart {
            width: 100%;
            height: 35px;
            border-top: 1px solid $color-gray-11;
        }
        &__swatch .c-card-item__thumb {
            padding: 0;
        }
        .c-flag {
            margin-bottom: 3px;
        }
        .c-starrate {
            &--amazon {
                dt {
                    @include size(12px);
                    margin-right: 2px;
                    background-size: 12px 12px;
                }
            }
            &__sati,
            &__gauge {
                @include size(53px 10px);
                background-size: 53px 10px;
            }
            &__sati {
                margin-right: 2px;
            }
            &__review {
                font-size: 11px;
            }
        }

        // 수량 + 장바구니 버튼이 있는 케이스
        &--space {
            .c-card-item {
                &__link {
                    height: calc(100% - 35px);
                }
            }
        }
    }
    .c-starrate {
        height: 13px;
        margin-top: 5px;
    }
    .c-nation {
        margin-bottom: 8px;
    }
    .c-flag__item--ooah,
    .c-flag__item--freshtable {
        margin-top: 3px;
    }

    // 수량 + 장바구니 버튼
    .w-prd__count .c-prd__count {
        button {
            width: 34px;
            height: 34px;
            border: 0;
        }
        &--save {
            width: 40px;
            height: 34px;
            border: 0;
            border-left: 1px solid $color-gray-11;
            border-radius: 0;

            &:before {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &--qty {
            height: 34px;
            border: 0;
            line-height: 36px;
        }
        &--remove {
            left: 7px;
        }
        &--add {
            right: 7px;
        }
    }
}
