[data-ui-type="Content_Time_Dawn"] {
    .w-reserv {
        margin: 0 16px;
        border-bottom: 1px solid #f4f4ff;

        &:last-child {
            padding-bottom: 24px;
        }
        h5 {
            padding-top: 24px;
            color: #333;
        }

        .c-reserv {
            position: relative;
            border-top: 1px solid #f4f4f4;
            padding-bottom: 24px;

            &:first-child {
                border-top: 0;
            }

            &__title {
                padding: 25px 0 16px 0;
                img {
                    display: inline-block;
                    height: 22px;
                    vertical-align: top;
                }
                span {
                    display: inline-block;
                    margin-left: 6px;
                    padding-left: 10px;
                    font-size: 14px;
                    color: #666;
                    line-height: 22px;
                    position: relative;
                    font-weight: normal;
                    &::after {
                        content: "";
                        display: block;
                        left: 0;
                        position: absolute;
                        top: 5px;
                        width: 1px;
                        height: 12px;
                        background-color: #f4f4f4;
                    }
                }
            }
        }

        .c-reserv__cmt {
            position: absolute;
            right: 0;
            top: 28px;
            font-size: 13px;
            color: #999;
        }

        .c-reserv__date {
            border: 1px solid #f4f4f4;
            border-radius: 4px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            text-align: center;
            color: #333;

            li {
                flex: 1;
                span {
                    border-right: 1px solid #f4f4f4;
                }

                &:last-child span {
                    border-right: 0;
                }

                span:first-child {
                    display: block;
                    background-color: #fafafa;
                    font-size: 12px;
                    color: #666;
                    height: 44px;
                    padding-top: 10px;
                    em {
                        display: block;
                        font-weight: bold;
                    }
                }

                .status {
                    display: block;
                    font-size: 13px;
                    padding: 10px;

                    @include mq-360 {
                        padding: 14px;
                    }
                }

                .disable {
                    color: #ccc;
                }
            }
        }

        .c-reserv__table {
            width: 100%;
            table-layout: fixed;

            th,
            td {
                border: 1px solid #f4f4f4;
                border-right-width: 0;
                border-bottom-width: 0;
                text-align: center;
                font-size: 13px;
                vertical-align: middle;
                font-weight: normal;

                &:last-child {
                    border-right-width: 1px;
                }
            }
            thead {
                th {
                    background-color: #fafafa;
                    font-size: 12px;
                    color: #666;
                    height: 44px;
                    width: 46px;
                    padding: 0;

                    &:first-child {
                        padding: 0;
                    }
                    em {
                        display: block;
                        font-weight: bold;
                        color: #333;
                    }
                }
            }
            tbody {
                tr {
                    &:last-child {
                        th,
                        td {
                            border-bottom-width: 1px;
                        }
                    }
                }
                th {
                    padding:0 10px;
                    letter-spacing: -1px;
                }
                th,
                td {
                    height: 54px;
                }
                td {
                    word-wrap: break-word;
                    padding: 0;

                    .disable {
                        color: #ccc;
                    }
                }
            }
        }

        .c-reserv__disable {
            text-align: center;
            height: 76px;
            background-color: #fafafa;
            padding: 16px;
            line-height: 46px;

            span {
                font-size: 15px;
                color: #999;
            }
        }

        .c-reserv__info {
            padding: 8px 0 0;
            font-size: 13px;
            color: #666;
            li {
                position: relative;
                padding: 0 0 0 10px;
                margin-bottom: 5px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 2px;
                    top: 6px;
                    width: 2px;
                    height: 2px;
                    display: block;
                    background-color: #666;
                }
            }
        }
    }
}
