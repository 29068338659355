[data-type="ProductList_TimeDeal"] {
    .c-content-box {
        overflow: hidden;
        min-height: 322px;
        margin: 1px 16px 24px;
        padding: 0 16px;
        border-radius: 4px;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.28), 0px 2px 6px 0px rgba(0, 0, 0, 0.06);

        &__item {
            position: relative;
            height: 161px;
            padding: 16px 0;
            border-top: 1px solid $color-gray-12;

            &:first-child {
                border-top: 0;
            }
        }
    }

    .c-card-item {
        &__link {
            table-layout: fixed;
        }
        &__thumb {
            width: 112px;
            height: 112px;
        }
        &__info {
            padding: 0 0 0 14px;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 40px;
            margin: 6px 0 5px;
            line-height: 20px;
        }
        &__soldout-text {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-gray-14;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            background-color: rgba($color-gray-01, 0.15);
        }
    }

    .c-product-price {
        &__item {
            height: 20px;
        }
        &__lead {
            font-size: 15px;
            line-height: 19px;
        }
        &__rate {
            margin-right: 5px;
            font-size: 17px;
        }
        &__price {
            font-size: 17px;
        }
    }

    .c-product-extra {
        margin-bottom: 1px;
    }

    .c-product-summary {
        margin-top: 8px;
    }

    .c-pagination {
        margin-top: -24px;
        &__indicator {
            line-height: 40px;
        }
    }
}
