@import "../../skpui/component/c-card-deal__info";

[data-type="ProductScroll_Card"] {
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding-bottom: 16px;
    }
    .c-card {
        &__deal {
            @include rounding-box;
            width: 296px;
        }
        &__info {
            @include c-card-deal__info;
            padding: 12px 16px;

            .c-card__benefit {
                margin-right: 70px;
            }
            .c-card__qty {
                bottom: 16px;
                font-size: 12px;
                color: #a9a9a9;

                &--urgent {
                    color: $color-11st-red;
                }
            }

            .c-card__name {
                display: block;
                height: 22px;
                line-height: 22px;
            }

            .c-card__rate {
                strong {
                    font-size: 19px;
                    font-weight: normal;
                    letter-spacing: $body-spacing;
                }
                em {
                    display: inline-block;
                    margin: 1px 0 -1px;
                    font-size: 14px;
                    vertical-align: bottom;
                }
            }
            .c-card__price {
                font-size: 13px;
                letter-spacing: $body-spacing;

                strong {
                    font-size: 19px;
                    letter-spacing: $body-spacing;
                }
                del {
                    padding-left: 4px;
                    font-size: 13px;
                }
            }
        }
        &__soldout__text {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            line-height: 100%;

            span {
                position: absolute;
                top: 50%;
                width: 100%;
                margin-top: -12px;
                font-size: 28px;
                font-weight: bold;
                line-height: 1;
                text-align: center;
            }
        }
        &__soldout-text {
            margin-top: 4px;
            padding-bottom: 3px;
            font-size: 13px;
            color: $color-gray-07;
        }
    }
    .s-list {
        padding: 0 10px 8px;

        li {
            position: relative;
            padding: 0 6px;
        }
        .more {
            padding-left: 130px;

            a {
                display: table;
                position: absolute;
                top: 0;
                left: 0;
                width: 130px;
                height: 218px;
            }

            span {
                display: table-cell;
                font-size: 15px;
                color: $color-gray-03;
                line-height: 22px;
                vertical-align: middle;
                text-align: center;

                &:before {
                    @include sprite-retina($sp_icons_more);
                    display: block;
                    margin: 0 auto 18px;
                    content: "";
                }
            }
        }
    }
}
