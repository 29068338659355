[data-type="AiFeed_Profile"] {
    .ai-feed-profile {
        &__wrap {
            display: flex;
            justify-content: flex-start;
            padding: 32px 20px;
            border-bottom: 1px solid $color-gray-11;
        }
        &__thumbnail {
            $size: 108px;
            position: relative;
            overflow: hidden;
            width: $size;
            height: $size;
            margin-right: 16px;
            border-radius: 100%;
            flex-shrink: 0;

            img {
                $size: 100%;
                width: $size;
                height: $size;
                object-fit: contain;
            }

            &::after {
                position: absolute;
                border: 1px solid rgba($color: $color-gray-01, $alpha: 0.05);
                border-radius: 100px;
                content: "";
                inset: 0;
            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__name {
            display: -webkit-box;
            overflow: hidden;
            font-size: 17px;
            font-weight: 700;
            color: $color-gray-02;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        &__subscript {
            display: -webkit-box;
            overflow: hidden;
            margin-top: 8px;
            font-size: 15px;
            font-weight: 400;
            color: $color-gray-03;
            line-height: 20px;
            letter-spacing: -0.4px;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
        }

        &__feed-count {
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            color: $color-gray-06;
            line-height: 22px;
        }

        &__number {
            font-weight: 700;
        }
    }
}
