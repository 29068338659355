[data-type="Contents_Luxury_Category"] {
    .c-accordion {
        &:before {
            top: -1px;
            bottom: initial;
        }
        &__type {
            padding: 12px 16px;
        }
        &__detail {
            padding: 0 16px;
            background: #fafafa;
        }
    }
    .c-category {
        display: flex;
        align-items: center;

        &__thumb {
            @include size(38px);
            display: inline-block;
            overflow: hidden;
            margin-right: 12px;
            vertical-align: top;

            img {
                @include size(100%);
                object-fit: cover;
            }
            .c-lazyload {
                &:before {
                    background-size: 30px 26px;
                }
            }
        }
        &__title {
            display: inline-block;
            font-size: 15px;
            font-weight: 600;
            color: $color-gray-02;
            line-height: 38px;
            vertical-align: top;
        }
    }
    .c-list {
        @include clearfix;
        margin: 0 -12px;

        // 구버전 PUI CSS 겹치는 문제
        width: auto;
        background-color: transparent;

        &__item {
            float: left;
            width: 50%;
            padding: 5px 6px;
        }
        &__link {
            display: block;
            overflow: hidden;
            max-height: 38px;
            padding: 0 6px;
            font-size: 14px;
            color: $color-gray-02;
            line-height: 38px;
        }
    }
}
