@import "../../svg/sp_common";
[data-type="Contents_OverflowVideo_Scroll"] {
    .c-board {
        overflow: hidden;
        position: relative;
        &__title {
            position: absolute;
            top: 24px;
            left: 16px;
            z-index: 20;
            a {
                display: inline-block;
                vertical-align: top;
            }
            // TODO: MPSR-131562 배포 후 제거 Start
            .lottie {
                display: block;
                width: 121px;
                height: 22px;
                canvas {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                }
            }
            // MPSR-131562 배포 후 제거 End
            .c-lottie {
                display: flex;
                &__container {
                    display: block;
                    width: 121px;
                    height: 22px;
                }
                canvas {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                }
            }
        }
    }

    .c-board-visual {
        overflow: hidden;
        position: relative;
        height: 488px;
        .swiper-wrapper {
            display: flex;
            width: 100%;
            height: 100%;
        }
        .swiper-slide {
            flex-shrink: 0;
            width: 100%;
        }
        &__item {
            position: relative;
            width: 100%;
            height: 100%;
            background: $color-gray-01;

            video,
            .c-board-visual__poster,
            .c-board-visual__video {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .c-board-visual__video {
                opacity: 0;
                transition: opacity 0.35s ease-out;
                &--fadein {
                    opacity: 1;
                }
            }

            &--fadein {
                .c-board-info {
                    opacity: 1;
                    transform: translateX(0);
                    transition-duration: 0.6s;
                }
            }
            &--fadeout {
                .c-board-info {
                    opacity: 0;
                    transform: translateX(16px);
                    transition-duration: 0.3s, 0;
                }
            }
        }

        &__link {
            display: block;
            position: relative;
            height: 100%;
            &:before,
            &:after {
                content: "";
                position: absolute;
                right: 0;
                left: 0;
                z-index: 1;
            }
            &:before {
                top: 0;
                height: 128px;
                background-image: linear-gradient(to bottom, rgba($color-gray-02, 0.3), rgba($color-gray-02, 0));
            }
            &:after {
                bottom: 0;
                height: 140px;
                background-image: linear-gradient(to top, $color-gray-02, rgba($color-gray-02, 0));
            }
        }
    }

    .c-board-info {
        position: absolute;
        right: 16px;
        bottom: 61px;
        left: 16px;
        z-index: 10;
        transform: translateX(16px);
        padding-right: 60px;
        color: $color-gray-14;
        opacity: 0;
        transition-property: opacity, transform;
        transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);

        &__title {
            display: block;
            overflow: hidden;
            height: 23px; // safe area -1px
            margin-top: 12px;
            font-size: 21px;
            line-height: 24px;
            word-break: break-all;
        }

        &__desc {
            display: block;
            overflow: hidden;
            height: 17px; // safe area -1px
            margin-top: 11px;
            font-size: 15px;
            line-height: 18px;
            word-break: break-all;
        }

        .c-profile {
            display: flex;
            align-items: center;
            padding-right: 0;
            &__pic {
                flex-shrink: 0;
                float: none;
                width: 28px;
                height: 28px;
                margin-right: 8px;
                span {
                    width: 100%;
                    height: 100%;
                }
            }
            &__name {
                flex: 1;
                height: 14px;
                font-weight: 700;
                font-size: 13px;
                color: $color-gray-14;
                line-height: normal;
            }
        }
    }

    .c-board-status {
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        bottom: 24px;
        left: 16px;
        z-index: 10;
    }

    .c-board-count {
        position: absolute;
        right: 0;
        bottom: 100%;
        min-width: 30px;
        margin-bottom: 19px;
        font-size: 11px;
        color: $color-gray-14;
        line-height: 18px;
        text-align: right;
        transition: opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
        opacity: 0;
        &--fadein {
            opacity: 1;
        }
    }

    .c-board-pagination {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        position: relative;
        width: 45px;
        height: 17px;
        margin-right: 12px;
        color: $color-gray-14;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1px;
            height: 9px;
            background-color: currentColor;
            opacity: 0.3;
        }
        &__current,
        &__total {
            display: block;
            flex: 1 0;
            font-weight: 600;
            font-size: 13px;
        }
        &__total {
            text-align: right;
            opacity: 0.5;
        }
    }

    .c-board-progress {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 2px;
        margin-bottom: 1px;
        background-color: $color-gray-01; // base color
        &::-webkit-progress-bar {
            background-color: rgba($color-gray-14, 0.4); // progress background
        }
        &::-webkit-progress-value {
            background-color: $color-gray-14; // progress value
            transition: width 0.5s linear;
        }
        // without rewind
        &[value="0"] {
            &::-webkit-progress-value {
                transition: none;
            }
        }
    }

    .c-board-button {
        background: $color-gray-02;
        padding: 0 16px 24px;

        .c-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-gray-04;
            color: $color-gray-14;
            font-size: 15px;
            transition-property: background-color;
            transition-duration: 0.3s;
            transition-timing-function: ease;
            &:after {
                @include ico-arrow-right(8px, currentColor);
                display: block;
                margin: 0 0 1px 5px;
            }

            &.c-button--active {
                border-color: var(--pickThemeColor, $color-luxury);
                background-color: var(--pickThemeColor, $color-luxury);
            }
        }
    }
}
