@import "../../eui/component/relates";

[data-ui-type="Contents_Coupon_Down"] {
    .c-coupon {
        margin: 0 14px;
        padding: 0 0 20px;
        li {
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
        }
        &__box {
            position: relative;
            padding: 16px 94px 16px 20px;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.09);
            &:before,
            &:after {
                content: "";
                position: absolute;
                right: 69px;
                width: 14px;
                height: 6px;
                background: url("/MW/img/mypage/bg_coupon_down_box.png") no-repeat 0 0;
                background-size: 14px 15px;
                z-index: 2;
            }
            &:before {
                top: -1px;
            }
            &:after {
                bottom: -1px;
                background-position: 0 100%;
            }
            .c-coupon__down {
                span {
                    background: transparent linear-gradient(#eee 50%, #fff 50%) repeat-y 0 0;
                    background-size: 2px 6px;
                }
            }
        }
        &__store {
            font-size: 14px;
            color: $color-gray-02;
            &:before {
                @include sprite-retina($sp_store_coupon_icon_store_black);
                display: inline-block;
                margin: -1px 3px 1px 0;
                vertical-align: middle;
                content: "";
            }
        }
        &__title {
            display: block;
            margin-top: 4px;
            font-size: 16px;
            color: $color-11st-red;
            line-height: 26px;
            span {
                font-size: 22px;
                vertical-align: middle;
            }
        }
        &__text {
            margin-top: 4px;
            font-size: 13px;
            line-height: 18px;
            color: $color-gray-04;
        }
        &__down {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background: none;
            border: none;
            color: #f43142;
            font-size: 15px;
            font-weight: 500;
            line-height: 19px;
            text-align: right;

            &:disabled {
                color: #999;
                font-size: 14px;
                font-weight: bold;
                line-height: 15px;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                width: 77px;
                height: 100%;
                text-align: center;
                font-size: 12px;
                line-height: 16px;
                color: $color-gray-03;
                &:before {
                    @include size(32px);
                    display: block;
                    margin: 0 auto 8px;
                    border: 1px solid $color-gray-10;
                    border-radius: 50%;
                    background: $color-gray-14;
                    content: "";
                }
                &:after {
                    @include ico-arrow-right(8px, $color-gray-02, 1px);
                    @include rotate(45deg);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -23px 0 0 -5px;
                    content: "";
                }
            }
        }
    }
    .c-relates {
        border-top: 0;
    }
}
