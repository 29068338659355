[data-type="Cap_ImgBtn"] {
    .c-headline {
        padding: 11px 16px;
        text-align: center;

        &__logo {
            position: relative;
            display: inline-block;
            vertical-align: top;

            img {
                display: inline-block;
                height: 28px;
                vertical-align: top;
            }

            &[href] {
                padding-right: 16px;

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 8px;
                    height: 14px;
                    background: url("/MW/img/pui/ic_chevron_right.png") no-repeat 0 0;
                    background-size: 100% auto;
                    transform: translateY(-50%);
                }
            }
        }
        &__title {
            height: 24px;
            padding: 0;
            font-size: 17px;
            line-height: 24px;
            white-space: normal;
        }
    }
}
