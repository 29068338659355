@charset "UTF-8";

// sheet - 신고하기
.ai-feed-report {
    font-size: 0;

    &__fieldset {
        appearance: none;
        margin: 0;
        padding: 20px 0;
        border: 0;

        & + & {
            padding: 0;
            border-top: 1px solid $color-gray-11;
        }
    }
    &__title {
        padding: 19px 0 17px 16px;
        color: $color-gray-02;
        font-size: 17px;
        font-weight: 700;
        line-height: normal;
        &-star {
            padding-left: 5px;
            color: #8155ea;
        }
    }
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    &__item {
        display: flex;
        position: relative;
        margin-bottom: 12px;
        padding-left: 20px;

        input {
            position: absolute;
            width: 1px;
            height: 1px;
            z-index: -1;
            opacity: 0;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        color: $color-gray-02;
        font-size: 15px;
        font-weight: 400;

        &::before,
        &::after {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        &::before {
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.5' y='1.5' width='21' height='21' rx='10.5' fill='white' stroke='%23BBBBBB' /%3E%3Crect x='7' y='7' width='10' height='10' rx='5' fill='%23DDDDDD' /%3E%3C/svg%3E") 0 0 no-repeat;
        }
        @at-root .ai-feed-report__item input:checked + &::before {
            background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='checkbox/circle/unselected'%3E%3Crect id='border' x='1' y='1' width='22' height='22' rx='11' fill='%238155EA'/%3E%3Crect id='radio' x='7' y='7' width='10' height='10' rx='5' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A") 0 0 no-repeat;
        }
    }

    &__textarea {
        width: calc(107% - 40px);
        height: var(--textareaHeight, 184px);
        margin: 0 20px;
        padding: 16px;
        border: 1px solid $color-gray-10;
        border-radius: 4px;
        font-size: 16px;
        resize: none;
        box-sizing: border-box;
        transform: scale(0.9375);
        transform-origin: 0 0;
    }
    &__buttons {
        position: absolute;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        padding: 20px;
        background-color: $color-gray-14;
    }
    &__button {
        appearance: none;
        flex-grow: 1;
        height: 48px;
        padding: 10px;
        border-radius: 4px;
        border: 0;
        background: #8155ea;
        font-size: 16px;
        font-weight: 700;
        color: $color-gray-14;
        &:disabled {
            background: $color-gray-09;
            cursor: not-allowed;
        }
    }
} //.ai-feed
