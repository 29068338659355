.c-product-extra {
    * {
        font-family: $font-family3;
    }

    &__desc {
        font-size: 13px;
        line-height: 16px;

        &--primary {
            color: $color-11st-red;
        }
    }
}
