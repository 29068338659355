[data-type="ProductGrid_Round"] {
    @include ootdLikeButton;

    .c-lazyload img {
        object-fit: cover;
        border-radius: 12px; // 아이폰 미니에서 radius가 정상적으로 랜더링 되지 않는다
    }
    .c-skeleton {
        &__box {
            padding-bottom: 133.33%;
            border-radius: 12px;
        }
        &__bar {
            &:nth-child(0) {
                width: 70px;
            }
            &:nth-child(1) {
                width: 137px;
            }
            &:nth-child(2) {
                width: 118px;
            }
        }
    }

    .c-n-product {
        &__rank {
            top: 8px;
            left: 8px;
            color: $color-gray-14;
            font-size: 12px;
            font-family: $font-outfit;
            background-color: rgba(34, 34, 34, 0.95);
            border-radius: 6px;
        }
        &__thumb {
            z-index: 1;
            overflow: hidden;
            border-radius: 12px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                border: 1px solid rgba($color-gray-01, 0.02);
                background-color: rgba($color-gray-01, 0.05);
                border-radius: 12px;
            }
            &:after {
                opacity: 1;
                background-color: transparent;
            }

            &--gradient:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 44px;
                background: linear-gradient(180deg, rgba($color-gray-01, 0.2), rgba($color-gray-01, 0));
            }
        }
        &__info {
            position: static;
            padding: 8px 6px 24px;
        }
        &__name {
            margin-top: 4px;
            color: $color-gray-02;
        }
        &__buttons {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
            padding-top: 134.55%; // lazyload 비율과 동일하게 넣어준다
        }
        &__mark {
            @include get-sprite-ootd("ic_mark", 40);

            flex-shrink: 0;
            margin-left: 3px;
        }

        .price-rate {
            color: $color-11st-red;
        }
    }

    .c-product {
        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 8px;
        }
        &__item {
            width: 50%;
            padding: 0 8px;
        }
        &__delete {
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;
            border: 0;
            outline: 0;
            background-color: transparent;

            &:before {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                background: url("/MW/img/ootd/ic_remove.png") no-repeat; // 이미지 이슈로 svg 사용하지 않음
                background-size: 100%;
            }
        }
    }
}
