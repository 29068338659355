[data-type="Contents_Luxury_BrandTitle"] {
    .c-n-headline {
        display: flex;
        padding: 25px 16px;
        align-items: center;

        &__title {
            overflow: hidden;
            max-height: 20px;
            padding-right: 16px;
            font-size: 16px;
            line-height: 20px;
            flex: 1;

            &-sub {
                display: inline-block;
                margin-left: 3px;
                font-size: 14px;
                font-weight: normal;
                color: $color-gray-06;
            }
        }
        &__float {
            margin: -10px auto;
        }
    }
}
