@import "../../search/component/search-filter";
@import "../../svg/sp_search";

[data-type="Contents_CategoryFilter"] {
    letter-spacing: $body-spacing;

    .s-list {
        padding: 0 42px 0 12px;

        li {
            padding: 6px 0;
        }
    }
    .c-sorting {
        display: flex;
        height: 48px;
        letter-spacing: $body-spacing;
        align-items: center;

        &__qty {
            float: none;
            display: flex;
            align-items: center;
            margin-right: auto;
            padding-left: 16px;
            font-size: 13px;
            font-family: $font-family3;
            color: $color-gray-03;

            strong {
                margin-top: -1px;
                padding-right: 2px;
                padding-left: 3px;
                font-size: 14px;
                color: $color-gray-02;
            }
        }
        &__order {
            float: none;
            border-left: 0;

            &__button {
                overflow: hidden;
                padding: 0 42px 0 16px;
                border: none;
                background: none;
                font-size: 14px;
                line-height: 44px;
                outline: none;

                &:before {
                    @include get-sprite-search("sorting_arrow");
                    top: 50%;
                    left: auto;
                    right: 16px;
                    transform: translateY(-50%);
                }
            }
        }
        &__filter {
            float: none;
            margin-right: 10px;
            border: none;
            flex-shrink: 0;

            &-button {
                height: 32px;
                padding: 0 6px 0 11px;
                border: none;
                border-radius: 4px;
                background: var(--blockPointColor, $color-11st-red);
                color: $color-gray-14;
                font-size: 14px;
                font-weight: bold;
                line-height: 30px;
                white-space: nowrap;

                &:before {
                    display: none;
                }
                &:after {
                    @include get-sprite-search("filter");
                    display: inline-block;
                    margin: -1px 0 1px;
                    vertical-align: middle;
                    content: "";
                }
                &:disabled,
                &.disabled {
                    background: $color-gray-09;
                }
            }
        }

        &-check {
            display: flex;
            height: 44px;
            margin: 0 auto 0 16px;
            align-items: center;

            &__item {
                display: block;
                margin-right: 12px;

                .c-checkbox {
                    & + label {
                        font-size: 14px;
                    }
                }
                .new {
                    overflow: hidden;
                    position: absolute;
                    top: 1px;
                    right: -5px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: $color-11st-red;
                    color: transparent;
                }
            }
        }

        &__wrapper {
            overflow-x: auto;
            overflow-y: hidden;
            display: flex;
            width: 100%;

            // flexbox 안에서 체크박스 선택시 튀는 현상 해결
            .c-checkbox {
                top: 0;
                left: 0;
            }

            .c-sorting-check {
                flex-shrink: 0;

                label {
                    font-weight: 700;
                }

                &__item {
                    position: relative;
                    flex-shrink: 0;
                }
            }
        }

        // PUI 어드민에서 색상값 옵션이 추가됨
        .c-checkbox:checked + label:before {
            border-color: var(--blockPointColor, $color-11st-red);
            background: var(--blockPointColor, $color-11st-red);
        }
    }
    .c-filter-keyword {
        position: relative;
        border-bottom: 1px solid $color-gray-11;
        background: $color-gray-13;

        &__reset {
            @include size(32px);
            overflow: hidden;
            position: absolute;
            top: 6px;
            right: 8px;
            border: none;
            background: none;
            color: transparent;

            &:after {
                @include get-sprite-search("reset", 24);

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }
        .c-chip {
            margin: 0;
            padding: 0 28px 0 4px;
            background: transparent;
            border: none;
            font-size: 15px;
            color: $color-gray-04;
        }
    }
}

.c-search-filter {
    z-index: 102;

    &--gap {
        padding-bottom: calc(env(safe-area-inset-bottom));
    }
}

.c-popup--guide {
    .c-popup__content {
        margin: 16px 0 32px;
        text-align: left;
    }
    .c-popup__text {
        margin-top: 0;
        color: $color-gray-03;
        font-size: 15px;
    }
}

.dim-filter {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    @include dimned-opacity();
}
