[data-type="GridList_Text_CtgrNavi"] {
    .c-subcategory {
        li {
            .c-subcategory__link {
                &.on {
                    font-weight: bold;
                }
            }
        }
    }
}
