.c-n-headline {
    display: block;
    position: relative;
    padding: 32px 16px 24px;

    &__title {
        color: #111;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
    }
    &__desc {
        margin-top: 5px;
        color: #888;
        font-size: 14px;
        font-family: $font-family3;
        line-height: 17px;
    }
    &__more {
        position: absolute;
        top: 0;
        right: 7px;
        padding: 7px;
        border: 0;
    }
    &__cross {
        @include get-sprite-luxury("ic-brand-plus", 18);

        overflow: hidden;
        display: block;
        color: transparent;
        transform: scale(0.78);
    }
}
