.c-toast {
    // 기대평 입력시 가상키패드로 인한 버그 예정을 위해 토스트 레이어 상단으로 위치 변경
    &--top {
        transform: none;
        top: 69px;
        left: 16px;
        right: 16px;
        bottom: auto;
    }
}
