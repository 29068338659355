[data-type="ExhibitionBanner_Roadshop_NewItem"] {
    position: relative;
    padding-top: 4px;
    padding-bottom: 16px;
    .c-photocard {
        position: relative;
        display: block;
        &__info--float {
            position: absolute;
            bottom: 20px;
            right: 40px;
            z-index: 1;
        }
        &__text {
            @include size(120px);
            position: absolute;
            bottom: -4px;
            right: -23px;
            background-color: #fff;
            text-align: center;
            opacity: 0;
            &:before {
                @include size(0);
                content: "";
                display: block;
                position: absolute;
                top: 50px;
                left: -11px;
                z-index: 1;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-right: 12px solid #fff;
            }
            &-title {
                display: block;
                margin: 30px 16px 0 16px;
                font-size: 14px;
                color: #000;
                line-height: 20px;
                max-height: 56px;
                overflow: hidden;
            }
            &-name {
                display: block;
                margin-top: 10px;
                font-size: 12px;
                color: #19b275;
            }
            &.active {
                opacity: 1;
            }
        }
        &__image {
            .c-lazyload img {
                width: auto;
                height: 100%;
                max-width: initial;
                max-height: initial;
                left: -100%;
                right: -100%;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
