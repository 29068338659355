[data-type="ProductScroll_BasicBox"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;
    .c-headline {
        &__title {
            height: 54px;
            font-size: $font-size-subtitle2;
            font-weight: normal;
            span {
                color: $color-11st-blue;
            }
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
    }
    .s-list {
        height: 100%;
        padding: 0 10px 24px;

        li {
            position: relative;
            padding: 0 6px;
            .c-card {
                width: 152px;
                height: 100%;

                &__thumb > .c-lazyload {
                    width: 152px;
                    height: 152px;
                }
                &__rank {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 24px;
                    height: 24px;
                    border-bottom-right-radius: 4px;
                    background: rgba($color: $color-11st-red, $alpha: 0.8);
                    font-size: 13px;
                    color: $color-gray-14;
                    line-height: 26px;
                    text-align: center;
                    content: "";
                }
                &__info {
                    padding: 12px 12px 10px 12px;
                }
                &__name {
                    display: block;
                    height: 38px;
                    margin-bottom: 5px;
                    line-height: 19px;
                }
                &__rate {
                    strong {
                        font-weight: normal;
                    }
                }
                &__price {
                    line-height: 20px;
                    vertical-align: middle;
                }
                &__counsel {
                    padding: 0;
                    line-height: 20px;
                }
                .c-starrate {
                    margin-top: 6px;

                    &__sati,
                    &__gauge {
                        @include size(53px 10px);
                        background-size: 53px 10px;
                    }
                    &__sati {
                        margin-right: 2px;
                    }
                    &__review {
                        font-size: 11px;
                    }
                }
            }
            .more {
                @include scroll-more(130px, 100%);
            }
            .c-ad__btn {
                position: absolute;
                left: 7px;
                top: 137px;
                outline: none;
            }
        }
        &--high {
            li {
                .c-card__name {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .c-box-list {
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $color-gray-12;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        &:nth-of-type(1) {
            &:before {
                display: none;
            }
        }
    }

    .viewport-ad {
        height: 100%;
    }
}
