.c-product-nudge {
    overflow: hidden;
    position: relative;
    height: 12px;

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        will-change: transform;
    }
    &__item {
        height: 12px;
    }
    &__unit {
        overflow: hidden;
        max-width: 100px;
        display: flex;
        align-items: center;
        color: $color-gray-07;
        font-size: 12px;
        font-family: $font-family3;
        line-height: 12px;

        &--warning {
            color: #ff7510;
        }
        &--primary {
            color: $color-11st-red;
        }
    }
    &__ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
    }
    &__text {
        flex-shrink: 0;
    }

    @media screen and (max-width: 359px) {
        display: none;
    }
}
