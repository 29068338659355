[data-type="Contents_Luxury_BrandSearch"] {
    padding: 0 16px 16px;

    .c-n-search-bar {
        &__input::placeholder {
            color: #646464;
        }
        &__submit {
            @include get-sprite-luxury("ic-brand-search", 24);

            right: 15px;
        }
    }
}
