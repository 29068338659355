.c-n-tab {
    font-family: $font-family3;

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__item {
        flex: 1;
        display: flex;
    }
    &__button {
        margin-right: auto;
        margin-left: auto;
        border: 0;
        background-color: #fff;
    }
    &__underline {
        display: inline-block;
        padding: 15px 4px 14px;
        border-bottom: 2px solid transparent;
        color: #666;
        font-size: 15px;
        line-height: 19px;
    }
    &__button--selected .c-n-tab__underline,
    &__button[aria-selected="true"] .c-n-tab__underline {
        border-color: #111;
        font-weight: 600;
        color: #111;
    }
    &__block {
        overflow: hidden;
        display: block;
        height: 42px;
        padding-right: 16px;
        padding-left: 16px;
        color: #111;
        font-size: 15px;
        line-height: 42px;
        background-color: #fafafa;
        border-radius: 4px;
    }
    &__button[aria-selected="true"] .c-n-tab__block {
        color: #fff;
        font-weight: 600;
        background-color: #111;
    }

    &--spread {
        .c-n-tab__list {
            overflow: auto hidden;
            width: 100%;
            flex-wrap: nowrap;
            justify-content: flex-start;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        .c-n-tab__item {
            flex: 0 0 auto;
        }
    }
}
