[data-type="AiFeed_GridScroll_ImgTextCircle"] {
    .ai-feed-category {
        &__filter {
            &--simple {
                z-index: 20;
                position: fixed;
                top: 56px;
                right: 0;
                left: 0;
                border-bottom: 1px solid $color-gray-11;
                background: $color-gray-14;
                transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
                will-change: transform;

                .ai-feed-category {
                    &__header {
                        display: none;
                    }

                    &__filter-list {
                        height: auto;
                        padding: 16px;
                    }

                    &__filter-item {
                        & + .ai-feed-category__filter-item {
                            margin-left: 6px;
                        }
                    }

                    &__filter-button {
                        align-items: center;
                        width: auto;
                        min-width: 69px;
                        height: 36px;
                        padding: 0;
                        border: none;
                        border-radius: 8px;
                        background: $color-gray-14;
                        box-sizing: border-box;

                        &--selected {
                            .ai-feed-category__filter-image:after {
                                content: none;
                            }
                        }
                    }

                    &__filter-checkbox:checked + .ai-feed-category__filter-label {
                        &:after {
                            z-index: -2;
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            bottom: -1px;
                            left: -1px;
                            border-radius: 9px;
                            background: linear-gradient(90deg, #47b8e7 0%, #df50f7 107.78%);
                            content: "";
                        }
                    }

                    &__filter-label {
                        flex-direction: row;
                        z-index: 1;
                        position: relative;
                        width: max-content;
                        min-width: 69px;
                        padding: 0 7px;
                        border: 1px solid $color-gray-10;
                        border-radius: 8px;

                        &:before {
                            z-index: -1;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            border-radius: 8px;
                            background-color: $color-gray-14;
                            content: "";
                        }
                    }

                    &__filter-image {
                        width: 24px;
                        height: 24px;
                        margin: 0 4px 0 0;
                    }
                }
            }

            &--hidden {
                transition: transform 195ms cubic-bezier(0.4, 0, 1, 1);
                transform: translateY(-100%);
            }

            &--visible {
                transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
                transform: translateY(0);
            }

            &:not(.ai-feed-category__filter--simple) {
                .ai-feed-category__filter-checkbox:checked + .ai-feed-category__filter-label {
                    .ai-feed-category__filter-image {
                        width: 56px;
                        height: 56px;
                        margin: -2px 0 6px;

                        img {
                            border: 2px solid $color-gray-14;
                        }
                    }

                    .ai-feed-category__filter-image {
                        &:after {
                            z-index: -1;
                            position: absolute;
                            top: -2px;
                            right: -2px;
                            bottom: -2px;
                            left: -2px;
                            border-radius: 50%;
                            background: linear-gradient(90deg, #47b8e7 0%, #df50f7 107.78%);
                            content: "";
                        }
                    }
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            padding: 20px 16px 0;
        }

        &__title {
            font-size: 13px;
            font-weight: 400;
            color: $color-gray-03;
        }

        &__filter-list {
            display: flex;
            align-items: stretch;
            overflow-x: auto;
            overflow-y: hidden;
            height: 108px;
            padding: 0 8px;
        }

        &__filter-item {
            height: 100%;
        }

        &__filter-checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
        }

        &__filter-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 76px;
            height: 100%;
        }

        &__filter-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 76px;
            height: 100%;
            padding: 16px 0;
            border: none;
            background: none;
            font-size: 13px;
            color: $color-gray-03;
            text-align: center;
            cursor: pointer;
        }

        .ai-feed-category__filter-checkbox:checked + .ai-feed-category__filter-label {
            font-weight: 700;
            color: #8155ea;

            .ai-feed-category__filter-image {
                z-index: 0;
                overflow: visible;
            }
        }

        &__filter-image {
            flex: 0 0 auto;
            position: relative;
            overflow: hidden;
            width: 52px;
            height: 52px;
            margin-bottom: 8px;
            border-radius: 50%;
            box-sizing: border-box;

            &:before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid rgba($color: $color-gray-01, $alpha: 0.05);
                border-radius: 50%;
                content: "";
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }
}
