[data-type="Tabs_RedBoxBtn"] {
    .c-tabbox {
        margin: 0 16px;
        padding: 1px;
        background-image: linear-gradient(135deg, #ff5a2e 0, #ff0038 51.96%, #ff00ef 100%);
        border-radius: 4px;

        &__list {
            overflow: hidden;
            display: flex;
            border-radius: 4px;
        }
        &__item {
            flex: 1;
            margin-left: 1px;
            background-color: $color-gray-14;

            &:first-child {
                margin-left: 0;
            }
        }
        &__link {
            overflow: hidden;
            position: relative;
            z-index: 5;
            width: 100%;
            height: 100%;
            border: 0;
            color: $color-gray-02;
            background-color: $color-gray-14;

            &[aria-selected="true"] {
                z-index: 6;
                width: calc(100% + 2px);
                margin: 0 -1px;

                .c-tabbox__inner {
                    color: $color-gray-14;
                    font-weight: 700;
                    background-image: linear-gradient(135deg, #ff5a2e 0, #ff0038 51.96%, #ff00ef 100%);
                    border-radius: 4px;
                }
            }
        }
        &__inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 12px 8px 11px 8px;
        }
        &__text {
            position: relative;
            font-size: 15px;
            line-height: 1;
            font-family: $font-family3;
        }
        &__dot {
            overflow: hidden;
            position: absolute;
            top: -2px;
            right: -4px;
            width: 4px;
            height: 4px;
            color: transparent;
            font-size: 5px;
            background-color: $color-11st-red;
            border-radius: 50%;
        }
    }
}
