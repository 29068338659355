// 하단 쇼호스트 오버블로 레이어
.layer-card__shophost {
    padding: 0 0 16px 0;

    .s-list {
        display: block;
        li {
            display: block;
        }
    }

    .w-scroll-css {
        .s-list {
            display: table;
            padding: 4px 12px;
            li {
                display: table-cell;
                padding: 4px;
                scroll-snap-align: start;
                &:first-child {
                    padding-left: 16px;
                }
            }
        }

        .c-card__shophost {
            width: 270px;
        }
    }

    .c-card__shophost {
        display: block;
        padding: 20px;
        position: relative;
        border-radius: 8px;
        .c-profile {
            padding-right: 0;
            &__pic {
                @include size(54px);
                span {
                    @include size(54px);
                    border-radius: 0;
                }
                &::after {
                    display: none;
                }
                .c-lazyload {
                    &:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include size(52px);
                        border: 1px solid rgba(92, 30, 30, 0.04);
                        border-radius: 27px;
                        content: "";
                    }
                }
            }
            img {
                @include size(54px);
                object-fit: cover;
                border-radius: 50%;
            }
            &__name {
                height: 54px;
                line-height: 54px;
                font-size: 17px;
                font-weight: bold;
                text-overflow: initial;
                white-space: initial;
            }
        }
        &-history {
            padding-top: 10px;
            line-height: 20px;
            font-size: 14px;
            color: $color-gray-02;
            height: 160px;
            overflow-y: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera*/
            }
            a {
                color: #2a5db0;
            }
        }
    }
}
