[data-ui-type="Content_Time_Picker"] {
    $font-family-num: Roboto, -apple-system; // PDP/전시 일관된 UI를 위해 숫자 영역에 font-famliy 지정
    min-height: 550px;

    .w-tab__date {
        padding: 0 16px;
        display: flex;
        flex: 1;
        border-bottom: 1px solid $color-gray-12;

        li {
            width: 25%;

            a {
                display: block;
                height: 70px;
                padding-top: 16px;
                text-align: center;
                font-size: 15px;
                color: #333;
                position: relative;
                font-weight: bold;
                font-family: $font-family-num;

                span {
                    display: block;
                    font-size: 12px;
                    color: #999;
                    padding: 2px 0 3px;
                }
            }

            &[aria-selected="true"] a {
                color: $color-11st-red;

                span {
                    color: $color-11st-red;
                }

                &::after {
                    content: "";
                    display: block;
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    background-color: $color-11st-red;
                }
            }
        }
    }

    .w-picker {
        padding: 0 16px;

        .c-picker {
            position: relative;

            border-top: 1px solid #f4f4f4;
            padding-bottom: 20px;

            &:first-child {
                border-top: 0;
            }

            &__title {
                padding: 25px 0 16px 0;

                img {
                    display: inline-block;
                    height: 22px;
                    vertical-align: top;
                }

                span {
                    display: inline-block;
                    margin-left: 6px;
                    padding-left: 10px;
                    font-size: 14px;
                    color: #666;
                    line-height: 22px;
                    position: relative;
                    font-weight: normal;

                    &::after {
                        content: "";
                        display: block;
                        left: 0;
                        position: absolute;
                        top: 5px;
                        width: 1px;
                        height: 12px;
                        background-color: #f4f4f4;
                    }
                }
            }

            &__cmt {
                position: absolute;
                right: 0;
                top: 28px;
                font-size: 12px;
                color: #999;
            }

            &__disable {
                text-align: center;
                height: 76px;
                background-color: #fafafa;
                padding: 16px;
                line-height: 46px;

                span {
                    font-size: 15px;
                    color: #999;
                }
            }

            &__term {
                padding: 10px 8px 10px;

                background-color: #fafafa;
                @include clearfix;

                .c-picker__gd {
                    width: 100%;
                    ol {
                        width: 100%;
                        padding: 0 30px;
                        display: flex;

                        li {
                            flex: 1;
                        }
                    }
                }

                li {
                    float: left;
                    width: 33.3%;
                    height: 28px;
                    line-height: 28px;
                    font-family: $font-family-num;
                    font-size: 15px;
                    color: #333;
                    text-align: center;
                    letter-spacing: -1px;

                    img[alt="굿모닝배송"] {
                        width: 16px;
                        position: relative;
                        top: 2px;
                        margin-right: 2px;
                    }

                    em + span {
                        color: #ccc;
                        cursor: not-allowed;
                    }
                }
            }
            &__noti {
                padding: 6px 0;
                font-size: 13px;
                em {
                    font-weight: bold;
                }
                img {
                    width: 16px;
                    position: relative;
                    top: 4px;
                    margin-right: 2px;
                }
            }
        }
    }
}
