[data-ui-type="ProductDetail_Ooah_Pick_Html"] {
    margin-bottom: 16px;
    .c-n-spinner {
        padding: 40px 10px;
    }
    .c-spinner {
        position: relative;
        top: inherit;
        bottom: inherit;
        left: inherit;
        right: inherit;
        z-index: 0;
        height: 100%;
        background: transparent;
        text-indent: 0;
        padding: 40px 10px;
        &::before {
            position: relative;
            top: inherit;
            left: inherit;
            display: block;
            margin: auto;
            border: 1px solid rgba(0, 0, 0, 0.06);
        }
    }
}
