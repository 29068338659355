.c-tooltip,
.c-tooltip-point {
    position: absolute;
    padding: 7px 12px;
    border-radius: 14px;
    font-size: 12px;
    color: $color-gray-14;
    letter-spacing: $body-spacing;
    line-height: 13px;

    &:before {
        position: absolute;
        top: -7px;
        left: 12px;
        border: solid transparent;
        border-width: 0 6px 7px;
        content: "";
    }

    &--top-center {
        &:before {
            left: 50%;
            margin-left: -6px;
        }
    }

    &--top-right {
        &:before {
            right: 12px;
            left: auto;
        }
    }

    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
        &:before {
            top: auto;
            bottom: -7px;
            // border-top-color: rgba($color: #000, $alpha: 0.8);
            border-width: 7px 6px 0;
        }
    }

    &--bottom-center {
        &:before {
            left: 50%;
            margin-left: -6px;
        }
    }

    &--bottom-right {
        &:before {
            left: auto;
            right: 12px;
        }
    }
}

.c-tooltip {
    background: rgba($color: #000, $alpha: 0.8);

    &:before {
        border-bottom-color: rgba($color: #000, $alpha: 0.8);
    }
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
        &:before {
            border-top-color: rgba($color: #000, $alpha: 0.8);
        }
    }
}

.c-tooltip-point {
    background: rgba($color: $color-11st-blue, $alpha: 0.8);

    &:before {
        border-bottom-color: rgba($color: $color-11st-blue, $alpha: 0.8);
    }
    &.c-tooltip--bottom-left,
    &.c-tooltip--bottom-center,
    &.c-tooltip--bottom-right {
        &:before {
            border-top-color: rgba($color: $color-11st-blue, $alpha: 0.8);
        }
    }
}

.c-tooltip-gradient {
    $body-size: (
        // height
        default: 28px,
        small: 22px
    );
    $tail-size: (
        // width height
        default: 12px 7px,
        small: 8px 4px
    );
    $tail-gap: (
        // 꼬리 좌우 간격
        default: 14px,
        small: 16px
    );
    // 좌우 반원 이미지
    $side-bg: radial-gradient(circle at right, #000 44%, transparent 45%), linear-gradient(#000, #000), radial-gradient(circle at left, #000 44%, transparent 45%);

    // 몸통 높이 (기본: 28px)
    @function getBodySize($type: default) {
        @if map-has-key($body-size, $type) {
            @return map-get($body-size, $type);
        }
        @warn "Unknown `#{$type}` in $body-size.";
        @return null;
    }
    // 꼬리 크기 (기본: 7px (12px 7px, 높이))
    @function getTailSize($type: default, $isHeight: true) {
        @if map-has-key($tail-size, $type) {
            @if ($isHeight) {
                @return nth(map-get($tail-size, $type), 2);
            }
            @return nth(map-get($tail-size, $type), 1);
        }
        @warn "Unknown `#{$type}` in $tail-size.";
        @return null;
    }
    // 꼬리 좌우 간격 (기본: 14px)
    @function getTailGap($type: default) {
        @if map-has-key($tail-gap, $type) {
            @return map-get($tail-gap, $type);
        }
        @warn "Unknown `#{$type}` in $tail-gap.";
        @return null;
    }
    // 몸통 이미지 위치 (기본: 위방향 28px 기준)
    @function getBodyPosition($position: top, $type: default) {
        @if ($position == top) {
            // 왼쪽 반원 X Y, 오른쪽 반원 X Y, 가운데 사각형 X Y
            @return -#{getBodySize($type) / 2} 100%, #{getBodySize($type) / 2} 100%, calc(100% + #{getBodySize($type) / 2}) 100%;
        } @else if ($position == bottom) {
            @return -#{getBodySize($type) / 2} 0, #{getBodySize($type) / 2} 0, calc(100% + #{getBodySize($type) / 2}) 0;
        }
        @warn "This is an unavailable value. Check position and gap.";
        @return null;
    }
    // px 단위 제거
    @function removeUnit($value) {
        @return ($value / ($value * 0 + 1));
    }
    // 마스크 이미지 (기본: 위방향 28px 기준)
    @function getMaskImg($position: top, $type: default) {
        $w: removeUnit(nth(map-get($tail-size, $type), 1));
        $h: removeUnit(nth(map-get($tail-size, $type), 2));
        // 좌우반원과 꼬리이미지 경로를 모아서 return
        @if ($position == top) {
            @return $side-bg, url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 #{$w} #{$h}"><path d="M#{$w/2} 0 L0 #{$h} L#{$w} #{$h} Z"/></svg>');
        } @else if ($position == bottom) {
            @return $side-bg, url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 #{$w} #{$h}"><path d="M0 0 L#{$w/2} #{$h} L#{$w} 0 Z"/></svg>');
        }
        @warn "This is an unavailable value. Check position or size.";
        @return null;
    }

    // base
    position: absolute;
    height: getBodySize() + getTailSize();
    padding: 7px + getTailSize() 12px 7px;
    color: $color-gray-14;
    letter-spacing: $body-spacing;
    font-size: 12px;
    white-space: nowrap;
    box-sizing: border-box;
    // bg
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(98deg, $color-11st-red 60%, $color-11st-gradient-pink 110%);
        opacity: 0.9;
        -webkit-mask-image: getMaskImg(top);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: getBodyPosition(), getTailGap() 0;
        -webkit-mask-size: getBodySize() calc(100% - #{getTailSize()}), calc(100% - #{getBodySize()}) calc(100% - #{getTailSize()}), getBodySize() calc(100% - #{getTailSize()}), getTailSize($isHeight: false) getTailSize();
    }
    .c-tooltip__text {
        position: relative;
    }

    &.c-tooltip--top-right {
        &:before {
            -webkit-mask-position: getBodyPosition(), calc(100% - #{getTailGap()}) 0;
        }
    }
    &.c-tooltip--top-center {
        &:before {
            margin-left: 0;
            -webkit-mask-position: getBodyPosition(), 50% 0;
        }
    }
    &.c-tooltip--bottom-left,
    &.c-tooltip--bottom-right,
    &.c-tooltip--bottom-center {
        padding: 7px 12px 7px + getTailSize();
        &:before {
            -webkit-mask-image: getMaskImg(bottom);
        }
    }
    &.c-tooltip--bottom-left {
        &:before {
            -webkit-mask-position: getBodyPosition(bottom), getTailGap() 100%;
        }
    }
    &.c-tooltip--bottom-right {
        &:before {
            -webkit-mask-position: getBodyPosition(bottom), calc(100% - #{getTailGap()}) 100%;
        }
    }
    &.c-tooltip--bottom-center {
        &:before {
            margin-left: 0;
            -webkit-mask-position: getBodyPosition(bottom), 50% 100%;
        }
    }

    // [CASE] small
    &-s {
        // base
        position: absolute;
        height: getBodySize(small) + getTailSize(small);
        padding: (5px + getTailSize(small)) 8px 4px;
        font-weight: 700;
        font-size: 11px;
        color: $color-gray-14;
        letter-spacing: $body-spacing;
        white-space: nowrap;
        box-sizing: border-box;
        filter: drop-shadow(1px 1px 0 rgba(black, 0.04)) drop-shadow(1px -1px 0 rgba(black, 0.04)) drop-shadow(-1px -1px 0 rgba(black, 0.04)) drop-shadow(-1px 1px 0 rgba(black, 0.04));
        // bg
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: $color-11st-gradient;
            -webkit-mask-image: getMaskImg(top, small);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: getBodyPosition($type: small), getTailGap(small) 0;
            -webkit-mask-size: getBodySize(small) calc(100% - #{getTailSize(small)}), calc(100% - #{getBodySize(small)}) calc(100% - #{getTailSize(small)}), getBodySize(small) calc(100% - #{getTailSize(small)}), getTailSize(small, $isHeight: false) getTailSize(small);
        }
        .c-tooltip__text {
            position: relative;
        }

        &.c-tooltip--top-right {
            &:before {
                -webkit-mask-position: getBodyPosition($type: small), calc(100% - #{getTailGap(small)}) 0;
            }
        }
        &.c-tooltip--top-center {
            &:before {
                margin-left: 0;
                -webkit-mask-position: getBodyPosition($type: small), 50% 0;
            }
        }
        &.c-tooltip--bottom-left,
        &.c-tooltip--bottom-right,
        &.c-tooltip--bottom-center {
            padding: 5px 8px (4px + getTailSize(small));
            &:before {
                -webkit-mask-image: getMaskImg(bottom, small);
            }
        }
        &.c-tooltip--bottom-left {
            &:before {
                -webkit-mask-position: getBodyPosition(bottom, small), getTailGap(small) 100%;
            }
        }
        &.c-tooltip--bottom-right {
            &:before {
                -webkit-mask-position: getBodyPosition(bottom, small), calc(100% - #{getTailGap(small)}) 100%;
            }
        }
        &.c-tooltip--bottom-center {
            &:before {
                margin-left: 0;
                -webkit-mask-position: getBodyPosition(bottom, small), 50% 100%;
            }
        }
    }
}
