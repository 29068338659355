[data-type="ProductScroll_Luxury_Basic"] {
    .c-n-product {
        width: 162px;

        &__info {
            padding: 12px 16px 0 0;
        }
        &__buttons {
            top: -38px;
        }
    }
    .c-n-like {
        padding: 4px;
        box-sizing: content-box;
    }

    .c-list-more {
        position: relative;
        display: block;
        width: 40px;
        margin: 57px 30px 37px;
        text-align: center;

        &:before {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            margin-bottom: 8px;
            border: 1px solid #111;
            border-radius: 50%;
        }

        &__circle {
            color: #111;
            font-size: 13px;
            line-height: 16px;

            &:before {
                @include get-sprite-luxury("ic-brand-plus", 18);

                content: "";
                position: absolute;
                top: 11px;
                left: 50%;
                transform: translateX(-50%) scale(0.78);
            }
        }
    }

    .s-list {
        padding: 0 13px;

        li {
            padding: 0 3px;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
}
