[data-type="Contents_BrandAd_Floating"] {
    .c-floating--flexible {
        // ootd 하단 고정되어 있는 이전 버튼과 겹치지 않도록 위치값을 조정한다
        bottom: calc(115px + env(safe-area-inset-bottom));

        // 웹뷰 환경에서의 위치값을 다시 한번 조정한다
        @at-root .wrap-inapp & {
            bottom: 147px;
        }
    }
}
