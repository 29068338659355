.c-n-product {
    position: relative;

    * {
        font-family: $font-family3;
    }
    dl {
        overflow: hidden;
    }
    &__link {
        overflow: hidden;
        display: block;

        &--flex {
            display: inline-flex;
            flex-wrap: wrap;
            vertical-align: top;
        }
    }
    &__thumb {
        position: relative;
        // padding: 12px;
        background-color: #fff;
        box-sizing: border-box;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #434a79;
            opacity: 0.05;
        }

        img {
            top: 12px;
            right: 12px;
            bottom: 12px;
            left: 12px;
        }
    }
    &__soldout {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: transparent;
        font-size: 1px;
        background: rgba($color-gray-01, 0.45) url("/MW/img/common/sprites/sp_soldout_202128_130331.png") no-repeat 50% 50%;
        background-size: 96px 96px;
    }
    &__info {
        position: relative;
        padding: 12px 24px 24px 16px;
        color: #111;
    }
    &__brand {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 600;

        dd {
            @include text-ellipsis;
        }
    }
    &__price dd {
        display: flex;
        line-height: 1em;
    }
    &__name {
        overflow: hidden;
        margin-top: 6px;
        max-height: 34px;
        color: #666;
        font-size: 14px;
        line-height: 17px;
        word-break: break-all;
        word-wrap: break-word;
    }
    &__flag {
        margin-top: 12px;

        dd {
            display: flex;
            line-height: 1em;
        }
    }
    &__rank {
        position: absolute;
        top: 0;
        left: 16px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        height: 24px;
        padding: 0 3px;
        border: 1px solid rgba(34, 34, 34, 0.4);
        color: #222;
        font-size: 13px;
        font-weight: 600;
        line-height: 15px;
        background-color: #fff;

        &--fill {
            color: #fff;
            border-color: $color-luxury;
            background-color: $color-luxury;
        }
    }
    &__desc {
        color: #111;
        font-size: 16px;
        line-height: 21px;
        opacity: 0.5;
    }
    &__buttons {
        position: absolute;
        top: -30px;
        right: 0;
    }
    &__seller {
        overflow: hidden;
        display: flex;
        height: 17px;
        margin-bottom: 3px;
        color: $color-gray-02;
        font-size: 13px;
        font-weight: bold;
        line-height: 17px;
        text-overflow: ellipsis;
    }

    .price-origin,
    .price-rate {
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        vertical-align: top;
    }
    .price-rate + .price-origin,
    .price-origin + .price-rate {
        margin-left: 4px;
    }
    .price-rate {
        color: $color-luxury;
    }
    .c-n-delete {
        overflow: hidden;
        position: relative;
        width: 40px;
        height: 40px;
        border: 0;
        background-color: transparent;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 6px;
            right: 12px;
            width: 1px;
            height: 14px;
            background-color: #111;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    .c-n-flag {
        margin-right: 4px;
    }
    .c-lazyload {
        img {
            width: 100%;
        }
    }
    &--list {
        overflow: hidden;
        flex: 1;
        display: flex;

        .c-n-product {
            &__link {
                // flex: 1;
                display: flex;
                min-width: 0;
            }
            &__thumb {
                @include size(108px);
                flex-shrink: 0;
            }
            &__info {
                flex: 1;
                min-width: 0;
                padding: 0 12px;
            }
            &__price dd {
                display: flex;
                align-items: baseline;
            }
            &__buttons {
                // display none이 들어간 이유
                // c-n-product__button을 사용하지 않고 따로 선언한다 (참고 : ProductList_Luxury_History 블럭)
                display: none;
            }
        }
        .price-origin {
            margin-right: 4px;
        }
    }
    &--box {
        width: 152px;
        border-radius: 4px;
        background: $color-gray-14;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.28), 0 2px 6px 0 rgba(0, 0, 0, 0.06);

        .c-n-product {
            &__info {
                padding: 12px 12px 24px;
            }
            &__price {
                margin-top: 6px;

                dd {
                    display: flex;
                    align-items: baseline;
                }
            }
        }
        .price-origin {
            margin-right: 4px;
        }
    }
}

.c-lazyload {
    overflow: hidden;
    display: block;
    position: relative;
    font-size: 0;
    line-height: 0;

    &:before {
        background-color: transparent;
    }
    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &--ratio_1x1 {
        padding-bottom: 100%;
    }
}
