[data-type="ContentsScroll_Tag"] {
    .w-swiper__tag {
        padding-left: 16px;
        height: 36px;
        overflow: hidden;
        li {
            float: left;
            &.swiper-slide {
                width: auto;
            }
            .c-tag__tab {
                font-size: 14px;
                text-align: center;
                display: block;
                height: 36px;
                padding: 10px 12px 9px 12px;
                border: 1px solid #eaeaea;
                line-height: 15px;
            }
            &.on .c-tag__tab {
                color: #fff;
                background-color: #43beff;
                border: 1px solid #43beff;
            }
        }
    }

}
