[data-type="AiFeed_Cap_Title"] {
    .ai-feed-category {
        &__feed-filter {
            display: flex;
            align-items: center;
            height: 52px;
            padding: 0 20px;
        }

        &__feed-count {
            font-size: 14px;
            color: $color-gray-03;
        }

        .ai-feed-category__sort-label + .ai-feed-category__sort-input + .ai-feed-category__sort-label {
            margin-left: 20px;

            &:before {
                top: 3px;
                left: -10px;
            }
        }

        &__sort-label {
            font-size: 14px;
        }
    }
}
