@import "../../common/helpers/grid_mixin";
@import "b-card--roadshop";

[data-type="ProductGrid_Roadshop"] {
    @include grid(2, 2, false);
    .b-card--roadshop {
        @include b-card--roadshop;
        .c-card__gallery {
            .c-card__info {
                .c-card__price {
                    height: auto;
                }
            }
        }
        .c-relates {
            &__emphasis {
                padding-right: 8px;
            }
        }
    }
}
