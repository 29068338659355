[data-type="ExhibitionBanner_ProductScroll"] {
    .c-linkimg {
        display: block;
        overflow: hidden;
        position: relative;
        height: 164px;

        &__inner {
            position: absolute;
            top: 0;
            left: 50%;
            width: 360px;
            transform: translateX(-50%);
        }
    }

    .w-scroll-css {
        position: relative;
        margin-top: -24px;
    }

    .s-list {
        height: 100%;
        padding: 0 10px 20px;

        li {
            padding: 0 6px 4px;
        }
    }

    .c-card-item {
        &--box {
            @include size(152px 100%);
        }

        &__lowest {
            margin: -1px 0;
            .c-iconinfo--help {
                margin-left: -1px;
                &:after {
                    @include get-sprite-common("ico_question", 13);
                }
            }
        }
        &__link {
            display: block;
            height: 100%;
        }
        &__thumb {
            padding: 11px 11px 0;
        }
        &__info {
            padding: 12px 11px 11px;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 36px;
            margin-bottom: 6px;
            color: $color-gray-03;
            line-height: 18px;
        }
        &__price-info {
            margin-bottom: 5px;
        }
        &__benefit {
            margin-bottom: 2px;
            padding-top: 0;
            font-size: 14px;
            line-height: 17px;
            .shooting-delivery,
            .shooting-install {
                display: flex;
                align-items: center;
                height: 17px;
                strong {
                    flex-shrink: 0;
                    margin: 0;
                }
            }
        }
        &__more {
            width: 100%;
            height: 100%;
        }
        &__more-inner {
            font-size: 16px;
            line-height: 19px;
            &:before {
                margin-bottom: 16px;
            }
        }
    }

    .c-flag-box {
        height: 22px;
    }
    .c-flag__item--ooah,
    .c-flag__item--freshtable {
        margin-top: 3px;
    }
    .c-starrate {
        margin: 0 0 6px;
        &__sati,
        &__gauge {
            width: 53px;
            height: 10px;
            background-size: 53px 10px;
        }
        &__review {
            line-height: 13px;
        }
    }
}
