// 컴포넌트의 위치 지정 - 추가되는 블럭 scss에서 조정
.c-more,
.c-more-down {
    padding-left: 50px;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), color-stop(40%, #fff));
    background: -webkit-linear-gradient(left, hsla(0, 0%, 100%, 0), #fff 40%);
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff 40%);
    font-size: 14px;
    color: $color-11st-blue;
    line-height: 20px;
    &:after {
        display: inline-block;
        margin: -1px 4px 1px 2px;
        vertical-align: middle;
        content: "";
        width: 7px;
        height: 7px;
        border: 1px solid $color-11st-blue;
        border-width: 1px 1px 0 0;
        @include rotate(45deg);
    }
    &-down {
        &:after {
            @include rotate(134deg);
            margin: -4px 4px 1px 7px;
        }
        &--close {
            &:after {
                @include rotate(315deg);
                margin: 3px 4px 1px 7px;
            }
        }
    }
}
