@import "../../pui/sprites/sp_mart";

[data-type="FlatButton_Mart_Location"] {
    .c-location {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        color: $color-gray-04;
        font-size: 14px;
        line-height: 48px;
        vertical-align: top;
        background-color: #fafafa;
        letter-spacing: 0;

        &__link {
            position: relative;
            display: block;
            padding-right: 100px;
        }

        &__seller {
            display: inline-block;
            color: transparent;
            height: 22px;
            line-height: 28px;

            img {
                height: 22px;
                vertical-align: top;
                margin-top: 1px;
                &:nth-child(2) {
                    margin-left: 4px;
                }
            }
        }

        &__info {
            display: inline-block;
            padding-left: 6px;
            position: relative;
            color: #666;

            &:first-child {
                padding-left: 0;
                margin-left: 0;
            }

            &:first-child::before {
                display: none;
            }

            em,
            strong {
                font-weight: bold;
            }

            em {
                color: #333;
                position: relative;
                z-index: 9;
            }

            // &--pin,
            // &--pin:first-child {
            //     padding-left: 15px;
            //     margin-left: 0;

            //     &::before {
            //         content: "";
            //         display: block;
            //         position: absolute;
            //         left: 0;
            //         top: 50%;
            //         margin-top: -6px;
            //         width: 9px;
            //         height: 12px;
            //         @include sprite-retina($sp_mart_pin);
            //     }
            // }
        }

        &__btn {
            position: absolute;
            top: 50%;
            right: 16px;
            text-align: center;
            display: inline-block;
            height: 28px;
            margin-top: -14px;
            padding: 0 12px;
            line-height: 28px;
            border: 1px solid #ddd;
            border-radius: 24px;
            background-color: #fff;
        }

        &__no-info {
            color: #999;
        }

        &--pin {
            .c-location__seller {
                img {
                    width: 9px;
                    height: 12px;
                    position: relative;
                    top: 5px;
                }
            }

            .c-location__info {
                margin-left: 0;
                padding-left: 4px;

                &::before {
                    display: none;
                }
            }
        }
    }

    .w-mart-emart,
    .w-mart-hp,
    .w-mart-gsfresh,
    .w-mart-ssg,
    .w-mart-gsdawn {
        position: relative;
        padding-right: 0;

        .c-location__info {
            padding-left: 0;
            margin-left: 3px;

            span {
                @include text-ellipsis;
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 20px;
            right: 12px;
            @include ico-arrow-right(7px, #999);
        }
    }

    .w-mart-emart {
        strong {
            color: $color-emart;
        }
        .c-location__time {
            color: $color-emart;
        }
    }

    .w-mart-hp {
        strong {
            color: $color-homeplus;
        }
    }

    .w-mart-gsfresh {
        strong {
            color: $color-gsfresh;
        }
        .c-location__time {
            color: $color-gsfresh;
        }
    }

    .w-mart-ssg {
        strong {
            color: $color-ssg;
        }
        .c-location__time {
            color: $color-ssg;
        }
    }

    .w-mart-gsdawn {
        strong {
            color: $color-gsdawn;
        }
        .c-location__time {
            color: $color-gsdawn;
        }
    }

    [data-type="Contents_Mart_Info"] + & {
        .l-grid__col:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #f4f4f4;
            content: "";
        }
    }
}
