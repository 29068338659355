[data-type="LineBanner_Luxury"] {
    .c-banner {
        &__link {
            display: block;
            width: 100%;
            border: 0;
            background-color: transparent;
        }
        &__image {
            display: block;
            width: 300px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .c-lazyload {
        height: 100px;
    }
}
