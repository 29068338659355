[data-type="LineBanner_Pagination"] {
    height: 100px;

    a {
        display: block;
        background-color: #fff;
        img {
            @include size(300px 100px);
            display: block;
            margin: 0 auto;
        }
    }

    .c-lazyload {
        height: 100px;
    }
    .c-pagination {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1;
    }

    [class*="swiper-button"] {
        visibility: hidden;
    }
}
