[data-type="BannerContents_Scroll_Narrow"] {
    .c-lazyload {
        width: 200px;
        height: 80px;
        padding-bottom: 0;
        border-radius: 8px;
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
    }
    .s-list {
        padding: 0 16px 16px 16px;

        li {
            padding: 0 4px;
        }
    }

    .c-skeleton__box {
        display: block;
        width: 200px;
        height: 80px;
        margin: 0;
        padding: 0;
        background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
        animation: none;
    }
}
