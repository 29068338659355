.wrap-ai-feed [data-type="FlatButton_Info"] {
    padding: 12px 8px;

    .c-button__info {
        color: $color-gray-14;
        background-image: linear-gradient(90deg, var(--flatButtonBgColor) 0, var(--flatButtonGradientColor) 100%);
        border-radius: 4px;

        &:after {
            border-color: $color-gray-14;
        }
    }
    .logo_aifeed {
        height: 15px;
        margin: -2px 0 2px;
        vertical-align: middle;
    }
}
