[data-type="ProductGrid_Round_Narrow"] {
    $card: ".c-card-item";

    padding-bottom: 20px;

    * {
        font-family: $font-family3;
    }
    .c-product {
        overflow: hidden;
        padding: 0 20px;
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: -18px 0 0 -7px;
        }
        &__item {
            width: 50%;
            padding: 18px 0 0 7px;

            @include mq-560 {
                width: 33.3%;
            }
            @include mq-768 {
                width: 25%;
            }
        }
    }
    .c-spinner-inner {
        padding: 0;
    }
    .c-starrate {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font-size: 0;
        white-space: nowrap;
        &__sati,
        &__gauge {
            width: 54px;
            height: 10px;
            background-size: auto 100%;
        }
        &__sati {
            margin-right: 2px;
        }
        &__review {
            font-size: 11px;
            line-height: 13px;
        }
    }
    .c-card-item {
        &__thumb {
            overflow: hidden;
            border-radius: 8px;
            &--amazon {
                padding: 8px;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid rgba(#002178, 0.04);
                border-radius: 8px;
                background: rgba(#002178, 0.04);
            }
        }
        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
        &__rank {
            display: flex;
            align-items: center;
            top: 8px;
            left: 8px;
            height: 20px;
            border-radius: 4px;
            background-color: $color-11st-red;
            justify-content: center;
            width: 20px;
            font-weight: 700;
            font-size: 11px;
            &--light {
                background: #fef4f7; // rgba($color-11st-red, 0.04) * $color-gray-14
            }
        }
        &__emblem {
            @include aside-emblem;
        }
        &__emblem-icon {
            @include aside-emblem-icon;
            &--time {
                @include get-sprite-common("ic_timedeal", 14);
            }
        }
        &__emblem-text {
            @include aside-emblem-text;
        }
        &__info {
            padding: 10px 0 0 4px;
            .sktpass {
                overflow: hidden;
                font-size: 11px;
                line-height: 15px;
                &:before {
                    @include get-sprite-position-common("logo_sktpass", 49);
                    margin: 0 4px 0 0;
                    vertical-align: top;
                }
            }
        }
        &__name {
            display: block;
            height: auto;
            max-height: 28px;
            margin: 0 4px 0 0;
            font-size: 12px;
            line-height: (14/12);
        }
        &__price-info {
            display: flex;
            margin-top: 4px;
            &--small {
                #{$card}__rate,
                #{$card}__price {
                    height: 13px;
                    line-height: 13px;
                    letter-spacing: -0.02em;
                    strong {
                        font-size: 11px;
                    }
                }
                #{$card}__rate {
                    padding-right: 2px;
                }
                #{$card}__price {
                    font-size: 9px;
                }
            }
        }
        &__rate,
        &__price {
            flex-shrink: 0;
            height: 16px;
            line-height: 16px;
            strong {
                font-size: 13px;
                line-height: (18/13);
            }
        }
        &__rate {
            padding-right: 3px;
        }
        &__price {
            font-size: 11px;
        }
        &__benefit {
            height: 15px;
            margin-top: 4px;
            font-size: 11px;
            color: $color-gray-04;

            .shooting-delivery {
                strong {
                    @include get-sprite-position-common("shooting_delivery", 48);
                    margin: 0;
                    vertical-align: top;
                }
            }

            .shooting-install {
                strong {
                    @include get-sprite-position-common("shooting_install", 48);
                    vertical-align: top;
                }
            }

            .shooting-fresh {
                strong {
                    @include get-sprite-position-common("shooting_fresh", 48);
                    vertical-align: top;
                }
            }

            .shooting-plus {
                strong {
                    @include get-sprite-position-common("shooting_plus", 76);
                    vertical-align: top;
                }
            }

            .shooting-seller {
                strong {
                    @include get-sprite-position-common("shooting_seller", 48);
                    vertical-align: top;
                }
            }
        }
        &__button {
            .c-ad__btn {
                @include sprite-retina($sp_icons_ad_link_kor);
                top: -4px;
                left: 4px;
                z-index: 0;
                margin-top: -23px;
            }
        }

        // 기획전 타입
        &--banner {
            height: 100%;
            border-radius: 8px;
            .c-card-item {
                &__link {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 100%;
                    text-align: center;
                }
                &__thumb {
                    width: 100%;
                    padding: 14.68% 14.68% 0;
                    border: none;
                    &:after {
                        display: none;
                    }
                    .c-lazyload {
                        border-radius: 50%;
                    }
                    img {
                        border-radius: initial;
                        object-fit: cover;
                    }
                }
                &__info {
                    width: 100%;
                    padding: 16px 8px 14.68%;
                    background-color: transparent;
                }
                &__name {
                    margin-right: 0;
                    font-weight: 700;
                    color: $color-gray-14;
                    word-break: keep-all;
                }
                &__desc {
                    margin-top: 5px;
                    font-size: 11px;
                    color: $color-gray-14;
                }
            }
        }

        // 동영상 재생 시 추가
        &--playing {
            .c-card-item {
                &__thumb {
                    .c-lazyload {
                        opacity: 0;
                        transition: opacity 1s 0.5s;
                    }
                }
            }
        }
    }

    .c-skeleton {
        &__box,
        &__bar {
            margin: 0;
            background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
            animation: none;
        }

        &__bar {
            width: 86px;
            margin: 0;
            margin-top: 4px;

            &:last-child {
                width: 92px;
                height: 13px;
            }
        }
    }

    .c-skeleton__box + .c-skeleton__bar {
        width: 100%;
        height: 28px;
        margin-top: 10px;
    }
}
