@import "../../live11/sprites/sp_icon";

[data-type="ProductList_BestLive"] {
    margin-bottom: -1px;

    @media screen and (min-width: 640px) {
        display: flex;
        margin-bottom: 0;
        flex-wrap: wrap;
    }

    .l-grid__col {
        border-bottom: 1px solid #f4f4f4;
    }

    .c-item-cap {
        position: relative;
        &__addition {
            float: none;
            position: absolute;
            top: 24px;
            left: 14px;
        }
        &__status--new {
            margin-left: -2px;
        }
    }

    .c-card-item {
        @include mq-640 {
            padding: 16px 16px 56px 0;
        }
        padding: 16px 16px 16px 0;

        &--list {
            min-height: 0;

            @include mq-640 {
                min-height: 0;
            }

            .c-card-item__thumb {
                padding-bottom: 14px;
            }

            .c-card-item__thumb {
                @include size(108px);

                @include mq-360 {
                    @include size(124px);
                }

                @include mq-640 {
                    @include size(147px);
                    min-height: 140px;
                }
            }

            .c-card-item__benefit {
                margin-top: 8px;
                font-size: 14px;
                color: #666;
            }

            .c-card-item__info {
                padding: 0 0 18px 14px;
                min-width: 123px;
            }

            .c-card-item__name {
                height: auto;
                font-size: 16px;
                line-height: 1.2em;
                margin-bottom: 6px;
            }

            .c-card-item__rate {
                strong {
                    font-weight: normal;
                    font-size: 15px;
                }
            }

            .c-card-item__price-del {
                font-size: 13px;
                line-height: 20px;
                del {
                    color: #949494;
                    font-size: 14px;
                }
            }
            .c-card-item__price {
                font-size: 13px;
                strong {
                    font-size: 20px;
                }
            }
        }

        &__counsel {
            padding: 1px 0 4px 0;
            em {
                font-size: 15px;
            }
        }
    }

    .c-sell-count {
        &-number {
            font-size: 15px;
            color: $color-11st-red;
            margin-bottom: 4px;
            font-weight: bold;
        }
    }

    .c-starrate {
        margin: 6px 0 8px;
    }
    .c-card-item__link-live {
        position: relative;
        width: 100%;
        padding: 0 12px;
        display: block;
        height: 40px;
        border-radius: 4px;
        line-height: 40px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);

        @include mq-640 {
            position: absolute;
            bottom: 16px;
            left: 44px;
            right: 16px;
            width: auto;
        }

        &-text {
            color: $color-11st-blue;
            font-size: 15px;
            &::before {
                content: "";
                display: inline-block;
                margin-right: 5px;
                position: relative;
                top: 2px;
                @include sprite-retina($sp_icon_play-blue);
            }
        }
        &-view {
            position: absolute;
            right: 12px;
            top: 0;
            font-size: 13px;
            padding-right: 12px;
            color: #949494;

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                // margin-top: -5px;
                transform: translate(0, -50%);

                @include sprite-retina($sp_icon_chevron-right);

                // @include ico-arrow-right(8px, #949494, 1px);
            }
        }
    }
}
