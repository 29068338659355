[data-type="RoundButton_Basic"] {
    .c-skeleton {
        padding: 0 16px;

        &__bar {
            width: 100%;
            height: 44px;
            border-radius: 44px;
        }
    }
    .c-round-box {
        padding: 0 16px;

        &__button {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-width: 100%;
            height: 44px;
            padding: 0 16px;
            border: 1px solid $color-gray-10;
            color: $color-gray-02;
            font-size: 15px;
            font-family: $font-family3;
            line-height: 44px;
            text-align: center;
            background-color: $color-gray-14;
            border-radius: 44px;

            &--refresh:after {
                @include sprite-retina($sp_icons_reloading_point);
                content: "";
                flex-shrink: 0;
                margin-left: 8px;
            }
            &--arrow:after {
                content: "";
                flex-shrink: 0;
                width: 8px;
                height: 14px;
                margin-left: 8px;
                background: url("/MW/img/pui/ic_chevron_right_dark.png") no-repeat 0 0;
                background-size: 100%;
            }
        }

        &__wrap {
            overflow: hidden;
            display: flex;
            gap: 4px;
            height: 44px;
        }
        &__bold {
            font-weight: 700;
        }
    }
}
