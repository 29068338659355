[data-type="GridScroll_Luxury_ImgText"] {
    .c-brand {
        &__link,
        &__add {
            display: block;
            padding-bottom: 1px; // 스크롤 발생시에 하단이 잘려보이는 문제로 추가
            border: 0;
            background-color: transparent;
        }
        &__circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 96px;
            height: 96px;
            padding-right: 16px;
            padding-left: 16px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            background-color: #111;
            border-radius: 50%;
            text-align: center;
        }
        &__name {
            overflow: hidden;
            display: block;
            max-height: 48px;
            line-height: 16px;
        }

        &__add [class*="circle"] {
            flex-direction: column;
            color: #111;
            font-size: 13px;
            background-color: #f5f6f8;

            &:before {
                @include get-sprite-luxury("ic-brand-plus", 18);

                content: "";
                flex-shrink: 0;
                display: block;
                margin: 0 auto 8px;
            }
        }
    }

    .w-scroll-css {
        padding: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .s-list {
        padding: 0 8px;
        vertical-align: top;

        li {
            position: relative;
            padding: 0 8px;
        }
    }

    .c-tooltip {
        position: absolute;
        top: 50%;
        left: 100%;
        z-index: 20;
        margin-left: -10px;
        padding: 0;
        border: 1px solid $color-luxury;
        color: $color-luxury;
        background-color: #fff;
        border-radius: 28px;
        white-space: nowrap;
        transform: translateY(-50%);

        &:before {
            @include get-sprite-luxury("tooltip", 185);

            content: "";
            position: absolute;
            top: 50%;
            left: -5.5px;
            width: 9px;
            border: 0;
            transform: translateY(-50%);
        }

        &__wrap {
            padding: 6px 13px 6px;
        }
        &__desc {
            font-size: 13px;
            line-height: 15px;
        }
    }
}
