[data-type="Billboard_Blurry_Small"] {
    .c-billboard {
        overflow: hidden;
        position: relative;
        margin: 0 16px 16px;
        border-radius: 16px;
        background-color: #f5f6fa;

        &__link {
            position: relative;
            z-index: 10;
            display: block;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                border-radius: 16px;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
        &__image {
            top: 50%;
            height: auto;
            transform: translate(0, -50%);
        }
        &__info {
            position: absolute;
            top: 0;
            right: 32px;
            bottom: 0;
            left: 32px;
            z-index: 20;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $color-gray-14;
        }
        &__title {
            overflow: hidden;
            max-height: 68px;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            font-weight: 600;
            font-family: $font-outfit;
            word-break: keep-all;
            word-wrap: break-word;
        }
        &__desc {
            overflow: hidden;
            height: 18px;
            margin-top: 10px;
            font-size: 15px;
            font-weight: 400;
        }
    }
    .c-lazyload {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba($color-gray-01, 0) 0, rgba($color-gray-01, 0.1) 100%);
        }
        &--ratio_343x240 {
            padding-bottom: 69.97%;
        }
    }
}
