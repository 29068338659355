[data-type="GridScroll_ImgText_CircleBox"] {
    .c-brand {
        position: relative;
        width: 68px;
        margin: 0 auto;

        &__text {
            @include text-ellipsis;

            display: block;
            margin-top: 8px;
            color: $color-gray-03;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
        }
        &__image {
            position: relative;
            overflow: hidden;
            width: 52px;
            height: 52px;
            margin: 0 auto;
        }
        &__floating {
            position: relative;
            z-index: 10;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
        &__dot {
            overflow: hidden;
            position: absolute;
            top: 7px;
            right: 7px;
            z-index: 20;
            width: 9px;
            height: 9px;
            border: 2px solid $color-gray-14;
            color: transparent;
            background-color: $color-11st-red;
            border-radius: 50%;
            box-sizing: border-box;
        }
    }
    .s-list {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        width: (68 * 5) + 36px; // 아이템 1개 width 68 + 양쪽 여백 16
        height: 198px; // Layout shift 문제로 기본 높이값 추가
        margin: 0 auto;
        padding: 13px 8px;
        box-sizing: border-box;

        li {
            padding: 5px 0;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
}
