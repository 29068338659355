[data-type="GridScroll_ImgText_Outlet_CtgrNavi"] {
    @import "../../skpui/component/_icontext_now"; // 나우배송 카테고리 아이스크롤 영역 아이콘+텍스트 조합 링크

    position: relative;
    // MPINNER-11609 삭제예정 시작
    .w-scroll {
        overflow: visible;
    }
    // MPINNER-11609 삭제예정 끝

    .s-list li {
        padding: 0 24px 0 4px;
        &:first-child {
            padding-left: 0;
        }
    }
    .c-icontext__icon {
        margin: 0 auto;
        &:after {
            border: 0;
        }
    }
    .c-icontext__text {
        font-weight: normal;
        font-size: 14px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 88px;
    }

    .b-category-navi {
        background-color: #fff;
        .close {
            display: none;
        }
    }

    .c-button-bottom {
        position: relative;
        display: block;
        height: 38px;
        background: #fff;
        button {
            position: absolute;
            top: 2px;
            right: 0;
            width: 100%;
            height: 34px;
            background: transparent;
            color: #333;
            font-size: 13px;
            border: 0;
            outline: none;
            span {
                display: block;
                position: relative;
                width: 80px;
                padding-right: 25px;
                margin: 0 auto;
                text-align: center;
                &:before {
                    @include size(8px);
                    @include rotate(45deg);
                    @include transition(0.3s);
                    position: absolute;
                    top: 7px;
                    right: 10px;
                    margin: -6px 0 0 -6px;
                    border: 1px solid #333;
                    border-width: 0 1px 1px 0;
                    content: "";
                }
            }
        }
        &.close {
            button {
                span {
                    &:before {
                        @include rotate(225deg);
                        top: 11px;
                    }
                }
            }
        }
    }

    .c-subcategory {
        background-color: #fafafa;
        border-bottom: 1px solid #eef2f8;
        li {
            .c-subcategory__link {
                font-size: 14px;
                padding: 0 0 0 15px;
                border-bottom: 1px solid #eef2f8;
            }
            &:nth-last-child(1),
            &:nth-last-child(2) {
                .c-subcategory__link {
                    border-bottom: none;
                }
            }
        }
    }
}
