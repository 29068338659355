[data-type="Tabs_Luxury_RoundBtn_Sorting_Scroll"] {
    .c-sorting {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        // 구버전 PUI CSS 겹치는 문제
        height: auto;
        border-bottom: 0;
        background-color: transparent;
        letter-spacing: 0;
    }
    .c-n-dropdown:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 16px;
        background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 82%);
    }
    .w-scroll-css {
        flex: 1;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .s-list {
        padding: 0 12px;

        li {
            padding: 0 4px;
        }
    }
}
