[data-type="ProductGrid_RoundImg_Col3"] {
    @include ootdLikeButton;

    .c-lazyload img {
        object-fit: cover;
        border-radius: 12px; // 아이폰 미니에서 radius가 정상적으로 랜더링 되지 않는다
    }
    .c-skeleton {
        &__box {
            padding-bottom: 133.33%;
            border-radius: 12px;
        }
        &__bar {
            width: 80px;
            margin: 0;
            margin-top: 8px;
        }
    }

    .c-n-product {
        &__thumb {
            z-index: 1;
            overflow: hidden;
            border-radius: 12px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                border: 1px solid rgba($color-gray-01, 0.02);
                background-color: rgba($color-gray-01, 0.05);
                border-radius: 12px;
            }
            &:after {
                opacity: 1;
                background-color: transparent;
            }

            &--gradient:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 44px;
                background: linear-gradient(180deg, rgba($color-gray-01, 0.2), rgba($color-gray-01, 0));
            }
        }
        &__info {
            position: static;
            padding: 6px 2px 16px;
        }
        &__buttons {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
            padding-top: 134.55%; // lazyload 비율과 동일하게 넣어준다
        }
        &__mark {
            @include get-sprite-ootd("ic_official", 14);

            flex-shrink: 0;
            margin: 1px 0 0 3px;
        }

        .price-rate {
            color: $color-11st-red;
        }
    }

    .c-product {
        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
        }
        &__item {
            width: 33.33%;
            padding: 0 6px;
        }
    }
}
