[data-type="ProductGrid_Masonry_Img"] {
    @include ootdLikeButton;

    .price-rate {
        color: $color-11st-red;
    }
    .c-lazyload {
        img {
            object-fit: cover;
        }
        &--ratio_2x3 {
            padding-bottom: 150%;
        }
    }
    .c-skeleton__box {
        padding-bottom: 133.33%;
        border-radius: 12px;
    }

    // 상품카드
    .c-n-product {
        overflow: hidden;
        border-radius: 12px;

        &__rank {
            top: 8px;
            left: 8px;
            color: $color-gray-14;
            font-size: 12px;
            font-family: $font-outfit;
            background-color: rgba(34, 34, 34, 0.95);
            border-radius: 6px;
        }
        &__chip {
            overflow: hidden;
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 10;
            display: flex;
            align-items: center;
            height: 22px;
            padding-right: 6px;
            padding-left: 6px;
            font-size: 12px;
            font-weight: 700;
            font-family: $font-outfit;
            color: $color-gray-14;
            background: rgba($color-gray-01, 0.6);
            border-radius: 4px;

            &--shockingdeal {
                background: linear-gradient(135deg, rgba(255, 90, 46, 0.85) 0, rgba(255, 0, 56, 0.85) 51.96%, rgba(255, 0, 239, 0.85) 100%);
            }
            &--timedeal {
                background: linear-gradient(90deg, rgba(120, 14, 255, 0.85) 0, rgba(44, 89, 255, 0.85) 100%);
            }
        }
        &__thumb {
            overflow: hidden;
            border-radius: 12px;

            &:after {
                z-index: 10;
                border: 1px solid rgba($color-gray-01, 0.02);
                background-color: rgba($color-gray-01, 0.05);
                border-radius: 12px;
                opacity: 1;
            }
        }
        &__info {
            padding: 8px 6px 0;
        }
        &__name {
            margin-top: 0;
            color: inherit;
            font-size: 13px;

            &--highlight {
                font-size: 14px;
                font-weight: 700;
            }
        }
        &__buttons {
            z-index: 20;
            top: 0;
            margin-top: 133.33%;
        }
        &__more {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 11px;
            padding-top: 10px;
            border-top: 1px solid rgba($color-gray-14, 0.3);
            font-size: 13px;

            &:after {
                @include get-sprite-ootd("ic_chevron_right", 8);

                content: "";
                display: block;
            }
        }
        &__link {
            .c-n-product__info {
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 20;
                min-height: 56px;
                padding: 10px;
                padding-top: 28px;
                color: $color-gray-14;
                background-image: linear-gradient(180deg, rgba($color-gray-01, 0) 0, rgba($color-gray-01, 0.4) 76px, rgba($color-gray-01, 0.4) 100%);
                border-radius: 0 0 12px 12px; // 아이폰 미니에서 radius가 정상적으로 랜더링 되지 않는다

                .price-origin {
                    font-size: 13px;
                }
            }
        }
        &__mark {
            @include get-sprite-ootd("ic_mark", 40);

            flex-shrink: 0;
            margin-left: 3px;
        }

        // 그라디언트가 적용된 스타일 추천 카드
        &--related {
            color: $color-gray-14;
            animation: animatedBgGradientMotion 5s ease infinite;
            background-color: #ed8cc1;
            background-size: 400% 400%;

            &:before {
                content: "";
                display: block;
                padding-top: 150%;
            }

            .c-lazyload {
                padding-bottom: 100%;
            }

            .c-n-product {
                &__link {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 16px 10px;
                }
                &__thumb {
                    flex-shrink: 0;
                    overflow: hidden;
                    width: 66.9%;
                    margin: 12px 0;
                    border-radius: 50%;

                    &:after {
                        display: none;
                    }
                }
                &__info {
                    position: static;
                    min-height: auto;
                    padding: 0;
                    text-align: center;
                    background-image: none;
                }
                &__name {
                    overflow: hidden;
                    height: 17px;
                }
                &__desc {
                    flex-shrink: 0;
                    overflow: hidden;
                    display: block;
                    max-height: 34px;
                    color: inherit;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    word-break: keep-all;
                    opacity: 1;
                }
                &__price dd {
                    justify-content: center;
                }
            }
        }

        // 게이트웨이 배너 이미지
        &--gateway {
            .c-n-product__thumb:after {
                background-color: transparent;
            }
        }

        // 스타일 이미지X, 대표이미지O 케이스 추가
        &--1x1 {
            .c-n-product__buttons {
                margin-top: 100%;
            }
        }
    }

    // 테마 카드
    .c-theme-card {
        overflow: hidden;

        &__list {
            margin-top: -4px;
        }
        &__item {
            margin-top: 4px;
        }
        &__link {
            display: block;
        }
        &__banner {
            display: flex;
            align-items: center;
            height: 52px;
            padding: 9px;
            border: 1px solid $color-gray-11;
            border-radius: 12px;
        }
        &__desc {
            overflow: hidden;
            height: 15px;
            color: $color-gray-02;
            font-size: 13px;
            font-weight: 700;
            line-height: 15px;
            white-space: normal;
        }

        .c-lazyload {
            flex-shrink: 0;
            width: 32px;
            height: 32px;
            margin-right: 8px;
            padding-bottom: 0;
            border-radius: 50%;
        }
    }

    // 뷰타입 분기
    .c-product {
        padding: 8px;

        &__list {
            position: relative;
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            width: 50%;
            padding: 8px;
        }

        &--loaded .c-product__list {
            display: block;
        }
        &--col1 {
            .c-product__item {
                width: 100%;
            }
            // 1열 뷰타입에서는 상품명이 노출되지 않는다
            .c-n-product__name {
                overflow: hidden;
                position: absolute;
                padding: 0;
                color: transparent;
                clip: rect(0, 0, 0, 0);
            }

            .c-skeleton__box {
                padding-bottom: 100%;
            }
            .c-n-product__buttons {
                margin-top: 100%;
            }
        }
        &--col3 {
            .c-product__item {
                width: 33.33%;
                padding: 6px;
            }
            // 3열 뷰타입에서는 상품정보가 노출되지 않는다
            .c-n-product__info dl {
                overflow: hidden;
                position: absolute;
                width: 1px;
                height: 1px;
                margin: -1px;
                color: transparent;
                clip: rect(0, 0, 0, 0);
            }
        }
    }

    // 맵 정의
    $colors: (
        color1: (
            #eb56a7,
            #ff753a,
        ),
        color2: (
            #e74c3c,
            #f39c12,
        ),
        color3: (
            #9ac000,
            #50b156,
        ),
        color4: (
            #50b156,
            #4db5b7,
        ),
        color5: (
            #4db5b7,
            #2192d1,
        ),
        color6: (
            #2192d1,
            #2a63d1,
        ),
        color7: (
            #2a63d1,
            #753af4,
        ),
        color8: (
            #753af4,
            #d631ff,
        ),
        color9: (
            #d631ff,
            #eb56a7,
        ),
        color10: (
            #eb5656,
            #d125fc,
        ),
    );

    // 클래스 이름과 그라디언트 배경색 적용
    @each $class, $color-list in $colors {
        $gradient-start: nth($color-list, 1);
        $gradient-end: nth($color-list, 2);

        .c-n-product--#{$class} {
            background-image: linear-gradient(135deg, $gradient-start 15.62%, $gradient-end 85.63%);
        }
    }
}

@keyframes animatedBgGradientMotion {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
