[data-type="Tabs_Luxury_Sorting"] {
    position: relative;

    .c-sorting {
        display: flex;
        padding: 12px 122px 12px 16px;
        background: $color-gray-14;

        // 구버전 PUI CSS 겹치는 문제
        height: auto;
        border-bottom: 0;
        letter-spacing: 0;

        .c-n-chip {
            background: $color-gray-14;
            font-weight: normal;

            &--selected,
            &[aria-selected="true"] {
                border-color: rgba(14, 41, 217, 0.06);
                background-color: #f2f2ff;
                font-weight: 600;
            }
            & + .c-n-chip {
                margin-left: 8px;
            }
        }
    }
    .c-n-dropdown {
        position: absolute;
        top: 12px;
        right: 16px;

        &__list {
            right: 0;
            left: auto;
        }
    }
    .w-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 10;
            border-bottom: 1px solid $color-gray-12;
            background: $color-gray-14;
        }
        &--absolute {
            position: absolute;
        }
    }
}
