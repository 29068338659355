@import "../sprites/sp_filter";
@import "../sprites/sp_search_sorting";

.c-search-filter {
    z-index: 100; //102로 변경 필요
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 262px;
    height: 100%;
    padding-top: 68px;
    background: $color-gray-11;
    box-sizing: border-box;

    .c-checkbox,
    .c-radio {
        &:checked + label {
            color: $color-11st-red;
        }
    }
    &__title {
        z-index: 100;
        position: absolute;
        //APP LG G6 안드로이드 8 - 버그
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        padding: 19px 16px 17px;
        border-bottom: 1px solid transparent;
        background: $color-gray-14;
        font-size: 17px;
        color: $color-gray-01;
        line-height: 24px;
        box-sizing: border-box;
        transform: translateZ(0);
        touch-action: none;
    }
    &__close {
        z-index: 101;
        position: absolute;
        overflow: hidden;
        top: 10px;
        right: 0;
        width: 40px;
        height: 40px;
        border: none;
        background: none;
        color: transparent;
        touch-action: none;

        @include btn-close-x(17px, 1px, $color-gray-04);
    }
    &__scroll {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        box-sizing: border-box;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;
    }
    &__brand-contents {
        position: relative;
        height: 100%;
        padding-top: 90px;
        box-sizing: border-box;

        .c-search-filter__scroll {
            padding-top: 8px;
            background: $color-gray-14;
        }
        .c-search-filter__list-title {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        .c-search-filter__brand-align {
            position: absolute;
            top: 44px;
            left: 0;
        }
        .c-search-filter__wrap:first-child {
            .c-search-filter__sector:first-child {
                border-top: 0;

                h4 {
                    border-top: 0;
                }
            }
        }
    }
    &__cetegory-contents {
        height: 100%;
        box-sizing: border-box;

        .c-search-filter__scroll {
            padding-top: 44px;
            background: $color-gray-14;
        }
        .c-search-filter__list-title {
            z-index: 100;
            position: fixed;
            top: 68px;
            right: 0;
            width: 100%;
            background: $color-gray-14;
        }
    }
    &__wrap {
        position: relative;
        margin-top: 8px;
        background: $color-gray-14;

        &:first-child {
            margin-top: 0;
        }
    }
    &__toggle {
        display: block;
        position: relative;
        width: 100%;
        height: 54px;
        padding: 15px 40px 15px 16px;
        border: none;
        background: $color-gray-14;
        font-size: 17px;
        font-weight: bold;
        color: $color-gray-01;
        line-height: 24px;
        text-align: left;
        box-sizing: border-box;

        h4 & {
            height: 44px;
            padding: 10px 40px 10px 16px;
            font-size: 15px;
            color: $color-gray-02;
        }

        &:after {
            @include ico-arrow-down(8px, $color-gray-04);
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -3px;
            transition: 0.3s;
            transform-origin: 70% 30%;

            content: "";
        }
        &[aria-expanded="true"] {
            &:after {
                @include rotate(315deg);
            }
        }
        strong {
            position: relative;
            margin-left: 4px;
            font-size: 15px;
            color: $color-11st-red;
        }
    }

    &__notice {
        height: 44px;
        padding: 10px 40px 10px 16px;
        font-size: 15px;
        color: $color-gray-02;
        line-height: 24px;
        box-sizing: border-box;
    }

    &__description {
        padding: 0 16px 20px;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-03;
        line-height: 16px;
    }

    &__prev {
        display: block;
        position: relative;
        width: 100%;
        height: 44px;
        padding: 10px 16px 10px 32px;
        border: none;
        background: $color-gray-14;
        font-size: 16px;
        color: $color-gray-02;
        text-align: left;
        box-sizing: border-box;

        &:before {
            position: absolute;
            top: 50%;
            left: 16px;
            width: 8px;
            height: 8px;
            margin-top: -4px;
            border: solid $color-gray-07;
            border-width: 0 0 1px 1px;
            transform: rotate(45deg);
            content: "";
        }
    }
    &__list-title {
        position: relative;
        &__title {
            display: block;
            position: relative;
            width: 100%;
            height: 44px;
            padding: 10px 16px 10px 32px;
            border: none;
            background: $color-gray-14;
            font-size: 16px;
            font-weight: normal;
            color: $color-gray-02;
            line-height: 24px;
            box-sizing: border-box;
        }
        .c-search-filter__prev {
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            color: transparent;
        }
    }
    &__sub-title {
        &__title {
            width: 252px;
            height: 54px;
            padding: 15px 40px 15px 16px;
            font-size: 17px;
            color: $color-gray-01;
            line-height: 24px;
            box-sizing: border-box;

            strong {
                margin-left: 4px;
                font-size: 15px;
                color: $color-11st-red;
                line-height: 20px;
                vertical-align: 1px;
            }
        }
        &--fixed {
            z-index: 100;
            position: fixed;
            overflow: hidden;
            top: 68px;
            right: 0;
            width: 262px;
            border-bottom: 1px solid $color-gray-11;
            background: $color-gray-14;
        }
        &--sticky {
            z-index: 100;
            position: -webkit-sticky;
            position: sticky;
            overflow: hidden;
            top: 0;
            right: 0;
            width: 262px;
            border-bottom: 1px solid $color-gray-11;
            background: $color-gray-14;
        }
        &--fixed,
        &--sticky {
            .c-chip-input--keyword {
                margin-bottom: -1px;
            }
        }
    }
    &__reset {
        @include sprite-retina($sp_filter_btn_reset);

        position: absolute;
        top: 12px;
        right: 16px;
        border: none;
        background-color: transparent;
        color: transparent;
    }
    &__sector {
        overflow: hidden;

        h4 {
            border-top: 1px solid $color-gray-11;
            transform: translateZ(0);
        }
    }
    &__cont {
        padding-bottom: 8px;

        &[aria-hidden="true"] {
            display: none;
        }
    }
    &__item {
        @include retail-delivery;
        position: relative;
        padding: 9px 32px 9px 16px;

        label {
            display: block;
            overflow: hidden;
            height: 26px;
            font-size: 15px;
            color: $color-gray-02;
            white-space: nowrap;
            text-overflow: ellipsis;
            box-sizing: border-box;
        }

        &.c-search-filter__item--help {
            display: flex;
            align-items: center;
            .c-iconinfo {
                margin-left: 2px;
            }
        }
        &.c-search-filter__item--category {
            padding-right: 12px;
        }
        .c-search-filter__category-sub {
            display: inline-block;
            position: relative;
            overflow: hidden;
            max-width: 86px;
            padding-right: 20px;
            font-size: 14px;
            color: $color-gray-07;
            vertical-align: top;
            text-overflow: ellipsis;

            &:after {
                @include ico-arrow-right(8px, $color-gray-09);

                position: absolute;
                top: 7px;
                right: 10px;
            }
        }
        .c-nation {
            display: block;
            margin-top: 2px;

            .nation {
                line-height: 18px;
                vertical-align: top;
            }
        }
        .c-starrate {
            color: $color-gray-02;

            &__sati {
                margin: 5px 16px 0 0;
                transform: scale(1.2);
                transform-origin: 0 0;
            }
        }
        .icon-11festa {
            @include sprite-retina($sp_filter_icon_11festa);
            display: inline-block;
            overflow: hidden;
            margin: -1px 0 1px;
            color: transparent;
            vertical-align: middle;
        }
        .icon-bfsale {
            @include sprite-retina($sp_search_sorting_icon_bfsale);
            display: inline-block;
            overflow: hidden;
            margin: -2px 0 2px;
            color: transparent;
            vertical-align: middle;
        }

        .logo {
            display: inline-block;
            overflow: hidden;
            min-width: 30px;
            height: 26px;
            margin-top: -2px;
            vertical-align: top;

            img {
                display: block;
                width: auto;
                height: 100%;
            }
        }
    }

    // 스마트 정렬 툴팁, 토글 영역
    &__detail {
        display: flex;
        align-items: center;
        position: absolute;
        top: 7px;
        right: 7px;

        .c-iconinfo {
            margin: 1px 0 0 2px;
        }

        .c-checkbox-toggle {
            margin-left: auto;

            > input {
                &:checked {
                    + em {
                        &:before {
                            opacity: 1;
                            background: $color-11st-red;
                        }

                        &:after {
                            left: initial;
                            border-color: rgba($color: $color-gray-01, $alpha: 0.06);
                            background-color: $color-gray-14;
                            transform: translate(19px, -50%);
                        }
                    }
                }
            }

            > em {
                margin-right: 4px;

                &:before {
                    width: 33px;
                    height: 20px;
                }

                &:after {
                    width: 16px;
                    height: 16px;
                    border-color: rgba($color: $color-gray-01, $alpha: 0.06);
                    transform: translate(4px, -50%);
                }
            }
        }
    }

    // "스마트 정렬"
    &__label {
        margin-top: -2px;
        font-size: 12px;
        color: $color-gray-03;
    }

    &__search {
        padding: 11px 16px 16px;

        &__select {
            overflow: hidden;
            margin-bottom: 16px;

            li {
                float: left;
                margin-right: 16px;

                label {
                    padding-top: 1px;
                    font-size: 15px;
                    color: $color-gray-02;
                    box-sizing: border-box;
                }
                .c-radio {
                    &:checked + label {
                        color: $color-gray-02;
                    }
                }
            }
        }
        .c-input {
            display: block;
            width: 100%;

            &::placeholder {
                color: $color-gray-08;
            }
            &:focus {
                font-size: 16px;
            }
        }
    }
    &__search-price {
        display: flex;
        align-items: center;
        margin: 8px 16px 8px 12px;
        font-size: 15px;

        .c-input {
            flex: 1;
            min-width: 0;
            height: 30px;
            margin: 0 4px;
            padding: 0 4px;
            line-height: initial;
            text-align: right;

            &::placeholder {
                color: $color-gray-08;
            }
            &:focus {
                font-size: 16px;
            }
        }
    }
    &__button-more,
    &__button-all {
        display: block;
        padding: 5px 16px 8px;
        border: none;
        background: none;
        font-size: 13px;
        color: $color-gray-04;
        line-height: 19px;
        text-align: left;

        &:after {
            display: inline-block;
            vertical-align: middle;
            content: "";
        }
    }
    &__button-more {
        &:after {
            @include ico-arrow-down(6px, $color-gray-07);
            margin: -3px 0 3px 4px;
        }
    }
    &__button-all {
        &:after {
            @include ico-arrow-right(6px, $color-gray-07);
            margin: -1px 0 1px 4px;
        }
    }
    &__brand-align {
        display: flex;
        width: 100%;
        border: solid $color-gray-11;
        border-width: 1px 0;
        background: $color-gray-14;

        li {
            flex: 1;

            & + li {
                border-left: 1px solid $color-gray-11;
            }
        }
        &__radio {
            position: absolute;
            opacity: 0;

            &:checked + label {
                color: $color-gray-02;

                &:before {
                    @include sprite-retina($sp_filter_ic_sorting_on);
                }
            }
        }
        label {
            display: block;
            height: 44px;
            padding-right: 8px;
            font-size: 14px;
            color: $color-gray-07;
            line-height: 44px;
            text-align: center;
            box-sizing: border-box;

            &:before {
                @include sprite-retina($sp_filter_ic_sorting_off);
                display: inline-block;
                margin: -1px 1px 1px 0;
                vertical-align: middle;

                content: "";
            }
        }
        //판매처 전체보기
        & + .c-search-filter__wrap {
            .c-search-filter__sector {
                border-top: none;

                h4 {
                    border-top: none;
                }
            }
        }
    }
    &__info-text {
        padding: 10px 16px 12px;
        font-size: 15px;
        color: $color-gray-04;
    }
    .c-check-box {
        margin: 8px 15px 8px 16px;
        padding: 1px 0 0 1px;

        &:after {
            display: block;
            clear: both;
            content: "";
        }

        &__item {
            margin-top: -1px;
            margin-left: -1px;

            &:first-child:not(.c-check-box__item--half) {
                margin-right: 2px;
            }

            &--half {
                float: left;
                width: 50%;
            }

            label {
                display: block;
                position: relative;
                overflow: hidden;
                height: 44px;
                padding: 0 12px;
                border: 1px solid #dedede;
                font-size: 15px;
                color: $color-gray-03;
                line-height: 44px;
                text-align: center;
                box-sizing: border-box;
            }
        }
        &__checkbox,
        &__radio {
            position: absolute;
            opacity: 0;

            &:checked + label {
                z-index: 2;
                border-color: $color-11st-red;
                color: $color-11st-red;
            }
        }

        &__half {
            float: left;
            width: calc(50% - 1px);

            .c-check-box__item {
                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }
    .c-chip-input--keyword {
        overflow-y: auto;
        max-height: 98px;
        padding: 0 26px 8px 16px;
        border-bottom: 1px solid $color-gray-11;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;

        &:empty {
            padding-bottom: 0;
            border-bottom: none;
        }
        li {
            display: inline;
        }
        .c-chip {
            overflow: hidden;
            max-width: 100%;
            height: 24px;
            margin: 0 0 8px;
            padding: 0 20px 0 0;
            border: none;
            font-size: 14px;
            color: $color-11st-red;
            line-height: 24px;
            vertical-align: top;
            text-overflow: ellipsis;
            box-sizing: border-box;
            transform: translateZ(0); //ios12, 11버그 (ACME-3457)
        }
        .delete {
            top: -4px;
            right: -4px;

            &:before,
            &:after {
                background: $color-11st-red;
            }
        }
    }
    // component 추가 검토 필요
    .c-iconinfo {
        @include size(20px 28px);

        display: inline-block;
        position: relative;
        overflow: hidden;
        margin: -1px 0 1px;
        padding: 0;
        border: 0;
        background: transparent;
        outline: none;
        color: transparent;
        vertical-align: middle;

        &--help {
            &:after {
                @include sprite-retina($sp_eui_info);

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }

        & + .new {
            margin: 0;
        }
    }
    .c-spinner {
        background: transparent;
    }
    .new {
        display: inline-block;
        overflow: hidden;
        width: 16px;
        height: 16px;
        margin: -1px 0 1px 4px;
        background: url("/MW/img/listing/ic_new.png") no-repeat 0 0;
        background-size: 100% 100%;
        color: transparent;
        vertical-align: middle;
    }
}
