.c-n-checkbox {
    font-family: $font-family3;

    &__label {
        position: relative;
        display: block;
        line-height: 22px;
    }
    &__input {
        position: absolute;
        width: 22px;
        height: 22px;
        opacity: 0;
    }
    &__input:checked + label {
        color: $color-luxury;
    }
    &__input:disabled + label {
        opacity: 0.5;
    }
}

// c-chip
.c-n-checkbox {
    &__input:checked + .c-n-chip {
        @extend .c-n-chip--selected;
    }
    &__input:checked + .c-n-chip-dark {
        @extend .c-n-chip-dark--selected;
    }
}
