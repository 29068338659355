[data-type="Cap_Luxury_Title"] {
    .c-n-headline {
        display: flex;
        align-items: center;

        &__title {
            overflow: hidden;
            max-height: 21px;
            flex: 1;
        }
        &__float {
            display: flex;
            margin: -9px auto;
        }
    }
    .c-n-chip-dark {
        margin-left: 8px;
        background: #f4f4f4;
        font-size: 14px;
        font-weight: 600;
        vertical-align: top;
    }
}
