@charset "UTF-8";

@mixin style-input($height: 40px) {
    height: $height;
    padding: 0 12px;
    background: #fff;
    border: 1px solid $color-gray-10;
    border-radius: 4px;
    color: $color-gray-02;
    font-size: 14px;
    box-sizing: border-box;

    &::placeholder {
        color: $color-gray-06;
    }

    &:focus {
        border-color: $color-gray-08;
        outline: none;
    }

    &:disabled {
        background-color: $color-gray-13;
        border-color: #f1f1f1;
        color: #ccc;
    }
}

@mixin style-select($height: 40px) {
    height: $height;
    padding: 0 42px 0 12px;
    background: #fff url("/MW/img/common/ico_dropdown_40.png") no-repeat 100% 50%;
    background-size: 40px 40px;
    border: 1px solid $color-gray-10;
    border-radius: 4px;
    color: $color-gray-02;
    outline: none;

    &:disabled {
        //background-color: $color-gray-13;
        background: linear-gradient(rgba(249, 249, 249, 0.75), rgba(249, 249, 249, 0.75)) no-repeat 0 0, url("/MW/img/common/ico_dropdown_40.png") no-repeat 100% 50%;
        background-size: 100% 100%, 40px 40px;
        border-color: #f1f1f1;
        color: $color-gray-09;
    }
    //option[value=""][disabled] {
    //    display: none;
    //}
    &:invalid {
        color: $color-gray-06;
    }
}

@mixin style-arrow-bottom($size, $color, $weight: 1px) {
    width: $size;
    height: $size;
    border: 1px solid $color;
    border-width: 0 $weight $weight 0;
    transform: rotate(45deg);
    transform-origin: 70% 70%;
}

@mixin style-popup-page-fixed($scroll-type: null) {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 102;
    box-sizing: border-box;

    @if $scroll-type == "iscroll" {
        .c-popup-page__content {
            overflow: hidden;
            position: relative;
            height: 100%;
            padding: 0;
        }
    } @else {
        .c-popup-page__content {
            overflow-y: auto;
            height: 100%;
            box-sizing: border-box;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch;
        }
    }
}
