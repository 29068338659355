@charset "UTF-8";

.c-aside-category {
    &__menu {
        display: block;
        width: 100%;
        padding: 14px;
        padding-right: 0;
        border: 0;
        border-left: 3px solid transparent;
        color: $color-gray-04;
        font-size: 13px;
        line-height: 16px;
        text-align: left;
        outline: 0;
        background-color: transparent;

        &[aria-selected="true"] {
            color: $color-11st-red;
            font-weight: 700;
            border-color: $color-11st-red;
            background-color: $color-gray-14;

            // 통합마트 전문관에서 사이드 메뉴 포인트 색상을 변경한다
            @at-root .wrap-mart & {
                border-color: $color-mart;
                color: $color-mart;
            }
        }
    }
    &__text {
        display: block;
        overflow: hidden;
        height: 16px;
        line-height: 16px;
    }

    .c-skeleton__bar {
        display: block;
        width: 68px;
        height: 16px;
        margin: 0;
        background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
        animation: none;
    }
}
