[data-type="GridList_Text_Ctg_Col3"] {
    .c-relates {
        background-color: $color-gray-14;
        .c-more-down {
            padding-left: 0;
        }
    }
    &#blckSn-9536 {
        .c-gridtext {
            margin-bottom: 0;
        }
    }
}
