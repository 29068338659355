[data-ui-type="Contents_Store_Index"] {
    padding: 22px 16px 0;
    .c-info {
        &__store {
            text-align: center;
            a {
                display: inline-block;
                position: relative;
                font-size: 15px;
                color: $color-gray-02;
                &:before {
                    @include sprite-retina($sp_store_coupon_icon_store_black);
                    display: inline-block;
                    margin: -1px 3px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
                &:after {
                    @include ico-arrow-right(8px, $color-gray-02, 1px);
                    @include rotate(45deg);
                    display: inline-block;
                    margin: -1px 0 1px 2px;
                    vertical-align: middle;
                    content: "";
                }
            }
        }
        &__title {
            display: block;
            margin-top: 10px;
            font-size: 20px;
            text-align: center;
            &--highlight {
                display: inline-block;
                position: relative;
                padding: 0 2px;
                &:before {
                    position: absolute;
                    top: 12px;
                    right: 0;
                    left: -4px;
                    bottom: 0;
                    opacity: 0.1;
                    content: "";
                    background-color: rgba(244, 49, 66, 0.9);
                }
                span {
                    display: inline-block;
                    overflow: hidden;
                    max-width: 96px;
                    margin: 0 4px 0 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: bottom;
                }
            }
        }
        &__text {
            display: block;
            margin-top: 4px;
            font-size: 15px;
            text-align: center;
        }
        &__time {
            display: block;
            margin-top: 10px;
            font-size: 15px;
            text-align: center;
            font-weight: normal;
            color: $color-gray-03;
            &:before {
                @include sprite-retina($sp_store_coupon_icon_time);
                display: inline-block;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
                content: "";
            }
        }
        .c-card-item--list {
            margin-top: 16px;
            border: 1px solid rgba(#6558be, $alpha: 0.3);
            border-radius: 4px;
            .c-card-item__text {
                height: 34px;
                padding: 10px 0 7px;
                font-size: 14px;
                text-align: center;
                color: $color-gray-14;
                line-height: 16px;
                background: #6558be;
                &:before {
                    display: inline-block;
                    margin: -1px 4px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }
            .c-card-item__link {
                padding: 16px;
                &[aria-disabled="true"] {
                    -webkit-tap-highlight-color: transparent;
                }
            }
            .c-card-item__thumb {
                @include size(107px 107px);
            }
            .c-card-item__info {
                padding: 0 0 0 14px;
                vertical-align: middle;
                .sktpass {
                    overflow: hidden;
                    height: 20px;
                    padding-top: 1px;
                    &:before {
                        margin-right: 0;
                        margin-left: 0;
                    }
                    span {
                        display: inline;
                    }
                }
            }
            .c-card-item__name {
                display: block;
                height: auto;
                max-height: 40px;
                margin: 0;
            }
            .c_starrate {
                margin-top: 6px;
            }
            .c-card-item__rate {
                float: none;
                margin-top: 6px;
                strong {
                    font-size: 13px;
                    font-weight: normal;
                }
            }
            .c-card-item__price {
                display: block;
                font-size: 14px;
                strong {
                    font-size: 20px;
                }
                del {
                    display: inline-block;
                    font-size: 15px;
                    color: $color-gray-06;
                }
            }
            &.c-card-item--none {
                display: block;
                width: auto;
                margin: 16px -16px 0;
                border-radius: 0;
                border: 0;
            }
        }
        &--cart {
            .c-card-item--list {
                .c-card-item__text {
                    &:before {
                        @include sprite-retina($sp_store_coupon_icon_cart_white);
                    }
                }
            }
        }
        &--together {
            .c-card-item--list {
                .c-card-item__text {
                    &:before {
                        @include sprite-retina($sp_store_coupon_icon_purchase_white);
                    }
                }
                .c-card-item__thumb {
                    @include size(80px 80px);
                }
            }
        }
        &--interest {
            .c-card-item--list {
                .c-card-item__text {
                    &:before {
                        @include sprite-retina($sp_store_coupon_icon_view_white);
                    }
                }
            }
        }
        &--discount {
            .c-card-item--list {
                .c-card-item__text {
                    &:before {
                        @include sprite-retina($sp_store_coupon_icon_percent_white);
                    }
                }
            }
        }
        &--store {
            .c-card-item--list {
                .c-card-item__text {
                    &:before {
                        @include sprite-retina($sp_store_coupon_icon_store_white);
                    }
                }
            }
        }
    }
    &.color_1 {
        .c-info {
            &__title {
                span {
                    color: #6558be;
                    &:before {
                        background-color: rgba(#6558be, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #6558be;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#6558be, $alpha: 0.3);
            .c-card-item__text {
                background: #6558be;
            }
        }
    }
    &.color_2 {
        .c-info {
            &__title {
                span {
                    color: #5273cc;
                    &:before {
                        background-color: rgba(#5273cc, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #5273cc;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#5273cc, $alpha: 0.3);
            .c-card-item__text {
                background: #5273cc;
            }
        }
    }
    &.color_3 {
        .c-info {
            &__title {
                span {
                    color: #4e99d0;
                    &:before {
                        background-color: rgba(#4e99d0, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #4e99d0;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#4e99d0, $alpha: 0.3);
            .c-card-item__text {
                background: #4e99d0;
            }
        }
    }
    &.color_4 {
        .c-info {
            &__title {
                span {
                    color: #51bbca;
                    &:before {
                        background-color: rgba(#51bbca, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #51bbca;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#51bbca, $alpha: 0.3);
            .c-card-item__text {
                background: #51bbca;
            }
        }
    }
    &.color_5 {
        .c-info {
            &__title {
                span {
                    color: #57c3a1;
                    &:before {
                        background-color: rgba(#57c3a1, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #57c3a1;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#57c3a1, $alpha: 0.3);
            .c-card-item__text {
                background: #57c3a1;
            }
        }
    }
    &.color_6 {
        .c-info {
            &__title {
                span {
                    color: #51bbca;
                    &:before {
                        background-color: rgba(#51bbca, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #51bbca;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#51bbca, $alpha: 0.3);
            .c-card-item__text {
                background: #51bbca;
            }
        }
    }
    &.color_7 {
        .c-info {
            &__title {
                span {
                    color: #4e99d0;
                    &:before {
                        background-color: rgba(#4e99d0, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #4e99d0;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#4e99d0, $alpha: 0.3);
            .c-card-item__text {
                background: #4e99d0;
            }
        }
    }
    &.color_8 {
        .c-info {
            &__title {
                span {
                    color: #5273cc;
                    &:before {
                        background-color: rgba(#5273cc, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #5273cc;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#5273cc, $alpha: 0.3);
            .c-card-item__text {
                background: #5273cc;
            }
        }
    }
    &.color_9 {
        .c-info {
            &__title {
                span {
                    color: #6558be;
                    &:before {
                        background-color: rgba(#6558be, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #6558be;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#6558be, $alpha: 0.3);
            .c-card-item__text {
                background: #6558be;
            }
        }
    }
    &.color_10 {
        .c-info {
            &__title {
                span {
                    color: #8a58b8;
                    &:before {
                        background-color: rgba(#8a58b8, $alpha: 0.9);
                    }
                }
            }
            &__text {
                color: #8a58b8;
            }
        }
        .c-card-item--list {
            border: 1px solid rgba(#8a58b8, $alpha: 0.3);
            .c-card-item__text {
                background: #8a58b8;
            }
        }
    }
}
