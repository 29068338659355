@import "../sprites/sp_icon";

@mixin c-popularity {
    font-size: 12px;
    line-height: 16px;
    color: #878787;
    white-space: nowrap;

    dt {
        display: inline-block;
        margin: -1px 0 1px;
        vertical-align: middle;

        &.like {
            overflow: hidden;
            color: transparent;

            @include sprite-retina($sp_icon_ic_heart_small);
        }

        &.talk {
            overflow: hidden;
            color: transparent;

            @include sprite-retina($sp_icon_ic_11talk);
        }
    }

    dd {
        display: inline-block;
        vertical-align: top;

        + dt {
            margin-left: 10px;
        }
    }
}
