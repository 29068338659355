// c-flag 클래스명이 이전 flag와 동일한 문제로 c-label 안에서 선언함
.c-label {
    overflow: hidden;
    height: 20px;

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -4px;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-right: 4px;
    }

    .c-flag {
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 20px;
        padding-right: 4px;
        padding-left: 4px;
        color: $color-gray-14;
        font-size: 11px;
        font-family: $font-family3;
        line-height: 1;
        background-color: var(--flagBgColor);
        border-radius: 4px;

        &--bold {
            font-weight: 700;
        }
        &--border {
            border: 1px solid $color-gray-10;
            color: $color-gray-04;
            background-color: transparent !important;
        }
        &--gradient {
            color: $color-gray-14;
            background-image: linear-gradient(90deg, var(--flagBgColor, rgba(0, 0, 0, 0.5)) 0%, var(--flagGradationColor, rgba(0, 0, 0, 0.5)) 100%);
        }
        &--image {
            height: auto;
            padding: 0;
            border-radius: 0;
            background-color: transparent;
        }
    }
    .icon-timer {
        @include get-sprite-common("ic_timer", 14);

        overflow: hidden;
        margin-right: 2px;
        color: transparent;
    }
    .image-flag {
        height: 18px;
    }

    .c-nation {
        display: flex;
        align-items: center;
        margin: 3px 0 0 0;

        .amazon {
            width: 47px;
            height: 14px;
            margin: 0;
            background-size: 100%;
        }
        .nation {
            display: flex;
            align-items: center;
            height: auto;
            margin-top: -4px;
            padding: 0;
            font-size: 12px;
            font-family: $font-family3;
            line-height: 1;

            &:before {
                position: static;
                display: block;
                margin: 0 3px 2px 3px;
            }
        }
    }
}
