[data-type="ProductScroll_Live11_Basic"] {
    .s-list {
        height: 100%;
        padding: 0 12px 20px 12px;
        li {
            padding: 0 4px 4px;
        }
    }
    .c-card-item {
        &--box {
            @include size(142px 100%);
            border: 0;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
        }
        &-badge {
            left: 10px;
            top: 10px;
            font-size: 12px;
            color: $color-gray-14;
            z-index: 10;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
            font-size: 0;
        }

        &__badge-date {
            display: block;
            font-size: 13px;
            font-weight: bold;
        }
        &__badge-time {
            display: block;
            font-size: 18px;
            font-weight: bold;
        }
        &__badge-11live {
            display: inline-block;
            width: 35px;
            height: 18px;
        }
        .live-img {
            display: inline-block;
            width: 35px;
            height: 18px;
            background: url(/MW/img/pui/ico_live_round.png) no-repeat;
            background-size: 100% 100%;
        }
        &__badge-view {
            color: $color-gray-14;
            position: relative;
            line-height: 16px;
            top: -4px;
            margin-left: 5px;
            font-size: 13px;
            font-weight: bold;
            em {
                font-weight: bold;
            }
        }
        &__thumb {
            display: block;
            width: 142px;
            height: 212px;

            // 상단 dim
            &::before {
                content: "";
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                width: 100%;
                height: 56px;
                border-radius: 4px 4px 0 0; // ios 대응
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
            }
            // default dim
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                border-radius: 4px 4px 0 0; // ios 대응
                background: $color-gray-01;
            }

            .c-video {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                &--hide {
                    opacity: 0;
                }

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &__info {
            padding-bottom: 0;
            .c-card-item__profile {
                padding: 0 0 12px;
            }
        }
        &__name {
            display: block;
            height: 38px;
            margin-bottom: 5px;
            color: $color-gray-03;
            line-height: 19px;
            font-size: 14px;
        }
        &__name-sub {
            color: $color-11st-blue;
            height: 17px;
        }
        &__benefit {
            padding-top: 8px;
        }
        &__addition {
            margin-top: 12px;
        }
        &__more {
            width: 130px;
            height: 100%;
        }
        .c-nation {
            margin-right: -6px;
        }
        .c-flag__item {
            margin-bottom: 6px;
        }

        &__profile {
            padding: 0 12px 12px;
            &-img {
                display: block;
                overflow: hidden;
                position: relative;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
                margin-right: 4px;
                border-radius: 100%;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 11;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 50%;
                    content: "";
                }
                .c-lazyload:before {
                    background-size: contain;
                }

                img {
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                }
                & + span {
                    @include text-ellipsis;
                    flex: 1;
                    font-size: 14px;
                }
            }
            dt {
                @include sr-only;
            }
            dd {
                display: flex;
                align-items: center;
                height: 26px;
                overflow: hidden;
                color: $color-gray-04;
            }
        }
        &__mini-card {
            position: absolute;
            z-index: 10;
            top: 144px;
            left: 0;
            width: 100%;
            height: 68px;
            color: $color-gray-14;
            // 하단 dim
            &::before {
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }

            a {
                position: absolute;
                bottom: 10px;
                left: 10px;
                right: 12px;
                height: 33px;
                padding: 2px 0 0 40px;
            }

            dl {
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                height: 33px;
            }

            dt {
                @include sr-only;
            }

            dd:nth-child(2) {
                position: absolute;
                left: 0;
                top: 0;
                @include size(32px);
                .c-lazyload {
                    border-radius: 4px;
                }
                img {
                    border-radius: 4px;
                    display: block;
                    @include size(32px);
                }
            }
            dd:nth-child(3) {
                display: block;
                overflow: hidden;
                height: 14px;
                font-size: 11px;
                line-height: 14px;
            }
            dd:nth-child(4) {
                display: block;
                font-size: 12px;
                font-weight: bold;
                padding-top: 1px;
                overflow: hidden;
                height: 19px;
            }
        }
    }
}
