@import "../../svg/sp_live11";

[data-type="ProductScroll_BestSelling"] {
    padding: 0 0 14px;
    .w-scroll-css {
        .s-list {
            padding: 0 12px;
            li {
                padding: 0 4px 10px;
            }
        }
    }
    .c-item-cap {
        display: flex;
        align-items: center;
        padding: 12px 12px 10px;
        &__rank {
            border-radius: 4px;
            font-weight: bold;
        }
        &__addition {
            margin-left: 8px;
        }
    }

    .c-card-item {
        @include rounding-box;
        width: 152px;
        height: 384px;
        padding-bottom: 40px;
        &__info {
            height: 148px;
            padding: 12px;
        }
        &__name {
            height: auto;
            margin: 6px 0 0;
            font-size: 14px;
            color: $color-gray-03;
        }
        &__rate {
            font-size: 14px;
            strong {
                font-size: 14px;
                font-weight: normal;
                line-height: 18px;
            }
        }
        &__price-info {
            margin-top: 6px;
        }
        &__price-del {
            font-size: 12px;
            color: $color-gray-08;
            line-height: 18px;
            del {
                font-size: 14px;
            }
        }
        &__price {
            display: block;
            clear: both;
            font-size: 12px;
            color: $color-gray-02;
            strong {
                font-size: 18px;
            }
        }
        &__fluid {
            dd {
                margin-top: 3px;
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
        &__counsel {
            margin-top: 8px;
            padding: 0;
            font-size: 12px;
            em {
                font-size: 14px;
                margin-bottom: 3px;
            }
        }
    }

    .c-sell-count {
        &-number {
            font-size: 14px;
            color: $color-11st-red;
        }
    }

    .c-starrate {
        margin: 6px 0 0;
        &__sati {
            @include logo-svg($logo-icon_star, 53.3px, 10px);
        }
        &__gauge {
            @include logo-svg($logo-icon_star_fill, 53.3px, 10px);
        }
    }

    .c-card-item__link-live {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0 12px;
        display: block;
        height: 40px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        line-height: 40px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        text-align: center;
        &-text {
            color: $color-11st-blue;
            font-size: 14px;
            &::before {
                content: "";
                @include get-sprite-live11("ic-playvod");
                margin-right: 2px;
                vertical-align: -1px;
            }
        }
    }
}
