.c-n-spinner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;

    &__icon {
        overflow: hidden;
        width: 60px;
        height: 60px;
        color: transparent;
        background: #fff url("/MW/img/luxury/loading_ooah.gif") no-repeat 50% 50%;
        background-size: 32px 32px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.06);
        border-radius: 50%;
    }

    &--fixed {
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 200;
        transform: translateY(-50%);
    }
}
