[data-type="GridScroll_Luxury_ImgTextCircle_Row2"] {
    $itemRowCount: 10;

    .s-list {
        display: flex;
        flex-wrap: wrap;
        width: (112 * $itemRowCount) + 16px; // 아이템 1개 width 112 + 양쪽 여백 16
        margin-top: -16px;
        padding: 0 8px;
        box-sizing: border-box;

        li {
            padding: 16px 8px 0 8px;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
    .c-lazyload:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        background-color: rgba(#434a79, 0.05);
        border-radius: 50%;
    }

    .c-brand {
        display: block;
        width: 96px;
        margin: 0 auto;
        text-align: center;

        &__image {
            border-radius: 50%;
        }
        &__text {
            @include text-ellipsis-multiple(2);

            max-height: 34px;
            margin-top: 6px;
            color: $color-gray-02;
            font-size: 14px;
            font-family: $font-family3;
            line-height: 17px;
        }
    }
}
