// 동영상 필터
[data-ui-type="Ooah_Pick_Billboard"] {
    .c-video__filter {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: transparent;
        &-after,
        &-before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
        } //c-video__filter-before, c-video__filter-after
    }
}
// 우아픽 매인 - 필터에 동작 존재 함
.ooah-pick-home {
    // 기본 하단 영역
    .c-video__filter {
        &-before {
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.2s ease-out;
        }
        &-after {
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: all 0.2s ease-out;
            }
            &::before {
                // show, prev 아닐때
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
                opacity: 1;
            }
            &::after {
                // show, prev 상태일 때
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 30%), linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 32px, rgba(0, 0, 0, 0) 40%);
                opacity: 0;
            }
        }
    }
    // show, prev 영역에서의
    .c-billboard__card--show,
    .c-billboard__card--prev {
        .c-video__filter {
            &-before {
                background-color: rgba(0, 0, 0, 0.1);
            }
            &-after {
                &::before {
                    // show, prev 아닐때
                    opacity: 0;
                }
                &::after {
                    // show, prev 상태일 때
                    opacity: 1;
                }
            }
        }
    }
}

// 브랜드 상세 - 필터에 동작 없음.
.ooah-pick-brand {
    .c-video__filter {
        &-before {
            background-color: transparent;
        }
        &-after {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 30%), linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 32px, rgba(0, 0, 0, 0) 40%);
        }
    }
}
