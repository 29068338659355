[data-type="BannerContents_Luxury"] {
    .c-banner {
        &__list {
            padding-bottom: 6.72%;
        }
        &__link {
            display: block;
        }
        &__image {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
    }

    .c-lazyload img {
        position: relative !important;
    }
}
