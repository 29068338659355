[data-type="ProductScroll_Basic"] {
    @include grid(1, 0, true);
    .s-list {
        li {
            .more {
                @include scroll-more(104px, 232px);
                border: 1px solid $color-gray-11;
                background: $color-gray-13;
                font-size: 13px;
                color: $color-gray-04;
                text-align: center;

                &:before {
                    @include size(48px);
                    @include border-radius(50%);
                    @include sprite-retina($sp_icons_more_arrow);
                    display: block;
                    margin: 74px auto 10px;
                    background-color: $color-gray-09;
                    content: "";
                }
            }
        }
    }
}
