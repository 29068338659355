[data-type="ProductList_Inner"] {
    padding: 0px 12px;
    .l-grid__col {
        padding: 4px;
        .c-card.c-card__gallery {
            .c-card__thumb {
                @include size(auto);
            }
            .c-card__info {
                padding: 8px 0;
                .c-card__name {
                    font-size: 13px;
                    height: 36px;
                    margin-bottom: 0;
                }
                .c-card__price {
                    min-height: 20px;
                    strong {
                        font-size: 13px;
                    }
                }
            }
        }
        &.l-grid__col--12 {
            padding: 0 4px 12px 4px;
            .c-card {
                border: 1px solid #f4f4f4;
                min-height: 78px;
                &__thumb {
                    @include size(80px);
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 9;
                    img {
                        z-index: 9;
                    }
                    .c-card__soldout__text {
                        z-index: 20;
                        &:before {
                            font-size: 12px;
                            margin-top: -15px;
                        }
                    }
                }
                &__info {
                    padding-left: 88px;
                }
                &__name {
                    font-size: 14px;
                    color: #111;
                    height: auto;
                    margin-bottom: 6px;
                }
                &__price {
                    strong {
                        font-size: 14px;
                        color: #111;
                    }
                }
            }
        }
    }
}
