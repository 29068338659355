[data-ui-type="Contents_BenefitNotice"] {
    padding: 0 16px 16px;
    .c-notice {
        padding: 12px;
        border: 1px solid rgba($color: $color-11st-blue, $alpha: 0.1);
        background: rgba($color: $color-11st-blue, $alpha: 0.02);
        border-radius: 4px;
        &-title {
            display: block;
            margin-bottom: 4px;
            font-size: 14px;
            color: $color-11st-blue;
            line-height: 19px;
            font-weight: normal;
            &:before {
                @include sprite-retina($sp_icon_info_blue);
                display: inline-block;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
                content: "";
            }
        }
        &-description {
            font-size: 14px;
            color: $color-gray-04;
            line-height: 19px;
        }
    }
}
