[data-type="Carrier_CategorySortingBtn_Roadshop"] {
    .c-list-header {
        position: relative;
        padding: 21px 16px 17px;
    }

    .c-count {
        display: block;
        height: 18px;
        font-size: 14px;
        line-height: 18px;
        color: #000;
        letter-spacing: 0;
    }

    @import "../../roadshop/component/sorting_inline";

    .c-sorting-inline {
        position: absolute;
        right: 16px;
        top: 21px;
    }

    .c-noinfo {
        padding: 70px 0;

        strong {
            font-size: 14px;
            line-height: 20px;
            color: #535353;
        }
    }
}
