[data-ui-type="Contents_11live_description"] {
    position: relative;
    padding: 20px;
    background-color: #111;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;

    [aria-hidden="true"] {
        display: none;
    }
    [aria-hidden="false"] {
        display: block;
    }
    p {
        padding-bottom: 20px;
        &:last-child {
            padding-bottom: 0;
        }
    }

    .c-toggle-content {
        overflow: hidden;

        &.expand {
            height: auto;
        }
        &--button {
            position: absolute;
            width: 100%;

            bottom: 0;
            left: 0;
            padding: 90px 0 24px;

            div {
                background-color: #111;
                padding-top: 10px;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 90px;
                width: 100%;
                background-image: linear-gradient(to bottom, rgba(17, 17, 17, 0), #111);
            }
        }
        .c-button__more {
            position: relative;
            display: block;
            margin: 0 auto;
            height: 44px;
            border-radius: 22px;
            line-height: 44px;
            background-color: rgba(255, 255, 255, 0.14);

            text-align: center;
            font-size: 15px;
            padding: 0 38px 0 20px;
            color: #fff;

            &::after,
            &::before {
                content: "";
                display: block;
                position: absolute;
                right: 23px;
                bottom: 18px;
                width: 1px;
                height: 10px;
                background-color: #fff;
            }
            &::after {
                transform: rotate(90deg);
            }
        }
    }

    .c-host {
        position: relative;
        margin: 0 -20px;
        padding: 0 0 11px;
        margin-bottom: 16px;
        &::after {
            content: "";
            position: absolute;
            right: 20px;
            left: 20px;
            bottom: 0;
            border-bottom: 1px solid rgba($color-gray-14, 0.12);
        }
        .s-list {
            display: flex;
            height: 100%;
            margin: 12px -8px 0;
            padding: 0 0 10px 20px;
            white-space: nowrap;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            li {
                padding: 0 8px;
                scroll-snap-align: center;
                &:last-child {
                    padding-right: 20px;
                }
            }
        }
        &__title {
            display: block;
            padding: 0 20px;
            font-size: 16px;
            color: $color-gray-14;
            line-height: 24px;
        }
        &__box {
            display: flex;
            align-items: center;
            border: 0;
            background: transparent;
            &-img {
                @include size(44px);
                display: block;
                overflow: hidden;
                position: relative;
                margin-right: 8px;
                border-radius: 50%;
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgba($color: $color-gray-14, $alpha: 0.04);
                    border-radius: 50%;
                    content: "";
                }
                .c-lazyload {
                    &::before {
                        background-color: transparent;
                    }
                }
            }
            &-name {
                font-size: 14px;
                color: $color-gray-14;
            }
        }
    }
}
