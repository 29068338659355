[data-type="ProductGrid_CheckerBoard"] {
    padding-top: 48px; // 유틸바 높이

    .c-util {
        display: flex;
        align-items: center;
        position: fixed;
        top: var(--recentUtilTop, 57px); // 개발에서 높이 지정 가능, 기본값 헤더 높이 기준
        right: 0;
        left: 0;
        z-index: 10;
        max-width: 1280px;
        height: 48px;
        margin: 0 auto;
        padding: 0 16px;
        border-bottom: 1px solid $color-gray-10;
        background: $color-gray-13;

        &__inner {
            display: flex;
            flex: 1;
            align-items: center;
            &:last-child {
                justify-content: flex-end;
            }
        }

        &__link-text {
            font-size: 14px;
            color: $color-gray-01;
            line-height: 28px;

            &:after {
                @include ico-arrow-right(7px, $color-gray-01);
                content: "";
                display: inline-block;
                margin: -1px 3px 1px;
                vertical-align: middle;
            }
        }

        &__button {
            min-width: 42px;
            height: 28px;
            padding: 0 8px;
            border: 1px solid $color-gray-09;
            background-color: $color-gray-14;
            font-size: 13px;
            color: $color-gray-02;

            &:not(:first-child) {
                margin-left: 8px;
            }

            &--primary {
                border-color: $color-11st-red;
                color: $color-11st-red;
            }
        }

        .c-label {
            font-size: 14px;
            color: $color-gray-01;
        }
    }

    .c-product {
        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            flex: 1 1 33.333%;
        }

        &__none {
            padding: 48px 0;
            background-color: $color-gray-14;
            text-align: center;
        }

        &__text {
            font-weight: 700;
            font-size: 18px;
            color: $color-gray-03;
            line-height: 22px;
        }

        &__desc {
            margin-top: 4px;
            font-size: 14px;
            line-height: 17px;
            color: $color-gray-04;
        }
    }

    .c-button-area {
        margin-top: 24px;

        .c-button {
            color: $color-gray-02;
        }
    }

    .c-card-item {
        &__checkbox {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 9px;

            label {
                height: 100%;
                padding: 0;
            }
        }

        &__thumb {
            transition: padding 0.2s ease-in-out;
        }

        &--selected {
            .c-card-item__thumb {
                padding: 13px;
            }
        }
    }
}
