@import "../../pui/sprites/sp_live";

[data-type="ProductDeal_Live11"] {
    padding: 24px 19px 24px 24px;
    background: $color-gray-01 url("/MW/img/pui/bg_live11.png") no-repeat;
    background-size: 100% auto;

    @media screen and (max-width: 374px) {
        background-size: cover;
    }

    .l-grid__col {
        position: relative;
        max-width: 332px;
        margin: 0 auto;
    }
    .c-flex {
        display: table;
        width: 100%;
        table-layout: fixed;

        &__col {
            display: table-cell;
            vertical-align: middle;

            &:last-child {
                width: 175px;
            }

            @media screen and (max-width: 339px) {
                &:last-child {
                    width: 55%;
                }
            }
        }
    }
    .c-headline {
        background: none;

        &__link {
            &:after {
                display: none;
            }
        }
        &__service {
            @include sprite-retina($sp_live_logo);
            display: block;
            overflow: hidden;
            width: 74px;
            color: transparent;
            line-height: 200px;
        }
        &__title {
            width: 112px;
            height: auto;
            max-height: 90px;
            margin-top: 32px;
            padding: 0;
            font-size: 24px;
            color: $color-gray-14;
            line-height: 30px;
            white-space: normal;

            span {
                display: block;
                overflow: hidden;
                height: 30px;
            }
        }
        &__subtext {
            width: 112px;
            max-height: 36px;
            margin-top: 10px;
            padding: 0;
            font-size: 14px;
            color: $color-gray-14;
            line-height: 18px;
            opacity: 0.7;
            white-space: normal;
        }
        &__more {
            display: inline-block;
            position: relative;
            height: 36px;
            margin-top: 40px;
            padding: 0 24px 0 12px;
            background: $color-gray-14;
            font-size: 15px;
            color: $color-gray-02;
            line-height: 38px;

            &:after {
                @include rotate(45deg);
                @include size(7px);
                position: absolute;
                top: 50%;
                right: 14px;
                margin-top: -4px;
                border: solid $color-gray-02;
                border-width: 1px 1px 0 0;
                content: "";
            }
        }
    }
    .c-lazyload {
        border-radius: 4px;
    }
    .c-video-vertical {
        @include thumbnail-playicon-large;
        position: relative;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-size: cover;

        &__thumb,
        &__video {
            &:after {
                position: absolute;
                top: 0;
                left: -1px;
                right: -1px;
                bottom: 0;
                z-index: 1;
                border-radius: 4px;
                background: rgba(0, 0, 0, 0.08);
                content: "";
            }
        }
        &__video {
            video {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                margin: auto;
                border-radius: 4px;
                object-fit: cover;
            }
        }
        &__info {
            position: absolute;
            top: 12px;
            left: 7px;
            z-index: 5;
            font-size: 14px;
            color: $color-gray-14;

            i {
                @include sprite-retina($sp_live_pinstop);
                display: inline-block;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
            }
        }
        .c-lazyload {
            margin: -1px;

            &:before {
                @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: initial;
                height: 109px;
                z-index: 1;
                border-radius: 4px;
                transform: rotate(180deg);
                content: "";
            }
            img {
                animation: none;
            }
        }
    }
    .c-card-item {
        &--list {
            overflow: initial;
            position: absolute;
            right: -1px;
            bottom: -1px;
            z-index: 5;
            width: 177px;
            min-height: initial;
            padding: 10px;
            background: none;

            @media screen and (max-width: 339px) {
                left: 45%;
                width: 55%;
            }

            &:before {
                @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 146px;
                border-radius: 4px;
                content: "";
            }
        }
        &__thumb {
            @include size(40px);
        }
        &__info {
            padding: 0 0 0 8px;
            background: none;
            vertical-align: middle;
        }
        &__name {
            display: block;
            height: 16px;
            margin-bottom: 1px;
            font-size: 13px;
            color: $color-gray-14;
            line-height: 1.3;
        }
        &__price-info {
            font-size: 0;
        }
        &__price {
            font-size: 13px;
            color: $color-gray-14;
            line-height: 16px;

            strong {
                font-size: 14px;
                line-height: 16px;
            }
        }
        &__counsel {
            padding: 0;

            em {
                font-weight: bold;
                color: $color-gray-14;
            }
        }
    }
}
