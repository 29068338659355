// 홈탭 플로팅 배너
.c-floating {
    position: fixed;
    top: auto;
    left: 8px;
    bottom: 61px;
    z-index: 101;
    transform: none;

    &__link {
        @include size(69px);
        display: block;

        img {
            @include size(100%);
        }
    }
    &__close {
        @include size(24px);
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background: none;

        &:before {
            @include size(20px);
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 100%;
            background: rgba($color: $color-gray-02, $alpha: 0.9);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
            transform: translate(-50%, -50%);
            content: "";
        }
        span {
            display: inline-block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: transparent;
            vertical-align: top;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 49%;
                width: 1px;
                height: 9px;
                margin-top: -5px;
                background: $color-gray-14;
                content: "";
            }
            &:before {
                @include rotate(45deg);
            }
            &:after {
                @include rotate(-45deg);
            }
        }
    }
    &--enlarge {
        .c-floating__link {
            @include size(90px);
            .lottie-container {
                @include size(90px);
                display: block;
                line-height: 1;
            }
        }
        .c-floating__close {
            right: -10px;
        }
    }
    &--flexible {
        bottom: 72px;
        left: 12px;

        .c-floating {
            &__banner {
                overflow: hidden;
                width: 144px;
                height: 56px;
                background-color: $color-gray-14;
                border-radius: 56px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                transition: width 0.3s cubic-bezier(0.42, 0, 1, 1);

                &--animated {
                    width: 56px;
                    transition-duration: 0.2s;
                }
            }
            &__link {
                display: block;
                width: auto;
                height: 100%;

                img {
                    width: auto;
                }
            }
            &__close {
                top: -3px;
                right: -22px;

                &:before {
                    @include size(18px);
                }
            }
        }
    }
}

// 타임딜 더보기 미션
.c-floating-timedeal {
    position: fixed;
    top: 50%;
    right: 6px;
    z-index: 101;
    transform: translateY(-50%);

    &__timer {
        display: flex;
        flex-direction: column;
        &:before {
            content: "";
            display: block;
            width: 64px;
            height: 38px;
            background: url(/MW/img/svg/img_clock_play.svg) no-repeat;
            background-size: 100% 100%;
        }
        &--ready {
            &:before {
                background-image: url(/MW/img/svg/img_clock_play2.svg);
            }
        }
    }

    &__link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 64px;
        height: 26px;
        padding: 0 6px;
        border-radius: 8px;
        background-image: linear-gradient(90deg, #780eff 0%, #2c59ff 100%);
        font-weight: 700;
        font-size: 13px;
        color: $color-gray-14;
        text-align: center;
        box-sizing: border-box;

        &:after {
            @include ico-arrow-right(4px, $color-gray-14, 1px);
            margin: -1px 0 0 1px;
        }
    }

    &__tooltip {
        position: absolute;
        right: 0;
        bottom: calc(100% + 14px);
        padding: 1px 12px 0;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.8);
        font-size: 13px;
        color: $color-gray-14;
        line-height: 31px;
        white-space: nowrap;

        &:after {
            content: "";
            position: absolute;
            right: 26px;
            bottom: -6.5px;
            width: 38px;
            height: 6.78681px;
            background-color: rgba(0, 0, 0, 0.8);
            clip-path: polygon(38px 0px, 32.7863px 6.61875px, 32.7863px 6.61875px, 32.675797px 6.73790571px, 32.550936px 6.83524008px, 32.414609px 6.91040697px, 32.269708px 6.96306024px, 32.119125px 6.99285375px, 31.965752px 6.99944136px, 31.812481px 6.98247693px, 31.662204px 6.94161432px, 31.517813px 6.87650739px, 31.3822px 6.78681px, 31.3822px 6.78681px, 31.3635743px 6.7718562px, 31.3453224px 6.7564716px, 31.3274521px 6.7406634px, 31.3099712px 6.7244388px, 31.2928875px 6.707805px, 31.2762088px 6.6907692px, 31.2599429px 6.6733386px, 31.2440976px 6.6555204px, 31.2286807px 6.6373218px, 31.2137px 6.61875px, 26px 0px, 38px 0px);
        }
    }

    &__close {
        position: absolute;
        top: 3px;
        right: 0;
        width: 18px;
        height: 18px;
        border: 0;
        border-radius: 50%;
        background: rgba($color-gray-02, 0.8);

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 8px;
            width: 1px;
            margin: auto;
            background-color: $color-gray-14;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
