// Tabs_Mart_Scroll 블럭에서 스크롤 제거 및  flex 처리가 추가되어  본 Tabs_Mart 블럭의 필요성이 없으나 서브 페이지에서 기존에 사용하고 있기 때문에 유지 해야함
[data-type="Tabs_Mart"] {
    @import "../../skpui/component/_icontext_now"; // 나우배송 카테고리 아이스크롤 영역 아이콘+텍스트 조합 링크
    background-color: #fafafa;

    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }

    .c-tab__list {
        display: flex;
        align-content: stretch;
        overflow: hidden;

        .c-tab__item {
            flex: 1;
        }
    }

    .c-icontext {
        position: relative;
        padding-top: 3px;
        width: 100%;
        height: 62px;
        border-radius: 0;
        background-color: #fafafa;

        &__hover {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: -70px;
            transition: all 0.4s ease;
        }

        &__icon {
            width: 36px;
            height: 36px;
            margin: 0 auto;

            &:after {
                display: none;
            }

            .img-on {
                display: none;
            }

            .img-off {
                display: block;
            }
        }

        &__text {
            margin-top: 0px;
            font-size: 13px;
            font-weight: normal;
            letter-spacing: 0;
            color: #666;
        }

        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 42px;
            position: absolute;
            top: 10px;
            right: 0;
            background-color: #f4f4f4;
        }
    }

    .on {
        .c-icontext {
            transition: all 0.4s ease;

            &__hover {
                bottom: 0;
                z-index: 1;
            }

            &__icon {
                z-index: 2;

                .img-on {
                    display: block;
                }

                .img-off {
                    display: none;
                }
            }

            &__text {
                position: relative;
                color: #fff;
                z-index: 2;
                font-weight: bold;
            }

            &:after {
                display: none;
            }
        }
    }

    .b-boundary {
        display: block;
        width: 100%;
        height: 2px;
    }
}
