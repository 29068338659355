[data-ui-type="Contents_Product_None"] {
    .c-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 600px;
        padding: 0 44px 30px;
        text-align: center;
        background: $color-gray-14;
        &__title {
            font-size: 21px;
            color: $color-gray-03;
            font-weight: bold;
            &:before {
                @include sprite-retina($sp_store_coupon_icon_notice);
                display: block;
                margin: 0 auto 36px;
                content: "";
            }
        }
        &__text {
            margin-top: 8px;
            font-size: 14px;
            color: $color-gray-04;
            line-height: 19px;
        }
        &__button {
            display: block;
            width: 100%;
            height: 40px;
            margin-top: 32px;
            font-size: 16px;
            line-height: 40px;
            color: $color-11st-red;
        }
    }
}
