[data-type="FlatButton_Arrow_Emphasis"] {
    .c-relates {
        width: 100%;

        &__arrow {
            font-size: 15px;
        }
        &__text-emphasis {
            color: $color-11st-blue;
        }

        &__text-point {
            font-weight: 700;
            color: $color-11st-red;
        }
    }
}
