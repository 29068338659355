[data-type="Contents_SubTitle_Sorting"] {
    .c-headline {
        display: flex;
        align-items: stretch;
        height: 54px;

        &__sorting {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            max-width: 50%;
            border: none;
            background-color: transparent;
            @include text-ellipsis;

            & + .c-headline__link {
                margin-left: 6px;
                padding-left: 0;
            }
        }

        &__sorting-text {
            position: relative;
            height: 24px;
            margin-left: 16px;
            padding: 4px 22px 4px 10px;
            border: 1px solid $color-gray-10;
            border-radius: 14px;
            background-color: $color-gray-14;
            font-size: 13px;
            color: $color-gray-03;
            line-height: 17px;
            box-sizing: border-box;
            @include text-ellipsis;

            &:after {
                @include ico-arrow-down(8px, $color-gray-03, 2px);
                position: absolute;
                top: 50%;
                right: 0;
                margin: -6px 10px 0 0;
                content: "";
            }
        }

        &__link {
            flex: 1 1 auto;
            overflow: hidden;
            padding-left: 10px;

            &:after {
                right: 22px;
            }
        }

        &__title {
            overflow: hidden;
            height: 54px;
            padding: 8px 40px 8px 0;
            font-size: 15px;
            font-weight: 400;
            color: $color-gray-02;
            white-space: normal;

            .c-headline__point {
                margin: -1px 4px 0 0;
                font-size: 15px;
            }
        }

        &__point {
            font-weight: normal;
            color: $color-11st-blue;
        }
    }
}
