[data-type="ProductScroll_Live11_Theme"] {
    padding-bottom: 32px;
    background: $color-gray-02 no-repeat top right;
    background-size: auto 100%;
    @include mq-360 {
        background-position-x: center;
        background-size: 100% auto;
    }

    .c-headline {
        background: transparent;
        padding: 48px 16px 32px;
        text-align: center;
        &__title {
            height: auto;
            padding: 0;
            font-family: $font-11st;
            font-size: 22px;
            color: $color-gray-14;
            line-height: 29px;
            white-space: normal;
            strong {
                display: block;
                overflow: hidden;
                max-height: 29px;
            }
        }
        &__desc {
            overflow: hidden;
            max-height: 17px;
            margin-top: 8px;
            font-size: 14px;
            color: $color-gray-14;
            line-height: 17px;
            opacity: 0.9;
        }
        &__text-link {
            display: inline-flex;
            max-height: 17px;
            vertical-align: top;
            &:after {
                @include get-sprite-live11("ic-chevron-right", 8);
                content: "";
                flex-shrink: 0;
                align-self: center;
                margin: -2px 0 0 4px;
            }
        }
    }

    .w-swiper {
        .swiper-slide {
            width: auto;
            padding: 0 6px;
        }
    }

    .c-card-item {
        height: 318px;
        border: 0;

        &-badge {
            right: 0;
            padding: 10px;
            font-size: 12px;
            color: $color-gray-14;
            z-index: 10;
            text-shadow: 0 0 2px rgba($color-gray-01, 0.3);
        }

        &__badge-date {
            display: block;
            font-size: 13px;
            font-weight: bold;
        }
        &__badge-time {
            display: block;
            font-size: 18px;
            font-weight: bold;
        }
        &__badge-11live {
            display: inline-block;
            width: 35px;
            height: 18px;
        }
        .live-img {
            display: inline-block;
            width: 35px;
            height: 18px;
            background: url(/MW/img/pui/ico_live_round.png) no-repeat;
            background-size: 100% 100%;
        }
        &__badge-view {
            color: $color-gray-14;
            position: relative;
            line-height: 16px;
            top: -4px;
            margin-left: 3px;
            font-size: 13px;
            font-weight: bold;
            em {
                font-weight: bold;
            }
        }
        &__link {
            display: block;
        }
        &__thumb {
            position: relative;
            height: 228px;
            // 상단 dim (비디오 재생 시 가려짐)
            &::before {
                content: "";
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                width: 100%;
                height: 56px;
                border-radius: 4px 4px 0 0; // ios 대응
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
            }
            // default dim
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                opacity: 0.1;
                background: $color-gray-01;
            }

            &-cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding: 10px 0 0 10px;
                line-height: 1.2;
            }
            &-vod {
                color: $color-gray-14;
                font-size: 12px;
            }
            .watcher {
                display: inline-block;
                position: relative;
                z-index: 10;
                height: 20px;
                padding: 3px 8px;
                background: rgba($color: $color-gray-01, $alpha: 0.3);
                border-radius: 10px;
                line-height: normal;
                font-weight: bold;
            }

            .c-video {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1; // 상단 dim 덮음
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                &--hide {
                    opacity: 0;
                }

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__info {
            padding-bottom: 0;
        }
        &__name {
            display: block;
            height: 38px;
            margin-bottom: 8px;
            color: $color-gray-03;
            line-height: 19px;
            font-size: 14px;
        }

        &__profile {
            padding: 0 12px;
            font-size: 14px;
            color: $color-gray-07;
            &-img {
                display: block;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
                position: relative;
                margin-right: 4px;
                font-size: 0;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 11;
                    border: 1px solid rgba($color-gray-01, 0.04);
                    border-radius: 50%;
                    content: "";
                }
                .c-lazyload {
                    display: block;
                    font-size: 0;
                }
                .c-lazyload:before {
                    background-size: contain;
                }

                img {
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                }

                & + span {
                    @include text-ellipsis;
                    color: $color-gray-04;
                    font-size: 14px;
                }
            }
            dt {
                @include sr-only;
            }
            dd {
                display: flex;
                align-items: center;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        &__mini-card {
            position: absolute;
            z-index: 10;
            top: 144px;
            left: 0;
            width: 100%;
            height: 84px;
            color: $color-gray-14;
            // 하단 dim
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }

            dl {
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                overflow: hidden;
                height: 33px;
            }

            a {
                position: absolute;
                bottom: 10px;
                right: 12px;
                left: 10px;
                padding-left: 38px;
                height: 33px;
            }

            dt {
                @include sr-only;
            }

            &-thumb {
                @include size(32px);
                position: absolute;
                left: 0;
                top: 0;
                background: $color-gray-14;
                border-radius: 4px;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    border: 1px solid rgba($color-gray-01, 0.04);
                    border-radius: 4px;
                    content: "";
                }
                .c-lazyload {
                    border-radius: 4px;
                }
                img {
                    @include size(32px);
                    border-radius: 4px;
                    display: block;
                }
            }
            &-name {
                overflow: hidden;
                height: 14px;
                padding-top: 2px;
                font-size: 11px;
                line-height: 1.2;
            }
            &-price {
                overflow: hidden;
                height: 19px;
                padding-top: 3px;
                font-size: 12px;
                font-weight: bold;
            }
        }

        &--vod {
            .c-card-item__thumb {
                &::after {
                    opacity: 0.2;
                }
            }
        }
    }

    .c-reload {
        padding: 24px 16px 0;

        &__button {
            width: 100%;
            height: 40px;
            border: 1px solid rgba($color-gray-14, 0.4);
            border-radius: 20px;
            background: transparent;
            font-size: 0;
            color: $color-gray-14;
            line-height: 40px;

            &:after {
                @include get-sprite-live11("ic-reloading");
                content: "";
                margin: -1px 0 0;
                vertical-align: middle;
            }

            .text {
                font-size: 15px;
                vertical-align: middle;
            }
        }

        .c-pagination {
            display: inline-block;
            vertical-align: middle;

            &__list {
                display: flex;
                height: auto;
                padding: 0 4px;
                background: transparent;
                font-size: 15px;
                line-height: 40px;

                :not(.c-pagination__active) {
                    opacity: 0.4;
                }
                .c-pagination__active,
                .c-pagination__total {
                    display: block;
                    min-width: 9px;
                    font-weight: 400;
                }
            }
        }
    }
}
