[data-type="ProductGrid_Luxury_Basic"] {
    .c-n-product {
        &__buttons {
            top: -38px;
        }
    }
    .c-n-like {
        padding: 4px;
        box-sizing: content-box;
    }

    .c-product {
        overflow: hidden;

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -8px;
            margin-left: -3px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        &__item {
            width: 50%;
            padding-top: 8px;
            padding-left: 3px;
        }
    }
}
