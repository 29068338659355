[data-type="ProductGrid_RoundBasic"] {
    overflow: hidden;

    * {
        font-family: $font-family3;
    }

    & > li {
        padding: 0 6px 24px;
    }

    .c-masonry {
        margin: 0 10px;
        padding-top: 8px;

        /* [D] 1280px 이상: 5단 노출 */
        @media screen and (min-width: 1281px) {
            padding-right: 5.2vw;
            padding-left: 5.2vw;
        }

        &__list {
            & > li {
                padding: 0 6px 24px;

                /* [D] 1280px 이상: 5단 노출 */
                @media screen and (min-width: 1281px) {
                    width: 20%;
                }

                /* [D] 800px ~ 1280px: 4단 노출 */
                @media screen and (min-width: 801px) and (max-width: 1280px) {
                    width: 25%;
                }

                /* [D] 560px ~ 800px: 3단 노출 */
                @media screen and (min-width: 561px) and (max-width: 800px) {
                    width: 33.3%;
                }

                /* [D] ~560px 이하: 2단 노출 */
                @media screen and (max-width: 560px) {
                    width: 50%;
                }
            }
        }

        &--grid .c-masonry__list {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .c-card-item {
        &--gallery {
            overflow: initial;
        }

        &--soldout {
            .c-card-item__thumb {
                &::after {
                    content: none;
                }
            }
        }

        // 동영상 재생 시 딤드, 타이틀 + 텍스트 숨김 처리를 위한 클래스
        &--playing {
            .c-card-item {
                &__thumb {
                    .c-lazyload {
                        opacity: 0;
                        transition: opacity 1s 0.5s;
                    }
                }
            }
        }

        // 추천유형
        // Big 구성
        &--big {
            .c-card-item {
                &__thumb {
                    background: rgba($color: $color-gray-01, $alpha: 0.08);

                    &:before {
                        z-index: 10;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 50%;
                        border-radius: 8px;
                        background: linear-gradient(to bottom, rgba($color-gray-01, $alpha: 0) 0, rgba($color-gray-01, $alpha: 0.3) 70%, rgba($color-gray-01, $alpha: 0.3) 100%);
                        content: "";
                    }
                }

                &__info {
                    z-index: 20;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 0 12px 16px;
                    background-color: transparent;

                    & > dl {
                        padding: 0;
                    }
                }

                &__desc {
                    max-height: 38px;
                    color: $color-gray-14;
                }

                &__name {
                    max-height: 40px;
                    margin-bottom: 0;
                    font-size: 15px;
                    font-weight: 600;
                    color: $color-gray-14;
                    line-height: 20px;

                    & + .c-card-item__desc {
                        margin-top: 5px;
                    }
                }

                &__desc {
                    @include text-ellipsis-multiple(2);
                    height: 14px;
                    opacity: 0.9;
                    font-size: 12px;
                }

                &__profile {
                    margin-bottom: 8px;
                }

                &__profile-image {
                    display: inline-block;
                    position: relative;
                    width: 28px;
                    height: 28px;
                    border: 2px solid $color-gray-14;
                    border-radius: 50%;
                    background-color: #d3d3d3;
                    vertical-align: middle;
                    box-sizing: border-box;

                    img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        vertical-align: top;
                    }
                }

                &__ad {
                    top: 8px;
                    right: 8px;
                    left: initial;
                }
            }

            .c-lazyload {
                padding-bottom: 164.5%;
            }

            img {
                object-fit: cover;
            }

            .c-video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &--hide {
                    opacity: 0;
                    // transition: opacity 0.3s;
                }
            }

            // 서비스 바로가기
            &.c-card-item--service {
                .c-card-item {
                    &__link {
                        padding: 0;
                    }

                    &__thumb {
                        margin-top: 0;
                        border-radius: 8px;

                        &:after {
                            background: rgba($color: $color-gray-01, $alpha: 0.08);
                        }
                    }

                    &__info {
                        padding: 20px 16px;
                    }
                }
            }
        }

        // 상품 배너 타입
        &--banner {
            height: 100%;
            border-radius: 8px;

            .c-card-item {
                &__link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    padding: 24px 16px 0;
                    text-align: center;
                }

                &__thumb {
                    width: 72%;
                    border: none;
                    border-radius: 50%;

                    img {
                        border-radius: initial;
                        object-fit: cover;
                    }

                    &:after {
                        content: none;
                    }
                }

                &__info {
                    width: 100%;
                    padding-top: 16px;
                    background-color: inherit;

                    & > dl {
                        padding: 0;
                    }
                }

                &__name,
                &__price {
                    color: $color-gray-14;
                }

                &__name {
                    margin-bottom: 4px;
                    letter-spacing: -0.28px;

                    strong {
                        font-size: 16px;
                        line-height: 19px;
                    }

                    dd {
                        @include text-ellipsis;
                    }

                    & + .c-card-item__desc {
                        margin-top: -3px;
                    }
                }

                &__desc {
                    height: 16px;
                    max-height: 36px;
                    font-size: 13px;
                    color: $color-gray-14;

                    @include text-ellipsis-multiple(2);
                }

                &__price {
                    strong {
                        line-height: 17px;
                    }
                }
                &__more {
                    width: calc(100% + 32px);
                    margin: auto -16px 0;
                }
            }
        }

        // 기획전 타입
        &--exhibition {
            .c-card-item {
                &__thumb {
                    position: relative;
                    padding: 24px;
                    border-radius: 8px;

                    img {
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                &__info {
                    margin-top: 12px;
                    padding: 0;
                }

                &__desc,
                &__benefit {
                    overflow: hidden;
                }

                &__desc {
                    height: 16px;
                    font-size: 14px;
                    color: $color-gray-02;
                }

                &__benefit {
                    margin-top: 4px;
                    padding: 0;
                }
            }

            .benefit {
                overflow: hidden;
                height: 16px;
                padding: 0;

                em {
                    color: rgba($color: $color-11st-blue, $alpha: 0.9);
                    line-height: 18px;
                }
            }
        }

        // 광고 타입
        &--ad {
            .c-card-item {
                &__ad {
                    top: 8px;
                    right: 8px;
                    left: initial;
                }

                &__info {
                    margin-top: 12px;
                    padding: 0;
                }

                &__name,
                &__desc {
                    height: 16px;
                    line-height: normal;
                }

                &__name {
                    margin-bottom: 4px;
                    font-weight: 600;
                }

                &__desc {
                    max-height: 36px;
                    font-size: 13px;
                    color: $color-gray-02;

                    @include text-ellipsis-multiple(2);
                }
            }

            .c-lazyload {
                // 165x147 사이즈 비율
                padding-bottom: 89%;
            }

            img {
                object-fit: cover;
            }
        }

        // 서비스 바로가기 배너 타입
        &--service {
            height: 100%;
            border-radius: 8px;

            .c-card-item {
                &__link {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                &__thumb {
                    align-self: center;
                    width: 100%;
                    height: 100%;
                    border-radius: initial;

                    &:after {
                        content: none;
                    }
                }

                &__desc,
                &__name {
                    height: 16px;
                    max-height: 36px;
                    color: $color-gray-14;

                    @include text-ellipsis-multiple(2);
                }

                &__desc {
                    font-size: 13px;
                }

                &__name {
                    height: 20px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                }

                &__info {
                    z-index: 20;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding: 20px 16px 24px;
                    text-align: center;
                    background: none;
                }
            }
            .c-lazyload {
                &--ratio_165x196 {
                    width: 165px;
                    height: 196px;
                    margin: 0 auto;
                    padding: 0 !important;
                }
            }

            &:not(.c-card-item--big) {
                .c-lazyload {
                    padding-bottom: 118%;
                }

                dl {
                    padding: 0;
                }
            }

            &.c-card-item--playing {
                .c-card-item__thumb {
                    &:after {
                        content: "";
                    }
                }
            }
        }

        &--store {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            margin-top: 0;
            border-radius: 8px;
            font-size: 12px;
            color: $color-gray-14;
            line-height: 14px;
            text-align: center;

            .c-card-item {
                &__link {
                    width: 100%;
                    padding: 20px 12px 16px;
                }

                &__thumb {
                    width: 96px;
                    height: 96px;
                    margin: 0 auto;
                    border-radius: 50%;

                    img {
                        object-fit: cover;
                    }
                }

                &__info {
                    margin-top: 12px;
                    padding: 0;
                    background: initial;

                    & > dl {
                        padding: 0;
                    }
                }

                &__desc {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    word-break: keep-all;

                    &:before {
                        display: inline-block;
                        margin-bottom: 4px;
                        vertical-align: top;
                        content: "";
                        @include sprite-retina($sp_icons_quotes_start_white);
                    }

                    dd {
                        margin-top: 4px;
                    }
                }

                &__sub {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 8px;

                    dd {
                        padding: 2px 0;
                    }

                    .c-flag {
                        padding: 0;
                    }
                }
            }

            .c-flag {
                height: auto;
                text-align: center;

                &__item {
                    margin: 0;
                    border: none;
                    border-radius: 2px;
                    background: rgba($color: $color-gray-14, $alpha: 0.2);
                    font-size: 11px;
                    font-weight: 600;
                    letter-spacing: -0.2px;

                    & + .c-flag__item {
                        margin-left: 4px;
                    }
                }
            }
        }

        &__store-link,
        &__more {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            width: 100%;
            padding: 13px 12px 14px;
            color: $color-gray-14;

            &:before {
                position: absolute;
                top: 0;
                right: 12px;
                left: 12px;
                height: 1px;
                background: rgba($color: $color-gray-14, $alpha: 0.2);
                content: "";
            }

            &:after {
                display: inline-block;
                flex: 0 0 auto;
                margin-left: 4px;
                vertical-align: top;
                content: "";
                @include sprite-retina($sp_icons_chevron_right);
            }
        }

        &__store-name {
            overflow: hidden;
            height: 17px;
            font-size: 14px;
            line-height: normal;
            letter-spacing: -0.2px;
            @include text-ellipsis-multiple(2);
        }

        &__header-title {
            font-size: 15px;
            font-weight: 600;
            color: $color-gray-02;
            line-height: 20px;
            word-break: keep-all;

            & + .c-card-item__keyword-list {
                margin-top: 9px;
            }
        }

        &__more {
            height: auto;

            .ellipsis {
                @include text-ellipsis;

                height: 17px;
                font-size: 14px;
                line-height: normal;
                letter-spacing: -0.2px;
            }
        }

        &--keyword,
        &--category {
            border: 1px solid rgba($color: #002178, $alpha: 0.04);
            border-radius: 8px;
            background-color: $color-gray-13;
        }

        &--keyword {
            padding: 20px 16px;
        }

        &__keyword-list {
            & + .c-card-item__keyword-time {
                margin-top: 9px;
            }

            li {
                &:first-child {
                    .c-card-item__keyword-link {
                        padding-top: 5px;
                    }
                }

                &:last-child {
                    .c-card-item__keyword-link {
                        padding-bottom: 5px;
                    }
                }
            }
        }

        &__keyword-link {
            display: block;
            padding: 7px 0;
            font-size: 14px;
            color: $color-gray-02;
            @include text-ellipsis;
        }

        &__keyword-time {
            font-size: 12px;
            color: $color-gray-06;
            word-break: keep-all;

            time {
                margin-right: 4px;
            }
        }

        &__ranking {
            margin-right: 8px;
            font-size: 15px;
            font-style: italic;
            color: $color-11st-red;
        }

        &--category {
            padding: 19px 12px 24px;
        }

        &__category-list {
            margin-top: 16px;

            & > li {
                display: flex;

                & + li {
                    margin-top: 9px;
                }
            }
        }

        &__category-link {
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;
            padding: 7px 32px 8px 12px;
            border: 1px solid rgba($color: $color-gray-01, $alpha: 0.04);
            border-radius: 100px;
            background-color: $color-gray-14;

            &:after {
                position: absolute;
                top: 50%;
                right: 12px;
                margin-top: -1px;
                transform: translateY(-50%);
                content: "";
                @include sprite-retina($sp_icons_arrow_right_white);
            }
        }

        &__category-text {
            margin: 1px 2px 0 0;
            font-size: 14px;
            font-weight: 600;
            color: $color-gray-14;
            @include text-ellipsis;
        }

        &__thumb {
            position: relative;
            overflow: hidden;
            border-radius: 9px;

            .c-lazyload {
                z-index: 1;
            }

            &:after {
                z-index: 10;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid rgba($color: #002178, $alpha: 0.04);
                border-radius: 8px;
                background: rgba($color: #002178, $alpha: 0.04);
                content: "";
            }

            img {
                border-radius: 8px;
                object-fit: cover;
            }
        }

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px; // iOS16 이슈 해결용
            object-fit: cover;
        }

        // 카드 상단 표시
        &__emblem {
            z-index: 10;
            position: absolute;
            top: 8px;
            left: 8px;
            height: 22px;
            padding: 0 6px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
            color: $color-gray-14;
            line-height: 22px;

            &-text {
                margin-left: 2px;
            }
        }

        &--soldout {
            .c-card-item__soldout-text {
                z-index: 10;
            }
        }

        &__info {
            z-index: 10;
            padding: 12px 0 0;

            & > dl {
                padding: 0 4px;
            }
        }

        &__name {
            display: block;
            height: auto;
            max-height: 36px;
            margin-bottom: 2px;
            font-size: 14px;
            line-height: 18px;
            word-break: initial;
            @include text-ellipsis-multiple(3);
        }

        &__rate {
            strong {
                font-size: 15px;
                font-weight: normal;
                line-height: 16px;
                vertical-align: middle;
            }
        }

        .c-ad__btn {
            z-index: 5;
            position: absolute;
            bottom: 1px;
            left: 0;
        }

        &__brand {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: 600;
            color: $color-gray-02;
            line-height: 15px;
        }

        &__brand-name {
            @include text-ellipsis;
        }

        &__official-logo {
            flex: 0 0 auto;
            margin-right: 4px;
            color: transparent;
            @include get-sprite-common("badge_official", 40);
        }

        &__price {
            font-size: 13px;
            font-weight: normal;
            line-height: 16px;

            strong {
                font-size: 15px;
            }
        }

        &__discount {
            display: block;
            min-height: auto;
            font-size: 12px;
            line-height: 15px;

            strong {
                font-size: 13px;
            }
        }

        &__discount-title {
            margin-right: 4px;
        }

        &__price-subtext {
            font-size: 13px;
            font-weight: normal;
            color: $color-gray-04;
        }

        &__price-type {
            margin: 0 0 0 1px;
            font-size: 13px;
            font-weight: normal;
            color: $color-gray-04;
        }

        &__benefit,
        &__delivery {
            height: auto;

            .c-card-item__point {
                display: inline-block;
                font-size: 13px;
                color: $color-11st-blue;
                vertical-align: top;
            }
        }

        &__benefit {
            margin-top: 6px;
            font-size: 13px;
        }

        &__delivery {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: baseline;
            margin-top: 6px;

            & + .c-card-item__benefit {
                margin-top: 3px;
            }
        }

        .shooting-delivery,
        .shooting-install,
        .shooting-fresh,
        .shooting-plus,
        .shooting-seller {
            @include text-ellipsis-multiple(2);
            height: 16px;
            font-size: 12px;
            line-height: 16px;

            // 높이값 - 슈팅배송 2일 14px, 그 외 12px
            strong {
                display: inline-block;
                margin: 2px 4px 0 0;
                color: transparent;
            }
        }

        .shooting-delivery {
            strong {
                @include get-sprite-common("shooting_delivery", 50.76925);
            }
        }

        .shooting-install {
            strong {
                @include get-sprite-common("shooting_install", 50.76925);
            }
        }

        .shooting-fresh {
            strong {
                @include get-sprite-common("shooting_fresh", 50.76925);
            }
        }

        .shooting-plus {
            strong {
                @include get-sprite-common("shooting_plus", 79.33334);
            }
        }

        .shooting-seller {
            strong {
                @include get-sprite-common("shooting_seller", 50.76925);
            }
        }

        &__ad {
            @include sprite-retina($sp_icons_ad_link_round_eng);
            z-index: 11;
            position: absolute;
            overflow: hidden;
            bottom: 4px;
            left: 4px;

            & > button {
                width: 100%;
                height: 100%;
                border: none;
                background-color: transparent;
                color: transparent;
            }
        }

        // '방금 본 상품' Border 표시 추가 클래스
        &--border {
            padding-bottom: 8px;

            &:after {
                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;
                border: 1px solid $color-gray-01;
                border-radius: 12px;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
                content: "";
            }
        }

        &--new-item {
            animation: fadeInRoundBasic 1s forwards;
        }
    }

    .c-nation {
        margin-bottom: 3px;

        .amazon {
            @include logo-svg($logo-amazon, 46px, 16px);
            margin: 2px 0 0 0;
        }
    }

    // 연관 상품 추천 레이어
    // [M] 매직리스팅 Masonry에서 사용되지 않으나 타 서비스에서 사용될 가능성이 있으므로 연관 상품 추천 레이어 숨김
    // .c-toast-layer {
    //     z-index: 20;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 8px;
    //     background: rgba($color: $color-gray-01, $alpha: 0.3);

    //     &--show {
    //         .c-toast-layer__overlay {
    //             height: 196px;
    //         }
    //     }

    //     &__overlay {
    //         position: absolute;
    //         overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
    //         right: 0;
    //         bottom: 0;
    //         left: 0;
    //         height: 48px;
    //         border-radius: 8px;
    //         background-color: $color-gray-14;
    //         transition: height 0.3s, opacity 0.3s; /* 애니메이션 설정 */
    //         filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
    //     }

    //     &__headline {
    //         padding: 16px 12px;
    //         font-size: 14px;
    //         font-weight: 600;
    //         color: $color-gray-01;
    //         line-height: 16px;
    //     }

    //     &__list {
    //         padding: 0 12px 12px;
    //         border-radius: 8px;
    //         background-color: $color-gray-14;

    //         & > li {
    //             & + li {
    //                 margin-top: 8px;
    //             }
    //         }
    //     }

    //     &__link {
    //         display: flex;
    //         align-items: center;
    //     }

    //     &__thumb {
    //         flex: 0 0 auto;
    //         position: relative;
    //         overflow: hidden;
    //         width: 40px;
    //         height: 40px;
    //         // border: 1px solid rgba($color: #002178, $alpha: 0.04);

    //         &:before {
    //             position: absolute;
    //             top: 0;
    //             right: 0;
    //             bottom: 0;
    //             left: 0;
    //             border: 1px solid rgba($color: #002178, $alpha: 0.04);
    //             border-radius: 4px;
    //             content: "";
    //         }

    //         & > img {
    //             width: 100%;
    //             height: 100%;
    //             border-radius: 4px;
    //         }
    //     }

    //     &__name {
    //         @include text-ellipsis-multiple(2);
    //         flex: 1 1 auto;
    //         margin-left: 6px;
    //         font-size: 13px;
    //         font-weight: normal;
    //         color: $color-gray-02;
    //     }

    //     &__close {
    //         position: absolute;
    //         top: 12px;
    //         right: 8px;
    //         border: none;
    //         background-color: transparent;
    //         color: transparent;
    //         @include sprite-retina($sp_icons_close_gray);
    //     }
    // }

    // 별점 아이콘 + 평점
    .c-starrate {
        margin-top: 4px;

        & + .c-card-item__delivery {
            margin-top: 6px;
        }
    }

    .c-video {
        video {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
    }

    @keyframes fadeInRoundBasic {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
