[data-type="Tabs_TextBar_Outlet_Col5"] {
    .c-tab {
        letter-spacing: 0;

        .c-tab__list {
            padding: 0 0 0;
            text-align: center;
        }

        .c-tab__item {
            width: initial;
            padding: 10px 10px;
        }

        .c-tab__link {
            display: inline-block;
            position: relative;
            height: initial;
            margin-right: 0;
            padding: 2px 1px;
            border: 0;
            background-color: initial;
            font-size: 15px;
            line-height: 22px;
            color: #4a4a4a;
            vertical-align: top;
            white-space: nowrap;

            &:after {
                position: absolute;
                left: -3px;
                right: -3px;
                bottom: -10px;
                height: 2px;
                content: "";
            }
        }

        .on {
            .c-tab__link {
                border: 0;
                background-color: initial;
                color: #c29254;

                &:after {
                    background-color: #c29254;
                }
            }
        }
    }
    .c-card__link-text {
        padding-right: 10px;
        position: absolute;
        left: 17px;
        bottom: 18px;
        font-size: 13px;
        color: #fff;
        letter-spacing: 0;

        &:after {
            content: "";
            position: absolute;
            top: 52%;
            right: 2px;
            width: 8px;
            height: 8px;
            margin: -5px 0 0 -5px;
            border: 1px solid #fff;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
        }
    }
}
