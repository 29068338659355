.aifeed-tutorial {
    .c-lazyload {
        padding-top: 78.66%;
    }
    .c-pagination__indicator span {
        border: 0;
        background-color: #ddd;
        opacity: 1;

        &.on {
            background-color: #8155ea;
        }
    }
}
