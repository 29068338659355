[data-type="ProductGrid_LongImg_Store"] {

    padding: 8px 12px;
    .l-grid__col {
        padding: 8px 4px;
    }
    .c-card {
        &_prd {
            display: block;
        }
        &__info {
            padding: 8px 0 0;
        }
        &__name {
            font-size: 13px;
            height: 17px;
            margin-bottom: 0;
            line-clamp: 1;
        }
        &__price {
            strong {
                font-size: 15px;
            }
        }
        &__store {
            display: block;
            padding: 8px 0;
            font-size: 12px;
            color: #999;
            span {
                position: relative;
                padding-right: 10px;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: block;
                    @include ico-arrow-right(7px, #999, 1px);
                    margin-top: -4px;
                }
            }
        }
        &__thumb {
            img {
                width: auto;
                height: 100%;
                max-width: initial;
                max-height: initial;
                left: -100%;
                right: -100%;
            }
        }
    }
}
