[data-type="ExhibitionBanner_ProductCard_Compact"] {
    .icon-timer {
        @include get-sprite-common("ic_timer", 14);

        overflow: hidden;
        margin-right: 2px;
        color: transparent;
    }

    .image-flag {
        height: 18px;
    }

    .c-linkimg {
        display: block;
        overflow: hidden;
        position: relative;
        height: 164px;
        margin-bottom: -32px;

        &__inner {
            position: absolute;
            top: 0;
            left: 50%;
            width: 360px;
            transform: translateX(-50%);
        }
        .c-lazyload {
            img {
                width: 100%;
                object-fit: cover;
            }
            &:before {
                background-color: transparent;
            }
        }
    }

    .c-nation {
        display: flex;
        align-items: center;
        margin: 3px 0 0 0;

        .amazon {
            width: 47px;
            height: 14px;
            margin: 0;
            background-size: 100%;
        }
        .nation {
            display: flex;
            align-items: center;
            height: auto;
            margin-top: -4px;
            padding: 0;
            font-size: 12px;
            font-family: $font-family3;
            line-height: 1;

            &:before {
                position: static;
                display: block;
                margin: 0 3px 2px 3px;
            }
        }
    }

    .c-lazyload--ratio_375x187 {
        padding-top: 49.86%;
    }

    .c-card-item {
        overflow: hidden;
        margin: 0 16px 12px;
        box-shadow: 0 0 1px 0 rgba($color-gray-01, 0.28), 0 2px 6px 0 rgba($color-gray-01, 0.06);
        background-color: $color-gray-14;
        border-radius: 4px;

        &__info {
            padding: 12px 16px 16px;
        }
        &__name {
            overflow: hidden;
            display: block;
            height: 15px;
            margin: 0;
            color: $color-gray-03;
            font-family: $font-family3;
            line-height: 15px;
            white-space: normal;
        }
    }
    .c-label {
        margin-bottom: 8px;
    }
    .c-product-price,
    .c-product-summary {
        margin-top: 8px;
    }
}
