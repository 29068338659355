[data-type="ProductScroll_Live11_Trailer"] {
    height: 478px;
    background: url("/MW/img/live11/bg_gra2.png") no-repeat;
    background-size: cover;
    .c-headline {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 136px 128px;
        &__title {
            height: auto;
            padding: 34px 16px 21px;
            line-height: normal;
            color: $color-gray-14;
            strong {
                display: block;
                position: relative;
                z-index: 1;
                font-family: $font-11st;
                font-weight: 800;
                font-size: 20px;
                white-space: normal;
                word-break: keep-all;
                & + span {
                    display: block;
                    position: relative;
                    z-index: 1;
                    margin: 8px 0 0;
                    padding: 0;
                    font-size: 13px;
                    color: rgba($color: $color-gray-14, $alpha: 0.8);
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .s-list {
        height: 100%;
        padding: 0 12px 24px 12px;
    }
    .c-card-item {
        &--box {
            @include size(152px 318px);
            padding-bottom: 40px;
            border: 0;
        }
        &__thumb {
            img {
                width: 100%;
                object-fit: scale-down;
            }
        }
        &__info {
            padding: 10px 12px 12px;
            background: none;
        }
        &__name {
            display: block;
            height: 38px;
            margin-bottom: 6px;
            font-size: 14px;
            line-height: 19px;
        }
        &__rate {
            strong {
                font-size: 14px;
                font-weight: normal;
                line-height: 18px;
                white-space: nowrap;
            }
        }
        &__price-del {
            display: inline-block;
            font-size: 12px;
            color: $color-gray-08;
            line-height: 18px;
            vertical-align: top;

            del {
                font-size: 14px;
            }
        }
        &__bargain {
            & + .c-card-item__price-info {
                margin-top: 0;
            }
        }
        &__price {
            display: block;
            margin-top: 1px;
            strong {
                margin: -1px 0 1px;
                font-size: 18px;
                line-height: 20px;
                vertical-align: middle;
            }
        }
        &__counsel {
            margin: 8px 0 0;
            padding: 0;
            line-height: 19px;
            font-size: 12px;
            em {
                font-size: 14px;
            }
        }
        &__fluid {
            span,
            strong {
                display: inline-block;
                vertical-align: top;
            }
            dd {
                margin-top: 3px;
            }
            &-info {
                line-height: 20px;
            }
            &-price {
                margin-top: 2px;
                line-height: 17px;
            }
        }
        &__benefit {
            height: auto;
            margin-top: 0;
            font-size: 14px;
            color: $color-gray-04;
            line-height: 19px;

            .benefit {
                display: block;
                overflow: hidden;
                margin-top: 1px;
                padding-top: 0;
            }
            .sktpass {
                overflow: hidden;
                height: 20px;
                margin-top: 1px;
                font-size: 14px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
            .retail-delivery {
                margin-top: 1px;
                padding-top: 0;

                strong {
                    &:before {
                        @include size(21px 15px);
                        background-size: 21px 15px;
                        margin: -2px 2px 2px 0;
                    }
                }
            }
        }
        &__advertise {
            overflow: hidden;
            height: 19px;
        }

        .c-starrate {
            &__sati {
                @include logo-svg($logo-icon_star, 54px, 10px);
            }
            &__gauge {
                @include logo-svg($logo-icon_star_fill, 54px, 10px);
            }
        }

        .c-flag-box {
            margin-bottom: 1px;
            height: auto;
            max-height: 44px;
        }
        .c-ad__btn {
            position: absolute;
            left: 0;
            bottom: -1px;
            z-index: 5;
        }
        &--soldout {
            .c-card-item__swatch + .c-card-item__soldout-text {
                height: calc(100% - 38px);
            }
            .c-card-item__counsel {
                em {
                    font-size: $font-size-subtitle2;
                    color: $color-gray-04;
                }
            }
        }
        .c-relates {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            padding: 0;
            margin: 0;
            background: transparent;
            &__button {
                &-link {
                    display: block;
                    position: relative;
                    height: 40px;
                    padding: 12px;
                    font-size: 13px;
                    color: $color-gray-03;
                    line-height: 16px;
                }
            }
        }
    }
}
