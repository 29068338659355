[data-type="ProductScroll_Shorts_Basic"] {
    .s-list {
        height: 100%;
        padding: 1px 10px 24px;
        li {
            padding: 0 6px;
        }
    }

    .c-card-item {
        height: 318px;
        border: 0;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.28), 0 2px 6px 0 rgba(0, 0, 0, 0.06);

        &__thumb {
            display: block;
            width: 152px;
            height: 228px;

            // 하단 dim
            &::before {
                content: "";
                position: absolute;
                z-index: 1;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 40px;
                border-radius: 0 0 4px 4px; // ios 대응
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }
            // default dim
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.2;
                border-radius: 4px 4px 0 0; // ios 대응
                background: $color-gray-01;
            }

            .c-lazyload {
                img {
                    object-fit: cover;
                }
            }
        }

        // 비디오 데이터 없을 경우 미노출
        &__play {
            margin-top: 0;
            padding-top: 0;
            &:before {
                position: relative;
            }
        }

        &__info {
            padding-bottom: 0;
        }

        &__name {
            display: block;
            height: 38px;
            margin-bottom: 8px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 19px;
        }

        &__profile {
            padding: 0 12px;
            font-size: 14px;
            color: $color-gray-07;

            dd {
                display: flex;
                align-items: center;
            }
        }

        &__profile-img {
            display: block;
            flex-shrink: 0;
            position: relative;
            width: 20px;
            height: 20px;
            margin-right: 4px;
            font-size: 0;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 50%;
                content: "";
            }
            .c-lazyload {
                display: block;
                font-size: 0;
                &:before {
                    background-size: contain;
                }
            }

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
        &__profile-name {
            @include text-ellipsis;
            color: $color-gray-04;
            font-size: 14px;
        }
    }

    // 비디오 데이터 없을 경우 미노출
    .c-video {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: opacity 1s 0.5s;

        &--hide {
            opacity: 0;
        }

        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    // 비디오 데이터 없을 경우 미노출
    .c-playing-time {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 10px;
        left: 8px;
        z-index: 1;
        dt {
            @include get-sprite-live11("icon_play", 16);
            overflow: hidden;
            font-size: 1px;
            color: transparent;
        }
        dd {
            padding-top: 1px;
            font-weight: 700;
            font-size: 12px;
            color: $color-gray-14;
        }
    }
}
