@import "../../common/partial/icon";
@import "../../common/helpers/variables";
@import "../../common/mixins";

@import "../../roadshop/sprites/sp_icon";
@import "../../roadshop/component/popularity";

@mixin b-card--roadshop {
    position: relative;

    .c-card__gallery {
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: "";
        }

        .c-card__thumb {
            width: auto !important;
            height: auto !important;

            .badge_codi {
                position: absolute;
                left: 0;
                top: 0;
                @include sprite-retina($sp_icon_ic_codi);
            }

            img {
                width: auto;
                height: 100%;
                max-width: initial;
                max-height: initial;
                left: -100%;
                right: -100%;
            }
        }

        .c-card__info {
            margin-top: 8px;
            padding: 0;
            min-height: initial;
            max-height: initial;
            line-height: 18px;

            .c-card__name {
                height: 16px;
                margin-bottom: 0;
                font-size: 12px;
                line-height: 16px;
                color: #878787;
                -webkit-line-clamp: 1;
            }

            .c-card__rate {
                strong {
                    font-size: 15px;
                    line-height: 18px;
                }
            }

            .c-card__price {
                min-height: initial;
                color: #111;

                strong {
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }

        .c-popularity {
            @include c-popularity;

            margin-top: 7px;
        }
    }

    .c-card__store {
        position: relative;
        margin-bottom: 5px;

        .c-card__store-info {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding: 0 16px;
            text-align: center;
            color: #fff;
            z-index: 2;

            .c-card__store-title {
                display: block;
                margin: 50px auto 10px;
                min-width: 100px;
                font-size: 16px;
                max-height: 54px;
                overflow: hidden;
            }

            .c-card__store-tag {
                font-size: 12px;

                span {
                    display: block;
                    line-height: 17px;
                }
            }
        }

        .c-card__thumb:before {
            @include size(100%);

            position: absolute;
            left: 0;
            top: 0;
            content: "";
            display: block;
            background: rgba(0, 0, 0, 0.3);
            z-index: 1;
        }

        .c-card__info {
            position: relative;
            overflow: visible;
            height: 92px;
            margin-top: 0;
            background: #333;
            text-align: center;

            &:before {
                height: 0;
                width: 0;
                content: "";
                display: block;
                position: absolute;
                top: -12px;
                left: 42%;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 14px solid #333;
                z-index: 1;
            }

            .c-card__store-head {
                display: block;
                padding-top: 25px;
                font-size: 11px;
                color: #50e3c2;
                letter-spacing: 0;
            }

            .c-card__store-name {
                display: block;
                padding-top: 6px;
                font-size: 13px;
                color: #fff;
                letter-spacing: 0;
            }
        }
    }

    .c-relates {
        margin: 0;
        padding: 0;
        border: 0;
        background: none;

        .c-relates__function {
            float: none;
            padding: 0;
            line-height: 16px;
        }

        .c-relates__button-link {
            position: relative;
            padding: 8px 0;
            color: #878787;

            &:after {
                right: 2px;
                top: 50%;
                width: 9px;
                height: 9px;
                margin: -4px 0 0 0;
                border-color: #afadaf;
            }
        }

        .c-relates__emphasis {
            font-weight: normal;
            color: #07bc88;
        }
    }

    .c-like-button--product {
        @include c-like-button--44;
        position: absolute;
        right: 0;
        top: -44px;
        margin-top: 133.3333%;
    }
}
