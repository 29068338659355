[data-type="Cap_Box_Ad"] {
    letter-spacing: $body-spacing;

    .c-headline {
        background: none;

        &__title {
            display: flex;
            padding-right: 56px;
            align-items: center;
        }

        &__subtitle {
            position: relative;
            display: inline-block;
            overflow: hidden;
            margin-left: 10px;
            padding-left: 12px;
            padding-right: 14px;
            font-size: 15px;
            font-weight: normal;
            color: $color-gray-04;
            line-height: 39px;
            text-overflow: ellipsis;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 12px;
                margin-top: -6px;
                background-color: $color-gray-11;
                content: "";
            }
            &[href] {
                &:after {
                    @include size(9px);

                    position: absolute;
                    top: 50%;
                    right: 2px;
                    margin: 0;
                    border: 1px solid $color-gray-07;
                    margin-top: -4px;
                    border-width: 0 1px 1px 0;
                    transform: rotate(315deg);
                    content: "";
                }
            }
        }

        // TODO: MPSR-131562 배포 후 제거 Start
        &__lottie {
            display: inline-block;
            line-height: 1;
            width: 67px;
            height: 16px;
            vertical-align: middle;
            & > span {
                display: inline-block;
                width: 67px;
                height: 16px;
            }
        }
        // MPSR-131562 배포 후 제거 End

        .c-lottie {
            display: flex;
            line-height: normal; // .c-headline__title reset
            &__container {
                display: block;
                width: var(--lottieWidth, 67px);
                height: var(--lottieHeight, 16px);
            }
        }

        .c-ad__btn {
            right: 16px;
        }
    }

    .c-iconinfo {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
    }
}
