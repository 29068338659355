@import "../../skpui/component/c-card-deal__info";
@import "../../skpui/component/c-deal-cap";

[data-type="ProductCard_Basic"] {
    @include grid(0, 0, false);
    letter-spacing: 0;
    .l-grid__col:after {
        border-color: #d1d1d6;
    }
    &.l-grid__row {
        background-color: $color-gray-11;
        .l-grid__col {
            padding: 0;
            background-color: $color-gray-14;
        }
    }
    .l-grid__col + .l-grid__col {
        margin-top: 8px;
    }
    .c-card__dealcard {
        .c-card__name-addition {
            margin-bottom: 8px;
            height: 18px;
            overflow: hidden;
            padding-bottom: 0;
        }
        .c-card__price--point {
            color: $color-11st-red;

            strong {
                color: $color-11st-red;
            }
        }
        .c-card__point {
            color: $color-11st-red;
        }
        .c-card__benefit {
            .benefit {
                &:first-child {
                    margin-bottom: 2px;
                }
            }
        }
        .c-card__qty {
            color: #666;
            font-size: 13px;
        }
        .c-card__delivery-info {
            justify-content: flex-end;

            .shooting-delivery,
            .shooting-install,
            .shooting-fresh,
            .shooting-plus {
                overflow: hidden;
                font-size: 13px;

                strong {
                    margin: -1px 2px 1px 0;
                }
                em {
                    margin-right: 3px;
                }
            }

            .c-card__qty {
                flex-shrink: 0;
                position: static;
                transform: none;
            }
            .c-card__qty + .c-card__qty {
                position: absolute;
                top: 50%;
                right: 16px;
                bottom: auto;
                transform: translateY(-50%);
            }
            .c-card__delivery + .c-card__qty {
                margin-left: 8px;
            }
        }
    }
    .c-card__benefit {
        clear: both;

        &::after {
            content: "";
            display: table;
            clear: both;
        }

        .benefit {
            margin-bottom: 2px;
        }
    }
    .c-card__counsel {
        height: 37px;
        line-height: 37px;
        border-top: 1px solid #f4f4f4;
        padding: 0 16px;
        font-size: 13px;
    }
    .c-card__name {
        clear: both;
    }
    .c-card__name + .c-card__counsel {
        height: auto;
        border: 0 none;
        padding: 5px 0 10px;
        line-height: 1;
    }
    .c-card__bargain {
        display: block;
        margin-bottom: 2px;
        font-size: 13px;
        color: $color-gray-07;

        .c-iconinfo--help {
            z-index: 5;
            margin: -11px 0 -9px;
        }
    }
    .c-card__delivery {
        padding-right: 120px;
        white-space: nowrap;

        &-content {
            em {
                margin-right: 6px;
                color: $color-11st-blue;
            }
            span {
                display: inline-block;
                position: relative;
                vertical-align: top;
            }
            span + span {
                @include text-ellipsis;
                max-width: 88%;
                margin-left: 2px;
                padding-left: 6px;

                &:before {
                    @include separators-dot(2px, 2px, $color-gray-10);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -1px;
                    content: "";
                }
            }
        }
        .c-card__free {
            display: inline-block;
            position: static;
        }
        .c-card__today {
            max-width: 88%;
            vertical-align: top;

            &:nth-child(2) {
                margin-left: 2px;
                padding-right: 0;
            }
        }
    }
    .c-card__info .c-card__qty {
        bottom: 0;
        line-height: 37px;
    }
    .c-card--relates {
        position: relative;
    }
    .c-relates__button-link {
        display: flex;
        align-items: center;
        position: relative;
        height: 38px;
        margin: 0 -16px -8px;
        padding: 0 16px;
        border-top: 1px solid $color-gray-12;
        font-size: 13px;
        line-height: 38px;
        color: $color-gray-04;

        em {
            margin-right: 4px;
        }

        .c-relates__icon {
            display: inline-block;
            height: 18px;
            margin-top: -1px;
            margin-left: 4px;

            img {
                display: block;
                height: 100%;
            }
            &:first-child {
                margin-left: 0;
                margin-right: 4px;
            }

            &--lg {
                @include size(20px);
            }
        }
    }
    .c-starrate {
        padding: 9px 0 3px;
    }
    .c-nation {
        float: left;
        margin: 2px 8px 3px 0;
    }
    .c-addition {
        background-color: $color-gray-14;
        border-top: none;
        padding: 0;
        &-quote {
            background-color: $color-gray-13;
            padding-left: 0;
            margin: 9px 16px;
        }
        &-link--store {
            display: none;
        }
        &-tag {
            padding: 0;
            padding-bottom: 4px;
            margin: 9px 16px;
            &__list {
                overflow: hidden;
                height: 26px;
                line-height: 26px;
            }

            &__item {
                display: inline-block;
                max-width: 100%;
                margin-right: 0;
                padding: 3px 8px;
                font-size: $font-size-body1;
                border: 1px solid $color-gray-10;
                border-radius: 24px;
                line-height: 17px;
                white-space: nowrap;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &-spec {
            margin: 9px 16px;
            background-color: $color-gray-13;
            font-size: $font-size-body1;
            .c-addition-spec-table {
                line-height: 22px;
                div,
                dt,
                dd {
                    display: inline-block;
                }
                div {
                    position: relative;
                    padding-right: 10px;
                    &:after {
                        @include size(2px);
                        content: "";
                        display: block;
                        border-radius: 1px;
                        background-color: #ddd;
                        position: absolute;
                        right: 2px;
                        top: 50%;
                        margin-top: -2px;
                    }
                    &:nth-last-of-type(1) {
                        &:after {
                            display: none;
                        }
                    }
                }
                dt {
                    color: $color-gray-07;
                }
                dd {
                    color: $color-gray-04;
                }
            }
        }
        .prd-snippet {
            @include rounding-box;
            @include size(210px 66px);
            padding: 8px 8px 8px 66px;
            &:after {
                display: none;
            }
            &__thumb {
                @include size(50px);
                left: 8px;
                top: 7px;
            }
            &__name {
                padding-top: 7px;
                font-size: $font-size-body1;
                color: $color-gray-04;
            }
            &__price {
                padding-top: 3px;
                font-size: $font-size-body2;
                strong {
                    font-size: $font-size-subtitle2;
                    font-weight: bold;
                }
            }
        }
        .c-card__quotation {
            position: relative;
            overflow: hidden;
            display: -webkit-box;
            padding: 0 0 0 27px;
            font-size: $font-size-body1;
            color: $color-gray-04;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.5;
            &:before {
                @include review-quote();
                left: 8px;
                top: -1px;
            }
        }
        .more {
            @include size(70px 66px);
            padding-top: 17px;
            font-size: $font-size-subtitle2;
            &:before {
                @include ico-arrow-right(8px, #666, 1px);
                background-image: none;
                margin: 0 auto 18px auto;
            }
            &:after {
                @include size(24px);
                content: "";
                display: block;
                border: 1px solid $color-gray-10;
                border-radius: 24px;
                position: absolute;
                top: 9px;
                left: 25px;
            }
        }
    }
    .c-card__mov__button {
        display: none;
    }

    .c-flag__item--ooah,
    .c-flag__item--freshtable {
        margin-top: 3px;
    }

    // 사람들이 보는 중
    .c-popular-count {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-left: 10px;

        &__wrap {
            display: flex;
            align-items: center;

            .c-popular-count__image + .c-popular-count__image {
                margin-left: -6px;
            }
        }
        &__image {
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid $color-gray-14;
            border-radius: 50%;
            box-sizing: border-box;
        }
        &__desc {
            margin-left: 4px;
            color: $color-gray-04;
            font-size: 13px;
            line-height: 16px;
        }
        &__point {
            color: $color-11st-blue;
            font-weight: 700;
        }
    }

    // 최대할인가
    .c-discount-price {
        display: flex;
        align-items: baseline;
        height: 19px;
        margin-top: 2px;
        font-size: 14px;
        color: $color-11st-red;
        &__title,
        &__value {
            flex-shrink: 0;
        }
        &__value {
            margin-left: 3px;
        }
        &__num {
            font-weight: 700;
            font-size: 15px;
        }
    }

    // MPSR-122760 김미선: c-card__info 영역 배송정보 케이스 구조 패치
    .c-card__addition {
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 16px;
        // border-top: 1px solid $color-gray-12;

        // 영역이 empty가 될때 c-card__addtion 영역을 감추기 위해 pseudo class 에서 border를 그린다
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $color-gray-12;
        }

        .c-card {
            &__delivery {
                @include retail-delivery;
                @include retail-install;
                @include retail-fresh;
                @include retail-plus;
                @include retail-seller;

                flex: 1;
                display: flex;
                align-items: center;
                min-width: 0;
                padding: 0;
                border-top: 0;
                font-size: 13px;

                .delivery + .delivery,
                .sktpass + .delivery {
                    @include text-ellipsis;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 2px;
                        height: 2px;
                        margin: -1px 5px 1px;
                        border-radius: 2px;
                        background-color: #ddd;
                        vertical-align: middle;
                    }
                }
                .sktpass:before {
                    @include get-sprite-common("logo_sktpass", 56);

                    content: "";
                    margin: -1px 4px 1px -1px;
                    vertical-align: middle;
                }
                .shooting-delivery,
                .shooting-install,
                .shooting-fresh,
                .shooting-plus,
                .shooting-seller {
                    overflow: hidden;
                    font-size: 13px;
                    text-overflow: ellipsis;

                    strong {
                        margin: -1px 4px 1px 0;
                    }
                    em {
                        margin-right: 3px;
                    }
                }

                & + .c-card__qty {
                    margin-left: 8px;
                }
            }
            &__soldout {
                color: $color-11st-red;
            }
            &__point {
                color: $color-11st-blue;
            }
            &__qty {
                margin-left: auto;
                line-height: 39px;

                em {
                    color: $color-11st-blue;
                }

                &--urgent {
                    animation: 3s linear 0s urgent infinite;

                    & + .c-card__qty--urgent {
                        animation-delay: -1.5s;
                    }
                }

                & + .c-card__qty {
                    position: absolute;
                    top: 0;
                    right: 16px;
                    line-height: 38px;
                }
            }
        }
        .c-popular-count {
            height: 39px;
            flex-shrink: 0;
        }
        .c-relates__button-link {
            margin: 0;
            padding: 0;
            border-top: 0;
        }
    }
}

@keyframes urgent {
    0%,
    25%,
    75%,
    100% {
        opacity: 0;
    }
    35%,
    65% {
        opacity: 1;
    }
}
