[data-ui-type="ProductGrid_Ooah_Pick_Basic"] {
    margin-bottom: 31px;
    .c-product {
        overflow: hidden;
        position: relative;
        z-index: 1;

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -8px;
            margin-left: -3px;
        }
        &__item {
            width: 50%;
            padding-top: 8px;
            padding-left: 3px;
        }
    }
    .c-n-dropdown {
        position: absolute;
        top: 25px;
        right: 16px;
        font-family: $font-family3;
        vertical-align: top;
        white-space: nowrap;
        z-index: 10;
    }
    .c-n-dropdown__list {
        right: 0;
        left: auto;
    }
}
