[data-type="ProductScroll_Img"] {
    @include ootdLikeButton;

    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
    .s-list {
        padding: 0 8px 16px;

        li {
            padding: 0 8px;
        }
    }
    .c-lazyload {
        height: 100%;
    }
    .c-skeleton__box {
        width: 152px;
        height: 204px;
        border-radius: 12px;
    }

    // 상품카드
    .c-n-product {
        overflow: hidden;
        width: 152px;

        &__link {
            height: 100%;
        }
        &__rank {
            top: 8px;
            left: 8px;
            color: $color-gray-14;
            font-size: 12px;
            font-family: $font-outfit;
            background-color: rgba(34, 34, 34, 0.95);
            border-radius: 6px;
        }
        &__thumb {
            overflow: hidden;
            width: 152px;
            height: 204px;
            border-radius: 12px;

            &:after {
                z-index: 10;
                border: 1px solid rgba($color-gray-01, 0.02);
                background-color: rgba($color-gray-01, 0.05);
                border-radius: 12px;
                opacity: 1;
            }
        }
        &__info {
            padding: 8px 6px 0;
        }
        &__buttons {
            z-index: 20;
            top: 0;
            margin-top: 133.33%;
        }
        &__mark {
            @include get-sprite-ootd("ic_mark", 40);

            flex-shrink: 0;
            margin-left: 3px;
        }
    }
    .price-rate {
        color: $color-11st-red;
    }
    .c-lazyload img {
        object-fit: cover;
    }
}
