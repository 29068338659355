@charset "UTF-8";

.c-aside-alert {
    position: fixed;
    bottom: 56px;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 9px;
    color: $color-gray-14;
    background-color: rgba($color-11st-blue, 0.95);

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__desc {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;

        &--scroll:before {
            @include get-sprite-common("coachmark", 12);

            content: "";
            margin-right: 8px;
        }
    }

    &--hide {
        animation: fadeOutAsideAlert 1s ease 0.3s 1 forwards;
    }
}

@keyframes fadeOutAsideAlert {
    from {
        display: block;
        opacity: 1;
    }
    to {
        display: none;
        opacity: 0;
    }
}
