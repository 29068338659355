[data-type="Tabs_ImgText_Col3_Center"] {
    letter-spacing: $body-spacing;
    .c-list {
        &.c-list__gallery {
            margin-bottom: 0;
            .c-list__item {
                width: 33.3333%;
                text-align: center;
                font-size: 13px;
                &:nth-child(2n) {
                    border-right: 1px solid #f4f4f4;
                }
                .c-list__item-link {
                    padding: 0 14px 0 10px;
                    height: 48px;
                    line-height: 48px;
                }
                &--border {
                    border-bottom: 1px solid #f4f4f4;
                }
            }
        }
    }
}
