.c-n-search-bar {
    overflow: hidden;
    position: relative;
    padding: 0 47px 0 16px;
    border: 1px solid #bbb;
    border-radius: 40px;

    &__wrap {
        display: flex;
        align-items: center;
        height: 38px;
    }
    &__input {
        flex: 1;
        display: block;
        height: 100%;
        color: #111;
        font-size: 15px;
        font-weight: 600;
        line-height: 38px;
        border: 0;
        outline: none;
    }
    &__input,
    &__input[type="text"],
    &__input[type="search"] {
        color: #111;
        font-family: $font-family3;

        &::placeholder {
            color: #ccc;
            font-weight: 400;
        }
        &:focus {
            font-size: 16px;
        }
    }
    &__del {
        display: none;
        overflow: hidden;
        position: absolute;
        top: 50%;
        right: 45px;
        width: 24px;
        height: 24px;
        border: 4px solid #fff;
        color: transparent;
        background-color: #ddd;
        border-radius: 50%;
        box-sizing: border-box;
        transform: translateY(-50%);

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            background-image: linear-gradient(transparent 4px, #fff 4px), linear-gradient(90deg, transparent 4px, #fff 4px);
            background-size: 100% 5px, 5px 100%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    &__submit {
        @include get-sprite-luxury("ic-gnb-search", 36);

        overflow: hidden;
        position: absolute;
        top: 50%;
        right: 6px;
        border: 0;
        color: transparent;
        transform: translateY(-50%);
        background-color: #fff;
    }

    &--selected {
        border-color: #333;
    }
    &--focus {
        .c-n-search-bar__wrap {
            margin-right: 30px;
        }
        .c-n-search-bar__del {
            display: block;
        }
    }
}
