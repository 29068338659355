.c-product-price {
    display: flex;
    flex-wrap: wrap;

    * {
        font-family: $font-family3;
        line-height: 1;
    }

    &__item {
        display: flex;
        align-items: center;
        color: $color-gray-03;
        font-size: 12px;
    }
    &__rate {
        margin-right: 4px;
        color: $color-11st-red;
        font-size: 18px;
        font-weight: 700;
    }
    &__price {
        margin-right: 4px;
        color: $color-gray-02;
        font-size: 18px;
        font-weight: 700;
    }
    &__unit {
        margin-right: 4px;
        margin-left: -3px;
        font-size: 15px;
    }
    &__origin {
        margin-top: 1px;
        color: $color-gray-07;

        &--primary {
            color: $color-11st-red;
        }
    }
    &__desc {
        font-size: 16px;

        &--info {
            color: $color-11st-blue;
        }
        &--secondary {
            color: $color-gray-07;
            font-size: 13px;
        }
    }

    // c-card-item__info dt 로 인해 선택자 우선순위가 낮아져 depth 높혀서 선언함
    .c-product-price__lead {
        overflow: visible;
        position: static;
        width: auto;
        height: auto;
        margin: 1px 4px 0 0;
        font-size: 16px;
        clip: auto;

        &--primary {
            color: $color-11st-red;
        }
        &--info {
            color: $color-11st-blue;
        }
    }

    &--column {
        flex-direction: column;
    }
}
