[data-type="ProductCard_Basic_Schedule"] {
    @include grid(0, 0, false);
    letter-spacing: 0;
    .l-grid__col:after {
        border-color: #d1d1d6;
    }
    &.l-grid__row {
        background-color: $color-gray-11;
        .l-grid__col {
            padding: 0;
            background-color: $color-gray-14;
        }
    }
    .l-grid__col + .l-grid__col {
        margin-top: 8px;
    }
    .c-card__mov__button {
        display: none;
    }
    .c-card__dealcard {
        .c-card {
            &__name {
                height: 16px;
                margin-bottom: 0;
                padding-top: 0;
                line-height: normal;
            }
            &__soldout-text {
                margin-top: 8px;
                padding-bottom: 6px;
                line-height: 16px;
            }
        }
    }

    .c-lazyload {
        &--ratio_375x156 {
            padding-bottom: 41.6%;
        }
    }

    // 예정 딜
    .c-scheduled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // 선배포 대응 스타일, 배포 후 padding-top으로 변경
        height: calc(100% - 68px);
        // padding-top: 41.6%;
        background-color: rgba(0, 0, 0, 0.5);

        &__wrap {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &__count {
            overflow: hidden;
            display: inline-block;
            height: 20px;
            margin-bottom: 8px;
            padding: 0 6px;
            color: $color-gray-14;
            font-size: 12px;
            line-height: 22px;
            background-color: rgba(0, 0, 0, 0.4);
            border-radius: 4px;
            vertical-align: top;

            // 선배포 대응 스타일, MPSR-141351 배포 시 제거 (Start)
            & + .c-scheduled__date {
                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
                text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
            }
            & ~ .c-scheduled__button {
                min-width: 133px;
                margin-top: 9px;
                background-color: rgba($color-gray-14, 0.3);
                &[aria-pressed="false"] {
                    background-color: $color-gray-14;
                }
            }
        }
        &__point {
            font-weight: 700;
        }
        // 선배포 대응 스타일, MPSR-141351 배포 시 제거 (End)
        &__date {
            color: $color-gray-14;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
        }
        &__button {
            overflow: hidden;
            height: 40px;
            margin-top: 8px;
            padding: 0 16px;
            border: 0;
            color: $color-gray-14;
            font-size: 14px;
            line-height: 42px;
            background-color: rgba($color-gray-14, 0.16);
            border-radius: 40px;

            &:before {
                @include sprite-retina($sp_eui_alarm_red2_on);

                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
            }

            &[aria-pressed="false"] {
                color: $color-11st-red;
                font-weight: 700;
                background-color: $color-gray-14;

                &:before {
                    @include sprite-retina($sp_eui_alarm_red);
                }
            }
        }
    }
}
