[data-type="Tabs_Text_Scroll"] {
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .c-nav {
        border-bottom: 1px solid $color-gray-12;
    }
    .c-nav__wrap {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            animation: 1s refeash linear infinite;
            content: '';
        }

        .c-nav__item {
            &--new {
                position: absolute;
                top: 10px;
                margin-left: 1px;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $color-11st-red;
                font-size: 2px;
                color: transparent;
            }
            &.on a {
                font-weight: 700;
                padding: 2px 10px;
            }
        }
    }
}