.wrap-ootd {
    &,
    #root {
        height: 100%;
    }

    * {
        font-family: $font-family3;
    }
    // [start] MPQA-74464 간헐적으로 GNB영역에 여백 노출되는 현상
    .b-fixed {
        position: static;
        background-color: transparent;
    }
    .c-gnb {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 31;
    }
    // [end] MPQA-74464 간헐적으로 GNB영역에 여백 노출되는 현상

    .l-grid {
        overflow: visible;
    }
    main,
    .main {
        padding-bottom: calc(constant(safe-area-inset-bottom) + 56px);
        padding-bottom: calc(env(safe-area-inset-bottom) + 56px);
    }

    // 페이지 하단 공통 여백 (뒤로가기, TOP버튼 대응)
    .carrier-list {
        padding-bottom: 72px;
    }

    .c-gotop,
    .c-goback {
        position: fixed;
        left: 12px;
        bottom: calc(68px + env(safe-area-inset-bottom));
        z-index: 100;
        display: block;
        width: 40px;
        height: 40px;
        padding: 8px;
        border: 0;
        background-color: $color-gray-14;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0px rgba(16, 0, 0, 0.14);
        border-radius: 50%;
        box-sizing: border-box;
        outline: 0;

        &:before {
            @include get-sprite-ootd("ic_arrow_back", 24);

            content: "";
            display: block;
        }
    }
    .c-gotop {
        position: static;
        margin-top: 8px;
        margin-left: auto;
        background-image: none;
        color: transparent;

        &:before {
            transform: rotate(90deg);
        }
    }

    [data-type="Contents_CategoryFilter"] .c-sorting:only-child {
        border-bottom: 0;
    }

    // 서랍필터 하단 탭바 만큼 여백 추가
    .c-search-filter__sector:last-child,
    .c-search-filter__cetegory-contents .c-search-filter__scroll {
        padding-bottom: calc(constant(safe-area-inset-bottom) + 56px);
        padding-bottom: calc(env(safe-area-inset-bottom) + 56px);
    }
}

// 앱 전용
.wrap-inapp {
    .c-tabbar--ootd {
        top: 100vh;
        bottom: auto;
        margin-top: -89px;
        padding-bottom: 32px;
    }
    .c-goback,
    .c-bottom-module {
        bottom: 100px;
    }
    .c-ootd-category {
        padding-bottom: 113px;
    }
}
