[data-ui-type="Content_Mart_Address"] {
    font-size: 15px;
    color: $color-gray-02;

    .c-headline__title {
        height: 69px;
        line-height: 1;
        padding: 24px 0 20px;
        margin: 0 16px;
    }
    .c-headline__option {
        margin-left: 4px;
        font-size: 15px;
        font-weight: normal;
    }
    p {
        margin: 0 16px;
        & + p {
            margin-top: 8px;
            color: $color-gray-07;
            padding-bottom: 16px;
        }
    }

    .mbtn {
        display: inline-block;
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 0 8px;
        border: 1px solid $color-gray-09;
        border-radius: 4px;
        background-color: $color-gray-14;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 30px;
        color: $color-gray-02;
    }
}
