.c-sorting-inline {
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;

    .c-sorting-inline__item {
        display: inline-block;

        .c-sorting-inline__link {
            display: block;
            position: relative;
            padding-left: 13px;
            color: #878787;

            &:before {
                position: absolute;
                left: 2px;
                top: 6px;
                width: 5px;
                height: 6px;
                background-color: #e8e6ea;
                transform: skewX(-35deg);
                content: '';
            }
        }
    }

    .c-sorting-inline__item.on {
        .c-sorting-inline__link {
            color: #000;

            &:before {
                background-color: #38d3b0;
            }
        }
    }

    .c-sorting-inline__item + .c-sorting-inline__item {
        margin-left: 10px;
    }
}
