[data-type="Contents_Date"] {
    .c-date-title {
        padding: 8px 0;
        text-align: center;

        &__wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        &__date {
            color: $color-gray-04;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
        }
        &__week {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            margin-left: 4px;
            color: $color-gray-14;
            font-size: 14px;
            line-height: 1;
            text-align: center;
            background-color: $color-gray-04;
            border-radius: 50%;
        }
    }
}
