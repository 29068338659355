[data-ui-type="Content_Mart_Time_Tab"] {
    padding: 16px 16px 0 16px;
}

[data-ui-type="Content_Mart_Time_Tab"] + [data-ui-type="Content_Time_Picker"] {
    .w-tab__date {
        border-width: 0 0 1px 0;
    }
}

.c-tab__link {
    font-size: 14px;
}
