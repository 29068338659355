.c-pick-list {
    $headerHeight: 56px;
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $headerHeight;
        padding: 10px 16px;
        background-color: $color-gray-14;
        font-size: 19px;
        font-weight: 700;
        color: $color-gray-01;
        box-sizing: border-box;

        &--close {
            display: flex;
        } // .c-list__header--close
    } // .c-list__header

    &__wrap {
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        width: 100%;
        height: var(--OoahPickVh);
        padding-top: $headerHeight - 1;
        background-color: $color-gray-01;
        box-sizing: border-box;
    } //c-list__wrap
    &__ul {
        width: 100%;
    } // .c-list__ul
    &__li {
        position: relative;
    } // .c-list__li
    &__link {
        position: relative;
        display: block;
        width: 100%;
        height: 120px;
        &--inner {
            $padding: 60px;
            position: absolute;
            left: $padding;
            right: $padding;
            top: 50%;
            z-index: 3;
            transform: translateY(-50%);
            text-align: center;
        } //c-pick__list--inner
        &--text {
            @include text-ellipsis-multiple(2);
            color: $color-gray-14;
            &:first-child {
                font-family: $font-outfit;
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
            }
        }
        &--ci {
            width: auto;
            height: 24px;
            vertical-align: top;
        } //c-pick__list--ci
        &--image {
            //배경 이미지
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        } //c-pick__list--image
        &::after,
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        &::after {
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
        }
        &::before {
            background-color: rgba(0, 0, 0, 0.5);
        }
    } // c-pick__list
}
