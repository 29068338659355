@import "../../pui/sprites/sp_icons";
[data-type="ProductList_SmallImg_Review"] {
    @include grid(0, 0, false);
    .l-grid__col {
        border-bottom: 1px solid #f4f4f4;
    }
    .l-grid__col--12 {
        width: 100%;
        float: none;
        padding: 16px;
        .b-card--recommand {
            .c-card.c-card__gallery {
                min-height: 88px;
                .c-card__thumb {
                    width: 96px !important;
                    height: 96px !important;
                    margin: 0;
                    float: left;
                }
                .c-prd-info {
                    padding-top: 0;
                    padding-left: 8px;
                    &__name {
                        padding-top: 2px;
                        font-size: 15px;
                        color: #111;
                        height: auto;
                        -webkit-line-clamp: 1;
                    }
                    .c-prd-info__price {
                        min-height: 0;
                    }
                    .c-prd-review {
                        &>div {
                            display: inline-block;
                        }
                        &__rate {
                            display: inline-block;
                            &+.c-prd-review__count {
                                display: inline-block;
                                font-size: 12px;
                                color: #999;
                            }
                        }
                        &__num {
                            color: #f8b907;
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 1px;
                        }
                        &__count {
                            position: relative;
                            font-size: 12px;
                            color: #999;
                            letter-spacing: 1px;
                            padding: 0 6px 0 4px;
                            &::after {
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 4px;
                                @include ico-arrow-right(6px, #999, 1px);
                            }
                        }
                    }
                    .c-prd-cmt {
                        p {
                            font-size: 13px;
                            color: #666;
                            @include text-ellipsis-multiple(2);
                            line-height: 16px;
                            background-image: url('/MW/img/pui/sprites/icons/qout_end.png');
                            background-size: 10px 10px;
                            background-position: bottom 7px right 7px;
                            background-repeat: no-repeat;
                            padding-right: 10px;
                        }
                        margin-top: 6px;
                        padding: 0px 6px 0 12px;
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: block;
                            @include size(10px);
                            @include sprite-retina($sp_icons_qout_start);
                        }
                    }
                    .c-prd-info__benefit {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
