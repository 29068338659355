// @import "../../common/partial/starrate";
[data-type="Product_myreco_SmallReview"] {
    @include grid(2, 2, false);
    letter-spacing: $body-spacing;

    .b-review {
        padding-bottom: 16px;
        li {
            padding-bottom: 10px;
        }
        &-item {
            position: relative;
            @include rounding-box;

            &__title {
                position: relative;
                display: block;
                padding: 18px 60px 18px 38px;
            }

            &__keyword {
                color: $color-gray-02;
                padding: 2px 0 4px 64px;
            }

            &__desc {
                padding-left: 64px;
                font-size: $font-size-body1;
                color: $color-gray-04;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .c-card {
                &__list {
                    border-radius: 4px;
                    &:after {
                        display: block;
                        position: absolute;
                        top: 40px !important;
                        right: 22px;
                        @include ico-arrow-right(7px, #999);
                    }
                }
                &__thumb {
                    @include size(58px);
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    display: block;
                    z-index: 1;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                &__info {
                    margin-left: initial;
                    padding: 26px 0 16px 0;
                    .c-card__name {
                        display: block;
                        height: initial;
                        max-height: 40px;
                        margin: 0 0 2px 86px;
                        font-size: $font-size-body1;
                        color: $color-gray-02;
                        @include mq-320 {
                            padding-right: 30px;
                        }
                        &--nowrap {
                            white-space: pre-line;
                            overflow: hidden;
                            height: 19px;
                        }
                    }
                    .c-card__description {
                        display: block;
                        overflow: hidden;
                        padding-left: 86px;
                        margin-right: 28px;
                        margin-bottom: 20px;
                        height: 18px;
                        font-size: $font-size-body1;
                        white-space: pre-line;
                        color: $color-gray-07;
                    }
                    .c-card__quotation {
                        position: relative;
                        overflow: hidden;
                        display: -webkit-box;
                        padding: 16px 16px 0 34px;
                        font-size: $font-size-subtitle2;
                        color: $color-gray-03;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        line-height: 1.5;
                        &:before {
                            @include review-quote();
                        }
                        &:after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: $color-gray-12;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                    .c-starrate {
                        padding-top: 10px;
                        padding-left: 34px;

                        &__sati,
                        &__gauge {
                            @include size(70px 14px);
                            background-size: 70px 14px;
                        }
                        &__review {
                            color: $color-gray-03;
                            font-weight: bold;
                            font-size: $font-size-body2;
                            line-height: 18px;
                        }
                    }
                    .c-review-info {
                        padding: 5px 0 0 34px;
                        color: $color-gray-07;
                        font-size: $font-size-caption1;
                        &__id {
                            position: relative;
                            padding-right: 6px;
                            &:after {
                                content: "";
                                display: block;
                                width: 1px;
                                height: 11px;
                                background-color: $color-gray-11;
                                position: absolute;
                                right: 0;
                                top: 2px;
                            }
                        }
                        &__date {
                            padding-left: 4px;
                        }
                    }
                }
            }
            .c-ad__btn {
                position: absolute;
                top: 8px;
                right: 8px;
            }
        }
    }
}
