[data-type="Cap_Luxury_Title_Bigtext"] {
    .c-n-headline {
        padding-top: 0;

        &__title {
            font-size: 26px;
            font-family: $font-outfit;
            font-weight: 500;
            line-height: 32px;
        }
    }
}
