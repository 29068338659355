[data-type="BannerContents_BrandAd"] {
    background-color: $color-gray-11;

    * {
        font-family: $font-family3;
    }

    .c-card-item {
        &__info {
            padding: 14px 16px 11px;
        }
        &__name-addition {
            overflow: hidden;
            height: 20px;
            margin-bottom: 1px;
            padding: 0;
            font-size: 14px;
            color: $color-gray-03;
        }
        &__name {
            display: block;
            height: 20px;
            margin: 0;
            font-size: 17px;
            font-weight: bold;
            color: $color-gray-02;
            line-height: 20px;
        }
        &__additional-text {
            overflow: hidden;
            height: 18px;
            margin-top: 2px;
            padding-right: 40px;
            font-size: 13px;
            color: $color-gray-04;
            line-height: 18px;
        }
        .c-lazyload {
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.04);
                content: "";
            }

            &--ratio_375x156 {
                padding-bottom: 41.6%;
            }
        }
        .c-ad-box {
            position: absolute;
            bottom: 11px;
            right: 12px;
        }
    }
    .l-grid__col {
        .c-card-item + .c-card-item {
            margin-top: 8px;
        }
    }

    .c-label {
        margin-bottom: 6px;
    }
}
