[data-type="ProductScroll_Luxury_Brand"] {
    .l-grid__col {
        &:after {
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 0;
            height: 1px;
            background: $color-gray-12;
            content: "";
        }
    }
    .c-n-product {
        &__thumb {
            @include size(106px);
            padding: 8px;
        }
        &__info {
            padding: 16px 6px 0 0;
        }
        .price-origin {
            vertical-align: top;
        }
    }
    .s-list {
        padding: 0 13px 24px;

        li {
            padding: 0 3px;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
}
