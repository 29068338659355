@import "../../roadshop/sprites/sp_icon";

[data-type="BannerContents_GateHome_Roadshop"] {
    position: relative;
    .swiper-wrapper {
        transition-timing-function: initial;
    }
    .swiper-slide {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        height: 400px;
        text-align: center;
        font-size: 18px;
        background: #fff;
        align-items: center;
    }
    .swiper-container-horizontal > .swiper-pagination-progressbar {
        position: absolute;
        top: 50px;
        left: auto;
        right: 0;
        width: 320px;
        height: 1px;
    }
    .c-parallax {
        &__title {
            position: absolute;
            top: 36px;
            left: 2px;
            padding-left: 36px;
            padding-right: 50px;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            z-index: 1;
            opacity: 0.5;
            &.selected {
                opacity: 1;
            }
            &:after {
                position: absolute;
                right: 20px;
                top: 3px;
                content: "";
                @include sprite-retina($sp_icon_ic_arrow_wh);
            }
        }
        &__image {
            @include size(100%);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
        &__info {
            position: relative;
            z-index: 1;
            top: -315px;
        }
        &__pagination {
            position: absolute;
            overflow: hidden;
            top: 71px;
            left: 37px;
            width: 90%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.3);
            z-index: 100;
            &-bar {
                position: absolute;
                opacity: 1;
                height: 1px;
                z-index: 101;
                background-color: #fff;
            }
        }
    }
    .c-gridcard {
        position: absolute;
        left: 50%;
        width: 284px;
        margin: 0 -142px;
        opacity: 0;
        &__item {
            display: block;
            width: 100%;
            overflow: hidden;
            .c-gridcard__thumb {
                @include size(140px);
                display: block;
                margin-right: 4px;
                float: left;
                img {
                    @include size(140px);
                }
            }
            .c-gridcard__info {
                @include size(140px);
                display: table-cell;
                position: relative;
                text-align: center;
                background-color: #38d3b0;
                vertical-align: middle;
                &:before {
                    @include size(0);
                    content: "";
                    display: block;
                    position: absolute;
                    top: 60px;
                    left: -11px;
                    z-index: 1;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-right: 12px solid #38d3b0;
                }
                &-title {
                    display: block;
                    height: 25px;
                    line-height: 25px;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.5);
                }
                &-text {
                    display: block;
                    padding: 0 16px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                }
            }
            .c-lazyload {
                &:before {
                    background-color: transparent;
                }
            }
        }
        li {
            padding-bottom: 4px;
            &:nth-child(2) {
                padding-bottom: 0;
                .c-gridcard__thumb {
                    float: right;
                    margin-right: 0;
                    margin-top: -140px;
                }
                .c-gridcard__info {
                    background-color: #222;
                    &:before {
                        left: initial;
                        right: -11px;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        border-left: 12px solid #222;
                        border-right: none;
                    }
                    &-title {
                        color: #50e3c2;
                    }
                }
            }
        }
        &.active {
            opacity: 1;
        }
    }
}
