[data-type="ContentsScroll_Noti"] {
    .c-product {
        position: relative;
        width: 100px;

        &__link {
            display: block;
        }
        &__wrap {
            overflow: hidden;
            background-color: $color-gray-14;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
            border-radius: 4px;
        }
        &__thumb {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
        &__info {
            padding: 12px 8px;
        }
        &__state {
            display: block;
            margin-top: 6px;
            color: $color-gray-05;
            font-size: 12px;
            line-height: 14px;
        }
        &__point {
            color: $color-11st-blue;
            font-weight: 700;
        }
        &__title {
            overflow: hidden;
            height: 32px;
            color: $color-gray-03;
            font-size: 13px;
            line-height: 16px;
        }
        &__addition {
            position: absolute;
            top: 56px;
            right: 0;
            z-index: 30;
        }
        &__button {
            display: block;
            border: 0;
            color: transparent;
            background-color: transparent;

            &[aria-pressed="true"] .c-product__notify {
                background-color: rgba(0, 0, 0, 0.16);

                &:before {
                    @include sprite-retina($sp_eui_alarm_red2_on);
                }
            }
            &[aria-pressed="false"] .c-product__notify {
                background-color: $color-gray-14;

                &:before {
                    @include sprite-retina($sp_eui_alarm_red);
                }
            }
        }
        &__notify {
            overflow: hidden;
            display: block;
            width: 28px;
            height: 28px;
            margin: 8px;
            background-color: $color-gray-14;
            border-radius: 50%;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                transform: translate(-50%, -50%);
            }
        }

        .c-schedule {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
        }
    }
    .c-schedule {
        padding: 10px;
        color: $color-gray-14;
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

        &__date {
            display: block;
            margin-bottom: 2px;
            font-size: 13px;
        }
    }

    .s-list {
        padding: 16px 10px;

        li {
            padding: 0 6px;
        }
    }
}
