[data-type="ProductList_SmallImg_Basic"] {
@include grid(2, 2, false);
 .l-grid__col--12 {
     width: 100%;
     float: none;
     .b-card--recommand {
         .c-card.c-card__gallery {
             min-height: 88px;
             .c-card__thumb {
                 width: 96px !important;
                 height: 96px !important;
                 margin: 0;
                 float: left;
             }
             .c-prd-info {
                 padding-top: 0;
                 padding-left: 8px;
                 &__name {
                     padding-top: 2px;
                     font-size: 15px;
                     color: #111;
                     height: auto;
                 }
                 .c-prd-info__price {
                     min-height: 34px;
                 }
                 .c-prd-info__benefit {
                     margin-top: 5px;
                 }
             }
         }
     }
 }
}
