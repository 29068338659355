[data-type="ProductScroll_Store_Ranking"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 16px 8px 0px 8px;
    }

    .c-content-list {
        padding-bottom: 12px;

        > li {
            @include rounding-box;
            margin-bottom: 16px;

            .c-content-box {
                .c-brandinfo {
                    display: table;
                    width: 100%;
                    padding: 12px 16px 12px 10px;
                    position: relative;
                    color: $color-gray-12;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    dl {
                        dt {
                            @include sr-only;
                        }
                    }
                    &__ranking {
                        box-sizing: border-box;
                        display: table-cell;
                        width: 34px;
                        height: 48px;
                        padding-right: 8px;
                        line-height: 40px;
                        vertical-align: middle;
                        text-align: center;
                        font-weight: bold;
                        text-shadow: 0 0 1px rgba(0, 0, 0, 0.28);
                    }
                    &__thumb {
                        @include size(60px 48px);
                        display: table-cell;

                        dd {
                            @include size(48px);
                            border-radius: 24px;
                        }
                        img {
                            @include size(48px);
                            display: block;
                            line-height: 0;
                            overflow: hidden;
                            border-radius: 24px;
                        }
                        .c-lazyload {
                            &:before {
                                background-color: transparent;
                                background-size: 30px 26px;
                            }
                        }
                    }
                    &__thumb--no {
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    &__info {
                        display: table-cell;
                        vertical-align: middle;
                    }
                    &__name {
                        display: inline-block;
                        position: relative;
                        overflow: hidden;
                        height: 18px;
                        padding-right: 12px;
                        margin: 2px 0;
                        font-size: 17px;
                        font-weight: bold;
                        line-height: 18px;
                        word-break: break-all;
                        color: $color-gray-14;
                        vertical-align: top;

                        &::after {
                            @include rotate(45deg);
                            @include size(8px);
                            content: "";
                            position: absolute;
                            top: 4px;
                            right: 2px;
                            margin-top: 0px;
                            border: solid #fff;
                            border-width: 1px 1px 0 0;
                        }
                        .c-brandinfo__best {
                            display: inline-block;
                            margin-right: 4px;
                            vertical-align: middle;

                            img {
                                vertical-align: top;
                            }
                        }
                    }
                    &__sale {
                        display: block;
                        height: 16px;
                        overflow: hidden;
                        margin-top: 2px;
                        font-size: 13px;
                        line-height: 16px;
                        span {
                            position: relative;
                            display: inline-block;
                            padding-right: 4px;
                            &:nth-child(2) {
                                padding-left: 8px;
                                &:before {
                                    @include separators-dot(3px, 3px, $color-gray-12);
                                    opacity: 0.4;
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 6px;
                                }
                            }
                        }
                    }
                    a {
                        display: block;
                        margin-right: 52px;
                    }
                    .c-brandinfo__btns {
                        position: absolute;
                        z-index: 33;
                        right: 12px;
                        top: 50%;
                        margin-top: -18px;
                        display: block;
                        height: 49px;
                        button {
                            @include size(36px);
                            position: relative;
                            display: inline-block;
                            background-color: transparent;
                            border: 0;

                            &:nth-child(1) {
                                margin-right: 4px;
                            }
                            &:nth-child(2) {
                                margin-left: 8px;
                            }
                            &:before {
                                content: "";
                                display: block;
                                margin: 6px auto 10px;
                                @include size(36px);
                            }
                            &:after {
                                content: "";
                                display: block;
                                border: 1px solid rgba(288, 288, 288, 0.4);
                                border-radius: 50%;
                                position: absolute;
                                @include size(36px);
                                top: 0;
                                left: 50%;
                                margin-left: -18px;
                            }
                            .c-like__count {
                                display: block;
                                margin-top: 4px;
                                font-size: 10px;
                                color: #666;
                                text-align: center;
                                min-width: 36px;
                            }
                        }
                        .c-like {
                            &:before {
                                @include svg-icon($icon-store-zzim-off, 24px, 24px);
                            }
                            &.on {
                                &:before {
                                    @include svg-icon($icon-store-zzim-on, 24px, 24px);
                                }
                            }
                            &__count {
                                display: block;
                                margin-top: 4px;
                                font-size: 10px;
                                color: #666;
                                text-align: center;
                            }
                        }
                        .c-storeCoupon {
                            &:before {
                                @include sprite-retina($sp_btn_store_coupon);
                            }
                            &__count {
                                display: block;
                                margin-top: 4px;
                                font-size: 10px;
                                color: #666;
                                text-align: center;
                            }
                        }
                    }
                }

                .s-list {
                    padding: 8px 10px;
                    li {
                        padding: 8px 6px;
                        .c-card-item {
                            &__thumb {
                                @include size(88px);
                            }
                            &__info {
                                width: 88px;
                                padding: 8px 8px 4px 0;
                            }
                            &__name {
                                display: block;
                                height: 37px;
                                margin-bottom: 4px;
                                font-size: 14px;
                                line-height: 1.3em;
                                color: $color-gray-03;
                                text-shadow: 0 0 1px rgba(0, 0, 0, 0.28);
                            }
                        }
                    }
                }

                .c-addition {
                    margin: -6px 16px 0 16px;
                    background: none;
                    font-size: 13px;
                    color: $color-gray-03;

                    &-link {
                        padding: 14px 0;
                    }
                }

                &--skin01 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(255, 75, 91), rgb(255, 91, 178));
                    }
                }
                &--skin02 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(251, 144, 173), rgb(179, 143, 255));
                    }
                }
                &--skin03 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(67, 178, 255), rgb(108, 112, 245));
                    }
                }
                &--skin04 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(16, 197, 196), rgb(65, 183, 122));
                    }
                }
                &--skin05 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(157, 194, 22), rgb(107, 158, 84));
                    }
                }
                &--skin06 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(235, 126, 106), rgb(227, 99, 99));
                    }
                }
                &--skin07 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(235, 125, 159), rgb(208, 72, 114));
                    }
                }
                &--skin08 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(176, 149, 122), rgb(135, 119, 93));
                    }
                }
                &--skin09 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(141, 163, 238), rgb(68, 105, 231));
                    }
                }
                &--skin10 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(138, 168, 119), rgb(82, 101, 71));
                    }
                }
                &--skin11 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(148, 158, 167), rgb(91, 97, 103));
                    }
                }
                &--skin12 {
                    .c-brandinfo {
                        background: linear-gradient(45deg, rgb(102, 102, 102), rgb(17, 17, 17));
                    }
                }
            }
        }
    }
}
