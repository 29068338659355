[data-type="PageTitle_Back"] {
    .c-headline {
        background-color: $color-11st-red;
        &__title {
            color: #fff;
            letter-spacing: 0;
        }
        &__text {
            .bt_prev {
                background-image: none;
                &:before, &:after {
                    position: absolute;
                    content: '';
                    top: 22px;
                }
                &:before {
                    left: 15px;
                    width: 13px;
                    height: 13px;
                    margin-top: -6px;
                    border-top: 1px solid #fff;
                    border-left: 1px solid #fff;
                    transform: rotate(-45deg);
                }
                &:after {
                    top: 22px;
                    left: 13px;
                    width: 20px;
                    height: 1px;
                    background-color: #fff;
                    transform: rotate(0);
                }
            }
        }
    }
}
