[data-type="Tabs_BoxBtn_Round"] {
    .c-tab {
        padding: 0 16px;
        &__list {
            overflow: hidden;
            border-radius: 20px;
            background-color: $color-gray-12;
            box-shadow: 0 0 0 1px rgba($color-gray-01, 0.02) inset;
        }
        &__link {
            height: 40px;
            padding: 0 12px;
            border: 0;
            background-color: transparent;
            font-size: 15px;
            color: $color-gray-02;
            line-height: 40px;
            font-weight: 400;
            &[aria-selected="true"] {
                border-radius: 20px;
                background-color: $color-11st-red;
                color: $color-gray-14;
                font-weight: 700;
            }
        }
    }
}
