.c-n-like {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 0;
    font-family: $font-family3;
    background: url("/MW/img/svg/ic-heart.svg") no-repeat 50% 50%;
    background-size: 30px 30px;
    box-sizing: content-box !important;

    &[aria-pressed="true"] {
        background-image: url("/MW/img/svg/ic-heart-fill.svg");
    }

    &__lottie {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        transform: translate(-50%, -50%);
    }

    &--border {
        padding: 4px;
        border-radius: 50%;
        border: 1px solid $color-gray-10;
    }

    &--lg {
        width: 36px;
        height: 36px;
        background-image: url("/MW/img/svg/ic-heart-lg.svg");
        background-size: 36px 36px;

        &[aria-pressed="true"] {
            background-image: url("/MW/img/svg/ic-heart-lg-fill.svg");
        }

        .c-n-like__lottie {
            width: 36px;
            height: 36px;
        }
    }

    // 이 modifier가 가장 하단에 선언되어 있어야 합니다
    &--loaded,
    &--loaded[aria-pressed="true"] {
        background: none;
    }
}
