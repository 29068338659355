[data-type="ProductScroll_Luxury_Row2"] {
    $itemRowCount: 10;

    .s-list {
        display: flex;
        flex-wrap: wrap;
        width: (168 * $itemRowCount) + 26px; // 아이템 1개 width 168 + 양쪽 여백 26
        margin-top: -32px;
        padding: 0 13px;
        box-sizing: border-box;

        li {
            padding: 32px 3px 0;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }

    .c-n-product {
        width: 162px;

        &__info {
            padding: 12px 24px 0 0;
        }
        &__buttons {
            top: -38px;
        }
    }
    .c-n-like {
        padding: 4px;
        box-sizing: content-box;
    }
}
