[data-type="Cap_Title_Logo"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;

    .c-headline {
        &__link {
            display: flex;
            align-items: center;
        }

        &__title {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            font-size: $font-size-subtitle2;
            font-weight: normal;

            span {
                color: $color-11st-blue;
            }

            img {
                position: static;
                width: auto;
                height: 24px;
                margin: initial;
            }

            & + .c-headline__text {
                margin-left: -6px;
                padding-left: 10px;

                &:before {
                    content: "";
                }
            }
        }

        &__text {
            position: relative;
            overflow: hidden;
            max-height: 36px;
            margin-right: 36px;
            font-size: 15px;
            color: $color-gray-04;
            line-height: 38px;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 12px;
                background-color: #d9d9d9;
                transform: translateY(-50%);
            }
        }
    }
}

[data-type="GridList_Img_Col3"] + [data-type="Cap_Title_Logo"] {
    margin-top: 8px !important;
}
