@charset "UTF-8";

// sheet - 더보기
.ai-feed-more {
    &__list {
        padding: 12px 20px;
    }
    &__item {
        & + & {
            border-top: 1px solid #eaeaea;
        }
    }
    &__button {
        $border-radius: 8px;
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 64px;
        border: 0;
        padding: 16px 20px;
        background-color: $color-gray-12;
        color: $color-gray-02;

        span {
            margin-right: 6px;
        }

        @at-root .ai-feed-more__item:first-child & {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        @at-root .ai-feed-more__item:last-child & {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
} //.ai-feed
.ai-feed-icon {
    // 더보기 이외에서 사용시 공통 파일로 이동 할 것
    &-exclamtion,
    &-cancel {
        display: inline-block;
        $size: 36px;
        width: $size;
        height: $size;
        margin-right: 6px;
    }

    &-exclamtion {
        background: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='gnb/ic_gnb_11talk'%3E%3Cpath id='Combined-Shape-Copy-2' fill-rule='evenodd' clip-rule='evenodd' d='M27.2052 28C27.4145 28 27.6153 27.9168 27.7634 27.7688C28.0717 27.4605 28.0717 26.9606 27.7634 26.6523L25.6046 24.4941C27.0981 22.7469 28 20.4788 28 18C28 12.4772 23.5228 8 18 8C12.4772 8 8 12.4772 8 18C8 23.5228 12.4772 28 18 28H27.2052Z' stroke='%23333333' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Line-2' d='M18 12V20' stroke='%23333333' stroke-width='2'/%3E%3Cpath id='Line-2-Copy' d='M18 22V24' stroke='%23333333' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A") center no-repeat;
    }

    &-cancel {
        background: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='gnb/ic_gnb_close'%3E%3Cpath id='Union' fill-rule='evenodd' clip-rule='evenodd' d='M27.5302 9.52974C27.8232 9.23692 27.8233 8.76205 27.5305 8.46908C27.2377 8.17611 26.7628 8.17598 26.4698 8.4688L17.9976 16.9366L9.53032 8.46966C9.23742 8.17677 8.76255 8.17678 8.46966 8.46968C8.17677 8.76258 8.17678 9.23745 8.46968 9.53034L16.9367 17.997L8.46981 26.4594C8.17684 26.7522 8.17671 27.2271 8.46953 27.5201C8.76235 27.813 9.23722 27.8132 9.53019 27.5203L17.9973 19.0576L26.4697 27.5296C26.7626 27.8225 27.2375 27.8225 27.5303 27.5296C27.8232 27.2367 27.8232 26.7618 27.5303 26.4689L19.0583 17.9972L27.5302 9.52974Z' fill='%23333333'/%3E%3C/g%3E%3C/svg%3E%0A")
            center no-repeat;
    }
}
