[data-type="Tabs_NewRoundBtn_Fill_ColorBG_Scroll"] {
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .c-chip {
        margin-bottom: 0;
        border-color: var(--blockPointBorderColor, $color-gray-10);

        // MPINNER-11431 삭제예정
        &--selected,
        &[aria-pressed="true"] {
            border: 0;
            background: var(--blockPointColor, $color-11st-red);
            color: var(--blockPointTextColor, $color-gray-14);
            line-height: 33px;
        }
        // //MPINNER-11431 삭제예정
        &[aria-selected="true"] {
            border: 0;
            color: var(--blockPointTextColor, $color-gray-14);
            background: var(--blockPointColor, $color-11st-red);
            line-height: 33px;
        }
    }
    .w-scroll-css {
        padding-bottom: 13px;
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
    }
    .s-list {
        padding: 0 10px 0 16px;

        li {
            position: relative;
            padding: 0;

            &:before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 7px;
                bottom: 1px;
                border-radius: 16px;
                background: $color-gray-14;
                content: "";
            }
        }
    }
}
