[data-type="Contents_Luxury_SearchFilter"] {
    .c-filter {
        position: relative;
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-bottom: 1px solid #f4f4f4;

        &__button {
            position: relative;
            display: block;
            width: 32px;
            height: 32px;
            border: 1px solid #eee;
            border-radius: 4px;
            background-color: #fff;

            &--selected [class*="icon"] {
                @include get-sprite-luxury("ic-filter-on", 16);
            }
        }
        &__icon {
            @include get-sprite-luxury("ic-filter", 16);

            overflow: hidden;
            display: block;
            margin: 0 auto;
            color: transparent;
        }
        &__count {
            position: absolute;
            top: -8px;
            left: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 16px;
            height: 16px;
            margin-left: -8px;
            padding-right: 4px;
            padding-left: 4px;
            color: #fff;
            font-size: 11px;
            font-weight: 600;
            line-height: 13px;
            text-align: center;
            background-color: $color-luxury;
            border-radius: 16px;
        }

        &__list {
            display: flex;
            align-items: center;
            margin-left: 12px;

            &:before {
                content: "";
                display: block;
                width: 1px;
                height: 24px;
                margin-right: 8px;
                background-color: #f4f4f4;
            }
        }
        &__item {
            padding-right: 4px;
            padding-left: 4px;
        }
        &__chip {
            display: inline-flex;
            align-items: center;
            height: 32px;
            padding-right: 12px;
            padding-left: 12px;
            border: 1px solid #eee;
            color: #111;
            font-size: 13px;
            font-weight: 600;
            background-color: #fff;
            border-radius: 4px;
            box-sizing: border-box;
            white-space: nowrap;
        }
        &__del {
            @include btn-close-x(12px, 1px, $color-gray-03);

            overflow: hidden;
            position: relative;
            width: 26px;
            height: 26px;
            margin-right: -10px;
            border: 0;
            color: transparent;
            background-color: transparent;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 12px;
                margin-top: -6px;
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
        &__float {
            position: absolute;
            top: 50%;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            height: 100%;
            padding-right: 10px;
            padding-left: 10px;
            background-color: #fafafa;
            transform: translateY(-50%);

            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: -16px;
                width: 16px;
                background-image: linear-gradient(90deg, rgba(250, 250, 250, 0), rgba(250, 250, 250, 1));
            }
        }
        &__refresh {
            overflow: hidden;
            position: relative;
            display: block;
            width: 32px;
            height: 32px;
            border: 0;
            color: transparent;
            background-color: transparent;

            &:before {
                @include get-sprite-luxury("ic-refresh", 20);

                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                overflow: hidden;
                transform: translate(-50%, -50%);
            }
        }

        &--depth {
            padding: 0;
            border-bottom: 0;
            background-color: #fafafa;
        }
    }

    .c-sorting {
        position: relative;
        height: auto;
        padding: 18px 122px 18px 16px;
        letter-spacing: 0;

        // 구버전 PUI CSS 겹치는 문제
        border-bottom: 0;

        &__title {
            color: #111;
            font-size: 14px;
            line-height: 19px;

            .unit {
                font-weight: 400;
            }
        }
    }

    .c-n-dropdown {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);

        &__list {
            right: 0;
            left: auto;
        }
    }

    .s-list {
        padding: 12px;
        padding-right: 56px;

        li {
            padding: 0 4px;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }

    .posFixedNotToScroll {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        overflow: hidden;
        border-bottom: 1px solid transparent;
    }

    // 우측 정렬필터 레이어
    .dim-filter {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 101;
        background-color: rgba(0, 0, 0, 0.5);
        touch-action: none;
    }
}
