[data-type="ProductList_Luxury_History"] {
    // min-height: calc(100vh - (200px + 67px + 10px));

    &:before {
        position: absolute;
        top: 0;
        left: 20px;
        width: 1px;
        height: 100%;
        background: $color-gray-12;
        content: "";
    }
    .c-product {
        padding-left: 21px;

        &__date {
            position: relative;
            padding: 16px 16px 16px 21px;
            font-size: 14px;
            font-weight: 600;
            color: $color-gray-01;
            line-height: 16px;

            &:before {
                @include size(20px);
                position: absolute;
                top: 50%;
                left: -10px;
                border: 3px solid $color-gray-03;
                border-radius: 100%;
                background: $color-gray-14;
                transform: translateY(-50%) scale(0.5);
                content: "";
            }
        }
        &__list {
            margin-top: -8px;
        }
        &__item {
            display: flex;
            padding: 8px 16px 8px 20px;
        }
        &__delete {
            @include btn-close-x(14px, 1px, #111);

            overflow: hidden;
            position: relative;
            width: 40px;
            height: 40px;
            border: 0;
            background-color: transparent;

            &:before,
            &:after {
                top: 6px;
                right: 12px;
                margin: 0;
            }
        }
        &__buttons {
            flex-shrink: 0;
            width: 40px;
            margin-left: auto;
        }
    }
}
