[data-type="Billboard_Inner"] {
    @include grid(0, 2, false);
    padding: 8px;
    .c-pagination {
        display: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}
