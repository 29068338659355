[data-type="ContentsGrid_Box_Col2"] {
    letter-spacing: $body-spacing;
    padding: 12px 10px;
    padding-top: 0;
    .l-grid__col {
        padding: 12px 6px;
        padding-top: 0;
    }
    .c-card {
        &__info {
            padding: 14px 12px 16px 12px;
        }
        &__title {
            display: block;
            margin: 0;
            font-size: $font-size-body1;
            height: 40px;
            line-height: 20px;
            overflow: hidden;
            color: $color-gray-03;
        }
        &__thumb {
            img {
                width: auto;
                height: 100%;
                max-width: initial;
                max-height: initial;
                left: 50%;
                top: 100%;
                transform: translate(-50%, -50%);
            }
        }
        &__name {
            display: block;
            margin: 0;
            height: 18px;
            color: $color-gray-03;
            font-size: $font-size-subtitle2;
            white-space: pre-line;
            overflow: hidden;
        }
        &__tag {
            display: block;
            float: none;
            padding-top: 5px;
            height: 21px;
            font-size: $font-size-subtitle1;
            color: $color-11st-blue;
            overflow: hidden;
            white-space: pre-line;
        }
    }
}
