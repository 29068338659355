@import "../../live11/sprites/sp_icon";
@import "../../svg/sp_live11";

[data-type="BannerContents_Swipe"] {
    .w-swiper-board {
        position: relative;
    }
    .c-board {
        overflow: hidden;
        &-title {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 40px;
            padding: 20px 24px 0;
            z-index: 10;
            .lottie-container {
                display: block;
                width: 68px;
                height: 24px;
            }
            &__button {
                position: absolute;
                top: 14px;
                right: 20px;
                font-size: 0;
                &-schedule,
                &-setting {
                    display: inline-flex;
                    align-items: center;
                    height: 32px;
                    margin-left: 6px;
                    background-color: rgba($color: $color-gray-01, $alpha: 0.2);
                    padding: 0 12px;
                    border-radius: 16px;
                    font-weight: bold;
                    font-size: 13px;
                    color: $color-gray-14;
                    &:before {
                        content: "";
                        @include get-sprite-live11;
                        display: block;
                        flex-shrink: 0;
                        margin-right: 4px;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                }
                &-schedule {
                    &:before {
                        @include get-sprite-position-live11("ic-schedule");
                    }
                }
                &-setting {
                    &:before {
                        @include get-sprite-position-live11("ic-setting");
                    }
                }
            }
        }
        // c-board-item
        &-item {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 562px;
            background: $color-gray-02;

            @include mq-768 {
                height: 100vw;
                width: 100%;
            }

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                z-index: 10;
            }

            &::before {
                top: 0;
                height: 216px;
                opacity: 0.35;
                background: rgb(17, 17, 17);
                background: linear-gradient(0deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 1) 45%, rgba(17, 17, 17, 1) 100%);
            }

            &::after {
                bottom: 0;
                height: 320px;
                opacity: 0.45;
                background: rgb(17, 17, 17);
                background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(17, 17, 17, 1) 45%, rgba(17, 17, 17, 0) 100%);
            }

            &__visual {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                height: 100%;
                width: 100%;
                img,
                video {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }

            &__link {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: relative;
                z-index: 21;
                height: 100%;

                .c-board-item__info {
                    position: relative;
                    bottom: auto;
                    left: auto;
                    padding: 0 24px 36px;
                }

                & + .c-board-item-relates2 {
                    flex-shrink: 0;
                    position: relative;
                    bottom: auto;
                    margin-top: -16px;
                    padding-bottom: 24px;
                }
            }

            &__info {
                position: absolute;
                bottom: 89px;
                left: 24px;
                z-index: 100;
                padding: 0;
                color: $color-gray-14;
                width: 100%;
            }
            &__badge {
                padding-bottom: 11px;
                &-icon {
                    display: inline-block;
                    height: 18px;
                    line-height: 20px;
                    padding: 0 4px;
                    border-radius: 9px;
                    font-size: 12px;

                    &-live {
                        background-image: linear-gradient(51deg, rgb(255, 5, 0) 32%, rgb(255, 16, 196) 86%);
                    }

                    &-teaser {
                        font-weight: bold;
                        padding: 0 8px;
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }
                &-lottie {
                    background: transparent;
                    width: 35px;
                    height: 18px;
                    display: inline-block;
                    line-height: 1;
                    padding: 0;
                    position: relative;
                    top: 5px;
                    span {
                        display: block;
                        height: 100%;
                        width: 100%;
                    }
                    .live-img {
                        background: url(/MW/img/pui/ico_live_round.png) no-repeat;
                        background-size: 100% 100%;
                    }
                }
                &-text {
                    font-size: 15px;
                    font-weight: bold;
                    padding-left: 6px;
                }
            }

            &__title {
                font-weight: bold;
                text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

                h2,
                h3,
                h4 {
                    font-size: 28px;
                    line-height: 1.2;
                    margin-right: 94px;
                    max-height: 66px;
                    overflow: hidden;
                    word-break: keep-all;
                }

                p {
                    font-weight: normal;
                    text-shadow: initial;
                    font-size: 15px;
                    opacity: 0.9;
                    letter-spacing: initial;
                    margin-top: 8px;
                    height: 19px;
                    margin-right: 30px;
                    @include text-ellipsis;
                }
            }

            &__status {
                display: flex;
                position: absolute;
                top: 74px;
                right: 24px;
                left: 24px;
                height: 26px;
                line-height: 26px;
                z-index: 999;

                &-host,
                &-text {
                    font-size: 14px;
                }
                &-host {
                    overflow: hidden;
                    flex: 1;
                    .c-profile__link {
                        display: flex;
                        align-items: center;
                        background-color: transparent;
                        border: 0;
                        .c-profile__pic {
                            @include size(26px);
                            flex-shrink: 0;
                            img {
                                @include size(26px);
                                border: 2px solid $color-gray-14;
                                border-radius: 13px;
                            }
                            .c-lazyload {
                                @include size(26px);
                            }
                            &::after {
                                display: none;
                            }
                        }
                        .c-profile__name {
                            height: 25px;
                            line-height: 26px;
                            color: $color-gray-14;
                            font-weight: bold;
                        }
                    }
                }

                &-text {
                    flex-shrink: 0;
                    position: relative;
                    padding-left: 9px;
                    color: $color-gray-14;
                    text-shadow: 0 0 1px rgba($color-gray-01, 0.2);
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-51%);
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: rgba(255, 255, 255, 0.4);
                    }

                    &:only-child {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    &.live {
                        &:before {
                            background-color: $color-11st-red;
                        }
                    }
                }
            }
            &__watcher {
                position: relative;
                font-size: 14px;
                margin: 16px 0 0;
                padding: 0 0 0 21px;

                line-height: 1.43;
                letter-spacing: normal;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 1px;
                    @include sprite-retina($sp_icon_watcher);
                }
            }

            // (S) MPSR-118945 배포 후 제거
            &__awaiter {
                &::before {
                    @include sprite-retina($sp_icon_user);
                }
            }
            // (E) MPSR-118945 배포 후 제거

            &-action {
                position: absolute;
                left: 0;
                top: 190px;
                right: 0;

                z-index: 50;
                text-align: center;

                &__button {
                    display: inline-block;
                    height: 38px;
                    line-height: 42px;
                    text-align: center;
                    font-weight: bold;
                    border-radius: 19px;
                    color: #000;
                    padding: 0 26px 0 14px;
                    background-color: $color-gray-14;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        right: 14px;
                        top: 50%;
                        margin-top: -4px;

                        @include ico-arrow-right(8px, #000, 1px);
                    }
                }
                &__text {
                    padding: 10px 0 0 0;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            &-loading {
                height: 562px;
                padding-top: 256px;
                text-align: center;

                background-image: url("/MW/img/live11/bg_board.png");
                background-position: 0 -125px;
                background-size: cover;

                .lottie-container {
                    display: inline-block;
                    height: 20px;
                    width: 70px;
                    line-height: 1;
                }

                &::before,
                &::after {
                    display: none;
                }

                &-text {
                    color: $color-gray-14;
                    font-size: 16px;
                    line-height: 22px;
                    padding-top: 7px;
                    font-weight: initial;
                }
            }

            .area-fade {
                opacity: 0;
            }
            .action-sheet {
                opacity: 0;
                top: 180px;

                @include mq-768 {
                    top: 40%;
                }
            }
            .c-board-item__dimm {
                position: absolute;
                z-index: 20;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(17, 17, 17, 0.6);
                opacity: 0;
            }

            &__box {
                display: flex;
                align-items: center;
                margin-top: 16px;
                .c-profile__link {
                    position: relative;
                    padding-right: 16px;
                    background-color: transparent;
                    border: 0;
                    &:not(:last-child) {
                        &:after {
                            position: absolute;
                            top: 50%;
                            right: 7px;
                            width: 1px;
                            height: 12px;
                            margin-top: -6px;
                            border-right: 1px solid rgba($color-gray-11, 0.4);
                            vertical-align: middle;
                            content: "";
                        }
                    }
                    .c-profile__pic {
                        @include size(20px);
                        img {
                            @include size(20px);
                            border-radius: 13px;
                        }
                        .c-lazyload {
                            @include size(20px);
                        }
                        &::after {
                            display: none;
                        }
                    }
                    .c-profile__name {
                        height: 20px;
                        line-height: 22px;
                        color: $color-gray-14;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
                .c-board-item__watcher {
                    height: 20px;
                    margin: 0;
                    line-height: 22px;
                }
            }
        }
    }

    // pagination__board animation
    .c-pagination__board {
        position: absolute;
        z-index: 111;
        top: 60px;
        width: 100%;
        padding: 0 22px;
    }
    .c-pagination__indicator__bartype {
        display: flex;
        justify-content: space-between;
        .c-pagination__bar {
            position: relative;
            height: 2px;
            border-radius: 1px;
            background-color: rgba(256, 256, 256, 0.4);
            margin: 0 1px;
            transition: flex-grow ease 1s 0s;
            flex-grow: 1;
            flex-basis: auto;
            width: 10px;
        }
        .on {
            flex-grow: 0;
            flex-basis: 50%;
            .c-pagination__bar-progress {
                position: absolute;
                left: 0;
                top: 0;
                height: 2px;
                width: 0;
                border-radius: 1px;

                background-color: $color-gray-14;
            }
        }
    }
    .c-pagination__bar {
        border-radius: 0;
    }

    //슬라이드 1개 인 경우 슬라이드
    .only-item {
        .c-pagination__indicator__bartype {
            .c-pagination__bar,
            .on {
                flex-basis: 100%;
            }
        }
    }

    // transiton 효과 관련
    .swiper-slide-active {
        .action-sheet {
            transform: translateY(0);
        }
        &.show-text {
            .area-fade {
                transition: opacity 2s ease-in-out 0s;
                opacity: 1;
            }
        }

        &.show-action-sheet {
            .action-sheet {
                transition: opacity 1s ease-in-out 0s, transform 500ms ease-in-out 0s;
                opacity: 1;
                transform: translateY(-10px);
            }

            .c-board-item__dimm {
                transition: opacity 1s ease-in-out 0s;
                opacity: 1;
            }
        }

        &.hide-all {
            .area-fade,
            .action-sheet {
                transition: opacity 1s ease-in-out 0s;
                opacity: 0;
            }
        }
    }
}
