//기존 common.css에 있던 공통 스타일
body.grayType {
    background-color: #eee;
}
#cts {
    position: relative;
    padding: 0 0 10px 0;
}

// sticky wrapper 간 ios bug fix
.l-grid.l-grid__visible {
    overflow: visible;
    z-index: 30;
}
