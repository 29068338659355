[data-ui-type="Ooah_Pick_Indicator"] {
    position: fixed;
    bottom: 228px;
    left: 24px;
    right: 22px;
    z-index: 50;

    .c-indicator {
        //숫자 영역
        &__counter {
            display: flex;
            font-family: $font-outfit;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.23;
            text-align: center;
            &-currunt,
            &-total {
                position: relative;
                display: inline-block;
                width: 17px;
                vertical-align: top;
                font-smooth: never;
                -webkit-font-smoothing: none;
                -moz-osx-font-smoothing: grayscale;
            }
            &-currunt {
                color: $color-gray-14;
                opacity: 1;
                &.is-motion {
                    animation: HideMotion 0.3s ease-in 0s 1 normal none;
                }
            } //c_indicator__counter-currunt
            &-total {
                margin-left: 9px;
                color: rgba(255, 255, 255, 0.3);
                &::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: -5px;
                    width: 1px;
                    height: 9px;
                    background-color: rgba(255, 255, 255, 0.3);
                }
            } //c_indicator__counter-total
        } //c_indicator__counter

        // 바영역
        $bar-left: 55px;
        $bar-right: 36px;
        &__progress {
            appearance: none;
            position: absolute;
            top: 50%;
            left: $bar-left;
            right: $bar-right;
            width: auto;
            height: 2px;
            background-color: transparent;
            transform: translateY(-50%);
            &::-webkit-progress-bar {
                background-color: rgba(255, 255, 255, 0.2);
            }
            &::-webkit-progress-value {
                background-color: $color-gray-14;
                transition: width 0.15s linear;
            }
            &[value="0"] {
                &::-webkit-progress-value {
                    transition: none;
                }
            }
        }
        &__volume {
            $speaker_size: 24px;
            position: absolute;
            top: 50%;
            right: 0;
            @include size($speaker_size);
            transform: translateY(-50%);
            border: 0;

            &[aria-checked="false"] {
                background: url("/MW/img/svg/ooah-pick/ic-speaker-mute.svg") no-repeat center;
                background-size: $speaker_size;
            }
            &[aria-checked="true"] {
                background: url("/MW/img/svg/ooah-pick/ic-speaker.svg") no-repeat center;
                background-size: $speaker_size;
            }
        } //c_indicator__volume
    }
}
@keyframes HideMotion {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
