[data-type="ProductGrid_Live11"] {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10.5px;

    .l-grid__col {
        float: none;
        padding: 0 5.5px 11px;
    }

    .c-card-item {
        @include rounding-box;
        height: 100%;

        &-badge {
            left: 10px;
            top: 10px;
            font-size: 12px;
            color: $color-gray-14;
            z-index: 10;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        }
        &__badge-date {
            display: block;
            font-size: 13px;
            font-weight: bold;
        }
        &__badge-time {
            display: block;
            font-size: 18px;
            font-weight: bold;
        }
        &__badge-11live {
            display: inline-block;
            width: 35px;
            height: 18px;
        }
        .live-img {
            display: inline-block;
            width: 35px;
            height: 18px;
            background: url(/MW/img/pui/ico_live_round.png) no-repeat;
            background-size: 100% 100%;
        }
        &__badge-view {
            color: $color-gray-14;
            position: relative;
            line-height: 16px;
            top: -4px;
            margin-left: 5px;
            font-size: 13px;
            font-weight: bold;
            em {
                font-weight: bold;
            }
        }

        &__info {
            min-height: 64px;
            padding: 12px 12px 8px;
        }

        &__info--has-link {
            padding: 0;
            > a {
                display: block;
                height: 60px;
                padding: 12px;
            }
        }
        &__thumb {
            // 상단 dim (비디오 재생 시 가려짐)
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 56px;
                border-radius: 4px 4px 0 0; // ios 대응
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
            }
            // default dim
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                background: $color-gray-01;
                opacity: 0.1;
            }

            .c-video {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1; // 상단 dim 덮음
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                &--hide {
                    opacity: 0;
                }

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding: 10px 0 0 10px;
                height: 100%;
                line-height: 1.2;
            }
            &-vod {
                color: $color-gray-14;
                font-size: 12px;
            }
            .watcher {
                display: inline-block;
                position: relative;
                z-index: 10;
                height: 20px;
                padding: 3px 8px;
                background: rgba($color: $color-gray-01, $alpha: 0.3);
                border-radius: 10px;
                line-height: normal;
                font-weight: bold;
            }
        }
        &__thumb-link {
            display: block;
            position: relative;
            padding-top: 150%;
            .c-lazyload {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__mini-card {
            display: flex;
            position: absolute;
            z-index: 10;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 0 10px 12px;
            font-size: 14px;
            // 하단 dim
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 80px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }
            &-thumb {
                @include size(40px);
                display: block;
                flex-shrink: 0;
                overflow: hidden;
                position: relative;
                margin-right: 8px;
                min-width: 40px;
                border-radius: 4px;

                .c-lazyload {
                    &::before {
                        border-radius: 4px;
                    }
                }

                img {
                    border-radius: 2px;
                }
            }

            dl {
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                overflow: hidden;
                position: relative;
                height: 42px;
                .point {
                    color: $color-11st-blue;
                }
                dt {
                    color: $color-gray-03;
                    @include sr-only;
                }
                dd {
                    line-height: 15px;
                    font-size: 13px;
                    height: 15px;
                    vertical-align: top;
                    overflow: hidden;
                    color: $color-gray-14;
                    font-weight: normal;
                    span {
                        font-size: 13px;
                        font-weight: bold;
                    }
                    &:nth-child(4) {
                        font-size: 11px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
        &__name {
            height: auto;
            color: $color-gray-03;
            font-size: 14px;
        }
        &__info {
            .c-view-count-number {
                color: $color-11st-blue;
                font-size: 14px;
                padding-bottom: 5px;
            }

            .c-profile {
                padding: 0 12px 12px;
                font-size: 0;
                &__link {
                    display: flex;
                    align-items: center;
                }

                &__pic {
                    flex-shrink: 0;
                    float: none;
                    margin-right: 4px;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 11;
                        border: 1px solid rgba(0, 0, 0, 0.04);
                        border-radius: 50%;
                        content: "";
                    }
                }

                &__pic,
                &__pic span {
                    @include size(20px);
                }

                &__pic img {
                    @include size(20px);
                    border-radius: 50%;
                }

                &__name {
                    height: 18px;
                    line-height: 18px;
                    font-size: 14px;
                    color: $color-gray-04;
                }
                .c-lazyload {
                    &::before {
                        background: none;
                    }
                }
            }
        }

        &--vod {
            .c-card-item__thumb {
                &::after {
                    opacity: 0.2;
                }
            }
        }
    }
}
