[data-type="Tabs_TimeTable"] {
    .w-magnet-timetable {
        width: 100%;
        max-width: 1280px;
    }
    .c-nav {
        border-top: 1px solid $color-gray-12;
        border-bottom: 0;
        background-color: $color-gray-13;

        &:before {
            content: "";
            position: absolute;
            top: 55px;
            left: 0;
            right: 0;
            border-bottom: 1px solid $color-gray-11;
        }

        &__wrap {
            margin-right: 0;
        }

        &__list {
            display: block;
            width: auto;
            height: 94px;
            padding: 6px 6px 8px;
            font-size: 0;
            white-space: nowrap;
        }

        &__item {
            display: inline-block;
            width: 100 / 7 * 1%;
            vertical-align: top;
            @include mq-768 {
                width: 50px;
            }
            @media screen and (max-width: 320px) {
                width: 50px;
            }
            &--button {
                display: inline-block;
                width: 100%;
                border-radius: 4px;
                border: solid 1px transparent;
                background-color: transparent;
                font-size: 11px;

                .c-nav__date {
                    display: block;
                    height: 49px;
                    padding-top: 6px;
                    line-height: 16px;
                    color: $color-gray-01;
                }
                .day {
                    display: block;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                    color: inherit;
                }

                .c-nav__total {
                    display: block;
                    padding: 8px 0;
                    color: $color-gray-04;
                    line-height: 13px;
                }
                .num {
                    display: inline;
                    font-weight: 700;
                    color: inherit;
                }

                &[aria-selected="true"] {
                    overflow: hidden;
                    position: relative;
                    z-index: 1;
                    border: solid 1px $color-11st-red;
                    color: $color-gray-14;

                    .c-nav__date {
                        background-image: linear-gradient(134deg, #ff6335, #fe1d30 55%, #ff31a1 87%);
                        font-weight: 700;
                        color: $color-gray-14;
                    }
                    .c-nav__total {
                        background: $color-gray-14;
                        color: $color-11st-red;
                    }
                }

                &[aria-disabled="true"] {
                    .c-nav__date,
                    .c-nav__total {
                        color: $color-gray-08;
                    }
                }

                &:focus:not(:focus-visible) {
                    outline: 0;
                }
            }
        }
    }

    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 100;
        }
        &--absolute {
            position: absolute;
        }
    }
    .w-magnet {
        width: 100%;
        max-width: 1280px;
        background-color: #fafafa;
    }
    // magnet 처리시 하단 border 노출
    &.l-grid__row--border {
        .l-grid__col {
            &:after {
                display: none;
            }
        }

        .c-nav__wrap {
            &::after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 1px solid #f4f4f4;
                content: "";
            }
        }
    }

    // 편성표 페이지 내 스와이퍼 상단 여백 대응 (ProductList_Live11)
    & + .l-grid {
        margin-top: 0;
    }
}
