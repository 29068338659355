[data-type="Contents_Reco_Floating"] {
    .c-button-floating {
        display: flex;
        align-items: center;
        z-index: 100;
        visibility: hidden;
        position: fixed;
        top: 170px; // 기본값
        left: 50%;
        height: 40px;
        padding: 0 8px;
        opacity: 0;
        border: 1px solid var(--recommendButtonColor);
        border-radius: 100px;
        background: rgba($color: $color-gray-14, $alpha: 0.95);
        box-shadow: 0 2px 4px 0 rgba($color: $color-gray-01, $alpha: 0.08);
        font-family: $font-family3;
        color: var(--recommendButtonColor);
        transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateX(-50%);

        // [M] 서브페이지 - AI 홈탭 상단 플로팅 버튼 top 값 분기
        @at-root #sub & {
            top: 68px;
        }

        &--visible {
            visibility: visible;
            opacity: 1;
        }

        &__wrapper {
            display: flex;
            align-items: center;

            & + .c-button-floating__text {
                margin: 0 6px 0 5px;
            }
        }

        &__image {
            flex: 0 0 auto;
            overflow: hidden;
            width: 28px;
            height: 28px;
            border: 2px solid rgba($color: $color-gray-14, $alpha: 0.95);
            border-radius: 50%;

            &:first-child {
                margin-left: -2px;
            }

            & + .c-button-floating__image {
                margin-left: -8px;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        &__text {
            font-size: 14px;
            font-weight: 700;
        }
    }
}
