.c-input {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 12px;
    background-color: $color-gray-14;
    border: 1px solid $color-gray-10;
    border-radius: 4px;
    font-size: 15px;
    color: $color-gray-02;
    line-height: 38px;
    outline: none;

    &[type="email"],
    &[type="number"],
    &[type="password"],
    &[type="search"],
    &[type="tel"],
    &[type="text"] {
        color: $color-gray-02;
    }

    &::placeholder {
        color: $color-gray-07;
    }

    &:focus {
        border-color: $color-gray-08;
        -webkit-transition: border 0.2s ease-out;
        transition: border 0.2s ease-out;
    }

    &--disabled,
    &[disabled] {
        border-color: rgba($color: $color-gray-09, $alpha: 0.2);
        background: rgba($color: $color-gray-09, $alpha: 0.2);
        color: $color-gray-09;

        &::placeholder {
            color: $color-gray-09;
        }
    }

    &.c-input--fail {
        border-color: $color-11st-red;

        &:focus {
            border-color: $color-11st-red;
        }
    }

    &--fail-text {
        margin-top: 7px;
        font-size: 12px;
        color: $color-11st-red;
        letter-spacing: $body-spacing;
    }
}
