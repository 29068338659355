[data-type="GridScroll_Luxury_ImgText_CircleBox"] {
    .s-list {
        display: flex;
        flex-wrap: wrap;
        width: (76 * 6) + 36px; // 아이템 1개 width 76 + 양쪽 여백 24
        height: 208px; // Layout shift 문제로 기본 높이값 추가
        margin: 0 auto;
        padding: 8px 18px 0;
        box-sizing: border-box;

        li {
            padding: 16px 6px 0;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }

    .c-brand {
        display: block;
        width: 64px;
        margin: 0 auto;
        text-align: center;

        &__image {
            border-radius: 50%;
        }
        &__text {
            overflow: hidden;
            display: block;
            height: 14px;
            margin-top: 6px;
            color: $color-gray-02;
            font-size: 12px;
            font-family: $font-family3;
            line-height: 14px;
            word-break: keep-all;
            word-wrap: break-word;
        }
    }
}
