[data-type="GridScroll_Category"] {
    background-color: #fff;
    letter-spacing: $body-spacing;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .w-scroll-css {
        overflow-y: hidden;
    }

    .c-listimg {
        width: calc(78px * 15 + 8px);
        padding-left: 8px;

        &__item {
            width: 78px;

            &:nth-child(4n + 1) {
                clear: none;
            }
            &:nth-child(1) {
                .c-listimg__link {
                    padding: 14px 0 10px;
                }
            }
            &:nth-child(16) {
                clear: both;
                .c-listimg__link {
                    padding: 14px 0 10px;
                }
            }

            .c-listimg__link {
                padding: 14px 0 10px;
                border-width: 0;
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    height: 1px;
                    position: absolute;
                    bottom: -1px;
                    top: auto;
                    left: -8px;
                    border-bottom: 1px solid #f4f4f4;
                    width: 110%;
                }

                .c-listimg__icon-wrap {
                    @include size(48px);
                }

                .c-listimg__name {
                    padding-top: 4px;
                    line-height: 18px;
                    color: #666;
                }
            }
        }
        .break {
            clear: both;
        }
    }
    // item 10개씩 2줄인 case
    .c-listing--br_10x2 {
        width: calc(78px * 10 + 8px);
        .c-listimg__item {
            &:nth-child(4n + 1) {
                clear: none;
            }
            &:nth-child(16) {
                clear: none;
            }
            &:nth-child(4n + 1) {
                clear: none;
            }
            &:nth-child(11) {
                clear: both;
            }
        }
    }
}
