.ooah-pick .c-sorting {
    position: relative;
    height: 77px;
    border-bottom: 0;
    letter-spacing: 0;
    &__title {
        max-width: 50%;
        padding: 31px 0 0 16px;
        font-size: 18px;
        color: $color-gray-02;
        white-space: nowrap;
    }
    &__brand {
        display: inline-block;
        max-width: 100%;
        @include text-ellipsis;
    }
    &__count {
        display: inline-block;
        margin-left: 4px;
        color: $color-luxury;
        vertical-align: top;
        @at-root .brand-pick#{&} {
            color: $color-11st-red;
        }
    }
}
