.ooah-pick .c-guide {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 500;
    &__text {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        text-align: center;
        color: $color-gray-14;
    }
    &__lottie {
        width: 144px;
        height: 72px;
    }
}
