@charset "UTF-8";

.c-aside-gateway {
    position: fixed;
    top: 56px;
    right: 0;
    left: 0;
    z-index: 40;
    border-bottom: 1px solid $color-gray-11;
    background-color: $color-gray-14;
    transition: all 0.1s linear; // c-aside의 trasition과 동일해야함

    &__wrap {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
        }
    }
    &__list {
        display: inline-flex;
        padding: 10px 12px 15px;
        vertical-align: top;
    }
    &__link {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        width: 66px;
    }
    &__icon {
        overflow: hidden;
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 12px;
    }
    &__text {
        overflow: hidden;
        display: block;
        height: 14px;
        margin-top: 8px;
        color: $color-gray-02;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        word-break: keep-all;
    }
    &__badge {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;

        img {
            display: block;
            height: 16px;
        }
    }

    .c-skeleton {
        &__box,
        &__bar {
            background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
            animation: none;
        }
        &__box {
            width: 50px;
            height: 50px;
            margin: 0 auto 8px;
            padding: 0;
            border-radius: 12px;
        }
        &__bar {
            width: 62px;
            height: 14px;
            margin: 0 auto;
            border-radius: 4px;
        }
    }
}
