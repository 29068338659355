.c-n-flag {
    overflow: hidden;
    display: inline-block;
    align-items: center;
    min-width: 18px;
    height: 18px;
    padding: 0 6px;
    color: $color-gray-03;
    font-size: 12px;
    font-family: $font-family3;
    font-weight: 600;
    line-height: 18px;
    background-color: #f5f6f8;
    white-space: nowrap;
    text-align: center;
    vertical-align: top;

    &--fill {
        color: #fff;
        font-size: 11px;
        background-color: $color-luxury;
    }
}
