[data-type="LineBanner_Ad_120"] {
    height: 60px;
    .c-banner {
        @include size(320px 60px);
        position: relative;
        margin: 0 auto;
        &__link {
            @include size(320px 60px);
        }
        &__img {
            @include size(322px 61px);
            margin-top: -1px;
            margin-left: -1px;
        }
    }
    .c-ad__btn {
        position: absolute;
        top: 4px;
        right: 6px;
        width: 20px;
        overflow: hidden;
        background-image: url("/MW/img/main/ic_ad.png");
        background-repeat: no-repeat;
        background-size: 16px 12px;
        background-position: 3px 3px;
    }
    .c-ad-box {
        position: relative;

        .c-lazyload {
            img {
                position: relative !important;
                width: 100%;
                height: auto;
            }
        }
        .c-ad__btn {
            position: absolute;
            right: 13px;
            bottom: 12px;
        }
    }
}
