// 공통 툴팁 홈탭 애니메이션 추가
.c-gnb__menu {
    .c-tooltip {
        display: none;
        top: 46px;
        left: 69px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &--show {
            opacity: 1;
            animation-name: fade-out;
            animation-delay: 4.5s;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
        }
    }

    // 상위 엘리먼트의 overflow 속성을 변경한다
    &--visible .c-tooltip {
        display: block;
    }
}
