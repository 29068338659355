[data-type="Contents_BodyText_OpenClose"] {
    .c-accordion {
        &__item {
            font-weight: normal;
        }

        &__detail {
            display: none;
            ul {
                li {
                    position: relative;
                    padding-bottom: 8px;
                    padding-left: 10px;
                    line-height: 18px;

                    &:before {
                        content: "-";
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}