[data-type="ProductGrid_Col3_Swipe"] {
    * {
        font-family: $font-family3;
    }

    .c-product {
        overflow: hidden;
        padding-bottom: 16px;

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -14px;
            padding: 0 12px;
        }
        &__item {
            width: 33.33%;
            padding: 14px 4px 0;
        }
    }

    .c-card-item {
        &__thumb {
            overflow: hidden;
            border-radius: 8px;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid rgba(#002178, 0.04);
                border-radius: 8px;
                background: rgba(#002178, 0.04);
            }
        }
        &__emblem {
            position: absolute;
            top: 4px;
            left: 4px;
            z-index: 10;
            display: flex;
            align-items: center;
            height: 20px;
            padding: 0 4px;
            color: $color-gray-14;
            font-size: 11px;
            font-weight: 700;
            border-radius: 4px;
            background-color: rgba($color-gray-01, 0.5);
        }
        &__info {
            padding: 6px 0 0 0;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 32px;
            margin: 0;
            color: $color-gray-03;
            font-size: 13px;
            line-height: 16px;
        }
        &__price-info {
            display: flex;
            margin-top: 2px;

            &--small .c-card-item__price strong {
                font-size: 12px;
            }
        }
        &__price {
            font-size: 12px;

            strong {
                font-size: 15px;
            }
        }
        &__button {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: calc(100% - 24px);
        }
    }

    .c-starrate {
        display: flex;
        align-items: center;
        margin-top: 3px;

        &__sati,
        &__gauge {
            width: 54px;
            height: 10px;
            background-size: auto 100%;
        }
        &__sati {
            margin-right: 2px;
        }
        &__review {
            font-size: 11px;
            line-height: 12px;
        }
    }

    .c-ad__btn {
        @include sprite-retina($sp_icons_ad_text_round);
    }

    .c-pagination {
        height: 24px;

        &__indicator {
            line-height: 1;
        }
    }
}
