@import "../../pui/sprites/sp_icons";

[data-type="Tag_Scroll_Selection"] {
    .w-scroll--css {
        padding: 16px;
        height: 68px;
        .c-tag {
            height: 36px;
            line-height: 34px;
            background-color: #fff;
            border: 1px solid #eaeaea;
            border-radius: 18px;
            color: #333;
            margin-bottom: 0;
            &.active {
                color: #fff;
                background-color: #2cb1f8;
                border: 1px solid #2cb1f8;
            }
        }
    }
    .c-select-tag {
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-left: 45px;
        border-top: 1px solid #f4f4f4;
        &__list {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            height: 45px;
            padding-left: 8px;
            li {
                display: inline-block;
                padding-top: 8px;
                .c-tag {
                    background-color: transparent;
                    color: #666;
                    padding: 0 6px;
                    span {
                        @include size(16px);

                        display: inline-block;
                        margin-left: 3px;
                        vertical-align: middle;
                        position: relative;
                        text-indent: -99999px;
                        font-size: 0;

                        &:before, &:after {
                            @include rotate(45deg);

                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            content: '';
                            width: 13px;
                            height: 1px;
                            margin-top: -2px;
                            margin-left: -7px;
                            background-color: #666;
                        }
                        &:after {
                            @include rotate(-45deg);
                        }
                    }
                }
            }
        }
        &__reset {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 45px;
            height: 45px;
            padding-top: 28px;
            border-right: 1px solid #f4f4f4;
            font-size: 10px;
            text-align: center;
            &:before {
                @include sprite-retina($sp_icons_arrow_refresh);
                content: '';
                position: absolute;
                left: 10px;
                top: 4px;
            }
        }
    }
}
