@charset "UTF-8";

/* -------------- 배송지 변경, 추가 -----------------*/
.delivery_header {
    position: relative;
    height: 55px;
    border-bottom: 1px solid #e3e3e8;

    .delivery_title {
        overflow: hidden;
        width: 100%;
        height: 55px;
        padding: 0 55px 0 20px;
        background: #fff;
        color: $color-gray-02;
        font-size: 17px;
        line-height: 56px;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;

        &.back {
            padding-left: 55px;
        }
    }

    .page_back,
    .page_close {
        height: 55px;
    }

    .page_back {
        width: 52px;

        //&:before {
        //    display: none;
        //}
        &:before {
            width: 42px;
            height: 3px;
            background: $color-gray-03;
            transform: translate(-50%, -50%) scale(0.5);
        }

        //&:after {
        //    left: 30px;
        //    width: 20px;
        //    height: 20px;
        //    margin-top: -2px;
        //}

        &:after {
            width: 24px;
            height: 24px;
            margin-left: -3px;
            border: 3px solid $color-gray-03;
            border-width: 0 0 3px 3px;
            box-sizing: border-box;
            transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
        }
    }
}

.delivery_change {
    padding-bottom: 10px;
    letter-spacing: 0;

    * {
        font-family: $font-family3;
    }
    .addr_add {
        padding: 16px;
        .c-button,
        .bbtn {
            width: 100%;
            font-size: 15px;
            line-height: 38px;
            text-align: center;
        }
        .c-button {
            height: auto;
            width: auto;
            display: block;
        }
    }

    .addr_list {
        li {
            position: relative;
            padding: 16px;
            border-top: 1px solid $color-gray-12;
            color: $color-gray-02;
            font-size: 14px;
            line-height: 20px;

            &:before {
                content: "";
                float: right;
                width: 48px;
                height: 10px;
            }
            &.item {
                &:before {
                    width: 168px;
                }
                &.base {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .radio_box {
            display: block;

            label {
                display: block;
                position: relative;
                padding-left: 32px;
                padding-top: 0;
                height: auto;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                &:before {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    width: 22px;
                    height: 22px;
                    margin-top: 0;
                }
            }
            //장보기 /html/oneday/delivery_change_pui.html
            .c-radio {
                & + label:before {
                    top: 0;
                }
            }
        }
        .name {
            padding-top: 1px;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;

            .nick {
                display: inline-block;
                color: $color-11st-blue;
                font-size: 14px;
                font-weight: normal;
                vertical-align: 1px;

                &:before {
                    content: "(";
                }

                &:after {
                    content: ")";
                }
            }
        }

        .tel {
            margin-top: 8px;
            color: $color-gray-07;
        }

        .addr {
            margin-top: 4px;
        }

        .mbtn.btn_del {
            position: absolute;
            top: 16px;
            right: 16px;
            padding: 0 8px;
            line-height: 26px;
            font-size: 13px;
            display: inline-block;
            border: 1px solid #ccc;
            background-color: #fff;
            color: $color-gray-02;
            box-sizing: border-box;
            letter-spacing: 0;
        }

        .addr_button {
            position: absolute;
            top: 16px;
            right: 16px;

            .mbtn {
                float: left;
                height: 28px;
                margin-left: 8px;
                padding: 0 8px;
                line-height: initial;
            }
        }
    }
}

.delivery_input {
    padding-bottom: 50px;
    letter-spacing: 0;

    * {
        font-family: $font-family3;
    }
    input[type="text"],
    input[type="tel"] {
        @include style-input;
    }

    .select {
        @include style-select;
        width: 100px;
        margin-right: 8px;
    }

    .check_box {
        label:before {
            border-radius: 50%;
        }

        + .help {
            margin-top: 3px;
        }
    }

    .inp {
        padding: 8px 16px 26px;
        color: $color-gray-02;
        font-size: 14px;

        li {
            display: flex;
            margin-top: 8px;

            input:not([type="checkbox"]) {
                display: block;
                width: 100%;
                flex: 1;
            }

            .mbtn ~ input {
                margin-left: 8px;
            }

            &.check {
                margin: 14px 0;
            }
        }

        .mbtn {
            display: block;
            width: 88px;
            padding: 0;
            //border-color: $color-gray-07;
            font-size: 15px;
            line-height: 38px;
            text-align: center;
        }
    }
}

.delivery_input_wrap {
    .bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px;
        background: #fff;

        .bbtn {
            width: 100%;
            font-size: 15px;
            line-height: 38px;
        }
    }
}
