[data-type="Tab_Text_Scroll"] {
    .c-nav {
        border-top: 1px solid $color-gray-12;
        border-bottom: 1px solid $color-gray-12;
    }
    .c-nav__wrap {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            animation: 1s refeash linear infinite;
            content: '';
        }
    }
}