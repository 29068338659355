[data-type="Tabs_ImgText_Round"] {
    // skpui.magnet 기능 사용시
    .c-magnet--fixed &.is--active .w-magnet {
        width: 100%;
    }

    .c-tablist {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        background-color: $color-gray-14;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }

        &__list {
            display: flex;
            width: 100%;
            padding: 16px 16px 0;
        }
        &__item {
            position: relative;
            & + .c-tablist__item {
                margin-left: 6px;
            }
        }
    }

    // sticky 될때 하단 여백 추가
    .is--active .c-tablist__list {
        padding-bottom: 8px;
    }

    .c-chip {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 40px;
        margin: 0;
        padding: 0 14px 0 3px;
        border: 1px solid $color-gray-11;
        border-radius: 360px;
        color: #222;
        line-height: 40px;
        &[aria-selected="true"] {
            background-color: $color-11st-red;
            color: $color-gray-14;
        }
        &__image {
            overflow: hidden;
            position: relative;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            & + .c-chip__text {
                padding-left: 8px;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba($color-gray-01, 0.02);
            }
        }
        &__text {
            padding-left: 10px;
        }
    }
    .c-lazyload:before {
        background-size: 20px 16px;
    }

    .c-speech-bubble {
        position: absolute;
        top: -16px;
        left: 0;
        height: 24px;
        z-index: 1;
        &__box {
            overflow: hidden;
            position: relative;
            border-radius: 24px;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
                background-color: var(--SpeechbubbleColor);
                opacity: 0.2;
            }
        }
        &__text {
            position: relative;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 22px;
            margin: 1px;
            padding: 0 8px;
            border-radius: 25px;
            background-color: $color-gray-14;
            font-size: 11px;
            color: var(--SpeechbubbleColor);
            font-weight: 700;
            font-family: $font-family3;
            white-space: nowrap;
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            transform: rotate(135deg) skew(-8deg, -8deg);
        }
        &:before {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 15px;
            z-index: 10;
            width: 9px;
            height: 9px;
            background-color: var(--SpeechbubbleColor);
            opacity: 0.2;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 16px;
            z-index: 20;
            width: 7px;
            height: 7px;
            background: $color-gray-14;
        }
    }
}
