[data-type="Contents_Luxury_BrandRanking"] {
    .c-item {
        display: flex;
        padding: 20px 42px 20px 16px;
        align-items: center;

        &__title {
            flex-shrink: 0;
            overflow: hidden;
            display: block;
            max-width: 66.9%;
            font-size: 16px;
            color: $color-gray-02;
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;

            &-sub {
                overflow: hidden;
                display: block;
                margin-left: 3px;
                font-size: 14px;
                font-weight: normal;
                line-height: 30px;
                color: $color-gray-06;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__side {
            flex-shrink: 0;
            width: 36px;
            margin: -5px 0;
        }
        &__num {
            font-size: 17px;
            font-weight: 600;
            color: $color-gray-01;
        }
    }
    .c-n-product {
        &__thumb {
            @include size(106px);
            // padding: 8px;
        }
        &__info {
            padding: 12px 6px 0 0;
        }
        .price-origin {
            vertical-align: top;
        }
    }
    .c-n-empty {
        height: 159px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            display: none;
        }
    }
    .c-n-like {
        margin-left: -3px;
    }
    .s-list {
        padding: 0 13px 24px;

        li {
            padding: 0 3px;
        }
    }
    .w-scroll-css:after,
    .c-n-empty:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 16px;
        left: 16px;
        height: 1px;
        background-color: #f4f4f4;
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
}
