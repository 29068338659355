@import "../../common/sprites/sp_eui";

.c-relates {
    background: $color-gray-13;
    position: relative;
    padding: 6px 0 5px;
    margin-top: -1px;
    border-top: 1px solid $color-gray-12;
    overflow: hidden;

    &__txt {
        display: inline-block;
        padding: 0 0 0 16px;
        font-size: 13px;
        line-height: 17px;
        color: $color-gray-04;
        white-space: nowrap;

        .c-relates__emphasis {
            color: #ff8411;
            padding-right: 8px;
            font-weight: normal;
        }

        .c-relates__item {
            padding-right: 8px;
        }

        .c-relates__tag {
            @include border-radius(12px);
            background: $color-gray-12;
            color: #a0a0a0;
            display: inline-block;
            padding: 0 10px;
            font-size: 12px;
        }
    }

    &__link {
        display: block;
        margin: -7px 0 -8px 0;
        padding: 10px 0;
        text-align: center;

        &-text {
            padding-right: 20px;
            position: relative;
            font-size: 14px;

            &:after {
                @include rotate(133deg);
                @include size(8px);
                position: absolute;
                top: 40%;
                right: 2px;
                margin: -5px 0 0 -8px;
                border: 1px solid $color-gray-03;
                border-width: 1px 1px 0 0;
                content: "";
            }
        }
        .point {
            color: $color-11st-blue;
        }
    }

    &--big {
        height: 44px;
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: $color-gray-03;
        line-height: 46px;
        letter-spacing: 0;
    }

    &__refresh {
        display: inline-block;
        position: relative;
        padding-right: 32px;

        &:after {
            @include sprite-retina($sp_eui_reloading);
            position: absolute;
            top: 10px;
            right: 0;
            content: "";
        }
        &.c-relates--point {
            &:after {
                @include sprite-retina($sp_eui_flat_refresh_type2);
            }
        }
    }

    &__more {
        display: inline-block;
        position: relative;
        padding-right: 32px;

        &:after {
            @include sprite-retina($sp_eui_flat_more);
            position: absolute;
            top: 10px;
            right: 0;
            content: "";
        }
        &.c-relates--point {
            &:after {
                @include sprite-retina($sp_eui_flat_more_type2);
            }
        }
    }

    &__arrow {
        display: inline-block;
        position: relative;
        padding-right: 32px;

        &:after {
            @include sprite-retina($sp_eui_flat_arrow);
            position: absolute;
            top: 10px;
            right: 0;
            content: "";
        }
        &.c-relates--point {
            &:after {
                @include sprite-retina($sp_eui_flat_arrow_type2);
            }
        }
    }

    &__arrow-line {
        display: inline-block;
        position: relative;

        &:after {
            @include ico-arrow-right(6px, $color-gray-07);
            display: inline-block;
            margin: -1px 0 1px 3px;
            vertical-align: middle;
        }
    }

    button.c-relates__link {
        width: 100%;
        border: 0;
        background: $color-gray-14;
    }

    &.c-relates__gallery {
        padding-bottom: 0;

        .c-relates__txt {
            padding-left: 8px;
        }

        .c-relates__function {
            .c-relates__button-link {
                &:after {
                    top: 67%;
                }
            }
        }
    }

    .c-card__deal + & {
        border-bottom: 1px solid #d1d1d6;
    }

    &.c-relates--close {
        .c-relates__link-text:after {
            @include rotate(314deg);
            margin-top: 0;
        }
    }

    &.c-relates--refresh {
        .c-relates__link-text {
            display: inline-block;
            position: relative;
            padding-left: 24px;
            font-size: 13px;

            &:after {
                @include sprite-retina($sp_eui_arrow_refresh);
                position: absolute;
                top: 50%;
                left: 0;
                width: 24px;
                height: 24px;
                margin: -12px 0 0 0;
                border: 0;
                transform: initial;
                content: "";
            }
        }
    }

    &.c-relates--linkmove {
        .c-relates__link-text {
            display: inline-block;
            position: relative;
            padding-right: 14px;
            font-size: 14px;

            &:after {
                @include rotate(45deg);
                @include size(8px);
                position: absolute;
                top: 50%;
                right: 0;
                margin: -5px 0 0 0px;
                border: 1px solid $color-gray-03;
                border-width: 1px 1px 0 0;
                content: "";
            }
        }
    }

    &.c-relates--border {
        margin-top: -1px;
        border-top: 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $color-gray-12;
        }
    }
    &.c-relates--no-border {
        border-top: 0;
    }
}
