@import "../../common/partial/icon";
@import "../../skpui/sprites/sp_icon";

[data-type="Title_RoadshopB"] {
    .c-headline {
        padding: 26px 0 14px;

        .c-headline__link {
            text-align: center;

            .c-headline__title {
                display: inline-block;
                position: relative;
                width: auto;
                height: initial;
                padding: 0 30px;
                line-height: 24px;
                font-size: 19px;
                font-weight: 600;

                &:after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    content: "";
                    @include sprite-retina($sp_icon_ic_arrow_bl);
                }
            }

            .c-headline__subtext {
                margin-top: 5px;
                padding: 0;
            }

            &:after {
                display: none;
            }
        }

        .c-headline__text {
            text-align: center;

            .c-headline__title {
                display: inline-block;
                position: relative;
                width: auto;
                height: initial;
                padding: 0;
                line-height: 24px;
                font-size: 19px;
            }

            .c-headline__subtext {
                margin-top: 5px;
                padding: 0;
            }

            &:after {
                display: none;
            }
        }
    }
}

[data-type="ContentsGrid_StoreOpen"] {
    .mod-swiper--bgimg {
        position: relative;
        padding-bottom: 8px;

        .swiper-background-wrapper {
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 60%;
            top: 0;

            .swiper-background {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: background 0.5s linear;
            }
        }

        .swiper-container {
            margin: 0;
            padding-top: 158px;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: transparent;
            /* Center slide text vertically */
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        .swiper-pagination {
            left: 50%;
            transform: translateX(-50%);
            span {
                margin: 10px 3px;
            }
        }

        .c-store-intro {
            position: relative;
            width: 90%;
            max-width: 328px;
            margin: 0 auto;
            padding-top: 25px;
            background-color: #fff;

            &__title {
                display: block;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                color: #19b275;
                text-align: center;
            }

            .c-store__explain {
                display: block;
                margin-top: 5px;
                font-size: 13px;
                line-height: 16px;
                color: #878787;
                text-align: center;
                .bar {
                    display: inline-block;
                    width: 1px;
                    height: 10px;
                    margin: -2px 5px 2px;
                    background-color: #e3e3e8;
                    vertical-align: middle;
                }
                .gap {
                    padding: 0 2px;
                }
            }

            &__text {
                display: block;
                margin-top: 10px;
                padding-bottom: 20px;
                font-size: 13px;
                color: #878787;
                text-align: center;
            }

            &__list {
                display: flex;
                margin: 0 -4px;

                li {
                    flex-grow: 1;
                    padding: 0 4px;
                    background: url("/MW/img/product/no_image_100.gif") no-repeat 50% 50%;
                    background-size: contain;

                    a {
                        display: block;
                        margin: 0 auto;

                        img {
                            @include size(100%);
                        }
                    }
                }
            }

            .c-like-button--store {
                @include c-store-like-button;
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }

        @include pagination-roadshop;
    }
}
