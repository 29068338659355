.c-n-dropdown {
    position: relative;
    z-index: 10;
    display: inline-block;
    font-family: $font-family3;
    vertical-align: top;
    white-space: nowrap;

    &:before {
        content: "";
        position: absolute;
        top: 4px;
        right: 0;
        bottom: 4px;
        width: 1px;
        background-color: #f4f4f4;
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        height: 32px;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 18px;
            width: 6px;
            height: 6px;
            margin-top: -6px;
            border: 1px solid #222;
            border-width: 1.5px 1.5px 0 0;
            transform-origin: center center;
            transform: rotate(135deg);
            box-sizing: content-box !important;
        }
    }
    &__button {
        display: block;
        width: 100%;
        height: 100%;
        padding-right: 35px;
        padding-left: 16px;
        border: 0;
        color: #111;
        font-size: 15px;
        font-weight: 600;
        background-color: transparent;
    }
    &__text {
        display: block;
    }
    &__list {
        display: none;
        position: absolute;
        top: 32px;
        left: 16px;
        min-width: 106px;
    }
    &__box {
        overflow: hidden;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.14), 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    }
    &__item {
        display: block;
        width: 100%;
        height: 43px;
        padding-right: 16px;
        padding-left: 16px;
        border: 0;
        border-top: 1px solid #f4f4f4;
        background-color: #fff;
        color: #666;
        font-size: 15px;
        line-height: 19px;
        text-align: left;

        &:first-child {
            border-top: 0;
        }

        &[aria-selected="true"] {
            color: #111;
            font-weight: 600;
            background-color: #f7f7f7;
        }
    }

    &--selected {
        z-index: 20;

        .c-n-dropdown__option:after {
            margin-top: -3px;
            transform: rotate(-45deg);
        }
        .c-n-dropdown__list {
            display: block;
        }
    }

    &--icon {
        &:before {
            display: none;
        }

        [class*="option"] {
            cursor: pointer;

            &:before {
                @include get-sprite-luxury("ic-product-filter", 20);

                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                content: "";
            }
            &:after {
                display: none;
            }
        }
        [class*="button"] {
            padding-right: 22px;
            font-size: 14px;
            font-weight: 400;
        }
        [class*="item"] {
            padding-right: 24px;
        }
    }
}
