[data-type="Tabs_Luxury_Text_CtgrNavi_Scroll"] {
    .c-n-dropdown {
        &:before {
            top: 14px;
            bottom: 14px;
        }
        &__option {
            height: 50px;
        }
        &__list {
            top: 40px;
        }
    }
    .c-n-tab__button {
        white-space: nowrap;
    }
    .c-category {
        &-depth1 {
            display: flex;
            border-bottom: 1px solid $color-gray-12;
            background: $color-gray-14;
            align-items: center;

            .s-list {
                padding: 0 6px;

                li {
                    padding: 0 10px;
                }
            }
        }
        &-depth2 {
            border-bottom: 1px solid $color-gray-12;
            background: $color-gray-13;

            .s-list {
                padding: 0 8px 0 4px;

                li {
                    padding: 0 12px;
                }
            }
            .c-n-tab {
                &__button {
                    background: none;
                }
                &__underline {
                    padding-left: 0;
                    padding-right: 0;
                }
                &__button--selected {
                    .c-n-tab__underline {
                        border-color: transparent;
                    }
                }
            }
        }
    }
    .w-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
}
