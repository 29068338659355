[data-type="ExhibitionBanner_Luxury_ProductScroll"] {
    .c-product {
        &__banner {
            overflow: hidden;

            .c-lazyload {
                margin: 0 -1px; // 고해상도 아이폰 이미지 이슈 수정
                padding-top: 0;

                img {
                    position: static !important;
                }
            }
        }
        &__banner ~ .w-scroll-css {
            margin-top: -32px;
        }

        .s-list {
            padding: 0 13px;

            li {
                padding: 0 3px;
            }
        }
    }

    .c-n-product {
        width: 162px;

        &__info {
            padding-left: 0;
            padding-bottom: 0;
        }
        &__buttons {
            top: -38px;
        }
    }
    .c-n-like {
        padding: 4px;
        box-sizing: content-box;
    }

    .w-scroll-css::-webkit-scrollbar {
        display: none;
    }
}
