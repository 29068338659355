.c-n-empty {
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;

    &:before {
        content: "";
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto 27px;
        background: url("/MW/img/luxury/notice_ooah.gif") no-repeat 50% 0;
        background-size: 100% auto;
    }
    &__title {
        display: block;
        color: #111;
        font-size: 15px;
        font-weight: 600;
        font-family: $font-family3;
        line-height: 19px;
    }
    &__point {
        color: $color-luxury;
    }
    &__desc {
        color: #999;
        font-size: 13px;
        font-family: $font-family3;
        line-height: 16px;
    }
    &__title + &__desc {
        margin-top: 7px;
    }
    &__image {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto 27px;
    }

    &--none:before {
        display: none;
    }
}
