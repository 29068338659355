[data-type="ExhibitionBanner_Outlet_BrandReco"] {
    // @include grid(2, 2, false);
    position: relative;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
    }
    .c-headline {
        background-color: transparent;
        &__title {
            color: #fff;
            font-size: 19px;
            height: 70px;
            line-height: 60px;
        }
    }
    .c-card-brand {
        position: relative;
        display: block;
        overflow: hidden;
        width: 140px;
        height: 200px;
        border-radius: 16px;
        background: #fff;
        &__image {
            display: block;
            width: 140px;
            height: 114px;
            .c-lazyload {
                &:after {
                    content: '';
                    display: block;
                    width: 140px;
                    height: 1px;
                    background-color: #eee;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
        &__info {
            display: block;
            padding: 28px 8px 8px 8px;
            height: 86px;
            & > span {
                display: block;
                font-size: 14px;
                text-align: center;
            }
            .c-card-brand__rate {
                font-size: 20px;
                font-weight: 600;
                color: #c29254;
                padding-top: 2px;
            }
            .c-card-brand__title {
                color: #9b9b9b;
            }
            .c-card-brand__name {
                color: #4a4a4a;
            }
            .c-card-brand__tag {
                font-size: 18px;
            }
        }
        &__logo {
            @include size(50px);
            display: block;
            position: absolute;
            bottom: 63px;
            right: 44px;
            border-radius: 32px;
            border: 1px solid rgba(183, 183, 183, 0.5);
            background-color: #fff;
            padding-top: 18px;
            img {
                @include size(38px 14px);
                display: block;
                margin: 0 auto;
            }
        }
        &--like {
            position: relative;
            padding: 18px;
            p {
                font-size: 16px;
                font-weight: bold;
                color: #000;
            }
            span {
                display: inline-block;
                padding-top: 6px;
                font-size: 13px;
                line-height: 16px;
                color: #9b9b9b;
            }
            .c-button-brand {
                position: absolute;
                bottom: 20px;
                left: 26px;
                background-color: #c29254;
                width: 88px;
                height: 30px;
                padding-right: 10px;
                border-radius: 16px;
                color: #fff;
                border: 0;
                font-size: 12px;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    right: 14px;
                    top: 50%;
                    width: 1px;
                    height: 9px;
                    margin: -5px 0 0;
                    background-color: #fff;
                    background-image: none;
                }
                &:before {
                    transform: rotate(0deg);
                }
                &:after {
                    transform: rotate(-90deg);
                }
            }
        }
    }
    .c-relates {
        border-top: 0;
        background-color: transparent;
        &__link {
            margin: 0;
            color: #fff;
            letter-spacing: 0;
            .c-relates__link-text {
                &:after {
                    @include size(9px);
                    right: 4px;
                    margin: -3px 0 0 -5px;
                    border-color: #fff;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
