[data-type="LineBanner_Product"] {
    .c-product {
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-right: 6px;
        white-space: nowrap;

        dd {
            display: flex;
            align-items: center;
            color: $color-gray-02;
            font-family: $font-family3;
        }

        &__price {
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }
        &__unit {
            font-size: 13px;
            line-height: 1em;
        }
        &__rate {
            margin-right: 4px;
            color: $color-11st-red;
            font-size: 14px;
        }
    }
    .c-starrate {
        display: flex;
        align-items: center;

        @media screen and (max-width: 360px) {
            display: none;
        }

        &__sati,
        &__gauge {
            width: 60px;
            height: 10px;
            margin-right: 0;
            background-size: 60px 10px;
        }
        &__review {
            font-family: $font-family3;
        }
        &__count {
            font-size: 11px;
        }
    }
    .c-ad__btn {
        position: absolute;
        bottom: -1px;
        left: 0;
    }
    .c-banner {
        padding: 16px;

        &__link {
            display: block;
        }
        &__highlight {
            overflow: hidden;
            display: block;
            height: 17px;
            margin-bottom: 3px;
            color: $color-gray-02;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            word-break: keep-all;
        }
        &__desc {
            overflow: hidden;
            display: block;
            width: 100%;
            height: 16px;
            color: $color-gray-02;
            font-size: 13px;
            line-height: 16px;
        }
        &__wrapper {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 70px;
            padding: 16px 20px 20px 102px;
            border: 1px solid $color-gray-11;
            background-color: $color-gray-14 !important; // 구버전 배너 어드민에서 입력되는 bgcolor값이 적용되지 않도록 important가 필요
            border-radius: 4px;
        }
        &__thumb {
            overflow: hidden;
            position: absolute;
            top: -12px;
            left: 20px;
            z-index: 10;
            width: 68px;
            height: 68px;
            background-color: $color-gray-14;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        }
        &__image {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
