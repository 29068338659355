[data-type="ProductGrid_Col3"] {
    .l-grid__border {
        position: relative;
    }
    .w-card__gallery {
        padding: 0 8px 2px;
        @include clearfix();
        li {
            margin-bottom: 10px;
        }
        .l-grid__col {
            padding: 0 4px;
        }
    }
    .l-grid__col {
        padding: 0 4px;

        .c-card.c-card__gallery {
            .c-card__thumb {
                @include size(auto);
            }
            .c-card__info {
                padding: 8px 0;
                .c-card__name {
                    font-size: 15px;
                    margin-bottom: 4px;
                }
                .c-card__price {
                    min-height: 20px;
                    strong {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .c-relates {
        width: 100%;
        background-color: #fff;
    }
}
