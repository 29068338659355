[data-type="GridScroll_BigImgText_CtgrNavi"] {
    .c-magnet--fixed,
    .c-magnet-multiple--fixed {
        border-bottom: 1px solid $color-gray-11;
    }

    .c-tablist {
        overflow: hidden;
        position: relative;
        padding: 14px 0;
        background-color: $color-gray-14;

        // 1단으로 사용하는 케이스의 여백 조정
        @at-root .l-grid &:only-child {
            padding: 20px 0;
        }

        &__wrap {
            overflow-x: auto;
            overflow-y: hidden;
            padding: 0 8px;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }

            .c-tablist__link {
                display: block;
                width: 100%;
                padding: 0;
                border: 0;
                outline: 0;
                font-weight: 500;
                background-color: transparent;
                text-align: center;

                &[aria-selected="true"] .c-tablist__name {
                    color: $color-11st-red;
                }
            }
        }
        &__list {
            display: table;
            width: 100%;
            padding: 0;
            table-layout: fixed;
        }
        &__item {
            display: table-cell;
            text-align: center;
            white-space: nowrap;
            vertical-align: top;

            // 선택된 상태
            &--on {
                .c-tablist {
                    &__name {
                        color: $color-11st-red;
                    }
                    &__chip {
                        padding: 0 12px;
                        border: 1px solid $color-11st-red;
                        color: $color-11st-red;
                        font-weight: 700;
                        background-color: rgba($color-11st-red, 0.02);
                        border-radius: 32px;
                    }
                }
            }
        }
        &__chip {
            display: flex;
            align-items: center;
            height: 32px;
            margin: 0 8px;
            border: 0;
            color: $color-gray-02;
            font-size: 14px;
            text-align: center;
            background-color: transparent;
            white-space: nowrap;

            &[aria-selected="true"] {
                padding: 0 12px;
                border: 1px solid $color-11st-red;
                color: $color-11st-red;
                font-weight: 700;
                background-color: rgba($color-11st-red, 0.02);
                border-radius: 32px;
            }
        }
        &__name {
            overflow: hidden;
            display: block;
            width: 68px;
            height: 14px;
            margin: 8px auto 0;
            color: $color-gray-03;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: -0.5px;
            white-space: normal;
        }

        &--chip {
            padding: 12px 0;

            .c-tablist__list {
                width: auto;
                border-bottom: 0;
            }
        }
        &--spread {
            .c-tablist__list {
                table-layout: auto;
            }
        }

        & + .c-tablist {
            border-top: 1px solid $color-gray-11;
        }
    }

    .c-lazyload {
        width: 52px;
        height: 52px;
        margin: 0 auto;
    }
}
