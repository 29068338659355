[data-type="GridList_ImgText_Column"] {
    .c-magnet--fixed & {
        width: 100%;
        z-index: 1;
    }

    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 100;
        }
        &--absolute {
            position: absolute;
        }
    }

    .c-category {
        background-color: $color-gray-14;

        &__list {
            display: flex;
        }
        &__item {
            flex: 1;
        }
        &__link {
            display: block;
            padding: 12px 5px;
            text-align: center;
        }
        &__image {
            display: block;
            width: 80px;
            height: 30px;
            margin: 0 auto 8px;

            img {
                display: block;
                height: 100%;
            }
        }
        &__text {
            overflow: hidden;
            display: block;
            height: 16px;
            color: $color-gray-03;
            font-size: 13px;
            line-height: 16px;
        }
    }
}
