[data-type="GridList_Luxury_ImgText_Col2"] {
    .c-service {
        overflow: hidden;

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -8px;
            margin-bottom: -8px;
            padding-right: 12px;
            padding-left: 12px;
        }
        &__item {
            width: 50%;
            padding: 8px 4px;
            box-sizing: border-box;
        }
        &__link {
            display: block;
        }
        &__text {
            @include text-ellipsis;

            display: block;
            margin-top: 8px;
            margin-right: 16px;
            color: $color-gray-02;
            font-size: 14px;
            line-height: 17px;
        }
        &__image {
            border-radius: 4px;
        }
    }

    .c-lazyload {
        padding-top: 57.95%;
    }
}
