[data-type="Contents_BrandList"] {
    @include ootdLikeButton;

    .c-accordion {
        &[open] {
            .c-store__link {
                padding-bottom: 9px;
            }
            .c-store__part {
                padding-top: 8px;
            }
        }
    }

    .c-store {
        display: flex;
        align-items: center;
        padding: 0 42px 0 16px;
        line-height: 30px;
        &__link {
            display: inline-flex;
            flex-shrink: 0;
            max-width: 90%;
            padding: 17px 0;
        }
        &__title {
            overflow: hidden;
            font-size: 16px;
            color: $color-gray-02;
            white-space: nowrap;
            text-overflow: ellipsis;
            &-sub {
                overflow: hidden;
                margin-left: 4px;
                font-size: 14px;
                color: $color-gray-06;
                font-family: $font-outfit;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        &__part {
            flex-shrink: 0;
            width: 30px;
            margin-right: 4px;
        }
        &__num {
            padding-left: 4px;
            font-size: 18px;
            color: $color-gray-02;
            font-weight: 700;
            font-family: $font-outfit;
        }

        .c-n-like {
            position: relative;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
            padding: 0;
            background-color: transparent;

            &:before {
                @include get-sprite-ootd("ic_heart_lg", 30);
            }

            &[aria-pressed="true"]:before {
                @include get-sprite-ootd("ic_heart_lg_on", 30);
            }

            &__lottie {
                width: 30px;
                height: 30px;
            }

            &--loaded,
            &--loaded[aria-pressed="true"] {
                &:before {
                    background-image: none;
                }
            }
        }
    }

    .c-n-product {
        position: relative;
        &__thumb {
            @include size(106px);
            overflow: hidden;
            border-radius: 12px;
        }
        &__info {
            padding: 8px 0 0 4px;
        }
        &__buttons {
            top: 68px;
            right: 6px;
        }
        &__title {
            font-size: 15px;
            color: $color-gray-02;
            font-weight: 700;
        }
        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 156px;
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 16px;
                left: 16px;
                height: 1px;
                background-color: $color-gray-12;
            }
        }
        .c-n-like {
            position: static;
            width: auto;
            height: auto;
        }
    }
    .price-rate {
        color: $color-11st-red;
        font-size: 13px;
    }

    .price-origin {
        font-size: 13px;
    }

    .s-list {
        padding: 0 13px 25px;
        li {
            padding: 0 3px;
        }
    }
    .w-scroll-css {
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 16px;
            left: 16px;
            height: 1px;
            background-color: $color-gray-12;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
