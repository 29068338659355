@import "../../svg/sp_live11";

[data-type="BannerContents_SearchLive11"] {
    @include grid(2, 2, false);
    background-color: $color-gray-14;
    border-bottom: 1px solid $color-gray-11;

    .c-search {
        position: relative;
        height: 40px;
        &__input {
            width: 100%;
            height: 100%;
            padding: 0 43px 0 16px;
            border: 1px solid $color-gray-11;
            border-radius: 20px;
            background-color: $color-gray-12;
            font-weight: bold;
            font-size: 16px;
            color: $color-gray-01;
            caret-color: $color-11st-red;
            outline: none;
            appearance: none;
        }

        &__label {
            position: absolute;
            top: 50%;
            left: 16px;
            right: 43px;
            transform: translateY(-50%);
            font-size: 15px;
            color: $color-gray-03;
        }

        &__btn-delete,
        &__btn-submit {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: 0;
            background: none;
            &:before {
                content: "";
                display: block;
                @include get-sprite-live11;
            }
        }
        &__btn-delete {
            right: 43px;
            &:before {
                @include get-sprite-position-live11("ic-delete", 24);
            }
        }
        &__btn-submit {
            right: 5px;
            &:before {
                @include get-sprite-position-live11("ic-search", 30);
            }
        }

        &--active {
            .c-search {
                &__input {
                    padding-right: 75px;
                    background-color: $color-gray-14;
                }
                &__label {
                    display: none;
                }
            }
        }
    }
}
