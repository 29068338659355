// 우아픽, 브랜드픽 공통, 개별 적용 스타일이 섞여 있음.
// 기본 스타일은 상단에 위치, 우아픽, 브랜드픽 전용은 하단 영역에 작성

// 매인 빌보드용 필터
[data-ui-type="Ooah_Pick_Billboard"] {
    .c-video__info {
        position: absolute;
        bottom: 68px;
        left: 0;
        // 탑버튼 + 여백
        right: 88px;
        padding-left: 24px;

        &-box {
            position: relative;
            display: flex;
        } //c-video__info-box
        &-logo {
            $size: 32px;
            overflow: hidden;
            position: relative;
            display: inline-block;
            width: $size;
            height: $size;
            border-radius: 100%;
            vertical-align: top;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
            }
        } //c-video__info-logo
        &-name {
            position: absolute;
            top: 7px;
            left: 40px;
            right: 0;
            font-family: $font-outfit;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            color: $color-gray-14;
            text-align: left;
            @include text-ellipsis;
        } //c-video__info-name
        &-ci {
            //브랜드픽에서만 사용
            height: 24px;
            img {
                width: auto;
                height: 100%;
                vertical-align: top;
            }
            & + .c-video-like {
                margin-left: 6px;
                &::before {
                    content: "";
                    width: 1px;
                    height: 9px;
                    margin-right: 6px;
                    background-color: rgba($color: $color-gray-14, $alpha: 0.296);
                }
            }
        }
        &-text1 {
            @include text-ellipsis-multiple(2);
            padding-top: 12px;
            font-family: $font-outfit;
            font-size: 26px;
            font-weight: bold;
            line-height: 1.29;
            color: $color-gray-14;
            word-break: break-all;
        } //c-video__info-text1
        &-text2 {
            @include text-ellipsis;
            padding-top: 12px;
            font-size: 16px;
            color: $color-gray-14;
        } //c-video__info-text2
        &-icon {
            position: absolute;
            bottom: 0;
            right: -76px;
            @include size(48px);
            border: 1px solid rgba($color: $color-gray-14, $alpha: 0.4);
            border-radius: 100%;
            box-sizing: border-box;
            background: url("/MW/img/svg/ooah-pick/ic-more.svg") no-repeat center;
            background-size: 10px auto;
        }
    }
    .c-video {
        &-like {
            // 브랜드픽 에서만 사용
            $size: 16px;
            display: inline-flex;
            align-items: center;

            &__icon {
                display: inline-block;
                width: $size;
                height: $size;
                margin-right: 1px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cg clip-path='url(%23clip0_2814_5981)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 3.5H3V14.0882L10 18.5L17 14.0882V3.5ZM10.0001 11.7479L12.222 13.4571C12.2586 13.4856 12.3024 13.4998 12.3458 13.4998C12.3896 13.4998 12.433 13.4856 12.4696 13.4571C12.5437 13.4001 12.5744 13.2994 12.5458 13.2077L11.6919 10.4609L13.9121 8.77715C13.9862 8.7206 14.0178 8.61995 13.99 8.52777C13.9618 8.43559 13.881 8.37324 13.7896 8.37324H11.0431L10.2001 5.63761C10.1437 5.45413 9.85654 5.45413 9.7997 5.63761L8.95717 8.37324H6.21064C6.11885 8.37324 6.03801 8.43559 6.01022 8.52777C5.98201 8.61995 6.01359 8.7206 6.0877 8.77715L8.30791 10.4609L7.45401 13.2077C7.42538 13.2994 7.45654 13.4001 7.53022 13.4571C7.60391 13.5141 7.70412 13.5145 7.7778 13.4571L10.0001 11.7479Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2814_5981'%3E%3Crect width='20' height='20' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                font-size: 1px;
                color: transparent;
                overflow: hidden;
            }
            &__count {
                font-size: 13px;
                font-weight: 600;
                color: $color-gray-14;
                line-height: $size;
            }
            &__box {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: -1px 0 0 5px;
            }
            &__button {
                $size: 12px;
                width: $size;
                height: $size;
                border: 0;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cg opacity='0.8' clip-path='url(%23clip0_2623_485)'%3E%3Cpath d='M11.4 6.49998C11.4 3.51764 8.98231 1.09998 5.99998 1.09998C3.01764 1.09998 0.599976 3.51764 0.599976 6.49998C0.599976 9.48231 3.01764 11.9 5.99998 11.9C8.98231 11.9 11.4 9.48231 11.4 6.49998Z' stroke='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 4.7V3.5H5.5V4.7H6.5ZM6.5 9.5V5.3H5.5V9.5H6.5Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2623_485'%3E%3Crect width='12' height='12' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center no-repeat;
                background-size: $size;
            }
            &__popup {
                display: none;
                position: absolute;
                bottom: 16.5px;
                left: 0;
                width: 240px;
                padding: 10px 38px 10px 12px;
                background: $color-gray-14;
                border-radius: 4px;

                &.c-video-like__popup--show {
                    display: block;
                }
            }
            &__text {
                color: $color-gray-02;
                font-size: 13px;
                font-weight: 400;
                line-height: 17px;
            }
            &__close {
                $size: 24px;
                position: absolute;
                top: 10px;
                right: 10px;
                width: $size;
                height: $size;
                border: 0;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.71427 6.02023L12.001 11.3069L17.2871 6.02023C17.4824 5.82496 17.7989 5.82496 17.9942 6.02023C18.1895 6.21549 18.1895 6.53207 17.9942 6.72733L12.708 12.0139L17.9942 17.3002C18.1895 17.4954 18.1895 17.812 17.9942 18.0073C17.7989 18.2025 17.4824 18.2025 17.2871 18.0073L12.001 12.7209L6.71427 18.0073C6.51901 18.2025 6.20243 18.2025 6.00716 18.0073C5.8119 17.812 5.8119 17.4954 6.00716 17.3002L11.294 12.0139L6.00716 6.72733C5.8119 6.53207 5.8119 6.21549 6.00716 6.02023C6.20243 5.82496 6.51901 5.82496 6.71427 6.02023Z' fill='%23333333'/%3E%3C/svg%3E") center no-repeat;
                background-size: $size;
            }
        }
    }
}
// 우아픽 - home - 동영상 - 로고에 관련한
.ooah-pick-home {
    @mixin c-video__info-name {
        display: block;
        top: 13px;
        left: 48px; // 부모 돔 padding-left : 24,  72 목표
        text-align: center;
    }
    .c-billboard__card:not(.c-billboard__card--prev):not(.c-billboard__card--show) {
        .c-video__info {
            &-logo,
            &-text1,
            &-text2,
            &-icon,
            .c-video-like {
                display: none;
            }
        }
    }
    .c-billboard__card--wait1,
    .c-billboard__card--wait2 {
        .c-video__info {
            right: 72px;
        }
        .c-video__info-name {
            @include c-video__info-name();
        }
        .c-video__info-ci {
            position: absolute;
            top: 11px;
            left: 50%;
            padding-left: 48px;
            transform: translateX(-50%);
        }

        + .c-billboard__card {
            // wait2 에서 화면 밖으로 빠진 때. wait2 클래스 제거됨 그로 인해 텍스트의 위치값이 기본 설정한 스타일로 돌아가면서 아래 속성으로 인해 이슈가 발생함.
            // [data-ui-type=Ooah_Pick_Billboard] .c-video__info-name. c-video__info-ci 이를 상쇄하기 위해 재 wait1, 2 와 + 선택자로 재선언 함.
            // 스타일은 wait1, 2 상태와 동일해야 하며 추가로right 도 유지해야함.
            .c-video__info {
                right: 72px;
            }
            .c-video__info-name {
                @include c-video__info-name();
                right: 0;
            }
            .c-video__info-ci {
                position: absolute;
                top: 11px;
                left: 50%;
                padding-left: 48px;
                transform: translateX(-50%);
            }
        }
    }

    // show, prev 영역에서의
    .c-billboard__card--show,
    .c-billboard__card--prev {
        .c-video__info {
            bottom: 92px;
        }
        .c-video__info-box--fix {
            // 로고 없을 경우
            height: 20px;
            .c-video__info-name {
                top: 0;
                left: 0;
            }
        } //c-video__info-box--fix
    }
}

//아우픽 - home - 동영상 -> show 영역 진입 후 인터렉션
.ooah-pick-home {
    .c-billboard__card--prev,
    .c-billboard__card--show {
        .c-video__info {
            &-box,
            &-text1,
            &-text2,
            &-icon {
                opacity: 0;
                transform: translateY(12px);
            }
        }
        // 나타나는 모션
        .on.c-video__info {
            .c-video__info {
                &-box,
                &-text1,
                &-text2,
                &-icon {
                    opacity: 1;
                    transform: translateY(0px);
                    transition: opacity 0.2s ease-out, transform 0.4s ease-out;
                }
                &-icon,
                &-text1 {
                    transition-delay: 0.1s;
                }
                &-text2 {
                    transition-delay: 0.2s;
                }
            }
        }
        // 사라지는 모션
        .off.c-video__info {
            .c-video__info {
                &-box,
                &-text1,
                &-text2,
                &-icon {
                    opacity: 0;
                    transform: translateY(-12px);
                    transition: opacity 0.2s ease-out, transform 0.4s ease-out;
                }
                &-box,
                &-text1,
                &-text2,
                &-icon {
                    transition-delay: 0;
                }
            }
        }
    }
}

// 우아픽 - 브랜드 상세 - c-video__info
.ooah-pick-brand .c-video__info {
    right: 88px;
    bottom: 28px;
}

.ooah-pick-brand,
.c-billboard__card--show,
.c-billboard__card--prev {
    //로고 없는 매인, 브랜드 상세 로고 없는 경우 대응
    .c-video__info-box--fix {
        // 로고 없을 경우
        height: 20px;
        .c-video__info-name {
            top: 0;
            left: 0;
        }
    } //c-video__info-box--fix
}
