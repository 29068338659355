[data-type="ProductScroll_Live11"] {
    height: 478px;
    background: url("/MW/img/live11/bg_gra.png") no-repeat center;
    background-size: cover;
    .c-headline {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 136px 128px;
        &__title {
            height: auto;
            padding: 34px 16px 21px;
            line-height: normal;
            color: $color-gray-14;
            strong {
                display: block;
                position: relative;
                z-index: 1;
                font-family: $font-11st;
                font-weight: 800;
                font-size: 20px;
                white-space: normal;
                word-break: keep-all;
                & + span {
                    display: block;
                    position: relative;
                    z-index: 1;
                    margin: 8px 0 0;
                    padding: 0;
                    font-size: 13px;
                    color: rgba($color: $color-gray-14, $alpha: 0.8);
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .s-list {
        height: 100%;
        padding: 0 12px 24px 12px;
    }
    .c-card-item {
        height: 318px;
        border: 0;
        &-badge {
            left: 10px;
            top: 10px;
            z-index: 10;
            font-size: 12px;
            color: $color-gray-14;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        }

        &__badge-date {
            display: block;
            font-size: 13px;
            font-weight: bold;
        }
        &__badge-time {
            display: block;
            font-size: 18px;
            font-weight: bold;
        }
        &__badge-11live {
            display: inline-block;
            width: 35px;
            height: 18px;
        }
        .live-img {
            display: inline-block;
            width: 35px;
            height: 18px;
            background: url(/MW/img/pui/ico_live_round.png) no-repeat;
            background-size: 100% 100%;
        }
        &__badge-view {
            position: relative;
            line-height: 16px;
            top: -4px;
            margin-left: 3px;
            font-size: 13px;
            font-weight: bold;
            color: $color-gray-14;
            em {
                font-weight: bold;
            }
        }
        &__thumb {
            display: block;
            width: 152px;
            height: 228px;

            // 상단 dim (비디오 재생 시 가려짐)
            &::before {
                content: "";
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                width: 100%;
                height: 56px;
                border-radius: 4px 4px 0 0; // ios 대응
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
            }
            // default dim
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                border-radius: 4px 4px 0 0; // ios 대응
                background: $color-gray-01;
            }

            .c-video {
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1; // 상단 dim 덮음
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                &--hide {
                    opacity: 0;
                }

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &-cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding: 10px 0 0 10px;
                height: 100%;
                line-height: 1.2;
            }
            &-vod {
                color: $color-gray-14;
                font-size: 12px;
            }
            .watcher {
                display: inline-block;
                position: relative;
                z-index: 10;
                height: 20px;
                padding: 3px 8px;
                background: rgba($color: $color-gray-01, $alpha: 0.3);
                border-radius: 10px;
                line-height: normal;
                font-weight: bold;
            }
        }

        &__info {
            padding-bottom: 0;
        }
        &__name {
            display: block;
            height: 38px;
            margin-bottom: 8px;
            color: $color-gray-03;
            line-height: 19px;
            font-size: 14px;
        }
        &__name-sub {
            color: $color-11st-blue;
            height: 17px;
        }
        &__benefit {
            padding-top: 8px;
        }
        &__addition {
            margin-top: 12px;
        }
        &__more {
            width: 130px;
            height: 100%;
        }
        .c-nation {
            margin-right: -6px;
        }
        .c-flag__item {
            margin-bottom: 6px;
        }

        &__profile {
            padding: 0 12px;
            font-size: 14px;
            color: $color-gray-07;
            &-img {
                display: block;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
                position: relative;
                margin-right: 4px;
                font-size: 0;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 11;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 50%;
                    content: "";
                }
                .c-lazyload {
                    display: block;
                    font-size: 0;
                }
                .c-lazyload:before {
                    background-size: contain;
                }

                img {
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                }

                & + span {
                    @include text-ellipsis;
                    color: $color-gray-04;
                    font-size: 14px;
                }
            }
            dt {
                @include sr-only;
            }
            dd {
                display: flex;
                align-items: center;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        &__mini-card {
            position: absolute;
            z-index: 10;
            top: 144px;
            left: 0;
            width: 100%;
            height: 84px;
            color: $color-gray-14;

            // 하단 dim
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }

            a {
                position: absolute;
                bottom: 10px;
                left: 10px;
                right: 10px;
                display: block;
                height: 33px;
                padding-left: 40px;
            }

            dl {
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                height: 33px;
            }

            dt {
                @include sr-only;
            }

            dd:nth-child(2) {
                @include size(32px);
                position: absolute;
                left: 0;
                top: 0;
                background: $color-gray-14;
                border-radius: 4px;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 4px;
                    content: "";
                }
                .c-lazyload {
                    border-radius: 4px;
                }
                img {
                    @include size(32px);
                    border-radius: 4px;
                    display: block;
                }
            }
            dd:nth-child(3) {
                display: block;
                font-size: 11px;
                overflow: hidden;
                padding-top: 2px;
                height: 14px;
                line-height: 1.2;
            }
            dd:nth-child(4) {
                display: block;
                font-size: 12px;
                font-weight: bold;
                padding-top: 3px;
                overflow: hidden;
                height: 19px;
            }
        }

        &--vod {
            .c-card-item__thumb {
                &::after {
                    opacity: 0.2;
                }
            }
        }
    }
}
