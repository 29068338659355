[data-type="ProductScroll_BestView"] {
    padding: 0 0 14px;
    .w-scroll-css {
        .s-list {
            padding: 0 12px;
            li {
                padding: 0 4px 10px;
            }
        }
    }
    .c-card-item {
        width: 152px;
        border-radius: 4px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
        .c-item-cap {
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 10;
            padding: 0;
            height: 24px;

            &__rank {
                @include size(24px);
                background-color: $color-11st-red;
                border-radius: 4px;
                color: $color-gray-14;
                font-weight: bold;
            }
        }

        &__info {
            min-height: 116px;
            padding: 12px 12px 8px;
        }

        &__info--has-link {
            display: flex;
            flex-direction: column;
            height: 116px;
            padding: 0;
            > a {
                display: block;
                flex: 1;
                padding: 12px 12px 0;
            }
            .c-profile {
                flex-shrink: 0;
                padding: 0 12px 12px;
            }
        }
        &__thumb {
            height: 228px;
            // default dim
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                background-color: $color-gray-01;
                opacity: 0.2; // vod case default
            }

            .c-video {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1; // default dim 덮음
                width: 100%;
                height: 100%;
                border-radius: 4px 4px 0 0; // ios 대응
                &--hide {
                    opacity: 0;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $color-gray-01;
                    opacity: 0.1;
                }

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &__thumb-link {
            display: block;
            height: 100%;
        }

        &__mini-card {
            display: flex;
            padding: 0 10px 12px;
            position: absolute;
            z-index: 11;
            left: 0;
            bottom: 0;
            right: 0;
            font-size: 14px;
            // 하단 dim
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 68px;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            }

            &-thumb {
                display: block;
                position: relative;
                @include size(32px);
                margin-right: 8px;
                min-width: 32px;

                .c-lazyload {
                    &::before {
                        border-radius: 4px;
                    }
                }

                img {
                    border-radius: 2px;
                }
            }

            dl {
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                overflow: hidden;
                position: relative;
                height: 34px;
                .point {
                    color: $color-11st-blue;
                }
                dt {
                    color: $color-gray-03;
                    @include sr-only;
                }
                dd {
                    line-height: 15px;
                    font-size: 11px;
                    height: 15px;
                    vertical-align: top;
                    overflow: hidden;
                    color: $color-gray-14;
                    font-weight: normal;
                    span {
                        font-size: 12px;
                        font-weight: bold;
                    }
                    &:nth-child(4) {
                        font-size: 12px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
        &__name {
            height: auto;
            margin-bottom: 8px;
            color: #333;
            color: $color-gray-03;
        }
        &__info {
            .c-view-count-number {
                color: $color-11st-blue;
                font-size: 14px;
                padding-bottom: 5px;
            }

            .c-profile {
                &__link {
                    display: inline-flex;
                    align-items: center;
                    max-width: 100%;
                    vertical-align: top;
                }
                &__pic,
                &__pic span {
                    @include size(20px);
                }

                &__pic {
                    flex-shrink: 0;
                    float: none;
                    margin-right: 4px;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 11;
                        border: 1px solid rgba(0, 0, 0, 0.04);
                        border-radius: 50%;
                        content: "";
                    }
                }

                &__pic img {
                    @include size(20px);
                    border-radius: 50%;
                }

                &__name {
                    flex: 1;
                    height: 15px;
                    line-height: normal;
                    font-size: 14px;
                    color: $color-gray-04;
                }
                .c-lazyload {
                    &::before {
                        background: none;
                    }
                }
            }
        }
    }
}
