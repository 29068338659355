[data-type="GridScroll_Text_CtgrNavi2"] {
    .c-magnet--fixed,
    .c-magnet-multiple--fixed {
        border-bottom: 1px solid $color-gray-11;
    }

    .c-nav {
        border-bottom: 0;

        &__wrap {
            overflow-x: auto;
            overflow-y: hidden;
            margin-right: 0;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__list {
            table-layout: fixed;
            padding: 0 8px;
            border-bottom: 1px solid $color-gray-12;
        }
        &__line {
            display: block;
            width: 100%;
            height: 45px;
            margin-bottom: -1px;
            padding: 0 8px;
            border: 0;
            border-bottom: 2px solid transparent;
            background-color: transparent;
            color: $color-gray-02;
            font-size: 15px;
            line-height: 1;
            box-sizing: border-box;

            &[aria-selected="true"] {
                border-color: $color-11st-red;
                color: $color-11st-red;
                font-weight: 700;
            }
        }
        &__chip {
            height: 32px;
            margin: 0 8px;
            border: 0;
            color: $color-gray-02;
            font-size: 14px;
            background-color: transparent;
            white-space: nowrap;

            &[aria-selected="true"] {
                padding: 0 12px;
                border: 1px solid $color-11st-red;
                color: $color-11st-red;
                font-weight: 700;
                background-color: rgba($color-11st-red, 0.02);
                border-radius: 32px;
            }
        }
        &__new {
            overflow: hidden;
            position: absolute;
            top: 10px;
            margin-left: 1px;
            width: 3px;
            height: 3px;
            background-color: $color-11st-red;
            color: transparent;
            border-radius: 50%;
        }
        &--chip {
            padding: 12px 0;

            .c-nav__list {
                width: auto;
                border-bottom: 0;
            }
        }
        &--spread {
            .c-nav__list {
                table-layout: auto;
            }
        }
    }
}
