.luxury {
    &,
    #root {
        height: 100%;
    }

    * {
        font-family: $font-family3;
    }

    .l-grid {
        overflow: visible;
        position: static;
        margin-top: 0;
        // 개발 서버에서 받는 inline padding style 초기화
        &:first-child {
            padding-top: 0 !important;
        }
        &:last-child {
            padding-bottom: 0 !important;
        }
        &--initial {
            background-color: #f4f4f4;
        }
        &--space {
            border-top: 10px solid #f4f4f4;
        }
    }

    .header-fixed {
        padding-top: calc(env(safe-area-inset-top) + 56px);
    }

    // 안드로이드 웹뷰 env(safe-area-inset-top) 적용되지 않음, 상단바 영역 (24px) 확보
    &.webview-aos .header-fixed {
        padding-top: 80px;
    }

    main,
    .main {
        padding-bottom: calc(constant(safe-area-inset-bottom) + 56px);
        padding-bottom: calc(env(safe-area-inset-bottom) + 56px);
        background-color: #fff;
    }

    // 페이지 하단 공통 여백 (뒤로가기, TOP버튼 대응)
    .carrier-list {
        padding-bottom: 72px;
    }

    // [e] MPINNER-12080 개발 구조 sync 완료. 이후 사용하지 않는 선택자 확인 필요
    .c-goback,
    .c-gotop {
        position: fixed;
        bottom: calc(68px + env(safe-area-inset-bottom));
        left: 12px;
        z-index: 100;
        display: block;
        width: 48px;
        height: 48px;
        border: 0;
        color: transparent;
        border-radius: 24px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(16, 0, 0, 0.14);
        background-color: #fff;
        opacity: 0.98;

        &:before {
            @include get-sprite-luxury("ic-gnb-back", 36);

            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .c-gotop {
        left: auto;
        right: 12px;
        background-image: none;

        &:before {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    // 서랍필터
    .c-search-filter {
        display: none;
        overflow: hidden;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 110;
        width: 262px;
        height: 100%;
        padding-top: 61px;
        background: #f4f4f4;
        box-sizing: border-box;
        outline: 0;

        &__title {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 60px;
            padding: 19px 16px 17px;
            background: #fff;
            color: #000;
            font-size: 17px;
            font-weight: 600;
            line-height: 24px;
            box-sizing: border-box;
            touch-action: none;
            //APP LG G6 안드로이드 8 - 버그
            overflow: hidden;
            z-index: 100;
            border-bottom: 1px solid transparent;
            transform: translateZ(0);
        }
        &__sub-title {
            &__title {
                width: auto;
                height: 54px;
                padding: 15px 80px 15px 16px;
                color: #000;
                font-size: 17px;
                line-height: 24px;

                strong {
                    display: inline-block;
                    margin: -1px 0 1px 4px;
                    color: $color-luxury;
                    font-size: 15px;
                    font-weight: 600;
                    vertical-align: middle;
                }
            }
        }

        &__close {
            overflow: hidden;
            position: absolute;
            top: 10px;
            right: 0;
            width: 40px;
            height: 40px;
            border: none;
            background: none;
            color: transparent;
            touch-action: none;
            z-index: 101;

            @include btn-close-x(17px, 1px, #111);
        }

        &__scroll {
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch;
        }

        &__wrap {
            position: relative;
            margin-top: 0;
            border-top: 10px solid #f4f4f4;
            background: #fff;
        }

        &__sector {
            border-top: 1px solid #f4f4f4;

            h4 {
                border-top: 0;
            }
        }

        &__cont {
            padding-bottom: 10px;

            &[aria-hidden="true"] {
                display: none;
            }
        }

        &__toggle {
            position: relative;
            display: block;
            width: 100%;
            height: 54px;
            padding: 15px 16px;
            border: 0;
            color: #000;
            font-size: 17px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            background-color: transparent;
            box-sizing: border-box;

            @at-root .c-search-filter h4 & {
                height: 44px;
                padding: 10px 40px 10px 16px;
                font-size: 15px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 16px;
                width: 8px;
                height: 8px;
                margin-top: -4px;
                border: 1px solid #111;
                border-width: 1px 1px 0 0;
                transform: rotate(135deg);
            }

            strong {
                display: inline-block;
                margin: -1px 0 1px 4px;
                color: $color-luxury;
                font-size: 15px;
                font-weight: 600;
                vertical-align: middle;
            }

            &[aria-expanded="true"]:after {
                margin-top: -3px;
                transform: rotate(315deg);
            }
        }

        &__item {
            position: relative;
            padding: 11px 32px 11px 16px;

            label {
                position: relative;
                display: block;
                padding-left: 30px;
                color: #111;
                font-size: 15px;
                line-height: 22px;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
            }

            .c-radio,
            .c-checkbox {
                position: absolute;
                left: -40px;
                width: 22px;
                height: 22px;
                opacity: 0;

                & + label {
                    color: #111;

                    &:after {
                        display: none;
                    }
                }
            }
            .c-radio {
                & + label:before {
                    @include get-sprite-luxury("ic-radio", 22);

                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border: 0;
                }
                &:checked + label:before {
                    @include get-sprite-luxury("ic-radio-on", 22);
                }
            }
            .c-checkbox {
                & + label:before {
                    @include get-sprite-luxury("ic-check", 22);

                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border: 0;
                }
                &:checked + label:before {
                    @include get-sprite-luxury("ic-check-on", 22);
                }
            }
        }

        &__reset {
            position: absolute;
            top: 13px;
            right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 28px;
            border: solid 1px rgba(15, 15, 217, 0.2);
            color: $color-luxury;
            font-size: 13px;
            line-height: 19px;
            background-color: #fff;
            border-radius: 28px;

            // 구버전 PUI CSS 겹치는 문제
            background-image: none;
        }

        &__search-price {
            display: flex;
            align-items: center;
            margin-right: 16px;
            margin-left: 12px;
            padding-bottom: 14px;
            color: #111;
            font-size: 15px;
            line-height: 30px;

            .c-input {
                flex: 1;
                display: block;
                width: 100%;
                height: 30px;
                margin: 0 4px;
                padding: 0 4px;
                border: 1px solid #eee;
                color: #111;
                font-size: 15px;
                text-align: right;
                outline: 0;

                &::placeholder {
                    color: #999;
                }

                &:focus {
                    font-size: 16px;
                    border-color: #111;
                }
            }
        }

        .c-chip-input--keyword {
            overflow-y: auto;
            max-height: 98px;
            display: flex;
            flex-wrap: wrap;
            padding: 0 26px 8px 16px;
            border-bottom: 0;
            box-sizing: border-box;

            li {
                margin-right: 12px;
                margin-bottom: 8px;
            }

            .c-chip {
                position: relative;
                display: inline-block;
                overflow: hidden;
                max-width: 100%;
                height: 24px;
                margin-bottom: 0;
                padding-right: 20px;
                color: $color-luxury;
                font-size: 14px;
                line-height: 24px;
                vertical-align: top;
                box-sizing: border-box;
                text-overflow: ellipsis;
                white-space: nowrap;
                transform: translateZ(0);
            }

            .delete {
                @include btn-close-x(14px, 1px, $color-luxury);

                position: absolute;
                top: 2px;
                right: 0;
                display: block;
                width: 20px;
                height: 20px;
                background-color: transform;
            }
        }
    }

    // dimmed 스크롤되는 현상 (MPQA-66121)
    &.posFixedNotToScroll {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        overflow: hidden;
        border-bottom: 1px solid transparent;
    }

    // pui storybook에서 짤리는 문제
    .main,
    .main article {
        overflow: visible;
    }
}

// ios 웹뷰 이슈 테스트
.luxury-app {
    .c-gotop,
    .c-goback {
        bottom: 100px;
    }
    .c-tabbar--luxury {
        top: 100vh;
        bottom: auto;
        padding-bottom: 32px;
        margin-top: -89px; // 하단탭바 높이 57 + 패딩 32
    }
}
