// Light
@font-face {
    font-family: "11StreetGothic";
    src: url("/MW/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("/MW/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff");
    font-weight: 300;
}

// Regular
@font-face {
    font-family: "11StreetGothic";
    src: url("/MW/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/MW/css/fonts/11StreetGothic_Optimizing.woff") format("woff");
    font-weight: 500;
}

// Bold
@font-face {
    font-family: "11StreetGothic";
    src: url("/MW/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("/MW/css/fonts/11STREET_Gothic_bold.woff") format("woff");
    font-weight: 700;
}
