[data-ui-type="ProductList_RoundingItem"] {
    padding: 0 8px 18px;
    .l-grid__col {
        padding: 6px 8px;
        &:first-child {
            padding-top: 0;
        }
    }
    .c-rounding-box {
        @include rounding-box;
        border-radius: 4px;
        overflow: hidden;
        .c-card-item {
            &__thumb {
                height: 104px;
                width: 104px;
            }
            &__info {
                padding-top: 6px;
                padding-right: 14px;
                margin-left: 104px;
                vertical-align: middle;
            }
            &__name {
                display: block;
                height: auto;
                margin-bottom: 4px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: initial;

                dd {
                    display: table-cell;
                    height: auto;
                    vertical-align: middle;
                    span {
                        display: block;
                        @include text-ellipsis-multiple(2);
                    }
                }
            }
            &__price-info {
                @include clearfix;
            }

            &__counsel {
                padding: 0;
            }

            &__price {
                float: left;
                margin-right: 4px;
            }

            &__price-del {
                color: #bbb;
                font-size: 12px;
                float: left;
                line-height: 20px;
            }

            .c-starrate {
                margin-top: 4px;
            }
        }
        &__car {
            .c-card-item {
                &__name {
                    margin: 0;
                    display: block;
                    height: 21px;
                    -webkit-line-clamp: unset;
                    -webkit-box-orient: initial;
                    dd {
                        overflow: hidden;
                        height: 21px;
                        span {
                            display: block;
                            height: 21px;
                            -webkit-line-clamp: unset;
                            -webkit-box-orient: initial;
                        }
                    }
                }
                &__fluid {
                    margin-top: 5px;
                    &-info {
                        font-size: 14px;
                        strong {
                            font-size: 12px;
                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    & + [data-type="FlatButton_More"] {
        button.c-relates__link {
            background: $color-gray-13;
        }
    }
}
