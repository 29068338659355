[data-type="ProductGrid_Metro"] {
    @include grid(2, 2, false);
    .c-tileview {
        &__col2 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            &>div:nth-child(1) {
                flex: 68.0981595 0 auto;
                padding-right: 2px;
            }
            &>div:nth-child(2) {
                flex: 31.9018405 0 auto;
            }
        }
        &__row2 {
            position: relative;
            >.c-tileview__item:last-child {
                margin-top: 2px;
            }
        }
        .c-lazyload--ratio_222x267 {
            @include mq-640 {
                padding-bottom: 119%;
            }
        }
    }
    .c-tileview:nth-child(2) {
        margin-top: 2px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &>div:nth-child(1) {
            flex: 0 1 32%;
            padding-right: 2px;
        }
        &>div:nth-child(2) {
            flex: 0 1 68%;
            a {
                img {
                    width: 100%;
                    height: auto;
                    left: 0;
                    right: 0;
                    top: -100%;
                    bottom: -100%;
                    margin: auto 0;
                }
            }

        }
    }
    .c-tileview__item {
        a {
            position: relative;
            display: block;
            text-align: center;
            height: 100%;
            width: 100%;
            line-height: 0;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
            }
            .c-tileview__txt {
                position: absolute;
                bottom: 16px;
                left: 16px;
                font-size: 18px;
                color: #fff;
                line-height: 1.2;
                z-index: 10;
                font-weight: normal;
                text-align: left;
                span {
                    display: block;
                    padding-right: 16px;
                    @include text-ellipsis-multiple(2)
                }
                strong {
                    display: inline-block;
                    font-size: 14px;
                    height: 21px;
                    margin-bottom: 12px;
                    position: relative;
                    font-weight: normal;
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: #fff;
                        width: 100%;
                        height: 2px;
                    }
                }
            }
            img {
                width: auto;
                height: 100%;
                max-width: initial;
                max-height: initial;
                margin: 0 auto;
                left: -100%;
                right: -100%;
            }
        }
    }
}
