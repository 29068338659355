[data-type="LineBanner_Luxury_Pagination"] {
    .c-banner {
        margin-right: 3px;
        margin-left: 3px;

        &__link {
            overflow: hidden;
            display: block;
            width: 100%;
            border: 0;
            background-color: transparent;
        }
        &__image {
            display: block;
            width: 300px;
            margin: 0 auto;
        }
    }

    .c-lazyload {
        height: 80px;
    }

    // swiper
    .w-swiper {
        padding-right: 13px;
        padding-left: 13px;
    }
    .swiper-slide {
        width: 96.27%;
    }
    // 배포 후 삭제
    .c-pagination__indicator {
        display: none;
        align-items: flex-end;
        justify-content: center;
        height: 19px;

        .c-pagination__bullet {
            display: inline-block;
            width: 14px;
            height: 2px;
            margin-right: 3px;
            margin-left: 3px;
            border: 0;
            background-color: #111;
            border-radius: 0;
            opacity: 0.1;

            &.on {
                opacity: 1;
            }
        }
    }
}
