.ooah-pick .c-sale_products {
    $size: 44px;
    &__button {
        // overflow: visible;
        position: relative;
        z-index: 100;
        width: $size;
        height: $size;
        margin-top: 12px;
        border-radius: 24px;
        border: solid 2px $color-gray-14;
        background-color: $color-gray-14;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(16, 0, 0, 0.14);
        color: transparent;
        -webkit-tap-highlight-color: transparent;
        box-sizing: content-box;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 24px;
            background-color: rgba($color: #434a79, $alpha: 0.5);
        }
    } //c-sale_products__button
    &__thumb {
        // overflow: hidden;
        position: relative;
        // top: 0;
        // left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 100%;

        &-box {
            overflow: hidden;
            position: relative;
            @include size($size);
            border-radius: 100%;
            isolation: isolate;
        } //c-sale_products__thumb-box
        &-img {
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border-radius: 100%;
            box-sizing: border-box;

            img {
                width: $size;
                height: $size;
                vertical-align: top;
            }
        } //c-sale_products__thumb-img
    } //c-sale_products__thumb
    &__count {
        $count_size: 20px;
        position: absolute;
        bottom: 32px;
        right: -5px;
        z-index: 101;
        padding: 0 6px;
        height: $count_size;
        min-width: $count_size;
        border-radius: 11px;
        background-color: $color-luxury;
        font-family: $font-outfit;
        font-size: 13px;
        line-height: $count_size;
        font-weight: 500;
        color: $color-gray-14;
        box-sizing: border-box;
        @at-root .brand-pick#{&} {
            background-color: $color-11st-red;
        }
    } //c-sale_products__count
}
