[data-ui-type="AiFeed_Layer_Report"],
[data-ui-type="AiFeed_Layer_Chat"],
[data-ui-type="AiFeed_Layer_More"] {
    // skpui css 초기화
    &.l-grid__row:nth-child(1):not([data-type]) {
        position: static;
    }

    .ai-feed-chat {
        &__content {
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            box-sizing: border-box;
            overscroll-behavior: contain;
        }
    }
}

[data-ui-type="AiFeed_Layer_Chat"],
[data-ui-type="AiFeed_Layer_More"] {
    .ai-feed-chat {
        &__area {
            & + .ai-feed-button-detail {
                &:before {
                    display: block;
                    margin-top: 20px;
                    content: "";
                }
            }
        }

        &__content {
            &::-webkit-scrollbar {
                display: none !important;
            }
        }

        &__balloon,
        .ai-feed-chat-message-wrap {
            white-space: pre-line;
        }

        &__scroll-down {
            position: absolute;
            bottom: 103px;
            left: 50%;
            padding: 20px;
            border: none;
            background-color: transparent;
            color: transparent;
            transform: translateX(-50%);

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }

            &:before {
                position: absolute;
                width: 13px;
                height: 13px;
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Line 10' d='M7.25 1C7.25 0.585786 6.91421 0.25 6.5 0.25C6.08579 0.25 5.75 0.585786 5.75 1H7.25ZM5.96967 11.5303C6.26256 11.8232 6.73744 11.8232 7.03033 11.5303L11.8033 6.75736C12.0962 6.46447 12.0962 5.98959 11.8033 5.6967C11.5104 5.40381 11.0355 5.40381 10.7426 5.6967L6.5 9.93934L2.25736 5.6967C1.96447 5.40381 1.48959 5.40381 1.1967 5.6967C0.903806 5.98959 0.903806 6.46447 1.1967 6.75736L5.96967 11.5303ZM5.75 1L5.75 11H7.25L7.25 1H5.75Z' fill='black'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
            }

            &:after {
                z-index: -1;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: $color-gray-14;
                box-shadow: 0px -2px 12px 0px rgba($color-gray-01, 0.06), 0px -1px 4px 0px rgba($color-gray-01, 0.18);
            }
        }

        &__toast {
            position: absolute;
            bottom: 95px;
            left: 20px;
            width: calc(100% - 72px);
            padding: 12px 16px;
            border-radius: 4px;
            background: rgba($color: $color-gray-01, $alpha: 0.8);
            font-size: 14px;
            color: $color-gray-14;
        }

        &__list-emoji {
            display: flex;
            margin-top: 20px;

            & > li {
                & + li {
                    margin-left: 6px;
                }
            }
        }

        &__button-emoji {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 0 8px;
            border: 1px solid $color-gray-11;
            border-radius: 50px;
            background-color: $color-gray-14;
            font-size: 12px;
            font-weight: 400;
            color: $color-gray-01;
            box-sizing: border-box;

            &:before {
                width: 20px;
                height: 20px;
                margin-top: -2px;
                background-position: 0 0;
                background-size: cover !important;
                transition: none;
                transform-origin: bottom center;
                content: "";
            }

            &--resolved {
                &:before {
                    background: url("/MW/img/vertical/aifeed/aifeed_love.png") no-repeat;
                }
            }

            &--interested {
                &:before {
                    background: url("/MW/img/vertical/aifeed/aifeed_like.png") no-repeat;
                }
            }

            &--unsure {
                &:before {
                    background: url("/MW/img/vertical/aifeed/aifeed_soso.png") no-repeat;
                }
            }

            &--clicked {
                &:before {
                    animation: aifeedEmojiMotion 1.5s steps(45) forwards, aifeedScaleImage 1.5s ease-in-out forwards;
                }
            }

            @media screen and (max-width: 360px) {
                .ai-feed-chat__button-text {
                    display: none;
                }
            }
        }

        &__button-text {
            margin: -2px 0 0 2px;
        }

        &__input-wrap {
            z-index: 20;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 20px;
            border-top: 1px solid $color-gray-11;
            background-color: $color-gray-14;
        }

        &__input {
            display: inline-block;
            position: relative;
            overflow: hidden;
            width: 100%;
            border: 2px solid transparent;
            border-radius: 20px;
            background-image: linear-gradient(to right, rgba(71, 184, 231, 1), rgba(223, 80, 247, 1));
            background-origin: border-box;
            vertical-align: top;
            box-sizing: border-box;
        }

        &__textarea {
            width: 107%;
            max-height: 170px;
            margin-bottom: -8px;
            padding: 6.4px 42.67px 7.47px 17.07px;
            border: none;
            font-size: 16px;
            color: $color-gray-03;
            line-height: 26.67px;
            resize: none;
            transform: scale(0.9375);
            transform-origin: 0 0;

            @media screen and (min-width: 430px) {
                margin-bottom: -10px;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-size: 15px;
                color: $color-gray-06;
            }

            // 텍스트 입력 시 전송 버튼 배경색 변경
            &:not(:placeholder-shown) {
                & + .ai-feed-chat__send {
                    &:before {
                        background-color: #8155ea;
                    }

                    &:disabled {
                        &:before {
                            background-color: $color-gray-10;
                        }
                    }
                }
            }

            &--disabled-pointer {
                pointer-events: none;
            }
        }

        &__send {
            position: absolute;
            right: 0;
            bottom: -2px;
            padding: 8px;
            border: none;
            background-color: transparent;
            font-size: 0;
            color: transparent;

            &:before {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: $color-gray-10;
                background-image: url('data:image/svg+xml,%3Csvg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath id="Arrow 3" d="M8.70711 0.315843C8.31658 -0.0746822 7.68342 -0.0746822 7.29289 0.315843L0.928932 6.6798C0.538408 7.07033 0.538408 7.70349 0.928932 8.09402C1.31946 8.48454 1.95262 8.48454 2.34315 8.09402L8 2.43716L13.6569 8.09402C14.0474 8.48454 14.6805 8.48454 15.0711 8.09402C15.4616 7.70349 15.4616 7.07033 15.0711 6.6798L8.70711 0.315843ZM9 13.0229L9 1.02295L7 1.02295L7 13.0229L9 13.0229Z" fill="white"/%3E%3C/svg%3E%0A');
                background-repeat: no-repeat;
                background-position: 50%;
                vertical-align: top;
                content: "";
            }

            &:disabled {
                cursor: not-allowed;
                pointer-events: none;
            }
        }

        &__product-recommend {
            display: flex;
            flex-wrap: wrap;
            margin: 20px 0 12px;
        }

        &__product-recommend-link {
            display: flex;
            flex: 0 1 auto;
            align-items: center;
            height: 32px;
            margin: 0 8px 8px 0;
            padding: 5px 4px 5px 12px;
            border-radius: 4px;
            background: rgba($color: #072572, $alpha: 0.05);
            font-size: 12px;
            font-weight: 700;
            color: $color-gray-01;
            line-height: 22px;
            text-align: center;
            box-sizing: border-box;

            &:after {
                width: 22px;
                height: 22px;
                background: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='chevron_right_nor'%3E%3Cpath id='Path' d='M8.38975 6.08708C8.57173 5.91113 8.86189 5.91602 9.03784 6.098L13.6347 10.6795C13.8057 10.8565 13.8065 11.137 13.6364 11.3149L9.04141 15.9009C8.86648 16.0839 8.57636 16.0904 8.39339 15.9155C8.21042 15.7405 8.2039 15.4504 8.37883 15.2674L12.6692 10.999L8.37883 6.73517C8.22243 6.57341 8.20891 6.32618 8.33565 6.14966L8.38975 6.08708Z' fill='%23333333'/%3E%3C/g%3E%3C/svg%3E%0A") center/cover no-repeat;
                content: "";
            }

            @media screen and (max-width: 360px) {
                flex: 1 1 auto;
            }
        }

        &__product-recommend-talk {
            &:before {
                flex: 0 0 auto;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='11talk_16'%3E%3Cpath id='icon' fill-rule='evenodd' clip-rule='evenodd' d='M8.22781 1.5C10.0011 1.5 11.5568 2.21161 12.6742 3.3666C13.8163 4.54718 14.5 6.188 14.5 8C14.5 9.812 13.8163 11.4528 12.6742 12.6334C11.5568 13.7884 10.0011 14.5 8.22781 14.5H2.26069L3.53928 12.6165C2.29534 11.4401 1.5 9.80713 1.5 8C1.5 6.22152 2.27023 4.61191 3.48033 3.43995C4.71626 2.24298 6.41205 1.5 8.22781 1.5Z' stroke='%23333333' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
                content: "";
            }
        }

        &__product-recommend-gradient {
            background: linear-gradient(92deg, #ffebdc 1.07%, #efc0ff 99.66%);
        }

        &__product-recommend-text {
            flex: 0 0 auto;
            max-width: 100px;
            margin-right: 5px;
            @include text-ellipsis;
        }

        &__product-recommend-icon {
            margin-right: 5px;
        }
    }

    .ai-feed-chat-message-wrap {
        margin-top: 4px;
    }
}

// 신고하기
[data-ui-type="AiFeed_Layer_Report"] {
    .ai-feed-chat {
        &__content {
            padding-bottom: 164px;
        }
    }
}

// 대화하기
[data-ui-type="AiFeed_Layer_Chat"] {
    .ai-feed-chat {
        &__content {
            display: flex;
            flex-direction: column;
            padding: 20px 20px 181px;
            padding-bottom: 181px;

            & > * {
                flex: 0 0 auto;
            }
        }

        &__guide {
            text-align: center;

            & + .ai-feed-product-overview {
                margin-top: 16px;
            }
        }

        &__guide-text {
            font-size: 15px;
            font-weight: 500;
            color: $color-gray-02;
        }

        &__guide-additional {
            font-size: 14px;
            color: $color-gray-04;
        }

        &__balloon {
            position: relative;
            max-width: 255px;
            margin: 20px 0 0 auto;
            padding: 12px;
            border-radius: 8px;
            background-color: #f6f3ff;
            box-sizing: border-box;

            &:after {
                position: absolute;
                right: 0;
                bottom: -7px;
                width: 11px;
                height: 15px;
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none"%3E%3Cpath d="M11 0V13.1315C11 13.9302 10.1099 14.4066 9.4453 13.9635L0.5 8L11 0Z" fill="%23F6F3FF"/%3E%3C/svg%3E');
                content: "";
            }
        }

        &__balloon-text {
            font-size: 14px;
            font-weight: 500;
            color: $color-gray-03;
            line-height: 20px;
        }

        &__area {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
        }

        &__message-area {
            flex: 1 0;
        }
    }

    .ai-feed-chat-profile-wrap {
        width: 40px;
        height: 40px;

        & + .ai-feed-chat__message-area {
            margin-left: 8px;
        }
    }

    .ai-feed-chat-profile-image {
        width: 100%;
        height: 100%;
    }
    .c-flag {
        box-sizing: border-box;
    }
}

// 대화목록
[data-ui-type="AiFeed_Layer_More"] {
    .ai-feed-product-overview-wrap {
        padding: 20px;
        border-bottom: 1px solid $color-gray-11;
    }

    .ai-feed-chat {
        &__content {
            padding-bottom: 141px;
        }

        &__area {
            display: flex;
            flex-wrap: wrap;
            flex: 1 0;
            padding: 32px 20px;

            & + .ai-feed-chat__area {
                padding-top: 32px;
                border-top: 1px solid $color-gray-10;
            }
        }

        &__message-area {
            flex: 1 0;
            position: relative;
        }

        &__guest {
            display: flex;
            position: relative;
            width: 100%;
            margin: 48px 0 8px;

            &:before {
                position: absolute;
                top: 0;
                bottom: -12px;
                left: 19px;
                width: 2px;
                border: none;
                border-radius: 20px;
                background-color: $color-gray-11;
                content: "";
            }

            &:first-child {
                margin-top: 0;
            }

            .ai-feed-chat__talker-area {
                margin-bottom: 4px;
            }

            & + .ai-feed-chat-profile-wrap {
                margin-top: 12px;
                & + .ai-feed-chat__message-area {
                    margin-top: 12px;
                }
            }

            .ai-feed-chat-talker-name {
                font-size: 14px;
            }

            .ai-feed-chat-message-wrap {
                display: inline;
                margin-bottom: 12px;
                background: rgba($color: #8155ea, $alpha: 0.1);
                font-size: 17px;
                font-weight: 400;
                color: $color-gray-03;
                line-height: 24px;
                word-break: break-all;
            }
        }
    }

    .ai-feed-chat-profile-wrap {
        position: relative;
        width: 40px;
        height: 40px;

        &:after {
            position: absolute;
            top: 100%;
            right: 0;
            bottom: 0;
            left: 0;
            height: 8px;
            background-color: $color-gray-14;
            content: "";
        }

        & + .ai-feed-chat__message-area {
            margin-left: 8px;
        }
    }

    .ai-feed-time {
        margin-left: 6px;
    }

    .ai-feed-chat-more-options {
        position: absolute;
        top: -26px;
        right: -43px;
        padding: 20px;
        border: none;
        background-color: transparent;
        color: transparent;

        &:before {
            position: absolute;
            top: 50%;
            right: 50%;
            width: 13px;
            height: 3px;
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='3' viewBox='0 0 13 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1.5' cy='1.5' r='1.5' fill='%23949494'/%3E%3Ccircle cx='6.5' cy='1.5' r='1.5' fill='%23949494'/%3E%3Ccircle cx='11.5' cy='1.5' r='1.5' fill='%23949494'/%3E%3C/svg%3E%0A");
            transform: translate(-50%, -50%);
            content: "";
        }
    }

    @keyframes aifeedScaleImage {
        0% {
            transform: scale(1);
        }
        37.5% {
            // 0.6s of 1.6s
            transform: scale(2);
        }
        81.25% {
            // 1.3s of 1.6s
            transform: scale(2);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes aifeedEmojiMotion {
        from {
            background-position: 0 0;
        }
        to {
            background-position: -900px 0;
        }
    }
}
