[data-type="ProductScroll_Luxury_TimeDeal"] {
    .c-timedeal {
        width: 100%;
        padding-right: 3px;
        padding-left: 3px;
    }
    .c-count {
        position: relative;
        padding: 8px 0 16px;

        // 구버전 PUI CSS 겹치는 문제
        border-radius: 0;
        box-shadow: none;
        height: auto;
        min-height: 0;

        &__time {
            display: flex;
        }
        &__number,
        &__symbol,
        &__text {
            overflow: hidden;
            width: 32px;
            height: 29px;
            color: #111;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            text-align: center;
        }
        &__symbol {
            width: 12px;
            line-height: 1em;
        }
        &__text {
            width: auto;
            text-align: left;
            white-space: nowrap;
        }
        &__group {
            display: flex;
            align-items: center;
        }
    }
    .c-progress {
        overflow: hidden;
        width: 100%;
        height: 1px;
        font-size: 0;
        line-height: 0;
        background-color: rgba(0, 0, 0, 0.2);

        &__bar {
            display: block;
            width: 0;
            height: 100%;
            background-color: #000;
        }
    }
    .c-count__time + .c-progress {
        margin-left: 8px;
    }
    .c-product-rate {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        padding: 0 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        background-color: $color-luxury;
        border-radius: 50%;

        &--special {
            font-size: 14px;
            line-height: 16px;
        }
        &--soldout {
            font-size: 14px;
            line-height: 16px;
            background-color: #111;
        }
    }

    .c-n-product {
        position: relative;

        &__info {
            overflow: hidden;
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 75.75%;
            padding: 16px;
            background-color: #fff;
        }
        &__brand {
            font-size: 18px;
            line-height: 21px;
        }
        &__price {
            float: left;
            font-size: 18px;
            line-height: 22px;
        }
        &__flag {
            float: left;
            margin-top: 2px;
            margin-left: 8px;
        }
        &__desc {
            margin-top: 6px;
            margin-bottom: 6px;
        }
        &__buttons {
            top: auto;
            bottom: 0;
            padding: 6px;
        }

        .price-origin {
            font-size: 18px;
            line-height: 21px;
        }
        .price-rate {
            position: absolute;
            left: 100%;
            color: transparent;
        }
    }

    // swiper
    .w-swiper {
        padding-right: 13px;
        padding-left: 13px;
    }
    .swiper-slide {
        width: 96.21%;
    }
}
