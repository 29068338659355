@charset "UTF-8";

// 브라우저 스크롤이 필요없는 케이스
.notToScroll {
    position: fixed;
    overflow-y: scroll;
    overscroll-behavior: none;
    width: 100%;
}

// 스크롤
.scrollDown {
    .c-aside {
        padding-top: 56px;
    }
    .c-aside-search {
        border-bottom: 1px solid $color-gray-11;
    }
    .c-aside-gateway {
        top: 0;
        transform: translateY(-98px);
    }
}

.c-aside {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-top: 154px;
    padding-bottom: 56px;
    background-color: $color-gray-14;
    box-sizing: border-box;
    transition: all 0.1s linear; // c-aside-gateway의 trasition과 동일해야함

    &__main {
        display: flex;
        height: 100%;
        box-sizing: border-box;
        // 검색바 + 전체서비스
        // padding-top: calc(56px + 98px);
    }
    &__lnb {
        flex-shrink: 0;
        overflow: hidden;
        overflow-y: auto;
        width: 110px;
        background-color: $color-gray-12;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
        }
    }
    &__content {
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
        }

        // 블럭간 border로 사용하는 bg색상을 초기화 한다
        .l-grid--initial {
            background-color: initial;
        }
    }

    // PUI wrapper scale down
    @media screen and (max-width: 359px) {
        .carrier-list {
            zoom: 0.85;
        }
    }
}
