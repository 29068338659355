[data-type="ProductList_HistoryBox"] {
    @include grid(2, 2, false);
    padding-left: 24px !important;

    &::before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background-color: $color-gray-11;
        position: absolute;
        top: 0;
        left: 16px;
    }
    .l-grid__col {
        padding: 6px 8px;
    }
    .c-card-item--list {
        @include rounding-box;
        overflow: initial;
        padding: 16px;

        &::before {
            @include size(11px);
            content: "";
            display: block;
            position: absolute;
            left: -22px;
            top: 6px;
            background-color: $color-11st-blue;
            border-radius: 100%;
            border: 2px solid $color-gray-14;
        }

        .c-card-item {
            &__thumb {
                @include size(96px);
                .c-card-item__rank {
                    border-bottom-right-radius: 0;
                }
            }
            &__info {
                padding-bottom: 0;
                padding-left: 16px;

                .sktpass {
                    overflow: hidden;
                    height: 18px;
                    margin-bottom: 2px;
                    padding-top: 1px;

                    &:before {
                        margin-right: 0;
                        margin-left: 0;
                    }
                    span {
                        display: inline;
                    }
                }
            }
            &__name {
                display: block;
                height: auto;
                max-height: 38px;
                margin-bottom: 6px;
                line-height: 19px;
            }
            &__rate {
                strong {
                    font-weight: normal;
                }
            }
            &__price {
                strong {
                    vertical-align: bottom;
                }
            }
            &__benefit {
                margin-top: 6px;
                font-size: 14px;

                .shooting-delivery,
                .shooting-install,
                .shooting-fresh,
                .shooting-plus,
                .shooting-seller {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    row-gap: 3px;
                    strong {
                        margin: 0 4px 0 0;
                    }
                    .c-card-item__point {
                        line-height: 17px;
                    }
                }
            }
        }
    }

    .c-card-item__hot {
        @include size(51px 24px);
        display: block;
        position: absolute;
        left: -41px;
        top: -9px;
        color: $color-gray-14;
        background: linear-gradient(90deg, rgb(48, 128, 255), rgb(51, 240, 228));
        text-align: center;
        font-weight: bold;
        font-size: $font-size-caption1;
        line-height: 25px;
        border-radius: 12px;
        z-index: 1;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2%);
        padding-left: 6px;
        &::before {
            @include size(4px);
            content: "";
            display: block;
            background-color: $color-gray-14;
            border-radius: 2px;
            position: absolute;
            left: 7px;
            top: 10px;
        }
    }

    .c-starrate {
        margin-top: 6px;
    }

    &.type-shooting {
        .c-card-item--list:before {
            background-color: $color-11st-red;
        }
        .c-card-item__hot {
            background: linear-gradient(to right, $color-11st-gradient-orange, $color-11st-red);
        }
    }
}
