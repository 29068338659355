.wrap-ai-feed {
    .dim {
        &.dim--fadein {
            display: block;
            z-index: 500;
            animation: aifeedFadeIn 0.2s;
        }
    }
}

.c-bottom-drawer {
    &--type-feed {
        --height-percent: 0%;
        display: block;
        z-index: 300;
        overflow: visible;
        bottom: 0;
        left: 0;
        max-width: none;
        height: 100vh;
        transform: translate3d(0, 100%, 0);
        overscroll-behavior: contain;
        will-change: transform;

        .c-bottom-drawer__wrap,
        [data-ui-type="AiFeed_Layer_Chat"],
        [data-ui-type="AiFeed_Layer_More"],
        [data-ui-type="AiFeed_Layer_Report"],
        .l-grid__col,
        .ai-feed-chat {
            height: 100%;
        }

        .c-bottom-drawer {
            &__indicator {
                position: absolute;
                top: -19px;
                left: 0;
                padding: 25px 0;
            }

            &__title {
                height: 72px;
                border-bottom: 1px solid $color-gray-12;
                line-height: 88px;
                text-align: center;
                box-sizing: border-box;
            }

            &__title-text {
                font-size: 19px;
                font-weight: 700;
                color: $color-gray-01;
            }

            &__close {
                position: absolute;
                top: 16px;
                right: 0;
                padding: 10px 16px;
                font-size: 0;
                color: transparent;

                &:before {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    background-image: url('data:image/svg+xml,%3Csvg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg id="gnb/ic_gnb_close"%3E%3Cpath id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M27.5302 9.52974C27.8232 9.23692 27.8233 8.76205 27.5305 8.46908C27.2377 8.17611 26.7628 8.17598 26.4698 8.4688L17.9976 16.9366L9.53032 8.46966C9.23742 8.17677 8.76255 8.17678 8.46966 8.46968C8.17677 8.76258 8.17678 9.23745 8.46968 9.53034L16.9367 17.997L8.46981 26.4594C8.17684 26.7522 8.17671 27.2271 8.46953 27.5201C8.76235 27.813 9.23722 27.8132 9.53019 27.5203L17.9973 19.0576L26.4697 27.5296C26.7626 27.8225 27.2375 27.8225 27.5303 27.5296C27.8232 27.2367 27.8232 26.7618 27.5303 26.4689L19.0583 17.9972L27.5302 9.52974Z" fill="%23333333"/%3E%3C/g%3E%3C/svg%3E%0A');
                    vertical-align: top;
                    content: "";
                }
            }
        }
    }

    &--slidedown,
    &--slideup {
        transition-duration: 0.5s;
        transition-property: transform;
    }

    &--slidedown {
        z-index: 300;
        transform: translate3d(0, calc(100% - var(--height-percent)), 0);
    }

    &--slideup {
        z-index: 600;
        transform: translate3d(0, var(--height-percent), 0);
    }

    &--more {
        height: auto;

        &.c-bottom-drawer--slideup,
        &.c-bottom-drawer--slidedown {
            transition-duration: 0.2s;
        }
    }
}

@keyframes aifeedFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
