@import "../../roadshop/sprites/sp_icon";

[data-type="ProductList_Best_Outlet"] {
    letter-spacing: 0;

    @include grid(2, 2, false);

    &.l-grid__row {
        background-color: #fff;
    }

    .l-grid__col--12 {
        width: 100%;
        float: none;
        .c-cap {
            @include size(35px 88px);
            float: left;
            display: block;
            padding-top: 30px;
            &__rank {
                position: relative;
                display: block;
                left: initial;
                top: initial;
                line-height: initial;
                width: 73%;
                font-size: 22px;
                font-style: normal;
                text-align: center;
                &-rising {
                    top: -7px;
                    left: -2px;
                    line-height: 1;
                    &:before {
                        top: 3px;
                    }
                }
            }
        }
        .b-card--recommand {
            .c-card.c-card__gallery {
                min-height: 88px;
                padding-bottom: 12px;
                border-bottom: 1px solid #f1f1f1;
                .c-card__thumb {
                    width: 88px !important;
                    height: 88px !important;
                    margin: 0;
                    float: left;
                }
                .c-prd-info {
                    padding-top: 0;
                    padding-left: 8px;

                    &__name {
                        font-size: 12px;
                        color: #888;
                    }

                    .c-prd-info__rate {
                        margin-top: 5px;
                        padding-right: 5px;
                    }

                    .c-prd-info__price {
                        margin-top: 5px;
                        min-height: 34px;
                    }

                    .c-prd-info__benefit {
                        margin-top: 5px;
                    }
                }
            }
            .c-like-button--product {
                @include c-like-button--44;
                width: 40px;
                height: 40px;
                position: absolute;
                left: 83px;
                top: 48px;
            }
        }
        .c-relates {
            background-color: #fff;
            border-top: 0;
            padding: 0;

            .c-relates__link-text {
                font-size: 13px;
                &:after {
                    transition: .3s;
                }
                &.close {
                    &:after {
                        transform: rotate(315deg);
                        margin-top: -1px;
                    }
                }
            }
        }
    }

    [data-type="GridScroll_ImgText_CtgrNavi"] + & {
        margin-top: 8px;
    }
}
