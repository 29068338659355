[data-type="Tabs_TextBar_Scroll"] {
    * {
        font-family: $font-family3;
    }

    // skpui.magnet 기능 사용시
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .c-magnet--fixed & {
        width: 100%;
        z-index: 1;
    }
    .is--active {
        .c-tablist:before {
            display: block;
        }
        .c-tablist__underline {
            padding-top: 13px;
            padding-bottom: 12px;
        }
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 100;
            border-bottom: 1px solid #eee;
        }
        &--absolute {
            position: absolute;
        }
    }

    .c-tablist {
        display: flex;
        align-items: stretch;
        width: 100%;
        background-color: $color-gray-14;

        // sticky 케이스에서 인접한 블럭에 box-shadow가 비춰 보이는 문제가 있어 before로 그린다
        &:before {
            content: "";
            display: none;
            position: absolute;
            top: 80%;
            left: 0;
            width: 100%;
            height: 20%;
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
        }

        &__wrap {
            flex: 1;
            overflow-x: auto;
            position: relative;
            background-color: $color-gray-14;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__list {
            display: table;
            padding: 0 14px;
            background-color: $color-gray-14;
            white-space: nowrap;
        }
        &__item {
            display: table-cell;
            vertical-align: top;
        }
        &__link {
            display: block;
            padding: 0 8px;
            color: $color-gray-02;
            font-size: 14px;
            line-height: 17px;
            border: 0;
            outline: 0;
            background-color: transparent;

            &[aria-selected="true"] {
                color: $color-11st-red;
                font-weight: 600;

                .c-tablist__underline {
                    border-color: $color-11st-red;
                }
            }
        }
        &__underline {
            display: block;
            padding: 9px 0 8px;
            border-bottom: 2px solid transparent;
        }
    }
    .c-n-dropdown {
        flex-shrink: 0;
        margin-right: 4px;
        background-color: $color-gray-14;

        &:before {
            top: 50%;
            right: 100%;
            bottom: auto;
            width: 16px;
            height: 18px;
            border-right: 1px solid $color-gray-12;
            background: linear-gradient(90deg, rgba($color-gray-14, 0), rgba($color-gray-14, 1));
            transform: translateY(-50%);
        }

        &__button {
            padding: 0 28px 1px 12px;
            font-size: 14px;
            font-weight: 700;
        }
        &__option {
            height: 100%;

            &:after {
                right: 14px;
                width: 8px;
                height: 14px;
                margin: -7px 0 0 0;
                border: 0;
                background: url("/MW/img/pui/ic_chevron_right_dark.png") no-repeat 0 0;
                background-size: 100%;
                transform: rotate(90deg);
            }
        }
        &__list {
            top: 100%;
            left: auto;
            right: 0;
        }
        &__item {
            font-size: 14px;

            &[aria-selected="true"] {
                background-color: #fafafa;
            }
        }

        &--selected {
            z-index: 30;

            .c-n-dropdown__option:after {
                transform: rotate(270deg);
            }
        }
    }
}
