[data-type="PageTitleA"] {
    margin-bottom: 16px;
    border-bottom: 1px solid $color-gray-11;

    .c-headline {
        &__title {
            span,
            em {
                color: #2cb1f8;
            }
        }
    }
}
