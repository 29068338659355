[data-type="Tab_Text_Scroll_Black"] {
    .c-tab {
        background-color: #222;
        .c-tab__list {
            display: table;
            position: relative;
            z-index: 2;
            width: 100%;
            padding: 0;
        }
        .c-tab__item {
            height: 44px;
            line-height: 44px;
            border-color: #222;
            background: none;
            width: auto;
            .c-tab__link {
                display: block;
                border-color: #222;
                background: #222;
                color: #878787;
                height: 44px;
                line-height: 44px;
                padding: 0 14px;
                text-align: center;
                white-space: nowrap;
            }
            &.on {
                .c-tab__link {
                    font-weight: bold;
                    color: #fff;
                }
            }
        }
    }
}
