[data-type="RoundButton_Refresh"] {
    display: inline-block; // 마지막 블럭인 경우 마진 통합 이슈 수정
    width: 100%;
    padding: 0 16px;
    vertical-align: top;

    // api 구조 문제로 c-relates 사용하지 않음
    .c-refresh {
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            border: 1px solid $color-gray-10;
            background-color: $color-gray-14;
            border-radius: 44px;
            color: $color-gray-03;
            font-size: 15px;
            font-family: $font-family3;
            line-height: 20px;
            letter-spacing: 0;

            &:after {
                @include sprite-retina($sp_icons_reloading_point);
                content: "";
                display: block;
                margin-left: 7px;
            }
        }
        &__point {
            color: $color-11st-blue;
        }
        &__light {
            color: $color-gray-06;
        }
    }
}
