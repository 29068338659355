[data-type="LineBanner_Expand"] {
    .c-banner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end; // 배너 확장시에 viewport가 아래에 있어야 함
        overflow: hidden;
        position: relative;
        margin: 16px;
        margin-top: 0;
        padding-top: 16px;
        box-sizing: content-box;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &--animated {
            .c-banner-preview {
                &__text,
                &__lottie {
                    animation: LineBannerExpandFadeOut 0.5s ease-in-out 0.25s 1 forwards;
                }
                &__image {
                    animation: LineBannerExpandSlideDown 0.5s ease-in-out 0.15s 1 forwards;
                }
            }
        }
    }
    .c-banner-preview {
        position: relative;

        &__link {
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 70px;
            padding-right: 136px;
            padding-left: 24px;
            border: 0;
            background-color: transparent;
            text-align: left;
            outline: 0;
            border-radius: 4px;
        }
        &__text {
            overflow: hidden;
            display: block;
            width: 100%;
            color: $color-gray-02;
            font-size: 13px;
            line-height: 16px;
        }
        &__highlight {
            overflow: hidden;
            display: block;
            height: 18px;
            margin-bottom: 4px;
            color: $color-gray-02;
            font-size: 15px;
            line-height: 18px;
        }
        &__desc {
            display: block;
            width: 100%;
            height: 16px;
            line-height: 16px;
        }
        &__image {
            position: absolute;
            bottom: 0;
            right: 12px;
            z-index: 10;
            width: 100px;
            height: 86px;
        }
        &__lottie {
            position: absolute;
            right: 8px;
            bottom: 10px;
            z-index: 20;
            width: 68px;
            height: 26px;

            .lottie-container {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &__play {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 20;
            border: 0;
            display: flex;
            align-items: end;
            padding-bottom: 10px;
            padding-right: 8px;
            background-color: transparent;

            .c-banner-preview__lottie {
                position: static;
            }
        }

        // Type B 이미지 배너
        &--full {
            .c-banner-preview {
                &__link {
                    padding: 0;
                }
                &__image {
                    right: 50%;
                    width: auto;
                    height: 100%;
                    transform: translateX(50%);
                }
            }
        }
    }

    .c-banner-drawer {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 30;
        width: 100%;
        border-radius: 4px;

        &__button {
            position: absolute;
            right: 14px;
            bottom: 12px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 58px;
            padding-top: 6px;
            padding-bottom: 5px;
            color: $color-gray-01;
            font-size: 13px;
            line-height: 1em;
            background-color: $color-gray-14;
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.28), 0 2px 6px rgba(0, 0, 0, 0.06);
            border-radius: 4px;

            &:after {
                @include ico-arrow-right(6px, $color-gray-01, 1px);

                margin-left: 2px;
            }
        }
        &__close {
            @include btn-close-x(16px, 1.5px, $color-gray-14, 2);

            position: absolute;
            top: 0;
            right: 0;
            z-index: 20;
            width: 40px;
            height: 40px;
            border: 0;
            background-color: transparent;
            outline: 0;
        }
    }
    .c-banner-stillcut {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 30;
        // padding-top: 55.97%;
        background-color: $color-gray-02;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            background-color: rgba(0, 0, 0, 0.3);
        }
        &:after {
            content: "";
            display: block;
            width: 100%;
            padding-top: 55.97%;
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__dimmed {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            background-color: #000;
            opacity: 0.3;
            transition-duration: 300ms;
            transition-property: opacity;
        }

        &--normal:before {
            display: none;
        }
    }
    .c-banner-video {
        overflow: hidden;
        position: relative;
        padding-top: 55.97%;
        background-color: $color-gray-02;

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__button {
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 20;
            background-color: transparent;
            border: 0;
            color: transparent;
            outline: 0;
            transform: translate(-50%, -50%);

            &--play {
                @include get-sprite-common("icon_play", 60);
            }
            &--pause {
                @include get-sprite-common("icon_pause", 60);
            }
        }
        &__mute {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
            padding: 8px;
            background-color: transparent;
            border: 0;
            color: transparent;
            outline: 0;

            &:before {
                content: "";
                @include get-sprite-common("icon_speaker_mute", 24);
            }

            &[aria-checked="false"]:before {
                @include get-sprite-common("icon_speaker", 24);
            }
        }
        &__link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
        }
    }
}

@keyframes LineBannerExpandFadeOut {
    100% {
        opacity: 0;
    }
}
@keyframes LineBannerExpandSlideDown {
    100% {
        opacity: 0;
        transform: translateY(66px);
    }
}
