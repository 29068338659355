[data-type="Tabs_RedBoxBtn_Col3"] {
    padding: 0 16px;

    .c-tab-box-fill {
        .on .c-tab__link {
            border: 1px solid transparent;
            background: $color-11st-gradient;
            font-weight: bold;
            color: $color-gray-14;
            border-radius: 4px;
        }
    }
}
