@import "../../roadshop/block/b-card--roadshop";

[data-type="BannerContents_Logo_Outlet"] {
    // @include grid(1, 1, false);
    .c-imgcover {
        position: relative;
        height: 100px;
        padding-top: 0;
        &__info {
            display: block;
            width: 100%;
            padding-top: 0;
            text-align: center;
            z-index: 2;
        }
        &:after {
            display: none;
        }
        &__bi {
            display: block;
            padding-top: 20px;
            width: 80px;
            height: 60px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 36px;
            img {
                display: block;
                width: 80px;
                height: 30px;
                margin: 0 auto;
            }
        }
        &__title {
            display: block;
            color: #000;
            text-align: center;
        }
    }
    .c-like-button--product {
        @include c-like-button--44;
        position: absolute;
        right: 4px;
        top: 4px;
    }
}
