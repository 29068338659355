[data-type="Billboard_Luxury"] {
    position: relative;

    .c-billboard {
        overflow: hidden;
        position: relative;
        width: 100%;
        background-color: #000;

        &__image {
            position: relative;
            z-index: 10;
            display: block;
            width: 100%;
        }
        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 30;
            width: 100%;
            padding: 0 48px 54px 24px;
        }
        &__flag {
            overflow: hidden;
            display: inline-block;
            max-width: 160px;
            height: 22px;
            padding-right: 7px;
            padding-left: 7px;
            border: 1px solid #fff;
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
            font-size: 13px;
            line-height: 20px;
            white-space: nowrap;
            vertical-align: top;
        }
        &__headline {
            overflow: hidden;
            display: block;
            max-height: 78px;
            margin-top: 8px;
            color: #fff;
            font-size: 32px;
            font-weight: 600;
            font-family: $font-outfit;
            line-height: 39px;
            word-break: keep-all;
            word-wrap: break-word;
        }
        &__desc {
            overflow: hidden;
            display: block;
            height: 19px;
            margin-top: 8px;
            color: #fff;
            font-size: 15px;
            line-height: 19px;
        }
        &__flag,
        &__headline,
        &__desc {
            opacity: 0;
            transition-property: all;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0.17, 0.17, 0.67, 1);
            transform: translateX(100px);
        }
        &__flag {
            transition-delay: 0.36s;
        }
        &__headline {
            transition-delay: 0.4s;
        }
        &__desc {
            transition-delay: 0.44s;
        }
    }
    .c-pagination {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 30;
    }

    .c-board-status {
        position: absolute;
        bottom: 16px;
        right: 0;
        left: 24px;
        z-index: 10;
        display: flex;
        align-items: center;
        height: 16px;
    }
    .c-board-pagination {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        width: 53px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;

        &__current {
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 1);
            font-family: $font-outfit;

            &:after {
                content: "";
                width: 1px;
                height: 9px;
                margin-right: 4px;
                margin-left: 4px;
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
        &__total {
            font-family: $font-outfit;
        }
    }
    .c-board-progress {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 2px;

        &,
        &::-webkit-progress-bar {
            background-color: rgba($color-gray-14, 0.2);
        }
        &::-webkit-progress-value {
            background-color: $color-gray-14;
        }
        &--animated::-webkit-progress-value {
            transition: width 0.5s linear;
        }
    }

    .animated {
        .c-billboard__flag,
        .c-billboard__headline,
        .c-billboard__desc {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
