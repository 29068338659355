.c-n-chip {
    overflow: hidden;
    display: inline-block;
    align-items: center;
    height: 32px;
    padding-right: 12px;
    padding-left: 12px;
    border: 1px solid #eee;
    color: #111;
    font-size: 14px;
    font-family: $font-family3;
    line-height: 32px;
    background-color: #fff;
    border-radius: 4px;
    white-space: nowrap;
    text-align: center;
    box-sizing: border-box;

    &--selected,
    &[aria-selected="true"] {
        color: $color-luxury;
        font-weight: 600;
        border-color: rgba(14, 41, 217, 0.06);
        background-color: #f2f2ff;
    }
}

.c-n-chip-dark {
    overflow: hidden;
    display: inline-block;
    align-items: center;
    min-width: 44px;
    height: 32px;
    padding-right: 16px;
    padding-left: 16px;
    border: 0;
    color: #111;
    font-size: 15px;
    font-family: $font-family3;
    font-weight: 600;
    line-height: 32px;
    background-color: #fafafa;
    border-radius: 32px;
    white-space: nowrap;

    &--selected,
    &[aria-selected="true"] {
        color: #fff;
        background-color: #111;
    }
}
