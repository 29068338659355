.c-product-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__list {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: 12px;
    }
    &__item {
        color: $color-gray-03;
        font-size: 12px;
        font-family: $font-family3;
        line-height: 12px;
    }
    &__item + .c-product-summary__item:before {
        content: "";
        display: inline-block;
        width: 2px;
        height: 2px;
        margin: -2px 5px 2px;
        background-color: $color-gray-08;
        border-radius: 50%;
        vertical-align: middle;
    }
    &__highlight {
        color: $color-11st-blue;
    }
}
