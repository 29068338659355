@import "../../common/sprites/sp_sns_share2";

.c-button {
    @include border-radius(2px);

    display: inline-block;
    min-width: 44px;
    height: 48px;
    padding: 0 8px;
    border: 0;
    border-radius: 4px;
    font-size: 17px;
    line-height: 48px;
    text-align: center;

    &.c-button__size-speciality-large {
        height: 60px;
        font-size: 20px;
        line-height: 60px;
    }

    &.c-button__size-speciality {
        height: 56px;
        font-size: 19px;
        line-height: 56px;
    }

    &.c-button__size-primary {
        height: 48px;
        font-size: 17px;
        line-height: 48px;
    }

    &.c-button__size-secondary {
        height: 44px;
        font-size: 16px;
        line-height: 44px;
    }

    &.c-button__size-extra-large {
        height: 40px;
        padding: 0 16px;
        font-size: 16px;
        line-height: 40px;
    }

    &.c-button__size-large {
        height: 36px;
        padding: 0 12px;
        font-size: 15px;
        line-height: 36px;
    }

    &.c-button__size-normal {
        height: 32px;
        font-size: 14px;
        line-height: 32px;
    }

    &.c-button__size-small {
        height: 28px;
        font-size: 13px;
        line-height: 28px;
    }

    &.c-button__size-extra-small {
        height: 24px;
        font-size: 11px;
        line-height: 24px;
    }

    &--full {
        border-radius: 0;
    }

    &--share {
        @include size(32px);

        overflow: hidden;
        position: relative;
        padding: 0;
        margin: 0;
        border: 1px solid $color-gray-10;
        border-radius: 24px;
        background: $color-gray-14;
        line-height: 32px;
        outline: none;
        color: transparent;

        &[type="button"] {
            border-radius: 24px;
        }

        &:after {
            @include button-share;
            position: absolute;
        }
    }
}

.c-button__primary {
    border: 1px solid $color-11st-red;
    background: $color-11st-red;
    color: $color-gray-14;
}

.c-button__alert {
    border: 1px solid $color-11st-red;
    background: $color-gray-14;
    color: $color-11st-red;
}

.c-button__normal {
    border: 1px solid $color-gray-09;
    background: $color-gray-14;
    color: $color-gray-03;
}

.c-button__dimned {
    border: 1px solid rgba(204, 204, 204, 0.2);
    background: $color-gray-13;
    color: $color-gray-09;
}

.c-iconinfo {
    @include size(20px 28px);

    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: -1px 0 1px;
    padding: 0;
    border: 0;
    background: transparent;
    color: transparent;
    vertical-align: middle;
    outline: none;

    &--help {
        &:after {
            @include sprite-retina($sp_eui_info);

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
        }
    }
}
