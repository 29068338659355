[data-type="ProductGrid_Standard"] {
    margin-bottom: -1px;

    @media screen and (min-width: 640px) {
        display: flex;
        margin-bottom: 0;
        flex-wrap: wrap;
    }

    .l-grid__col {
        margin-bottom: -1px;
        border-bottom: 1px solid #f4f4f4;

        &.l-grid__col--12 {
            &.medium-6 {
                @media screen and (min-width: 640px) {
                    & {
                        &:nth-child(odd) {
                            padding-right: 0;
                        }

                        &:nth-child(even) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
    .c-flex {
        display: flex;

        @media screen and (min-width: 640px) {
            & {
                height: 100%;
            }
        }
        &__item {
            position: relative;
            padding: 10px;
            width: 50%;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 1px;
                background: $color-gray-12;
                content: "";
            }
            &:before {
                left: 0;
            }
            &:after {
                right: -1px;
            }
            &:first-child {
                &:before {
                    display: none;
                }
            }
        }
    }
    .c-item-cap {
        margin-top: -10px;
        padding-right: 6px;
    }
    .c-card-item {
        &--gallery {
            background: none;

            .c-relates {
                margin-bottom: -15px;

                &__icon {
                    margin-top: -1px;
                    margin-bottom: 1px;
                }

                &__button-link--activated {
                    height: 43px;
                    padding: 10px 20px 10px 0;

                    &:after {
                        border-color: #333;
                    }
                }
            }
        }
        &--space {
            height: 100%;
            padding-bottom: 41px;

            .c-card-item__cart,
            .c-card-item__addition,
            .c-relates {
                position: absolute;
                left: 6px;
                right: 6px;
                bottom: 6px;
            }
            .c-card-item__addition-link {
                &:after {
                    border-color: $color-gray-03;
                }
            }
            .c-prd__count {
                margin-right: 36px;
            }
        }
        &__thumb {
            img {
                width: 100%;
                object-fit: scale-down;
            }
        }
        &__info {
            padding: 12px 6px 6px;
            background: none;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 36px;
            margin-bottom: 6px;
            line-height: 18px;
        }
        &__rate {
            strong {
                font-size: 15px;
                font-weight: normal;
                line-height: 18px;
                white-space: nowrap;
            }
        }
        &__price-del {
            display: inline-block;
            font-size: 12px;
            color: $color-gray-08;
            line-height: 18px;
            vertical-align: top;

            del {
                font-size: 15px;
            }
        }
        &__bargain {
            & + .c-card-item__price-info {
                margin-top: 0;
            }
        }
        &__price {
            display: block;
            height: auto;
            margin-top: 1px;
            font-size: 14px;
            clear: both;

            strong {
                margin: -1px 0 1px;
                font-size: 20px;
                line-height: 22px;
                vertical-align: middle;
            }
        }
        &__counsel {
            margin: 10px 0;
            padding: 0;
            line-height: 19px;

            em {
                font-size: 15px;
            }
        }
        &__fluid {
            margin-top: 10px;

            span,
            strong {
                display: inline-block;
                vertical-align: top;
            }
            &-info {
                line-height: 20px;
            }
            &-price {
                margin-top: 2px;
                line-height: 17px;
            }
        }
        &__benefit {
            height: auto;
            margin-top: 4px;
            font-size: 14px;
            color: $color-gray-04;
            line-height: 19px;

            .benefit {
                display: block;
                overflow: hidden;
                margin-top: 2px;
                padding-top: 0;
                line-height: 17px;
            }
            .sktpass {
                overflow: hidden;
                height: 20px;
                margin-top: 1px;
                font-size: 14px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
            .retail-delivery {
                margin-top: 1px;
                padding-top: 0;
                font-size: 13px;

                strong {
                    &:before {
                        @include size(21px 15px);
                        background-size: 21px 15px;
                        margin: -2px 2px 2px 0;
                    }
                }
            }
            .shooting-delivery {
                strong:before {
                    @include get-sprite-common("shooting_delivery", 55);
                }
            }
            .shooting-install {
                strong:before {
                    @include get-sprite-common("shooting_install", 53);
                }
            }
            .shooting-fresh {
                strong:before {
                    @include get-sprite-common("shooting_fresh", 55);
                }
            }
            .shooting-plus {
                strong:before {
                    @include get-sprite-common("shooting_plus", 85);
                }
            }
            .shooting-seller {
                strong:before {
                    @include get-sprite-common("shooting_seller", 55);
                }
            }
            .shooting-delivery,
            .shooting-install,
            .shooting-fresh,
            .shooting-plus,
            .shooting-seller {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 1px;
                min-height: 17px;
                margin-top: 2px;
                strong {
                    display: flex;
                    align-items: center;
                    height: 17px;
                    margin: 0 4px 0 0;
                    background: 0;
                    font-size: 1px;
                    &:before {
                        display: block;
                        flex-shrink: 0;
                        content: "";
                    }
                }
                .c-card-item__point {
                    margin-right: 4px;
                    line-height: 17px;
                }
                .benefit {
                    max-height: 17px;
                    margin-top: 0;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &__advertise {
            overflow: hidden;
            height: 19px;
        }
        &__button {
            top: 7px;
            margin-top: 0;

            .c-like-button {
                margin-top: 0;
            }
        }
        &__recent {
            @include text-ellipsis;

            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            height: 23px;
            padding: 0 6px;
            color: #fff;
            font-size: 12px;
            line-height: 24px;
            background-color: rgba(73, 163, 199, 0.9);
            box-sizing: border-box;
        }
        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .c-flag-box {
            margin-bottom: 3px;
            height: auto;
            max-height: 44px;
        }
        .c-ad__btn {
            position: absolute;
            left: 0;
            bottom: -1px;
            z-index: 5;
        }
        &--soldout {
            .c-card-item__swatch + .c-card-item__soldout-text {
                height: calc(100% - 38px);
            }
            .c-card-item__counsel {
                em {
                    font-size: $font-size-subtitle2;
                    color: $color-gray-04;
                }
            }
        }
    }
    .c-starrate {
        margin: 6px 0;
    }
    &.l-grid__row--background {
        .l-grid__col {
            border-bottom: 0;
        }
        .c-flex__item {
            & + .c-flex__item {
                &:before {
                    display: none;
                }
            }
        }
    }

    .c-flag__item--ooah,
    .c-flag__item--freshtable,
    .c-flag__item--mart-plus {
        margin-top: 3px;
    }

    // 통합추천 모듈
    .c-theme-card {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #fff;
        color: #111;
        text-align: center;
        box-sizing: border-box;

        &__thumb {
            position: relative;
            width: 100%;
        }

        &__gallery {
            flex-shrink: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto 16px;
            width: 100%;
            background-color: #fff;

            .c-theme-card__image {
                position: relative;
                border-radius: 0;
            }
            .c-theme-card__thumb {
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.04);
                }
                &--small {
                    width: calc(50% - 1px);
                    margin-top: 2px;
                }
            }
        }
        &__image {
            display: block;
            width: 100%;
            border-radius: 50%;
        }
        &__info {
            padding-right: 10px;
            padding-left: 10px;
        }
        &__desc {
            @include text-ellipsis;

            margin-bottom: 14px;
            font-size: 13px;
            line-height: 16px;
        }
        &__title {
            @include text-ellipsis;

            display: block;
            font-size: 17px;
            line-height: 20px;
        }
        &__hash-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 8px;
        }
        &__hash {
            @include text-ellipsis;

            display: inline-block;
            max-width: 100%;
            height: 22px;
            margin: 2px;
            padding-right: 6px;
            padding-left: 6px;
            color: $color-gray-04;
            font-size: 12px;
            line-height: 23px;
            text-align: left;
            background-color: $color-gray-12;
            vertical-align: top;
        }
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 32px;
            margin: 22px auto 0;
            border: 1px solid rgba(255, 255, 255, 0.6);
            border-radius: 32px;
            color: #fff;
            font-size: 13px;
            line-height: 16px;
            text-align: center;

            &:after {
                content: "";
                width: 7px;
                height: 7px;
                margin-top: -2px;
                margin-left: 5px;
                border: 1px solid #fff;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
            }
        }
        &__relates {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 47px;
            padding-right: 6px;
            padding-left: 6px;
            color: #333;
            font-size: 14px;
            line-height: 22px;
            background-color: #fff;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 6px;
                left: 6px;
                height: 1px;
                background-color: $color-gray-12;
            }

            &:after {
                content: "";
                width: 7px;
                height: 7px;
                border: 1px solid $color-gray-03;
                margin: -2px 10px 0 auto;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
            }

            .text-hightlight {
                margin-right: 4px;
            }
        }
        &__link {
            overflow: hidden;
            display: block;
            width: 100%;
            height: 100%;
        }
        &__video {
            overflow: hidden;
            position: relative;
            height: 100%;
            min-height: 356px;
            background-color: $color-gray-14;

            video {
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                height: calc(100% + 3px); // 영상 영역 하단에서 컨텐츠가 비쳐 보이는 이슈 수정
                object-fit: cover;
            }
        }
        .text-highlight {
            color: $color-11st-blue;
        }
        .icon-brand {
            display: flex;
            align-items: center;
            margin-right: 4px;

            &:before {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                margin-right: 4px;
                background: url("/MW/img/common/ico-brand.png") no-repeat 0 0;
                background-size: 100% auto;
                vertical-align: middle;
            }
        }

        &--category {
            color: #fff;
            background-color: #666;
            border-radius: 4px;

            .c-theme-card {
                &__hash {
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.12);
                }
                &__thumb {
                    overflow: hidden;
                    width: 126px;
                    height: 126px;
                    margin: 0 auto 16px;
                    padding: 6px;
                    background-color: #fff;
                    border-radius: 50%;
                }
                &__link {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
            .c-lazyload {
                border-radius: 50%;
            }
        }
        &--brand {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;

            .c-theme-card__info {
                padding-bottom: 18px;
            }
            .c-theme-card__relates {
                margin-bottom: -10px;
            }
        }
        &--banner {
            .c-theme-card__link {
                border-radius: 4px;
            }
            .c-lazyload {
                padding-top: 212.5%;
            }
        }
    }
}
