[data-ui-type="Contents_Pagination"] {
    margin-top: -1px;
    padding: 13px 0;
    border-top: 1px solid $color-gray-12;
    background: $color-gray-13 !important;

    .c-pagination {
        &__list {
            li {
                vertical-align: middle;
                a {
                    position: relative;
                    font-size: 0;
                }
            }
        }
        &__active {
            padding-left: 8px;
            color: $color-11st-red;
        }
    }
}
