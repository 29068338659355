// Modified Amazon
[data-type="ProductCard_TimeDeal_Scroll_Box"],
[data-type="ProductScroll_Box"],
[data-type="ProductGrid_ImageBox"],
[data-type="ExhibitionBanner_ProductList"],
[data-type="ProductList_HistoryBox"],
[data-type="ProductList_Module"],
[data-type="ProductList_ReviewBox"],
[data-type="ProductList_BookBox"],
[data-type="ProductCard_Deal_Scroll_Box"],
[data-type="ProductCard_NewDealBox"],
[data-type="ProductList_RankingBox"],
[data-type="ContentGrid_Category_Col2"],
[data-type="ExhibitionBanner_BrandList"],
[data-type="ProductGrid_Standard"] {
    .c-card-item__thumb {
        .c-lazyload {
            img {
                width: 100%;
                object-fit: scale-down;
            }
        }
    }
}
