// emblem base
@mixin aside-emblem {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
    height: 20px;
    border-radius: 4px;
    color: $color-gray-14;
    white-space: nowrap;
    // bg
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(90deg, var(--flagBgColor, #{rgba($color-gray-01, 0.5)}) 0%, var(--flagGradationColor, #{rgba($color-gray-01, 0.5)}) 100%);
    }
}

// text
@mixin aside-emblem-text {
    position: relative;
    font-weight: 700;
    font-size: 11px;
    margin-right: 4px;
    &:only-child {
        margin: 0 5px;
    }
}

// icon base
@mixin aside-emblem-icon {
    position: relative;
    flex-shrink: 0;
    margin-left: 3px;
    font-size: 1px;
    color: transparent;
}
