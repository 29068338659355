@charset "UTF-8";

.c-aside-search {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 50;
    display: flex;
    padding: 8px 16px;
    background-color: $color-gray-14;

    &__form {
        flex: 1;
        overflow: hidden;
        position: relative;
        height: 40px;
        padding-right: 42px;
        padding-left: 16px;
        background-color: #f8f8fb;
        border-radius: 40px;
    }
    &__input[type="text"] {
        // width: 124%;
        width: 120%;
        height: 100%;
        color: $color-gray-01;
        font-size: 16px;
        background-color: transparent;
        border: 0;
        outline: 0;
        transform: scale(0.81);
        transform-origin: 0 50%;

        &::placeholder {
            color: rgba($color-gray-01, 0.4);
        }
    }
    &__button {
        position: absolute;
        top: 50%;
        right: 4px;
        padding: 10px;
        border: 0;
        background-color: transparent;
        transform: translateY(-50%);
    }
    &__icon {
        @include get-sprite-common("ic_search", 20);

        color: transparent;
        font-size: 10px;
    }
    &__menu {
        display: flex;
        align-items: center;
        padding-left: 8px;
    }

    .c-gnb__cart {
        position: relative;
        top: 0;
        right: 0;
        display: block;
    }

    // 마트 사이드메뉴 검색창 해제
    @at-root .wrap-mart .c-aside-search {
        display: none;
    }
}
