[data-type="ProductGrid_BestLive"] {
    padding: 0 10px 10px;

    .l-grid__col {
        padding: 0 6px 12px;
    }

    .c-card-item {
        @include rounding-box;
        .c-item-cap {
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 100;
            padding: 0;
            height: 24px;

            &__rank {
                @include size(24px);
                background-color: $color-11st-red;
                border-radius: 4px;
                color: #fff;
                font-weight: bold;
            }
        }

        &__info {
            min-height: 116px;
            padding: 12px 12px 8px;
        }

        &__info--has-link {
            padding: 0;
            position: relative;
            min-height: auto;
            a {
                display: block;
                min-height: 116px;
                padding: 12px 12px 8px;
            }
            .c-profile {
                position: absolute;
                bottom: 10px;
                left: 12px;
            }
        }
        &__thumb {
            video {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__thumb-link {
            display: block;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0.2;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 11;
                width: 100%;
                height: 98px;

                background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }

        &__mini-card {
            display: flex;
            padding: 0;
            overflow: hidden;
            position: absolute;
            z-index: 91;

            left: 10px;
            bottom: 12px;
            right: 10px;
            font-size: 14px;

            &-thumb {
                display: block;
                @include size(40px);
                margin-right: 8px;
                min-width: 40px;

                .c-lazyload {
                    &::before {
                        border-radius: 4px;
                    }
                }

                img {
                    border-radius: 2px;
                }
            }

            dl {
                display: flex;
                flex-direction: column;
                height: 42px;
                align-content: center;
                justify-content: center;
                .point {
                    color: $color-11st-blue;
                }
                dt {
                    color: $color-gray-03;
                    @include sr-only;
                }
                dd {
                    line-height: 15px;
                    font-size: 13px;
                    height: 15px;
                    vertical-align: top;
                    overflow: hidden;
                    color: #fff;
                    font-weight: normal;
                    span {
                        font-size: 14px;
                        font-weight: bold;
                    }
                    &:nth-child(4) {
                        font-size: 12px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
        &__name {
            height: auto;
            margin-bottom: 6px;
            color: #333;
        }
        &__info {
            .c-view-count-number {
                color: $color-11st-blue;
                font-size: 14px;
                padding-bottom: 5px;
            }

            .c-profile {
                padding-bottom: 6px;
                padding-right: 0;
                &__link {
                    background-color: transparent;
                    border: 0;
                }
                &__pic,
                &__pic span {
                    @include size(19px);
                }

                &__pic img {
                    @include size(20px);
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                }

                &__name {
                    height: 18px;
                    line-height: 18px;
                    font-size: 14px;
                    color: $color-gray-04;
                }
                .c-lazyload {
                    &::before {
                        background: none;
                    }
                }
            }
        }
    }
}
