@import "../../live11/sprites/sp_icon";

[data-type="ProductList_TimeLine"] {
    @include grid(0, 2, false);
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background-color: $color-gray-11;
        position: absolute;
        top: 30px;
        left: 20px;
        z-index: 1;
    }

    .l-grid__col {
        padding: 0 8px;
        &:last-child {
            padding-bottom: 24px;
        }
    }

    .c-headline {
        &__title {
            height: 51px;
            position: relative;
            font-size: 16px;
            line-height: 34px;
            padding: 11px 16px 11px;
            color: $color-gray-03;
            font-weight: normal;

            > span {
                float: left;
            }

            &::before {
                width: 10px;
                height: 10px;
                content: "";
                display: block;
                position: absolute;
                z-index: 4;
                left: 0;
                top: 21px;
                border-radius: 50%;
                background-color: $color-gray-10;
            }

            &::after {
                width: 4px;
                height: 4px;
                content: "";
                display: block;
                position: absolute;
                z-index: 5;
                left: 3px;
                top: 24px;
                border-radius: 50%;
                background-color: $color-gray-14;
            }
        }
        &__status {
            position: relative;
            padding-left: 8px;
            margin-left: 5px;
            color: $color-gray-07;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                left: 0;
                top: 13px;
                background-color: $color-gray-09;
            }
            em {
                color: $color-11st-blue;
            }
            strong {
                color: $color-11st-red;
                font-weight: initial;
            }
        }
        &__title-active {
            &::before {
                background-color: $color-11st-red;
            }
        }
    }

    .c-card-item {
        @include rounding-box;

        z-index: 1;
        padding: 0;

        &__thumb {
            height: 228px;
            width: 132px;

            @include mq-360 {
                width: 138px;
            }

            @include mq-375 {
                width: 152px;
            }

            a {
                position: relative;
                display: block;
                width: 132px;
                line-height: 0;
                @include mq-360 {
                    width: auto;
                }

                .c-lazyload--ratio_152x228 {
                    padding-bottom: 173%;

                    @include mq-360 {
                        padding-bottom: 165%;
                    }

                    @include mq-375 {
                        padding-bottom: 150%;
                    }
                }

                img {
                    object-fit: cover;

                    @include mq-360 {
                        width: 100%;
                        height: 100%;
                        left: 0;
                    }
                }

                video {
                    width: 152px;
                    height: 228px;
                    object-fit: cover;
                    margin-left: -10px;

                    @include mq-360 {
                        margin-left: 0;
                    }
                }

                &::before,
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: 80;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                &::before {
                    background-color: #000;
                    opacity: 0.2;
                }

                &::after {
                    z-index: 120;
                    height: 74px;
                    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
                }
            }
            &-cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding: 10px 0 0 20px;
                height: 100%;
                line-height: 1.2;
            }

            &-replay {
                width: 20px;
                height: auto;
                top: auto;
                left: auto;
                bottom: 14px;
                right: 22px;
                transform: rotate(92deg);
                > div {
                    width: 20px;
                    height: 2px;
                    background: $color-gray-14;
                    color: transparent;
                    position: absolute;
                    left: 0;
                    top: 0;

                    &::before,
                    &::after {
                        content: " ";
                        display: block;
                        width: 20px;
                        height: 2px;
                        position: absolute;
                        top: -8px;
                        right: -5px;
                        background-color: $color-gray-14;
                    }
                    &::before {
                        transform: rotate(56deg);
                    }
                    &::after {
                        transform: rotate(-56deg);
                        left: -4px;
                    }
                }
            }

            &-passed {
                color: $color-gray-14;
                font-size: 18px;
                line-height: 228px;
                font-weight: bold;
                padding: 0;

                text-align: center;
                width: 100%;
                div {
                    position: relative;
                    z-index: 110;
                    font-weight: bold;
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 101;
                    @include dimned-opacity(0.4);
                }
            }
            &-realtime {
                color: $color-gray-14;
                font-size: 13px;
                .watcher,
                div {
                    position: relative;
                    z-index: 130;

                    em {
                        font-weight: bold;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    z-index: 130;
                    left: 10px;
                    top: 15px;
                    @include size(5px);
                    border-radius: 50%;
                    background-image: linear-gradient(32deg, rgb(255, 5, 0) 23%, rgb(255, 16, 196) 86%);
                }
            }
            &-vod,
            &-ready {
                color: $color-gray-14;
                font-size: 13px;
                .watcher,
                div {
                    position: relative;
                    z-index: 130;
                    em {
                        font-weight: bold;
                    }
                }
                .live_time {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    margin-top: -28px;
                    color: $color-gray-14;
                    font-size: 18px;
                    text-align: center;
                    font-weight: bold;
                    .time {
                        font-size: 26px;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    @include dimned-opacity(0.4);
                }
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 130;
                    left: 10px;
                    top: 15px;
                    @include size(5px);
                    background-image: none;
                    background-color: $color-gray-14;
                    opacity: 0.4;
                    border-radius: 50%;
                }
            }

            &-vod {
                &::before {
                    display: none;
                }
            }

            &--end {
                a {
                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }

            .watcher {
                position: relative;
                z-index: 130;

                em {
                    font-weight: bold;
                }
            }
        }

        &__info {
            padding: 12px 14px;
            .c-profile {
                padding-bottom: 6px;
                padding-right: 0;
                &__link {
                    background-color: transparent;
                    border: 0;
                }
                &__pic,
                &__pic span {
                    @include size(19px);
                }

                &__pic img {
                    @include size(18px);
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                }

                &__name {
                    height: 18px;
                    line-height: 18px;
                    font-size: 14px;
                    color: $color-gray-04;
                }
                .c-lazyload {
                    &::before {
                        background: none;
                    }
                }
            }
        }

        &__name {
            margin-top: 1px;
            font-size: 18px;
            color: $color-gray-02;
            font-weight: bold;
            height: 71px;
            width: 120px;
            @include mq-360 {
                width: auto;
            }

            dd {
                height: 43px;
                margin-bottom: 8px;
                @include text-ellipsis-multiple(2);
                line-height: 23px;
            }

            dd:nth-child(4) {
                height: 17px;
                line-height: 17px;
                width: 160px;
                font-size: 14px;
                font-weight: initial;
                display: block;
                overflow: hidden;
                color: #0b83e6;
            }
        }
        &__button-view {
            position: absolute;
            bottom: 78px;
            left: 14px;
        }
        &__mini-card {
            font-size: 14px;
            position: absolute;
            bottom: 0;
            right: 14px;
            left: 14px;
            display: flex;
            padding: 12px 0;
            border-top: 1px solid $color-gray-12;
            overflow: hidden;

            &-thumb {
                @include size(42px);
                margin-right: 8px;
                min-width: 42px;
                img {
                    border-radius: 4px;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
                    border: 1px solid rgba(0, 0, 0, 0.04);
                }
            }
            a {
                flex: 0 0 1;
            }

            dl {
                display: flex;
                flex-direction: column;
                height: 42px;
                align-content: center;
                justify-content: center;
                .point {
                    color: $color-11st-blue;
                }
                dt {
                    color: $color-gray-03;
                }
                dd {
                    line-height: 15px;
                    font-size: 14px;
                    color: $color-gray-02;
                    height: 15px;
                    vertical-align: top;
                    overflow: hidden;
                    span {
                        font-size: 14px;
                        font-weight: bold;
                    }
                    &:nth-child(4) {
                        font-size: 12px;
                        position: relative;
                        top: 3px;
                        span {
                            float: left;
                        }
                    }
                }
            }
        }
    }

    .c-card-item--list {
        margin-top: 16px;

        .c-card-item__name {
            height: 71px;
        }
    }
    .c-headline + .c-card-item--list {
        margin-top: 0;
    }
    // 라이브 보러가기 버튼

    .c-button {
        &--watch {
            display: inline-block;
            height: 30px;
            padding: 0 22px 0 14px;
            border-radius: 16px;
            position: relative;
            background-image: linear-gradient(to right, #ff0000, #ff10c4);
            color: $color-gray-14;
            border: 0;
            font-weight: normal;
            vertical-align: top;
            font-size: 14px;
            line-height: 30px;

            &__logo {
                width: 30px;
                height: 18px;
                display: inline-block;
                position: relative;
                top: 4px;
            }

            &::after {
                position: absolute;
                right: 12px;
                top: 11px;
                display: block;
                @include ico-arrow-right(6px, #fff, 1px);
                @include rotate(45deg);
            }
        }
        &--watch__replay {
            padding: 0 22px 0 12px;
            background: none;
            border: 1px solid $color-gray-10;
            color: $color-11st-red;

            span {
                font-weight: bold;
                margin-right: 3px;
            }

            &::after {
                @include ico-arrow-right(6px, $color-11st-red, 1px);
                @include rotate(45deg);
            }
        }
        &--watch__alarm {
            padding: 0 14px 0 32px;
            background: none;
            border: 1px solid $color-gray-10;
            color: $color-11st-red;
            &::after {
                display: none;
            }

            &::before {
                content: "";
                position: absolute;
                top: 4px;
                left: 7px;
                width: 20px;
                height: 20px;
            }
            &[aria-checked="false"] {
                &::before {
                    @include sprite-retina($sp_icon_alarm_red);
                }
            }

            &[aria-checked="true"] {
                color: $color-gray-04;
                &::before {
                    @include sprite-retina($sp_icon_alarm_red_on);
                }
            }
        }

        // 알림설정 ui개선 (최종 배포 이후 수정 필요 watch__alarm2 -> watch__alarm)
        &--watch__alarm2 {
            padding: 1px 13px 1px 34px;
            background: none;
            // background-image: linear-gradient(to right, #ff0500, #ff10c4);
            background-color: $color-11st-red;
            color: $color-11st-red;
            span {
                display: inline-block;
                position: relative;
            }
            &::before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 1px;
                bottom: 1px;
                background: #fff;
                border-radius: 16px;
                content: "";
            }
            &::after {
                content: "";
                position: absolute;
                top: 5px;
                left: 10px;
                right: auto;
                width: 20px;
                height: 20px;
                border: none;
                transform: none;
            }
            &[aria-checked="false"] {
                &::after {
                    @include sprite-retina($sp_icon_alarm_red);
                }
            }

            &[aria-checked="true"] {
                color: $color-gray-04;
                background: $color-gray-10;
                &::after {
                    @include sprite-retina($sp_icon_alarm_red2_on);
                }
            }
        }
        &--watch__tooltip {
            position: absolute;
            z-index: 99;
            left: -3px;
            top: 30px;
            color: $color-gray-14;
            font-size: 11px;
            padding: 4px 8px;
            white-space: nowrap;
            border-radius: 13px;
            border: solid 1px rgba(0, 0, 0, 0.04);
            background-image: linear-gradient(101deg, #ff6335, #fe1d30 55%, #ff31a1 87%);

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -8px;
                left: 15px;
                border-width: 0 6px 7px;
                border-bottom-color: #ff6335;
            }
        }
    }
}
