[data-type="Information_PayPoint"] {
    letter-spacing: $body-spacing;
    z-index: 2;
    .l-grid__col {
        .c-box-data {
            display: table;
            width: 100%;
            padding: 0 16px;
            height: 46px;
            line-height: 46px;
            letter-spacing: 0;
            &__key {
                display: table-cell;
                padding-left: 3px;
                line-height: 46px;
                img {
                    height: 21px;
                    width: 93px;
                    vertical-align: middle;
                    margin-top: -3px;
                }
            }
            &__value {
                display: table-cell;
                position: relative;
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: bold;
                text-align: right;
                &-link {
                    color: $color-gray-03;
                    padding-right: 16px;
                    display: inline-block;
                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                        vertical-align: middle;
                        margin-top: -3px;
                    }
                    &:after {
                        @include ico-arrow-right(8px, #999, 1px);
                        position: absolute;
                        right: 2px;
                        top: 19px;
                    }
                }
            }
        }
        .c-linktext {
            border-top: 1px solid #f4f4f4;
            height: 44px;
            line-height: 44px;
            font-size: 13px;
            padding-right: 18px;
            text-align: right;
            &__text {
                position: relative;
                display: inline-block;
                padding-top: 14px;
                padding-right: 12px;
                padding-bottom: 14px;
                &:after {
                    @include ico-arrow-right(8px, #999, 1px);
                    position: absolute;
                    right: 0;
                    top: 18px;
                }
                img {
                    display: block;
                    width: 131px;
                    height: 15px;
                }
                strong {
                    display: inline-block;
                    position: relative;
                    width: 76px;
                    height: 14px;
                    color: transparent;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 15px;
                        display: block;
                        width: 76px;
                        height: 14px;
                        background: url("/MW/img/skpui/tmp/ic_skpoint_gray.png") no-repeat 0 0;
                        background-size: 76px 14px;
                    }
                }
            }
        }
    }
}
