[data-type="Contents_Luxury_No_BrandResult"] {
    .c-n-empty {
        padding: 64px 0;

        &:before {
            display: none;
        }
        &__link {
            display: inline-block;
            min-width: 80px;
            height: 40px;
            margin-top: 19px;
            padding: 0 16px;
            border: 1px solid $color-gray-11;
            border-radius: 4px;
            background: $color-gray-14;
            font-size: 14px;
            font-weight: 500;
            color: $color-gray-02;
            line-height: 40px;
        }
    }
}
