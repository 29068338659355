[data-type="Banner_Pagination_Narrow"] {
    padding: 16px 20px;

    .c-banner {
        &__link {
            display: block;
        }
        &__wrap {
            position: relative;
            color: $color-gray-14;
            font-size: 14px;
        }
        &__content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 24px 10px 12px;
            background: linear-gradient(180deg, rgba($color-gray-01, 0.1) 0, rgba($color-gray-01, 0.3) 100%);
            transform: translate3d(0, 0, 0);
        }
        &__desc {
            overflow: hidden;
            display: block;
            max-width: 160px;
            height: 13px;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px;
            word-break: keep-all;
        }
        &__highlight {
            overflow: hidden;
            display: block;
            max-width: 204px;
            height: 17px;
            margin-top: 3px;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            word-break: keep-all;
        }
        &__image {
            position: relative;
            z-index: 10;
            padding-bottom: 50%;
        }
        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .c-pagination {
        position: absolute;
        top: 10px;
        right: 12px;
        z-index: 10;

        &__indicator {
            display: flex;
            line-height: 0;

            span {
                margin-left: 3px;
            }
        }
    }
    .c-lazyload,
    .c-lazyload img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .c-skeleton {
        padding: 16px 20px;

        &__box {
            display: block;
            padding-bottom: 50%;
            background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
            animation: none;
        }
    }
    .w-swiper {
        overflow: hidden;
        border-radius: 8px;
    }
}
