.page_header{
    position: relative;
    letter-spacing: 0;
}

.page_title{
    overflow: hidden;
    width: 100%;
    height: 56px;
    padding: 0 55px;
    background: #fff;
    border-bottom: 1px solid #d1d1d6;
    color: #111;
    font-size: 18px;
    line-height: 56px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;

    .page_header_default &{
        padding: 0 55px 0 16px;
        text-align: left;
    }

    .page_header_default .page_prev + &,
    .page_header_default .page_back + & {
        padding-left: 52px;
    }
}

.page_close {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: 55px;
    border: none;
    background: none;
    text-indent: -99px;
    color: transparent;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 3px;
        height: 52px;
        background-color: #333;
        transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) scale(0.5) rotate(-45deg);
    }
}

.page_back {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 55px;
    border: none;
    background: none;
    color: transparent;
    font-size: 10px;

    &:before, 
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
    }

    //&:before{
    //    width: 42px;
    //    height: 3px;
    //    background: #333;
    //    transform: translate(-50%, -50%) scale(0.5);
    //}

    &:after {
        width: 31px;
        height: 31px;
        margin-left: -1px;
        border: 3px solid #333;
        border-width: 0 0 3px 3px;
        box-sizing: border-box;
        transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
    }
}

.page_prev {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 55px;
    border: none;
    background: none;
    color: transparent;
    font-size: 10px;

    &:before, 
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
    }

    &:before{
        width: 42px;
        height: 3px;
        background: #333;
        transform: translate(-50%, -50%) scale(0.5);
    }

    &:after {
        width: 19px;
        height: 19px;
        margin-left: -5px;
        border: 3px solid #333;
        border-width: 0 0 3px 3px;
        box-sizing: border-box;
        transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
    }
}

.page_header_fix{
    position: relative;
    height: 56px;

    .page_title,
    .page_close,
    .page_back,
    .page_prev{
        position: fixed;
        z-index: 99;
    }
}
