[data-type="Tabs_Luxury_Filter_Scroll"] {
    .c-n-chip {
        max-width: 192px;
        background: none;
        font-weight: 600;
        text-overflow: ellipsis;

        &--selected {
            border-color: $color-luxury;
        }
    }
    .s-list {
        padding: 12px;

        li {
            padding: 0 4px;
        }
    }
    .w-scroll-css {
        border-bottom: 1px solid #f4f4f4;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
