[data-type="ProductList_BestRanking"] {
    letter-spacing: 0;
    @include grid(2, 2, false);
    &.l-grid__row {
        background-color: #fff;
    }
    .l-grid__col--12 {
        width: 100%;
        float: none;
        .c-cap {
            @include size(35px 126px);
            float: left;
            display: block;
            padding-top: 50px;
            &__rank {
                position: relative;
                display: block;
                left: initial;
                top: initial;
                line-height: initial;
                width: 73%;
                font-size: 22px;
                font-style: normal;
                text-align: center;
                &-rising {
                    top: -7px;
                    left: -2px;
                    line-height: 1;
                    &:before {
                        top: 3px;
                    }
                }
            }
        }
        .b-card--recommand {
            .c-card.c-card__gallery {
                min-height: 88px;
                padding-bottom: 12px;
                border-bottom: 1px solid #f1f1f1;
                .c-card__thumb {
                    width: 126px !important;
                    height: 126px !important;
                    margin: 0;
                    float: left;
                }
                .c-prd-info {
                    padding-top: 0;
                    padding-left: 12px;
                    &__name {
                        font-size: $font-size-subtitle2;
                        color: $color-gray-02;
                        padding-top: 2px;
                    }
                    .c-prd-info__rate {
                        margin-top: 5px;
                        padding-right: 5px;
                    }
                    .c-prd-info__price {
                        margin-top: 5px;
                        min-height: 34px;
                    }
                    .c-prd-info__benefit {
                        margin-top: 5px;
                    }
                    .c-card__benefit {
                        height: 42px;
                        .benefit {
                            display: block;
                        }
                    }
                }
            }
        }
        .c-relates {
            background-color: #fff;
            border-top: 0;
            padding: 0;
            .c-relates__link-text {
                font-size: 13px;
                color: #999;
                padding-right: 15px;
                &:after {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: 50%;
                    border-color: #999;
                }
            }
        }
    }
    [data-type="GridScroll_ImgText_CtgrNavi"] + & {
        margin-top: 8px;
    }
}
