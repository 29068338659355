[data-type="ProductGrid_Luxury_Pagination"] {
    .c-product {
        overflow: hidden;

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -8px;
            margin-left: -3px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        &__item {
            width: 50%;
            padding-top: 8px;
            padding-left: 3px;
        }
    }

    .c-pagination__indicator {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .c-pagination__bullet {
            display: inline-block;
            width: 14px;
            height: 2px;
            margin-right: 3px;
            margin-left: 3px;
            border: 0;
            background-color: #111;
            border-radius: 0;
            opacity: 0.1;

            &.on {
                opacity: 1;
            }
        }
    }
}
