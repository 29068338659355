[data-type="ProductCard_Compact"] {
    // 공통요소 custom
    .l-grid__col + .l-grid__col {
        border-top: 8px solid $color-gray-11;
    }
    .c-lazyload--ratio_375x187 {
        padding-top: 49.86%;
    }
    .c-card-item--deal {
        .c-card-item__info {
            padding: 12px 16px 16px;
        }
        .c-card-item__name {
            overflow: hidden;
            display: block;
            height: 16px;
            margin: 0;
            color: $color-gray-03;
            font-family: $font-family3;
            line-height: 16px;
            white-space: normal;
        }
        .c-card-item__soldout-text {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-gray-14;
            font-size: 19px;
            font-weight: 700;
            line-height: 1;
            background-color: rgba($color-gray-01, 0.15);
        }
    }
    .c-label {
        margin-bottom: 8px;
    }
    .c-product-price,
    .c-product-summary {
        margin-top: 8px;
    }

    // 영상 영역
    .c-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 49.86%;
        background-color: $color-gray-01;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            background-color: rgba($color-gray-01, 0.1);
        }

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            object-fit: cover;
        }
        &__playtime {
            position: absolute;
            bottom: 8px;
            right: 8px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 52px;
            height: 22px;
            color: $color-gray-14;
            font-size: 13px;
            font-family: $font-family3;
            line-height: 1;
            text-align: center;
            background-color: rgba($color-gray-01, 0.5);
            border-radius: 22px;
            opacity: 1;

            &--fadeout {
                animation: fade-out 0.5s ease-out 2s 1 forwards;
            }
        }
        &__relates {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 20;
            color: $color-gray-14;
            font-size: 13px;
            line-height: 1;
            transform: translate(-50%, -50%);

            &:before {
                @include get-sprite-common("icon_relates", 60);

                content: "";
                display: block;
                margin: 0 auto 11px;
            }
        }
    }
}
