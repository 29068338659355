[data-type="ProductScroll_Card_Narrow"] {
    padding-bottom: 20px;

    * {
        font-family: $font-family3;
    }
    .c-headline {
        padding: 0 20px 8px;
        &__text {
            font-size: 14px;
            font-weight: 700;
            color: $color-11st-blue;
            line-height: (17/14);
        }

        .c-skeleton__bar {
            width: 130px;
            height: 18px;
            margin: 0;
        }
    }
    .w-scroll-css {
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .s-list {
        padding: 0 16px 8px;
        li {
            padding: 0 4px;
        }
    }
    .c-starrate {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font-size: 0;
        white-space: nowrap;
        &--amazon {
            dt {
                width: 12px;
                height: 12px;
                margin-right: 2px;
                background-size: 100% 100%;
            }
        }
        &__sati,
        &__gauge {
            width: 54px;
            height: 10px;
            background-size: auto 100%;
        }
        &__sati {
            margin-right: 2px;
        }
        &__review {
            font-size: 11px;
            line-height: 13px;
        }
    }
    .c-card-item {
        width: 200px;
        &__thumb {
            overflow: hidden;
            border-radius: 8px;
            &--amazon {
                padding: 8px;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid rgba(#002178, 0.04);
                border-radius: 8px;
                background: rgba(#002178, 0.04);
            }
        }
        &__emblem {
            @include aside-emblem;
        }
        &__emblem-icon {
            @include aside-emblem-icon;
            &--time {
                @include get-sprite-common("ic_timedeal", 14);
            }
        }
        &__emblem-text {
            @include aside-emblem-text;
        }
        &__info {
            padding: 10px 4px 0;
            .sktpass {
                overflow: hidden;
                font-size: 11px;
                line-height: 15px;
                &:before {
                    @include get-sprite-position-common("logo_sktpass", 49);
                    margin: 0 4px 0 0;
                    vertical-align: top;
                }
            }
        }
        &__name {
            display: block;
            height: 14px;
            margin-bottom: 0;
            font-size: 12px;
            line-height: (14/12);
        }
        &__price-info {
            display: flex;
            margin-top: 4px;
        }
        &__rate,
        &__price {
            flex-shrink: 0;
            height: 16px;
            line-height: 16px;
            strong {
                font-size: 13px;
                line-height: (18/13);
            }
        }
        &__rate {
            padding-right: 3px;
        }
        &__price {
            font-size: 11px;
        }
        &__benefit {
            height: 15px;
            margin-top: 4px;
            font-size: 11px;
            color: $color-gray-04;

            .shooting-delivery {
                strong {
                    @include get-sprite-position-common("shooting_delivery", 48);
                    margin: 0;
                    vertical-align: top;
                }
            }

            .shooting-install {
                strong {
                    @include get-sprite-position-common("shooting_install", 48);
                    vertical-align: top;
                }
            }

            .shooting-fresh {
                strong {
                    @include get-sprite-position-common("shooting_fresh", 48);
                    vertical-align: top;
                }
            }

            .shooting-plus {
                strong {
                    @include get-sprite-position-common("shooting_plus", 76);
                    vertical-align: top;
                }
            }

            .shooting-seller {
                strong {
                    @include get-sprite-position-common("shooting_seller", 48);
                    vertical-align: top;
                }
            }
        }
    }

    .c-skeleton {
        &__box,
        &__bar {
            margin: 0;
            background: linear-gradient(90deg, rgba(242, 245, 249, 0.02) 0%, rgba(242, 245, 249, 0.02) 0.01%, rgba(250, 252, 255, 0.6) 51.43%, rgba(246, 248, 251, 0.6) 100%, #f2f5f9 100%), #fff;
            animation: none;
        }
        &__box {
            display: block;
            width: 200px;
            padding-bottom: 50%;
            animation: none;
        }
        &__bar {
            width: 62px;
            height: 16px;
            margin: 0;
            margin-top: 4px;
            border-radius: 4px;
        }
    }
    .s-list {
        .c-skeleton {
            width: 200px;

            &__bar {
                width: 86px;

                &:last-child {
                    width: 92px;
                    height: 13px;
                }
            }
            &__box {
                width: 200px;
                height: 100px;
                padding-bottom: 0;
            }
        }
        .c-skeleton__box + .c-skeleton__bar {
            width: 100%;
            height: 28px;
            margin-top: 10px;
        }
    }
}
