@import "../../live11/sprites/sp_icon";

[data-ui-type="Contents_11live_Visual"] {
    color: #fff;
    z-index: 2 !important;
    .c-video {
        &-preview {
            height: 562px;
            overflow: hidden;

            @include mq-768 {
                height: 100vw;
                width: 100%;
            }
            width: 100%;
            video,
            img {
                height: 100%;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                min-height: 562px;
                object-fit: cover;
                @include mq-768 {
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                }
            }
        }

        &-info {
            position: absolute;
            bottom: 0;
            // z-index: 10;
            padding: 0;
            width: 100%;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 320px;
                width: 100%;
                object-fit: contain;
                background-image: linear-gradient(to bottom, rgba(17, 17, 17, 0), #111 64%);
            }
            .c-magnet {
                position: relative;
                .w-magnet {
                    width: auto;
                    background: transparent;
                }
            }
            .c-magnet.is--active {
                .c-video-info__detail { // w-magnet
                    width: 100%;
                    background-color: $color-gray-02;
                    border-bottom: 0;
                    max-width: 1280px;
                    margin: 0;
                    padding: 14px 20px 0;
                }
            }
        }
        &-play__btns {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }
        &-btn__player {
            position: absolute;
            z-index: 11;
            left: 0;
            right: 0;
            top: 0;
            bottom: 59px;
        }

        &-btn__replay {
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            top: 0;
            bottom: 211px;
            width: 100%;
            border: 0;
            background-color: transparent;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -30px 0 0 -30px;
                @include sprite-retina($sp_icon_replay);
            }
        }

        &-btn__play {
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            top: 0;
            bottom: 211px;
            width: 100%;
            border: 0;
            background-color: transparent;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -30px 0 0 -30px;
                @include sprite-retina($sp_icon_btn_play);
            }
        }

        &-info__shoppingHost {
            position: relative;
            z-index: 100;
            padding: 0 0 18px 20px;
            dt {
                @include sr-only;
            }
            dd {
                .c-profile__link {
                    border: 0;
                    background: transparent;
                    display: inline-block;
                    position: relative;
                    z-index: 9999;
                    vertical-align: middle;

                    .c-profile {
                        line-height: 0;
                    }

                    .c-profile__pic {
                        @include size(24px);

                        span {
                            padding: 0;
                            @include size(24px);
                            border: 2px solid #fff;

                            img {
                                object-fit: cover;
                            }
                        }
                    }
                    .c-profile__name {
                        height: 26px;
                        line-height: 26px;
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
        }
        &-info__title {
            position: relative;

            margin: 0 20px;
            padding: 0 0 20px;
            border-bottom: 1px solid rgba(256, 256, 256, 0.12);
            h1 {
                height: 62px;
                margin-bottom: 4px;
                text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
                font-size: 26px;
                font-weight: bold;
                line-height: 1.2;
                @include text-ellipsis-multiple(2);
            }
            h2 {
                font-size: 15px;
                opacity: 0.9;
                line-height: 1.4;
                height: 21px;
                font-weight: normal;
                @include text-ellipsis();
            }
        }
        &-info__detail {
            margin-left: 20px;
            margin-right: 20px;
            padding: 14px 0 0;
            height: 59px;
            line-height: 30px;
            border-bottom: 1px solid rgba(256, 256, 256, 0.12);
            display: flex;
            justify-content: space-between;

            .c-button {
                &--share {
                    width: 30px;
                    height: 30px;
                    border: 1px solid rgba(256, 256, 256, 0.12);
                    background: transparent;
                    position: relative;
                    color: transparent;
                    margin-right: 3px;

                    &::after {
                        content: "";
                        display: block;
                        @include sprite-retina($sp_icon_share);
                    }
                }

                &--watch__alarm {
                    padding: 0 14px 0 32px;
                    &::after {
                        display: none;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 10px;
                        width: 20px;
                        height: 20px;
                    }
                    &[aria-checked="false"] {
                        &::before {
                            @include sprite-retina($sp_icon_alarm);
                        }
                    }

                    &[aria-checked="true"] {
                        background: #333;

                        &::before {
                            @include sprite-retina($sp_icon_alarm_on);
                        }
                    }
                }

                // 알림설정 ui개선 (최종 배포 이후 수정 필요 watch__alarm2 -> watch__alarm)
                &--watch__alarm2 {
                    padding: 0 14px 0 32px;
                    &::after {
                        display: none;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 10px;
                        width: 20px;
                        height: 20px;
                    }
                    &[aria-checked="false"] {
                        background: $color-gray-14;
                        color: $color-11st-red;
                        &::before {
                            @include sprite-retina($sp_icon_alarm_red);
                        }
                    }

                    &[aria-checked="true"] {
                        background: rgba($color: $color-gray-14, $alpha: 0.1);

                        &::before {
                            @include sprite-retina($sp_icon_alarm_red2_on);
                        }
                    }
                }
            }

            p,
            div {
                flex: 1;
            }
            p {
                font-weight: bold;
                font-size: 14px;

                @include mq-320 {
                    font-size: 16px;
                }
            }
            div {
                text-align: right;
                min-width: 160px;
            }
        }
    }
    // 라이브 보러가기 버튼
    .c-button--watch {
        display: inline-block;
        height: 30px;
        padding: 0 22px 0 14px;
        border-radius: 16px;
        position: relative;
        background-image: linear-gradient(to right, #ff0000, #ff10c4);
        color: $color-gray-14;
        border: 0;
        font-weight: normal;
        vertical-align: top;
        font-size: 14px;
        line-height: 30px;

        &__logo {
            width: 30px;
            height: 18px;
            display: inline-block;
            position: relative;
            top: 4px;
        }
        .live-img {
            display: inline-block;
            position: relative;
            top: 4px;
            width: 30px;
            height: 18px;
            background: url(/MW/img/pui/ico_live_text.png) no-repeat;
            background-size: 100% 100%;
        }

        &::after {
            position: absolute;
            right: 12px;
            top: 11px;
            display: block;
            @include ico-arrow-right(6px, $color-gray-14, 1px);
            @include rotate(45deg);
        }

        // 라이브 다시보기 버튼
        &__replay {
            background: $color-gray-14;
            color: $color-11st-red;
            span {
                margin-right: 3px;
                font-weight: bold;
            }
            &:after {
                border-color: $color-11st-red;
            }
        }
    }
}
