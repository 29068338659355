.ooah-pick {
    @mixin positionReset {
        position: relative;
        bottom: auto;
        right: auto;
    }
    .c-button-wrap {
        position: fixed;
        z-index: 100;
        right: 12px;
        bottom: calc(16px + env(safe-area-inset-bottom));
        @at-root .webview-ios#{&} {
            bottom: 50px;
        }
    }
    .c-like-button,
    .c-goback,
    .c-gotop {
        $size: 48px;
        z-index: 100;
        display: block;
        width: $size;
        height: $size;
        border: 0;
        border-radius: 24px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(16, 0, 0, 0.14);
        background: $color-gray-14;
        color: transparent;
        opacity: 0.98;

        &:before {
            @include get-sprite-luxury("ic-gnb-back", 36);
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
    .c-goback {
        position: fixed;
        left: 12px;
        bottom: calc(16px + env(safe-area-inset-bottom));
        &:before {
            transform: translate(-50%, -50%);
        }
    }
    .c-gotop {
        @include positionReset;
        &:before {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }
    .c-like-button {
        @include positionReset;
        margin-top: 12px;
        &:before {
            content: none;
        }
    }

    .c-gomore {
        $size: 48px;
        position: relative;
        width: 48px;
        height: 96px;
        margin-top: 9px;
        border-radius: 24px;
        background-color: rgba(0, 0, 0, 0.2);
        border: solid 0.5px rgba(255, 255, 255, 0.1);
        -webkit-backdrop-filter: blur(24px);
        backdrop-filter: blur(24px);
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(16, 0, 0, 0.14);
        box-sizing: border-box;
        transition: all 0.133s ease-in;

        &.c-gomore--hidden {
            height: $size;
            background-color: rgba(0, 0, 0, 0);
            .c-goluxe {
                opacity: 0;
            }
        }

        button {
            position: absolute;
            left: -1px;
            width: 48px;
            height: 48px;
            border-radius: 24px;
            border: 0;
            background-color: transparent;
            font-family: $font-outfit;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            line-height: 49px;
            color: $color-gray-14;
        }
        .c-goluxe {
            top: -1px;
            z-index: 101;
            transition: all 0.1s ease-in;
        }
        .c-goooah {
            left: -0.5px;
            bottom: -0.5px;
            z-index: 102;
            border-radius: 24px;
            background-color: #0f0fd9;
            line-height: 48px;
        }
        @at-root .brand-pick#{&} {
            //브랜드픽에서는 미사용
            display: none;
        }
    }

    .c-view-all {
        position: relative;
        margin-top: 12px;
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            aspect-ratio: 1/1;
            background-color: $color-gray-14;
            border-radius: 100%;
        }
        &__tooltip {
            $radius: 14px;

            position: absolute;
            top: 50%;
            right: 53px; // 버튼 넓이 48 + 마진 5
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            padding: 0 19px 0 12px;
            background-color: rgba(15, 15, 217, 0.8);
            transform: translateY(-50%);
            font-size: 12px;
            font-weight: 400;
            color: $color-gray-14;
            white-space: nowrap;
            text-align: right;

            mask-image:
                // 아래 코드에서 0.5 는 안티앨리어싱 효과를 주기 위해
                radial-gradient(#fff ($radius - 0.5), #fff0 $radius),
                // 위 왼쪽 모서리
                radial-gradient(#fff ($radius - 0.5), #fff0 $radius),
                // 위 오른쪽 모서리
                radial-gradient(#fff ($radius - 0.5), #fff0 $radius),
                // 아래 왼쪽 모서리
                radial-gradient(#fff ($radius - 0.5), #fff0 $radius),
                // 아래 오른쪽 모서리
                linear-gradient(#fff, #fff),
                // 수평 영역
                linear-gradient(#fff, #fff),
                // 수직 영역
                url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10L5.61875 5.7543C5.68127 5.70705 5.73769 5.65279 5.78681 5.59267C6.12717 5.17608 6.05192 4.57303 5.61875 4.24571L0 0V10Z' fill='%23000000' fill-opacity='1'/%3E%3C/svg%3E");

            mask-position: 0 0,
                // 위 왼쪽 모서리
                calc(100% - 6px) 0,
                // 위 오른쪽 모서리
                0 100%,
                // 아래 왼쪽 모서리
                calc(100% - 6px) 100%,
                // 아래 오른쪽 모서리
                0 $radius,
                // 수평 영역
                $radius 0,
                // 수직 영역
                calc(100% - 1px) 50%; // 꼬리 -1 은 100% 경우 랜더링 시 끝 부분이 잘림
            mask-size: ($radius * 2) ($radius * 2),
                // 위 왼쪽 모서리
                ($radius * 2) ($radius * 2),
                // 위 오른쪽 모서리
                ($radius * 2) ($radius * 2),
                // 아래 왼쪽 모서리
                ($radius * 2) ($radius * 2),
                // 아래 오른쪽 모서리
                calc(100% - 6px) calc(100% - #{$radius * 2}),
                // 수평 영역 넓이 - 꼬리 들어갈 영역 만큼 뺌, calc(100% - #{$radius * 2})높이는 적당히 줄여서  수직 영역으로 계산 하도록
                calc(100% - #{$radius * 2} - 6px) 100%,
                // 수직 영역
                6px 10px; // 꼬리
            mask-repeat: no-repeat;
        }
    }
}
