[data-type="ProductList_Live11"] {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    padding: 0 16px 8px;
    vertical-align: top;

    .l-grid__col {
        margin-top: 12px;
        &:first-child {
            margin-top: 0;
        }
    }

    .c-card-item {
        @include rounding-box;
        table-layout: fixed;
        z-index: 1;
        padding: 0;
        &__thumb {
            height: 180px;
            width: 120px;
            // default dim
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $color-gray-01;
                opacity: 0.1;
            }

            a {
                position: relative;
                display: block;
                width: 120px;
                height: 100%;
                line-height: 0;
                @include mq-360 {
                    width: auto;
                }
            }

            .c-lazyload {
                height: 100%;
            }

            .c-video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                &--hide {
                    opacity: 0;
                }
                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__thumb-cover {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            padding: 10px 0 0 20px;
            height: 100%;
            line-height: 1.2;
        }

        &__badge-11live {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 35px;
            height: 18px;
        }

        .live-img {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 35px;
            height: 18px;
            background: url(/MW/img/pui/ico_live_round.png) no-repeat;
            background-size: 100% 100%;
        }

        &__status {
            display: flex;
            justify-content: space-between;
            strong {
                @include text-ellipsis;
                display: block;
                margin-right: 6px;
                font-size: 14px;
                color: $color-11st-red;
            }
            .watcher {
                font-size: 13px;
                color: $color-gray-05;
                flex-shrink: 0;
            }
            .status {
                font-weight: normal;
            }
        }

        &__info {
            padding: 12px 12px 12px 14px;
            .c-profile {
                padding-right: 0;
                &__link {
                    display: inline-flex;
                    align-items: center;
                    max-width: 100%;
                    vertical-align: top;
                    font-size: 0;
                }
                &__pic {
                    flex-shrink: 0;
                    float: none;
                    margin-right: 4px;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 11;
                        border: 1px solid rgba(0, 0, 0, 0.04);
                        border-radius: 50%;
                        content: "";
                    }
                }
                &__pic,
                &__pic span {
                    @include size(20px);
                }

                &__pic img {
                    @include size(20px);
                    border-radius: 50%;
                }

                &__name {
                    flex: 1;
                    height: 15px;
                    line-height: normal;
                    font-size: 14px;
                    color: $color-gray-04;
                }
                .c-lazyload {
                    &::before {
                        background: none;
                    }
                }
            }
        }

        &__flex-row {
            display: flex;
            margin-top: 10px;
        }
        &__name {
            display: block;
            flex: 1;
            height: auto;
            max-height: none;
            margin: 0;
            font-size: 17px;
            color: $color-gray-02;
            font-weight: bold;
            dl {
                overflow: hidden;
                max-height: 40px;
                margin-bottom: 8px;
            }
            dd {
                line-height: 20px;
            }
            .keyword {
                background-color: transparent;
                color: $color-11st-blue;
            }
        }

        &__mini-card {
            font-size: 14px;
            position: absolute;
            bottom: 0;
            right: 14px;
            left: 14px;
            display: flex;
            padding: 12px 0;
            border-top: 1px solid $color-gray-12;
            overflow: hidden;

            &-thumb {
                @include size(36px);
                position: relative;
                margin-right: 8px;
                min-width: 36px;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 4px;
                    content: "";
                }
                .c-lazyload {
                    &:before {
                        background-size: 36px auto;
                    }
                }
                img {
                    border-radius: 4px;
                }
            }
            a {
                flex: 0 0 1;
            }

            dl {
                display: flex;
                flex-direction: column;
                height: 36px;
                align-content: center;
                justify-content: center;
                .point {
                    color: $color-11st-blue;
                }
                dt {
                    color: $color-gray-03;
                }
                dd {
                    line-height: 15px;
                    font-size: 13px;
                    color: $color-gray-02;
                    height: 15px;
                    vertical-align: top;
                    overflow: hidden;
                    span {
                        font-size: 13px;
                        font-weight: bold;
                    }
                    em {
                        font-size: 13px;
                    }
                    &:nth-child(4) {
                        position: relative;
                        top: 3px;
                        font-size: 11px;
                        span {
                            float: left;
                        }
                    }
                }
            }
        }

        &--vod {
            .c-card-item {
                &__thumb {
                    &:after {
                        opacity: 0.4;
                    }
                }
                &__status {
                    strong {
                        color: $color-gray-03;
                    }
                }
            }
        }

        &--end {
            .c-card-item {
                &__status {
                    strong {
                        color: $color-gray-03;
                    }
                }
            }
        }

        &--ready {
            .c-card-item {
                &__thumb {
                    color: $color-gray-14;
                    font-size: 13px;
                    &:after {
                        opacity: 0.4;
                    }
                    .live_time {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 100%;
                        color: $color-gray-14;
                        font-size: 14px;
                        text-align: center;
                        font-weight: bold;
                        .time {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }
    // 라이브11 편성표, 검색결과 내 임시 간격 적용
    // [H] 라이브11 2.0 개선 시 ProductList_Live11 블럭 개선 필요함.
    // as-is : swiper구조 > ProductList_Live11
    // to-be : ProductList_Live11 > swiper 구조 + swiper 없는 케이스
    #carrier_8279 &,
    [data-type="Tabs_TimeTable"] ~ &,
    &:only-child {
        padding-top: 16px;
    }
}
