[data-type="Cap_Sub_Title"] {
    letter-spacing: 0;
    .c-headline {
        &__title {
            line-height: 34px;
            padding: 14px 16px 11px 16px;
        }
        &__subtext {
            font-size: 14px;
            color: $color-gray-04;
            padding-bottom: 18px;
        }
        .c-chip {
            position: absolute;
            right: 18px;
            top: 22px;
            padding-right: 23px;
            margin: 0;
            color: $color-gray-03;
            &:after {
                @include ico-arrow-right(7px, #999, 1px);
                display: block;
                position: absolute;
                right: 12px;
                top: 12px;
            }
        }
    }
}
