@charset "UTF-8";

.oneday {
    #cts .l-grid:first-child {
        overflow: visible;
    }

    .l-grid:first-child {
        .l-grid__row:first-child {
            .c-headline__title {
                height: 56px;
                line-height: 36px;
                font-size: 18px;
                color: #000;
                strong {
                    color: $color-11st-red;
                }

                &.c-headline__key {
                    strong {
                        color: $color-11st-blue;
                    }
                }
            }
        }
    }

    // category sub 보더
    [data-type="GridScroll_ImgText_CtgrNavi"] {
        z-index: 2;
        .category-w-scroll {
            border-bottom: 1px solid #f4f4f4;
        }
    }

    .header-fixed [data-type="GridScroll_ImgText_CtgrNavi"] .c-magnet--fixed.is--active .w-magnet {
        top: 57px !important;
    }

    [data-type="GridScroll_ImgText_CtgrNavi"] + [data-type="ProductGrid_Mart"],
    [data-type="Information_Product_Empty"] + [data-type="ProductGrid_Mart"] {
        min-height: 550px;
    }

    // 안드로이드 4.4 대응
    .v4_4 .backType {
        .pop_atype {
            position: absolute !important;
            top: 252px !important;
        }
    }

    // 마그넷 2개 이상 처리
    [data-type="Tabs_Mart"] .c-magnet-multiple--fixed,
    [data-type="Tabs_Mart_Scroll"] .c-magnet-multiple--fixed,
    [data-type="GridScroll_ImgText_CtgrNavi"] .c-magnet-multiple--fixed {
        position: fixed;
        width: 100%;
        z-index: 10;
    }

    [data-type="Tabs_Mart"] .c-magnet-multiple--absolute,
    [data-type="Tabs_Mart_Scroll"] .c-magnet-multiple--absolute,
    [data-type="GridScroll_ImgText_CtgrNavi"] .c-magnet-multiple--absolute {
        position: absolute;
    }

    // 카테고리 sub 더보기 버튼 스크롤 관련 chrome bug fix
    body.grayType {
        overflow-anchor: none;
    }

    // 아이폰5 대응
    [data-type="FlatButton_Mart_Delivery"] .c-location__no-info {
        width: 105px;
        overflow: hidden;
        height: 22px;
        vertical-align: top;
        line-height: 22px;
        top: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq-360 {
            width: auto;
            overflow: visible;
            height: auto;
            vertical-align: top;
            line-height: inherit;
            top: inherit;
            top: 0;
            text-overflow: inherit;
            white-space: normal;
        }
    }

    [data-type="LineBanner_h80"] {
        .c-banner__link {
            .c-banner__img {
                transform: translate(-50%, -50%);
            }
        }
    }

    [data-type="GridList_Text_Ctg_Col3"] {
        .c-gridtext {
            margin-bottom: 0;
        }
    }
}

// 배송지 설정
[data-ui-type="PageTitle"] {
    height: 60px;
}

[data-ui-type="Content_Dimm_Text"] {
    p {
        margin: 0 16px;
        padding: 16px 0 24px;
        border-top: 1px solid #f4f4f4;
        color: #999;
        font-size: 13px;
    }
}

.delivery_setting_modal {
    display: none;
    overflow: hidden;
    left: 50%;
    position: absolute;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.15);
    margin-top: -75px;
    top: 260px;
    width: 296px;
    border-radius: 8px;
    background: #fff;
    z-index: 102;
    .pop_cmt {
        padding: 32px 16px;
        text-align: center;
        font-size: 15px;
        line-height: 1.5;
    }
}

[data-type="GridScroll_ImgText_CtgrNavi"] {
    z-index: 2;
    .category-w-scroll {
        border-bottom: 1px solid #f4f4f4;
    }
}

.header-fixed [data-type="GridScroll_ImgText_CtgrNavi"] .c-magnet--fixed.is--active .w-magnet {
    top: 57px !important;
}

[data-type="GridScroll_ImgText_CtgrNavi"] + [data-type="ProductGrid_Mart"],
[data-type="Information_Product_Empty"] + [data-type="ProductGrid_Mart"] {
    min-height: 550px;
}
