[data-type="ContentsGrid_Gallery"] {
    .c-gallery {
        overflow: hidden;
        margin: 0 16px;
        border-radius: 8px;

        &__inner {
            overflow: hidden;
            position: relative;
            z-index: 5;
            padding-top: 100%;
            border-radius: 4px;
        }
        &__grid {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(3) {
                order: 4;
            }
            &:nth-child(4) {
                order: 5;
            }
            &:nth-child(5) {
                order: 2;
            }

            &--1x2 {
                grid-row: span 2;

                .c-gallery__inner,
                .c-lazyload {
                    padding-top: calc(200% + 3px);
                }
            }
        }
        &__wrap {
            overflow: hidden;
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 3px;
            row-gap: 3px;
            margin-top: 3px;

            &:first-child {
                margin-top: 0;
            }

            &--type2 {
                .c-gallery__grid {
                    &:nth-child(1) {
                        order: 2;
                    }
                    &:nth-child(2) {
                        order: 3;
                    }
                    &:nth-child(3) {
                        order: 4;
                    }
                    &:nth-child(4) {
                        order: 5;
                    }
                    &:nth-child(5) {
                        order: 1;
                    }
                }
            }
            &--type3 {
                .c-gallery__grid {
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                    }
                    &:nth-child(3) {
                        order: 3;
                    }
                    &:nth-child(4) {
                        order: 5;
                    }
                    &:nth-child(5) {
                        order: 4;
                    }
                }
            }
            &--type4 {
                .c-gallery__grid {
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                    }
                    &:nth-child(3) {
                        order: 4;
                    }
                    &:nth-child(4) {
                        order: 5;
                    }
                    &:nth-child(5) {
                        order: 3;
                    }
                }
            }
        }
    } // .c-gallery

    .c-product {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-gray-14;

        &__link {
            display: block;
            height: 100%;
        }
        &__info {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 20;
            width: 100%;
            padding: 24px 8px 9px;
            color: $color-gray-14;
            font-size: 14px;
            font-weight: 700;
            font-family: $font-family3;
            line-height: 17px;
        }
        &__more {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            padding: 16px 8px 9px;
            color: $color-gray-14;
            font-size: 14px;
            font-weight: 700;
            font-family: $font-family3;
            line-height: 17px;

            &:after {
                content: "";
                display: block;
                width: 8px;
                height: 14px;
                background: url("/MW/img/pui/ic_chevron_right_white.png") no-repeat 0 0;
                background-size: 100% auto;
            }

            .c-product__title {
                padding-right: 30px;
            }
        }
        &__title {
            overflow: hidden;
            display: block;
            width: 100%;
            height: 17px;
            margin-top: 6px;
            font-family: $font-family3;
        }
        &__price {
            display: flex;
            align-items: center;
        }
        &__rate {
            float: left;
            padding-right: 4px;
        }
        &__badge {
            overflow: hidden;
            display: inline-block;
            align-items: center;
            height: 20px;
            padding: 0 5px;
            color: $color-gray-01;
            font-size: 11px;
            font-weight: 700;
            line-height: 20px;
            background-color: #deff58;
            vertical-align: top;
            border-radius: 4px;
        }

        &--gradient {
            .c-product__info,
            .c-product__more {
                background: linear-gradient(180deg, rgba($color-gray-01, 0) 0%, rgba($color-gray-01, 0.5) 100%);
            }
        }
    } // .c-product

    .c-player {
        position: relative;
        width: 100%;
        height: calc(100% + 1px); // 영상 영역 하단에서 컨텐츠가 비쳐 보이는 이슈 수정

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__info {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 20;
            display: flex;
            align-items: center;
            height: 20px;
            padding: 0 6px;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }
        &__time {
            color: $color-gray-14;
            font-size: 10px;
            font-weight: 700;
            font-family: $font-family3;
            line-height: 1em;
        }
    } // .c-player

    .c-lazyload {
        position: static;
        padding-top: 100%;

        img {
            object-fit: cover;
        }
    }
}
